import React from "react";

/**
 * 
 * @param {{isSmall:boolean, style:object}} props
 */
const LoadSpinner = (props) => {
    return (
        <div className={props.className || "container h-100"} style={props.style || {} }>
            <div className={`row h-100 ${props.innerClassName ? props.innerClassName : "justify-content-center align-items-center pt-2"}`} style={{ marginTop: props.noPaddingTop ? 0 : "" }}>
                <div className={`spinner-border ${props.isSmall === true ? "spinner-border-sm":""}`} role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        </div>
        )
}

export default LoadSpinner;