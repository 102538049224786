import React from "react"
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import Feed from "components/Feed/Feed";
import FollowSuggestions from "components/FollowSuggestions";
import UserTileSmall from "components/UserTileSmall";
import ProfileInfoCardHomeView from "components/ProfileInfoCardHomeView";
import FeedFilterBar from "components/FeedFilterBar";
import LoadSpinner from "components/LoadSpinner";
import UserTileGeneral from "components/UserTileGeneral";

import { toggleAddProductModal, toggleCreateLockerModal, toggleFollowersFollowingModal, toggleFetchFollowing } from "stateManagement/reducers/modalReducer";

import blankFeedPost from "../../sharedUtils/blankFeedPost";
import { fetchUserSuggestions, blankUserSuggestion } from "sharedUtils/userSuggestion";
import { postRequest, getRequest } from "sharedUtils/httpUtils";
import { DateTime, Interval } from "luxon";
import AddToHomescreenIOS from "components/AddToHomescreenIOS";
import { Helmet } from 'react-helmet';

import ShareBlock from "components/Share/ShareBlock";
import MerchantShareBlock from "components/Share/MerchantShareBlock";
import { toggleProductPageModal, toggleShareModal } from "stateManagement/reducers/modalReducer";
import { getMerchantShareLink } from "../../sharedUtils/shareLinkPrefixes"
import Modal from "../../components/Modal";

class Home extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            posts: [blankFeedPost],
            loading: true,
            whoToFollow: [blankUserSuggestion],
            finishedFetchingSuggestions: false,
            showFollowSuggestions: false,
            isGrid: true,
            finishedFetching: false,
            isIOS: false,
            fetchFollowing: props.fetchFollowing,
            modalOpen: false,
            shareLink: "",
        }
    }

    async componentDidMount() {
        this.props.setShowTopNav(true);

        await this.mount();

        let isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        let isIosDevice = /iPhone|iPad|iPod/i.test(navigator.userAgent);

        if (isMobileDevice) {
            this.setState({ isIOS: isIosDevice });
            this.handleInstallPrompt()
        }
    }

    mount = async () => {
        let userData = await this.fetchLoggedInUser();
        await this.props.setLoggedInUser(userData);

        let suggestions = await this.fetchSuggestions(this.props.loggedInUser.id, 0, 5);
        let posts = await this.populateData(this.props.loggedInUser.id, 0, 24);

        this.setState({
            loading: false,
            posts: posts,
            whoToFollow: suggestions,
            showFollowSuggestions: suggestions?.length > 0,
            finishedFetching: posts.length === 0,
            isGrid: posts.length !== 0
        });
    }

    async componentDidUpdate(prevProps) {
        if (this.props.fetchFollowing !== prevProps.fetchFollowing) {
            this.setState({ fetchFollowing: this.props.fetchFollowing });

            let userData = await this.fetchLoggedInUser();
            await this.props.setLoggedInUser(userData);

            let suggestions = await this.fetchSuggestions(this.props.loggedInUser.id, 0, 5);
            this.setState({
                loading: false,
                whoToFollow: suggestions,
                showFollowSuggestions: suggestions?.length > 0,
            });

            if (this.props.fetchFollowing) this.props.toggleFetchFollowing();
        }
    }
    
    displayInstallPrompt = () => {
        if (this.state.showInstallPrompt === true && this.state.loading == false) {
            return <AddToHomescreenIOS isIOS={this.state.isIOS} showPrompt={this.state.showInstallPrompt} closePrompt={this.handleCloseInstallPrompt} />
        }
        else {
            return <span></span>
        }
    }

    fetchLoggedInUser = async () => {
        let url = `/api/account/getProfile?userName=${this.props.loggedInUser?.userName}`;
        let data = await getRequest(url);
        return data;
    }

    fetchSuggestions = async (userId, skip, take) => {
        let suggestions = await fetchUserSuggestions(userId, skip, take);
        if (suggestions === undefined || suggestions === null) {
            return []
        }
        else {
            return suggestions;
        }
    }

    fetchMoreSuggestions = async () => {
        let { whoToFollow } = this.state;

        let suggestions = await this.fetchSuggestions(this.props.loggedInUser.id, whoToFollow.filter((suggestion) => { return !suggestion.isFollowing }).length, 24);
        let finishedFetching;
        if (suggestions === undefined || suggestions?.length < 24) {
            finishedFetching = true;
        } else {
            finishedFetching = false;
        }
        let updated = [...whoToFollow, ...suggestions];
        this.setState({ whoToFollow: updated, finishedFetchingSuggestions: finishedFetching });
    }

    populateData = async (userId, skip, take) => {
        let url = `api/HomeFeed/Get?userId=${userId}&skip=${skip}&take=${take}`;
        let data = await getRequest(url);

        if (data === undefined) {
            return [];
        }
        else {
            return data;
        }
    }

    fetchMore = async () => {
        let userId = this.props.loggedInUser.id
        let url = `api/HomeFeed/Get?userId=${userId}&skip=${this.state.posts.length}&take=${24}`;
        let data = await getRequest(url);
        if (data === undefined || data === null || data?.length === 0) {
            this.setState({ finishedFetching: true })
        }
        else {
            let newData = [...this.state.posts, ...data];
            this.setState({ posts: newData });
        }
    }

    followSuggestion = async (userId, location) => {

        let users = [...this.state.whoToFollow];
        let action = users[location].user.isFollowing === false ? "follow" : "unfollow";
        users[location].user.isFollowing = !this.state.whoToFollow[location].user.isFollowing;
        users[location].isFollowing = !this.state.whoToFollow[location].isFollowing;
        this.setState({ whoToFollow: users });

        let url = action === "follow" ? `api/Account/Follow?id=${userId}` : `api/Account/Unfollow?id=${userId}`;

        await getRequest(url);

        let userData = await this.fetchLoggedInUser();
        await this.props.setLoggedInUser(userData);
    }

    handleCloseInstallPrompt = () => {
        //const installPromptKey = "rockporch.InstallPrompt";
        //const installPrompt = JSON.parse(localStorage.getItem(installPromptKey));

        //let updatedPrompt = { time: DateTime.now(), optOut: true };
        //localStorage.setItem("rockporch.InstallPrompt", JSON.stringify(updatedPrompt));

        this.setState({ showInstallPrompt: false, deferredPrompt: null });
    }

    handleInstallPrompt = () => {
        const today = DateTime.now();
        const installPromptKey = "rockporch.InstallPrompt";
        const installPrompt = JSON.parse(localStorage.getItem(installPromptKey));

        const lastPromptTime = installPrompt?.time || null;
        const optOut = installPrompt?.optOut !== undefined ? installPrompt.optOut : false;
        const promptCount = installPrompt?.count || null;

        if (promptCount && promptCount == 4) {
            let updatedPrompt = { time: today, optOut: true, count: 5 };
            localStorage.setItem(installPromptKey, JSON.stringify(updatedPrompt));
            this.setState({ showInstallPrompt: false });
            return;
        }

        if (optOut === false) {

            if (lastPromptTime) {

                const duration = Interval.fromDateTimes(DateTime.fromISO(lastPromptTime), today);
                const countForward = promptCount + 1;

                const intervalDays = duration.length("days");

                let correctInterval = countForward == 2 && intervalDays >= 5 || countForward === 3 && intervalDays >= 10 || countForward === 4 && intervalDays >= 15

                const shouldPrompt = (lastPromptTime != null && correctInterval);

                if (shouldPrompt && "localStorage" in window) {
                    let updatedPrompt = { time: today, optOut: false, count: promptCount + 1 };

                    localStorage.setItem(installPromptKey, JSON.stringify(updatedPrompt));

                    this.setState({ showInstallPrompt: true });
                }
            }
            //first time showing prompt 
            else {
                if (this.props.loggedInUser.imageUrl != null) {
                    let updatedPrompt = { time: today, optOut: false, count: 1 };
                    localStorage.setItem(installPromptKey, JSON.stringify(updatedPrompt));

                    this.setState({ showInstallPrompt: true });
                }
            }
        }

    }

    renderFollowSuggestions = () => {
        if (this.state.loading === true) {
            return <LoadSpinner />
        }
        else if (this.state.whoToFollow && this.state.whoToFollow?.length > 0) {

            return this.state.whoToFollow.map((suggestion, i) => {
                return (
                    <li key={i} className="d-flex mt-3 mb-0">
                        <UserTileGeneral
                            user={suggestion.user}
                            subtitle={" "}
                            showCheckMark={false}
                            truncateUsername={false}
                            useLargeAvatars={true}
                        />
                        <button className="btn btn-sm follow-btn" style={{ height: "30px", fontSize: "10px", lineHeight: "1rem" }}
                            onClick={() => this.followSuggestion(suggestion.user.id, i)}
                        >
                            {suggestion.user.isFollowing === false ? "+" : "-"}
                        </button>
                    </li>
                )
            })
        }
    }


    toggleColumns = () => {
        let newColumnState = !this.state.isGrid;
        this.setState({ isGrid: newColumnState });
    }

    updatePosts = (newPosts) => {
        this.setState({ posts: newPosts })
    }

    /**
     * 
     * @param {string} followersFollowing
     */
    showFollowersFollowing = (followersFollowing) => {
        this.props.toggleFollowersFollowingModal(this.props.loggedInUser.id, followersFollowing, null);
    }



    toggleProductShareModal = async (urlFragment) => {
        let showModal = !this.state.modalOpen;
        let shareLink = await getMerchantShareLink(urlFragment);

        if (showModal) {
            this.setState({ modalOpen: showModal, shareLink });
        }
        else {
            this.setState({ modalOpen: showModal, shareLink: "" });
        }
    }


    displayShareModal = () => {
        let shareProps = {
            shareCode: this.state.shareLink,
            title: "Merchant share",
            customStyle: { maxWidth: "100%", backgroundColor: "white" },
            shareBlockStyle: { border: "none", backgroundColor: "transparent" },
            loggedInUser: this.props.loggedInUser,
            type: "merchant",
        }
        return (
            <Modal isOpen={this.state.modalOpen} toggleModal={this.toggleProductShareModal}>
                <ShareBlock {...shareProps}>
                    <MerchantShareBlock
                        {...shareProps}
                    />
                </ShareBlock>
            </Modal>
        )
    }

    render() {
        let { deviceSize } = this.props;
        let { isGrid, showFollowSuggestions } = this.state;
        if (this.state.loading === true) {
            return (
                <>
                    < LoadSpinner />
                    <Helmet>
                        <title>RockPorch - Home Feed</title>
                    </Helmet>
                </>
            )
}
        return (
            <div className="container-fluid gray-bg full-height explore-feed-wrapper px-0"
                style={
                    deviceSize === "sm" ? { paddingLeft: "1px", paddingRight: "1px" } : {}
                }
            >
                <Helmet>
                    <title>RockPorch - Home Feed</title>
                </Helmet>
                {this.displayShareModal()}

                {isGrid === true ? <FeedFilterBar toggleAction={this.toggleColumns} isGrid={isGrid} className={`home-filter-bar ${deviceSize === "sm" || deviceSize === "md" ? "mt-0" : ""}`} /> : null}
                <div className="container" style={{ margin: "auto" }}>
                    <div className="row mt-0 mx-0">
                        {
                            isGrid === true ? null : (
                                <div className="col-md-3 user-profile top-section-child ">
                                    <div className="mt-0 row" style={{ position: "sticky", top: "100px" }}>
                                        <ProfileInfoCardHomeView
                                            firstNameLastName={this.props.loggedInUser?.name}
                                            userName={this.props.loggedInUser?.userName}
                                            profileBio={this.props.loggedInUser?.tagLine}
                                            profilePictureUrl={this.props.loggedInUser?.imageUrl ? this.props.loggedInUser.imageUrl : "/images/avatar2.svg"}
                                            followers={this.props.loggedInUser?.followers}
                                            following={this.props.loggedInUser?.following}
                                            showFollowersFollowing={this.showFollowersFollowing}
                                            user={this.props.loggedInUser}
                                        />
                                    </div>
                                </div>
                            )
                        }



                        <div className={`${isGrid === true || deviceSize === "md" ? "col-12" : "col-md-6"} px-0 px-md-5 top-section-child`} >
                            {isGrid === false ?
                                <div className={`mt-0 row ${deviceSize === "sm" || deviceSize === "md" ? "mt-0" : ""}`}>
                                    <div className="col-12 mb-0 mx-auto activity-feed-wrapper px-0 px-md-4 px-lg-0"
                                        style={{ marginBottom: "0rem !important" }}
                                    >
                                        <FeedFilterBar toggleAction={this.toggleColumns} isGrid={isGrid} className={`home-filter-bar ${deviceSize === "sm" || deviceSize === "md" ? "mt-0" : ""}`} />
                                    </div>
                                </div> : null
                            }

                            <div className={`${deviceSize === "sm" || deviceSize === "md" ? "mt-1" : ""} home-feed-wrapper row grid`}>
                                <div style={{ width: "100%" }}>
                                    <Feed
                                        posts={this.state.posts}
                                        isGrid={this.state.isGrid}
                                        loggedInUser={this.props.loggedInUser}
                                        fetchMore={this.fetchMore}
                                        finishedFetching={this.state.finishedFetching}
                                        showFollowSuggestions={this.state.showFollowSuggestions || this.state.posts.length === 0}
                                        deviceSize={this.props.deviceSize}
                                        updateParent={this.updatePosts}
                                        showUserTile={true}
                                        screenWidth={this.props.screenWidth}
                                        toggleProductShareModal={this.toggleProductShareModal}
                                    />
                                </div>

                            </div>
                            {this.displayInstallPrompt()}
                        </div>

                        {
                            isGrid === true ? null : (
                                <div className="col-md-3 user-profile top-section-child px-0" style={{ width: "70% !important" }}>
                                    <div className="mt-0 row" style={{ position: "sticky", top: "100px" }}>
                                        {showFollowSuggestions ?
                                            <FollowSuggestions>
                                                {this.renderFollowSuggestions()}

                                                {
                                                    this.state.finishedFetchingSuggestions === false ? (
                                                        <Link to="#" onClick={this.fetchMoreSuggestions}>
                                                            See more
                                                        </Link>
                                                    ) : <span></span>
                                                }

                                            </FollowSuggestions>
                                            : <></>}
                                    </div>
                                </div>
                            )
                        }

                    </div>
                </div>
            </div>
        )
    }


}

function mapStateToProps(storeState, componentProps) {
    let result = { feedLayout: storeState.feedLayoutReducer, fetchFollowing: storeState.modalReducer.fetchFollowing }
    return result;
}

export default connect(mapStateToProps, { toggleAddProductModal, toggleCreateLockerModal, toggleFollowersFollowingModal, toggleFetchFollowing, toggleShareModal })(Home);