import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';

import GridIconAlternate from "components/icons/GridIconAlternate";
import DoubleBarIcon from "components/icons/DoubleBarIcon";

/**
 * 
 * @param {{ isGrid:boolean, toggleAction:Function, fetchResults:Function, hideToggleAction:boolean, selectedCase:Number,style:object}} props 
 */
const BrowseBar = (props) => {
    const [categories, setCategories] = useState([{ id: 0, name: "All" }]);
    const [breadCrumbCategories, setBreadCrumbCategories] = useState([{ id: 0, name: "All" }]);

    const [filterOpen, setFilterOpen] = React.useState(false);

    const filterRef = React.useRef();


    useEffect(() => {
        setCategories([...categories, ...props.categories])
    }, [props.categories])

    useEffect(() => {
        if (categories.length > 1) {
            const selectedCategory = props.category;
            let categoryArray = [];
            let currentCategory = categories.find(category => category.id === selectedCategory);
            while (!!(currentCategory)) {
                categoryArray.unshift(currentCategory);
                if (!!(currentCategory.parentCategoryId)) {
                    currentCategory = categories.find(category => category.id === currentCategory.parentCategoryId);
                } else {
                    currentCategory = null;
                }
            }
            setBreadCrumbCategories(categoryArray);
        } else {
            setBreadCrumbCategories([]);
        }
    }, [categories, props.category])

    useEffect(() => {
        props.setFilterOpen(filterOpen);
    }, [filterOpen]);

    useEffect(() => {
        setFilterOpen(props.filterOpen);
    }, [props.filterOpen]);

    useEffect(() => {
        if (!!(filterRef.current)) {    
            setFilterOpen(false);
            filterRef.current.classList.remove('show');
            props.resetCloseFilter();
        }
    }, [props.closeFilter])


    let renderToggleIcon = () => {
        let icon = props.isGrid === true ? <GridIconAlternate /> : <DoubleBarIcon />
        let iconStyle = {};
        if (props.hideToggleAction !== true) {
            return (
                <button className="browse-filter-toggle-btn" onClick={props.toggleAction} style={iconStyle}>
                    {icon}
                </button>
            )
        }
    }

    let renderFilterIcon = () => {
        if (["sm", "md"].includes(props.deviceSize)) {
            return (
                <button className="browse-filter-btn" data-toggle="collapse" aria-controls="toggleFilter" aria-expanded="false" aria-label="Toggle filter" style={{ fontSize: "14px", paddingRight: "10px", fontFamily: "'Archivo', sans-serif" }} >
                    Filter
                </button>
            )
        }
    }

    let searchIconStyle = { paddingBottom: "3px", border: "none", backgroundColor: "transparent", marginLeft: "10px" };
    searchIconStyle.bottom = "6px";
    searchIconStyle.left = "-4px";

    const uuidv4 = () => {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    };

    const handleBreadCrumbs = (level) => {
        let url = "/Browse?category=" + encodeURIComponent(breadCrumbCategories[0].name);

        if (level === 2) {
            if (props.category > 0) {

                url += "&subcategory=" + encodeURIComponent(breadCrumbCategories[1].name);
            }
        }

        url += "&i=" + uuidv4();
        //breadCrumbIncrement = breadCrumbIncrement + 1;

        return url;
    }

    const mapBreadCrumbCategories = () => {
        return (
            <>
                {breadCrumbCategories.map((category, index) => (
                    <React.Fragment key={category.id}>
                        {index > 0 && <span>{' > '}</span>}
                        {index === breadCrumbCategories.length - 1 ? (
                            <Link
                                to="#"
                                style={{ color: '#000', cursor: 'default' }}
                                onClick={(e) => e.preventDefault()}
                            >
                                {category.name.toLowerCase() === "all" ? "All Products" : !!category.menuName ? category.menuName : category.name}
                            </Link>
                        ) : (
                            <Link
                                to={() => handleBreadCrumbs(category.level)}
                            >
                                {!!category.menuName ? category.menuName : category.name}
                            </Link>
                        )}
                    </React.Fragment>
                ))}
            </>
        );

    }

    return (
       
        <section className={`filter-wrapper`}
            style={{ borderTop: !(props.loggedInUser) ? "1px solid lightgray" : "1px solid lightgray", position: filterOpen ? "" : "sticky", top: filterOpen && props.screenWidth > 560 ? "" : filterOpen ? "0" : props.screenWidth < 756 && !(props.loggedInUser) ? "171px" : props.screenWidth < 756 ? "171px" : !(props.loggedInUser) && props.screenWidth < 991 ? "147px" :  props.screenWidth < 850 ? "147px" : props.screenWidth < 991 ? "147px" : props.screenWidth > 991 && !!(props.loggedInUser) && props.loggedInUser.proStatusId !== 1 ? "143px" : !!(props.loggedInUser) && props.loggedInUser.proStatusId === 1 && props.screenWidth > 991 ? "143px" : (!(props.loggedInUser) && props.screenWidth > 991) ? "143px" : "" }}>
            <div style={{
                paddingLeft: 0,
                paddingRight: 0,
                marginLeft: 0,
                marginRight: 0,
            }}>
                <div className="mt-0">

                    <div className={`d-flex align-items-center justify-content-between ${!["sm"].includes(props.deviceSize) ? "" : "px-3"} py-3 pt-sm-3 w-100`} style={{ height: !["sm", "md"].includes(props.deviceSize) ? "50px" : "", padding: !["sm"].includes(props.deviceSize) ? "0px 6%" : "" }}>
                        {
                            props.deviceSize !== "sm" ?
                                <h6 style={{ textAlign: "center", marginBottom: 0 }}>
                                    {mapBreadCrumbCategories()}
                                </h6>
                                :
                                <h4 style={{ textAlign: "left", marginBottom: 0 }}>
                                    {mapBreadCrumbCategories()}
                                </h4>
                        }

                        <div className="d-flex" style={{ lineHeight: 1 }}>
                            {renderFilterIcon()}
                            {renderToggleIcon()}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default BrowseBar;