import * as React from "react";
import LoadSpinner from "components/LoadSpinner";
import { NavLink } from "react-router-dom";
import { getRequest, postRequest } from "sharedUtils/httpUtils";

class OnboardInterests extends React.Component {

    state = {
        sectors: [],
        filter: "",
        filteredSectors: [],
        submitting: false,
        loading: true,
    }

    componentDidMount = async () => {
        this.props.setShowTopNav(false);
        await this.getSectors();
        await this.getUserSectors();
        this.setState({ loading: false })
    }

    getSectors = async () => {
        let sectors = await getRequest('/api/Sector/GetSectors');
        sectors = sectors.sort((a, b) => { return a.name < b.name ? -1 : 1 })
        this.setState({ sectors: sectors, filteredSectors: sectors });
    }

    getUserSectors = async () => {
        let userSectors = await getRequest('/api/Sector/GetUserSectors');
        let sectors = this.state.sectors;

        for (var i = 0; i < userSectors.length; ++i) {
            for (var j = 0; j < sectors.length; ++j) {
                if (userSectors[i].sectorId === sectors[j].sectorId) {
                    sectors[j].active = true;
                    break;
                }
            }
        }

        this.setState({ sectors: sectors });
    }

    renderSectors = () => {
        return this.state.filteredSectors.map((sector, i) => {
            if (sector.active) {
                return <button key={i} className="btn d-flex align-items-center active" onClick={() => this.onSectorClick(sector.name)}>{sector.name}</button>
            }
            return <button key={i} className="btn d-flex align-items-center" onClick={() => this.onSectorClick(sector.name)}>{sector.name}</button>
        });
    }

    renderSubmit = () => {
        if (this.state.sectors.filter(sector => sector.active === true).length > 0) {
            if (this.state.submitting) {
                return <button className="btn btn-primary">
                    <div className="spinner-border spinner-border-sm" role="status">
                        <span className="sr-only">Loading...</span>
                    </div> Continue
                </button>;
            }
            return (
                <div className="row mx-0 py-4 position-fixed white-bg secondary-footer">
                    <div className="col-12 text-center text-lg-right">
                        <NavLink to={this.props.loggedInUser.proStatusId === 2 || this.props.loggedInUser.pendingMerchantUser === true ? "/Account/OnboardWhoToFollow" : "/Account/OnboardUserType"} >
                            <button className="btn btn-primary" onClick={() => this.onSubmitClick()}>Continue</button>
                        </NavLink>
                    </div>
                </div>);
        }

        return <button className="btn btn-primary disabled">Continue</button>;
    }

    convertToUserModel = () => {
        return {
            name: this.props.loggedInUser.name,
            firstName: this.props.loggedInUser.firstName,
            lastName: this.props.loggedInUser.lastName,
            bio: this.props.loggedInUser.bio,
            tagLine: this.props.loggedInUser.tagLine,
            website: this.props.loggedInUser.website,
            imageUrl: this.props.loggedInUser.imageUrl,
            userName: this.props.loggedInUser.userName,
            email: this.props.loggedInUser.email,

            phoneNumber: this.props.loggedInUser.phoneNumber,
            proStatusId: this.props.loggedInUser.proStatusId,
            address: this.props.loggedInUser.address,
            city: this.props.loggedInUser.city,
            state: this.props.loggedInUser.state,
            postalCode: this.props.loggedInUser.postalCode,
            country: this.props.loggedInUser.country,

            onboardingComplete: this.props.loggedInUser.onboardingComplete,
            profileBannerUrl: this.props.loggedInUser.profileBannerUrl,
            quickStart: this.props.loggedInUser.quickStart
        }
    }

    saveUser = async () => {
        let userUrl = '/api/Account/PostUser';
        let user = await postRequest(userUrl, this.convertToUserModel());
        this.props.setLoggedInUser(user);
    }

    updateSectors = async () => {
        let selectedSectorIds = this.state.sectors.filter(sector => sector.active === true).map((sector) => { return sector.sectorId });
        await postRequest('/api/Sector/UpdateUserSectors', selectedSectorIds);
    }

    onSubmitClick = async () => {
        this.props.setShowTopNav(true);
        this.setState({ submitting: true });

        await this.updateSectors();
        await this.saveUser();
        this.setState({ submitting: false });
    }

    onSectorClick = async (name) => {
        let sectors = this.state.sectors;
        let i = sectors.findIndex((s) => { return s.name === name })
        sectors[i].active = !sectors[i].active;
        this.setState({ sectors: sectors, filter: "", filteredSectors: this.state.sectors });
    }

    onInputChange = async (e) => {
        let filter = e.target.value;
        let filteredSectors = this.state.sectors.filter(sector => sector.name.toLowerCase().includes(filter.toLowerCase()));

        this.setState({filter: filter, filteredSectors : filteredSectors })
    }

    render = () => {
        if (this.state.loading)
        {
            return <LoadSpinner />
        }
        return (
            <div className="container-fluid gray-bg full-height">
                <div className="row mt-0">
                    <div className="col-12 px-0 top-banner position-relative">
                        <img src="images/top-banner.png" alt="" className="w-100 h-100" />
                        <div className="interest-steps position-absolute text-center w-100">
                            <h1 className="mb-4">Pick Your Interests</h1>
                            <p>Tell us what you're interested in so we can populate your explore feed</p>
                            <ul className="steps-count pl-0">
                                <li className="d-inline-flex justify-content-center align-items-center active">1</li>
                                <li className="d-inline-flex justify-content-center align-items-center">2</li>
                                <li className="d-inline-flex justify-content-center align-items-center">3</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-12 search-interest py-4 mx-auto mb-5">
                        <div className="search-block">
                            <form action="">
                                <div className="form-group position-relative mx-auto">
                                    <input type="search" id="gsearch" name="gsearch" placeholder="Search for interests..." value={this.state.filter} onChange={(e) => this.onInputChange(e)} />
                                    <input type="submit" className="position-absolute" />
                                </div>
                            </form>
                            <div className="tag-results d-flex justify-content-center flex-wrap py-3">
                                {this.renderSectors()}
                            </div>
                        </div>
                    </div>
                    <div className="col-12 interest-footer d-flex flex-column justify-content-center align-items-center p-4 position-fixed text-center">
                        {this.renderSubmit()}                        
                    </div>
                </div>
            </div>)
    }
}

export default OnboardInterests;