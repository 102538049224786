import React, { useState, useEffect } from "react";
import { getRequest } from "sharedUtils/httpUtils";
import { getAuthorizedUserData } from "sharedUtils/authUtils";
import ProductSelectCard from "components/ProductSelectCard";
import LoadSpinner from "components/LoadSpinner";
import InfiniteScroll from "react-infinite-scroll-component";

/**
 * 
 * @param {{postShareCode:string, existingProducts:object[], selectProduct:Function, selectedProducts:object[], searchFilter:string, deviceSize:string}} props
 */
const ProductsSelectorForm = (props) => {
    let [products, setProducts] = useState([]);
    let [filteredProducts, setFilteredProducts] = useState([]);
    let [loggedInUser, setUser] = useState({});
    let [loading, setLoading] = useState(true);
    let [loadingMore, setLoadingMore] = useState(false);
    let [take, setTake] = useState(100);
    let [searchProp, setSearchProp] = useState("");
    let [finishedFetchingProducts, setFinishedFetchingProducts] = useState(false);
    useEffect(() => {
        loadData(0,take);
    }, [])

    useEffect(() => {
        if (props.searchFilter.length !== 0 || searchProp !== props.searchFilter) {
            loadSearch(props.searchFilter);
        }
        setSearchProp(props.searchFilter);
    }, [props.searchFilter])

    const loadSearch = async () => {
        await fetchProductsForSearch(0);
    }

    function concatMerchantProduct(product) {
        if (product.name.toLowerCase().indexOf(product.manufacturer.toLowerCase()) === 0) {
            return product.name;
        }
        return (product.manufacturer + ' ' + product.name).trim();
    }

    async function loadData(skip, take) {
        let user = await fetchUser();
        let products = await fetchProducts(user.userName, skip, take);

        setUser(user);
        setProducts(products);
        setFilteredProducts(products);
    }

    async function fetchUser() {
        let user = await getAuthorizedUserData();
        return user;
    }

    async function fetchProducts(userName, skip, take) {
        let data = await getRequest(`/Products/GetUserMerchantProducts/?userName=${userName}&skip=${skip}&take=${take}&searchTerm=${props.searchFilter}`);
        let products = data?.map((product) => {
            return { ...product, name: concatMerchantProduct(product) }
        });
        setFinishedFetchingProducts(products?.length < take || products == null || products == undefined);
        setLoading(false);
        return !!(products) ? products : [];
    }

    const fetchProductsForSearch = async (skip) => {
        setLoading(true);
        setProducts([]);
        setFilteredProducts([]);

        let products = await fetchProducts(loggedInUser.userName, skip, take);

        setProducts(products);
        setFilteredProducts(products);
    }

    const fetchMore = async () => {
        setLoadingMore(true);
        let skip = filteredProducts.length;

        let products = await fetchProducts(loggedInUser.userName, skip, take);

        const totalProducts = [...filteredProducts, ...products];

        setProducts(totalProducts);
        setFilteredProducts(totalProducts);

        setLoadingMore(false);
    }

    function handleSelect(product) {
        props.selectProduct(product);
    }

    function isProductSelected(productShareCode) {
        let found = props.selectedProducts.find((product) => {
            return product.shareCode === productShareCode
        });
        if (found === undefined) {
            return false;
        } else {
            return true;
        }
    }

    function isProductExisting(productShareCode) {
        var existing = props.existingProducts.find((prod) => prod.userMerchantProduct?.coupon === productShareCode);
        if (existing === undefined) {
            return false
        }
        else {
            return true;
        }
    }

    function mapProducts() {
        if (products.length > 0) {

            return filteredProducts.map((product, i) => {

                return (
                    <div key={i} className="col-6 col-md-3 px-1">
                        <ProductSelectCard product={product}
                            arrayKey={i}
                            select={handleSelect}
                            isSelected={isProductSelected(product.shareCode)}
                            isExisting={isProductExisting(product.shareCode)}
                        />
                    </div>
                )

            })
        }
    }

    if (loading) {
        return <LoadSpinner />
    } else if (!loadingMore && props.searchFilter?.length > 0 && products.length === 0) {
        return (
            <div>
                <div className="container">
                    <div className="row mx-0 mt-0">
                        <div className="col-12">
                            <div className="row">
                                <span className="w-100 text-center">
                                    No products in your inventory match your search criteria. Try again with a new search.
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>);
    } else if (!loadingMore && products.length === 0) {
        return (
            <div>
                <div className="container">
                    <div className="row mx-0 mt-0">
                        <div className="col-12">
                            <div className="row">
                                <span className="w-100 text-center">
                                    Looks like you haven't added any products yet! Click here to get started and products from our partners that you can include in posts and lockers
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>);
    }
    return (        
        <div>
            <div className="container add-product-to-post-modal-content" id="scrollableProductModal">
                <InfiniteScroll
                    dataLength={!!(products) && products.length > 0 ? products.length : 0}
                    next={fetchMore}
                    hasMore={!finishedFetchingProducts}
                    loader={<LoadSpinner />}
                    style={{ overflow: "initial" }}
                    scrollableTarget="scrollableProductModal"
                >
                    <div className="row mx-0">
                        <div className="col-12">
                            <div className="row">
                                {mapProducts()}
                            </div>
                        </div>
                    </div>
                </InfiniteScroll>
            </div>
        </div>
    )
}

export default ProductsSelectorForm;