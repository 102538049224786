import React from "react";
import { Link } from 'react-router-dom';

/**
 * 
 * @param {{breadCrumbList:[{link:string,text:string}]}} props 
 */
function AccountBanner(props) {
    let showBreadcrumb = () => {
        if (props.breadCrumbList) {
            return props.breadCrumbList.map((breadcrumb, i) => {
                return <li key={i} className={breadcrumb.active ? "breadcrumb-item active" : "breadcrumb-item"} aria-current="page"><Link to={breadcrumb.link}>{breadcrumb.text}</Link></li>
            })
        }
    };

    return (
        <div className="login-top-banner">
            <div className="container">
                <div className="py-3" style={{ marginTop: props.creatorCentric && ["sm","md"].includes(props.deviceSize ?? "") ? "-4px" : "" }}>
                    <div className="col-12">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb p-0">
                                {showBreadcrumb()}
                            </ol>
                        </nav>
                        {props.children}
                    </div>
                </div>
            </div>            
        </div>
    )
}


export default AccountBanner;

