import React from "react";
import ProfileInfoCard from "../../components/ProfileInfoCard";
import ProductSlide from '../../components/ProductSlide';
import BrowseProductDetailSection from '../../components/BrowseProductDetailSection';
import ProductDetailSection from '../../components/ProductDetailSection';
import CommentForm from '../../components/CommentForm';
import Modal from "../../components/Modal";
import ShareBlock from "components/Share/ShareBlock";
import CommentTile from '../../components/CommentTile';
import { Link, Redirect } from "react-router-dom";
import LoadSpinner from "components/LoadSpinner";
import ShareBar from "components/Share/ShareBar";
import { toggleShareModal, toggleProductPageModal, toggleFollowersFollowingModal } from "stateManagement/reducers/modalReducer";

import RightArrowIconSmall from "../../components/icons/RightArrowIconSmall";

import authService from "../../components/api-authorization/AuthorizeService";

import BookmarkIcon from "components/icons/BookmarkIcon";
import ShareIosIcon from "components/icons/ShareIosIcon";

import { getRequest, postRequest } from "sharedUtils/httpUtils";

import MessageDetails from "../Account/MessageDetails";
import UserTileSmall from "components/UserTileSmall";
import UserTileGeneral from "components/UserTileGeneral";
import { connect } from "react-redux";
import { closeAllModals } from "stateManagement/reducers/modalReducer";
import SwipeableDiv from "components/SwipeableDiv";
import { DateTime } from "luxon";
import { getProductShareLink } from "../../sharedUtils/shareLinkPrefixes"
import PlusIcon from "components/icons/PlusIcon";
import ThumbsUpIcon from "../../components/icons/ThumbsUpGeneric";
import Tick from "components/icons/Tick";
import { Helmet } from 'react-helmet';

var jsonref = require('jsonref');

class ProductPage extends React.Component {
    commentsTextArea = React.createRef();
    browse = false;
    noCommission = false;

    state = {
        redirect: false,
        loadingPartial: true,
        loading: true,
        loadingComments: false,
        loadingNextLinkedPosts: false,
        loadingPrevLinkedPosts: false,
        loadingBuy: false,
        commentInput: "",
        isFollowing: false,
        shareCode: "",
        linkedPostPage: 0,
        lastLinkedPostPage: 0,
        userDescriptionPage: 0,
        userDescriptions: [],
        productInfo: {
            userMerchantProductId: 0,
            userDescription: "",
            merchantProductId: 0,
            userRating: 0,
            rating: 0,
            displayRating: 0,
            ratingCount: 0,
            description: "",
            referalLink: {},
            manufacturer: "",
            name: "",
            price: 0,
            originalPrice: 0,
            salePrice: 0,
            sale: false,
            likes: 0,
            likedByUser: false,
            savedByUser: false,
            imageUrl: "",

            user: {
                name: "",
                userName: "",
                imageUrl: "",
                tagLine: "",
                bio: "",
                proStatusId: 0
            },
            comments: [],
            linkedPosts: []
        },
        modalOpen: false,
        showMessageDetails: false,
        hidePageExtras: false,
        submittingComment: false,
        shareLink: "",
        displayAddedToInventoryPrompt: false,
        isUserMerchant: false,
        redirectOccurred: false,
        rating: 0,
        products: [],
        browse: false,
        showRecommendedFlag: true
    }

    async componentDidMount() {
        let search = decodeURIComponent(this.props.location?.search);
        let params = new URLSearchParams(search);

        let pathname = decodeURIComponent(this.props.location?.pathname);

        let search2 = pathname.split('?');
        if (search == "" && search2.length > 0) {
            params = new URLSearchParams('?' + search2[1]);
        }

        let browse = params.get('b') === '1';
        let redirect = !this.props.isModal && (params.get('r') === '1' || params.get('r') == null);
        if (browse) {
            redirect = false;
        }
        this.browse = browse;
        //this.setState({ showRecommendedFlag: (!!(this.props.location?.search?.toLowerCase().includes("showrecommended=true")) || this.props.isSearch || this.props.isBrowse) });

        if (redirect) {
            this.props.setShowTopNav(false);
        } else {
            if (this.props.setShowTopNav) {
                this.props.setShowTopNav(true, !!(window.location.search.toLowerCase().includes("showbrandnav=")) ? this.props.showBrandNav(window.location.search) : true);
            }
        }

        if (this.props.isModal !== true) {
            this.props.closeAllModals()
        } else {
            this.setState({ hidePageExtras: false })
        }

        let shareCode = "";
        let productInfo = null;
        let loggedInUser = null;

        // feed
        if (this.props.match.params != undefined && this.props.match.params.product != undefined) {
            shareCode = this.props.match.params.product.shareCode;
            productInfo = this.props.match.params.product;
            productInfo.comments = [];
            productInfo.linkedPosts = [];
            loggedInUser = this.props.match.params.loggedInUser;
            // modal
        } else if (this.props.location.state != undefined && this.props.location.state.destinationState != undefined && this.props.location.state.destinationState.params &&
            this.props.location.state.destinationState.params.product != undefined) {
            shareCode = this.props.location.state.destinationState.params.product.shareCode;
            productInfo = this.props.location.state.destinationState.params.product;
            loggedInUser = this.props.location.state.destinationState.params.loggedInUser;
            // direct
        } else {
            shareCode = this.props.match.params.shareCode;
            loggedInUser = this.props.loggedInUser;
        }
        if (this.props.isBrowse && productInfo != null) {
            let merchantProductId = productInfo.merchantProductId;

            let products = await getRequest(`/Products/GetUserMerchantProductsWithMerchantProductId?merchantProductId=${merchantProductId}`);
            this.setState({ products });
        }

        shareCode = decodeURIComponent(shareCode).split('?')[0];

        await this.setState({ shareCode });

        if (redirect) {
            await this.populateData(true); // this will track the product view

            this.setState({ shareCode, redirect: true });

            return;
        };

        let shareLink = await getProductShareLink(shareCode, !!(this.props.isBrowse));

        await this.setState({ shareCode, productInfo, loggedInUser, shareLink, redirect, isUserMerchant: productInfo?.user?.id === this.props.loggedInUser?.id }, () => {
            this.populateData();
        });

        if (this.commentsTextArea?.current != null && this.props.isModal === true && this.props.isCommenting === true) {
            this.setState({ hidePageExtras: false }, () => {
                setTimeout(() => {
                    this.commentsTextArea.current.scrollIntoView();
                    this.commentsTextArea.current.focus();
                }, 500);

            });
        }
    }

    async componentDidUpdate(prevProps, prevState) {

        //for (const index in prevProps) {
        //    if (prevProps[index] !== this.props[index]) {
        //        console.log(index, this.props[index], '-->', prevProps[index]);
        //    }
        //}

        if (this.props.match.params.shareCode != prevProps.match.params.shareCode) {
            this.setState({ loading: true });
            let isAuthenticated = this.state.loggedInUser ? true : await authService.isAuthenticated();

            let shareCode = this.props.match.params.shareCode;

            let product = this.props.location.state.destinationState.params.product;

            if (product == null || product == undefined) {

                product = await this.fetchProduct(shareCode);

                if (product == null || product == undefined) {
                    this.props.history.push("/NotFound");
                    return -1;
                }
                else {
                    await this.getLikes(isAuthenticated, product.userMerchantProductId);
                    await this.getSavedByUser(product.userMerchantProductId);
                    await this.getRating(isAuthenticated, product.userMerchantProductId);
                    await this.loadUserDescriptions(product.userMerchantProductId, this.state.loggedInUser ? this.state.loggedInUser.id : -1);


                }
            }
            this.loadLinkedPosts(0);

            let products = [];

            this.setState({ productInfo: product, loading: false, isUserMerchant: product?.user?.id === this.props.loggedInUser?.id });
        }
    }

    fetchProduct = async (shareCode) => {
        let isAuthenticated = this.state.loggedInUser ? true : await authService.isAuthenticated();
        const browse = this.browse ? "&browse=true" : "";
        let baseUrl = isAuthenticated === true ? `/Products/GetProductAuthorized` : `/Products/Get`;
        let productUrl = `${baseUrl}?shareCode=${shareCode}&referrer=${document.referrer}${browse}`;
        let product = await getRequest(productUrl);


        return product;

    }

    async populateData(forRedirect = false) {
        var isAuthenticated = this.state.loggedInUser ? true : await authService.isAuthenticated();
        const browse = this.props.isBrowse || this.browse ? "&browse=true" : "";

        let baseUrl = isAuthenticated === true ? `/Products/GetProductAuthorized` : `/Products/Get`;
        let shareCode = this.props.match.params.shareCode ? this.props.match.params.shareCode : this.state.shareCode;
        let productUrl = `${baseUrl}?shareCode=${shareCode}&referrer=${document.referrer}${browse}&forRedirect=${forRedirect}`;

        var data;
        if (this.state.productInfo && this.state.productInfo.userMerchantProductId && this.state.productInfo.userMerchantProductId !== 0 && this.state.productInfo?.hasOwnProperty('commission')) {
            data = this.state.productInfo;
            this.noCommission = !(data.commission)
            let trackBaseUrl = isAuthenticated ? `/Products/TrackProductViewAuthorized` : `/Products/TrackProductView`
            let trackProductViewUrl = `${trackBaseUrl}?userMerchantProductId=${data.userMerchantProductId}&referrer=${document.referrer}${browse}`;
            getRequest(trackProductViewUrl);
        } else {
            data = await getRequest(productUrl);
            if (data == null) {
                data = await getRequest(`/Products/Get?shareCode=${shareCode}&referrer=${document.referrer}${browse}`)
                if (data == null) {
                    this.props.history.push("/NotFound");
                    return;
                }
            }
            this.noCommission = !(data.commission);
        }

        let products = [];

        if (data === undefined || data === null) {
            this.setState({ productInfo: null });
            this.props.history.push("/NotFound");
            return -1;
        } else if (!!(data) && (this.browse || this.props.isBrowse)) {
            let merchantProductId = data.merchantProductId;

            products = await getRequest(`/Products/GetUserMerchantProductsWithMerchantProductId?merchantProductId=${merchantProductId}`);
        }

        await this.setState({ productInfo: data, products, loading: false, isUserMerchant: data?.user?.id === this.props.loggedInUser?.id });

        // short circuit redirect as other data isn't needed
        if (data.integrated === true && forRedirect === true) {
            return;
        }

        if (data.inUserInventory == null || data.inUserInventory == undefined) {
            await this.getInUserInventory(isAuthenticated, data.merchantProductId);
        }

        if (data.likes == null || data.likes === 0) {
            this.getLikes(isAuthenticated, data.userMerchantProductId);
        }

        if (data.ratingAverage == null || data.ratingAverage === 0) {
            this.getRating(isAuthenticated, data.userMerchantProductId);
        }

        if (data.savedByUser == null) {
            this.getSavedByUser(data.userMerchantProductId);
        }

        this.loadUserDescriptions(data.userMerchantProductId, this.state.loggedInUser ? this.state.loggedInUser.id : -1);

        this.setState({ loadingPartial: false });

        if (data.comments?.length == 0) { this.loadPreviousComments(0, 3); }
        this.loadLinkedPosts(0);

        if (!!(this.state.loggedInUser)) {
            let isFollowing = await getRequest(`/api/Account/IsFollowing?id=${this.state.productInfo?.user?.id}`);
            this.setState({ isFollowing: isFollowing });
        }
    }

    addProductToInventory = async () => {
        let merchantProductId = this.state.productInfo.merchantProductId;

        let addModel = { MerchantProductId: merchantProductId };

        let updatedProductInfo = { ...this.state.productInfo };
        updatedProductInfo.inUserInventory = !updatedProductInfo.inUserInventory;

        this.setState({ productInfo: updatedProductInfo, displayAddedToInventoryPrompt: true });

        var url = "/Products/AddProduct";
        await postRequest(url, addModel);
    }

    onClickMessage = async () => {
        this.setState({ showMessageDetails: true });
    }

    onClickBack = async () => {
        this.setState({ showMessageDetails: false });
    }

    toggleModal = () => {
        let newModalOpen = !this.state.modalOpen;
        this.setState({ modalOpen: newModalOpen });
    }

    getInUserInventory = async (isAuthenticated, merchantProductId) => {
        if (isAuthenticated) {
            let url = `/Products/InInventory?merchantProductId=${merchantProductId}`;
            let prod = await getRequest(url);
            let productInfo = { ...this.state.productInfo };
            productInfo.inUserInventory = prod;
            this.setState({ productInfo });
        }
    }

    getLikes = async (isAuthenticated, userMerchantProductId) => {
        return;
        let url = isAuthenticated ? `/Products/GetLikesAuthorized/?userMerchantProductId=${userMerchantProductId}` : `/Products/GetLikes/?userMerchantProductId=${userMerchantProductId}`;

        var likes = await getRequest(url);
        let productInfo = { ...this.state.productInfo };
        productInfo.likes = likes?.count;
        productInfo.likedByUser = likes?.likedByUser;

        this.setState({ productInfo });
    }

    getRating = async (isAuthenticated, userMerchantProductId) => {
        let url = isAuthenticated ? `/Products/GetRatingAuthorized/${userMerchantProductId}` : `/Products/GetRating/${userMerchantProductId}`;

        var rating = await getRequest(url);
        if (rating == null) {
            rating = await getRequest(`/Products/GetRating/${userMerchantProductId}`);
        }
        let productInfo = { ...this.state.productInfo };
        productInfo.userRating = rating?.userRating;
        productInfo.rating = rating?.average;
        productInfo.ratingCount = rating?.count;
        productInfo.displayRating = rating?.average;

        this.setState({ productInfo });
    }

    likeCommentAction = async (comment) => {
        let like = comment.likedByUser ? -1 : 1;

        var index = this.state.productInfo.comments.findIndex((i) => { return i.id === comment.id });

        comment.likes += like;
        comment.likedByUser = !comment.likedByUser;

        var comments = [...this.state.productInfo.comments];
        comments[index] = comment;

        this.setState({ productInfo: { ...this.state.productInfo, ...{ comments } } });

        const token = await authService.getAccessToken();
        await fetch("/Products/LikeComment",
            {
                headers: !token ? {} : { Authorization: `Bearer ${token}`, "Content-Type": "application/json" },
                method: "POST",
                body: JSON.stringify({ id: comment.id, likedByUser: !comment.likedByUser })
            });
    }

    followAction = async () => {
        const token = await authService.getAccessToken();

        let url = "/api/Account/"
        if (this.state.isFollowing) {
            url += "Unfollow"
        } else {
            url += "Follow"
        }
        url += "?id=" + this.state.productInfo.user.id;

        const response = await fetch(url,
            {
                headers: !token ? {} : { Authorization: `Bearer ${token}` },
            });

        if (response.ok) {
            this.setState({ isFollowing: !this.state.isFollowing });
        }
    }

    getSavedByUser = async (userMerchantProductId) => {
        let url = `/Products/IsSaved/${userMerchantProductId}`;
        let savedByUser = await getRequest(url);

        let productInfo = { ...this.state.productInfo };
        productInfo.savedByUser = savedByUser;

        this.setState({ productInfo });
    }

    saveAction = async () => {
        let productInfo = { ...this.state.productInfo };

        productInfo.savedByUser = !productInfo.savedByUser

        if (this.props.match && this.props.match.params && this.props.match.params.product) {
            this.props.match.params.product.savedByUser = productInfo.savedByUser;
        }

        this.setState({ productInfo: productInfo });

        const token = await authService.getAccessToken();

        let url = "/Products/"
        if (!productInfo.savedByUser) {
            url += "Unsave"
        } else {
            url += "Save"
        }
        url += "?id=" + this.state.productInfo.userMerchantProductId;

        const response = await fetch(url,
            {
                headers: !token ? {} : { Authorization: `Bearer ${token}` },
            });
    }

    likeAction = async () => {
        var like = this.state.productInfo.likedByUser ? -1 : 1;

        var newLike = {
            likeCount: this.state.productInfo.likes + like,
            likedByUser: !this.state.productInfo.likedByUser
        }

        if (this.props.match && this.props.match.params && this.props.match.params.product) {
            this.props.match.params.product.likedByUser = newLike.likedByUser;
            this.props.match.params.product.likes = newLike.likeCount;
        }

        this.state.productInfo.likes = newLike.likeCount;
        this.state.productInfo.likedByUser = newLike.likedByUser;

        this.setState({ productInfo: { ...this.state.productInfo } });

        const token = await authService.getAccessToken();
        const response = await fetch("/Products/Like?shareCode=" + this.state.shareCode,
            {
                headers: !token ? {} : { Authorization: `Bearer ${token}` },
                method: "POST"
            });

        let toggle = await response.text();
        var like = parseInt(toggle);
    }

    starHover = () => {
        var productInfo = this.state.productInfo;
        productInfo.displayRating = productInfo.userRating || 0;

        this.setState({ productInfo: productInfo });
    }

    starHoverOut = () => {
        var productInfo = this.state.productInfo;
        productInfo.displayRating = productInfo.rating || 0;

        this.setState({ productInfo: productInfo });
    }

    starClick = async (nextValue, prevValue, name) => {
        var productInfo = this.state.productInfo;

        productInfo.displayRating = nextValue;
        productInfo.userRating = nextValue;

        this.setState({ productInfo })

        const token = await authService.getAccessToken();

        let url = "/Products/Rate?id=" + this.state.productInfo.userMerchantProductId + "&rating=" + nextValue

        const response = await fetch(url,
            {
                headers: !token ? {} : { Authorization: `Bearer ${token}` },
            });

        if (response.ok) {
            var productRating = await response.json();

            productInfo.rating = productRating.average;
            productInfo.ratingCount = productRating.count;

            this.setState({ productInfo });
        }

    }

    handleCommentInput = (e) => {
        this.setState({ commentInput: e.target.value });
    }

    postComment = async () => {
        this.setState({ submittingComment: true });

        let comment = {
            text: this.state.commentInput,
            likes: 0,
            likeByUser: false,
            createdOn: DateTime.now().toLocaleString(),
            user: this.props.loggedInUser
        }

        var comments = [...this.state.productInfo.comments];
        if (comments.length > 0) {
            comments[0].totalCount = comments[0].totalCount + 1;
        } else {
            comment.totalCount = 1;
        }

        comments = comments.concat(comment);

        if (this.props.match && this.props.match.params && this.props.match.params.product) {
            this.props.match.params.product.comments = [...comments];
        }

        this.setState({ productInfo: { ...this.state.productInfo, ...{ comments } }, commentInput: "" });

        const token = await authService.getAccessToken();

        const response = await fetch("/Products/PostComment?shareCode=" + this.state.shareCode + "&comment=" + encodeURIComponent(comment.text),
            {
                headers: !token ? {} : { Authorization: `Bearer ${token}` },
                method: "POST"
            });
        this.setState({ submittingComment: false });
    }

    renderModal() {
        return (
            <Modal isOpen={this.state.modalOpen} toggleModal={this.toggleModal}>
                <ShareBlock shareCode={this.state.shareLink}
                    isUserMerchant={this.state.isUserMerchant}
                    title="Product share"
                    customStyle={{ maxWidth: "100%", backgroundColor: "white" }}
                    shareBlockStyle={{ border: "none", backgroundColor: "transparent" }}
                    loggedInUser={this.props.loggedInUser}
                />
            </Modal>
        )
    }

    loadPreviousComments = async (skip = this.state.productInfo.comments.length, take = 25) => {
        this.setState({ loadingComments: true });

        let url = this.state.loggedInUser ? '/Products/GetCommentsAuthorized?shareCode=' : '/Products/GetComments?shareCode=';
        const token = await authService.getAccessToken();
        const response = await fetch(url + this.state.shareCode + "&skip=" + skip + "&take=" + take, {
            headers: !token ? {} : { Authorization: `Bearer ${token}` },
        });

        let data = await response.json();

        data = await jsonref.parse(data, { scope: window.location });

        let comments = [...this.state.productInfo.comments]
        comments = data.concat(comments);
        this.setState({ productInfo: { ...this.state.productInfo, ...{ comments } } });
        this.setState({ loadingComments: false });
    }

    renderLoadPreviousComments = () => {
        if (this.state.productInfo.comments.length > 0 && this.state.productInfo.comments.length < this.state.productInfo.comments[0].totalCount) {
            if (this.state.loadingComments) {
                return (
                    <div className="col text-right mb-0 mt-1 pr-0">
                        <div className="spinner-border spinner-border-sm" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                );
            } else {
                return (
                    <div className="col text-right mb-0 pr-0">
                        <Link to="#" className="load-previous" onClick={(e) => { e.preventDefault(); this.loadPreviousComments() }}>Load previous</Link>
                    </div>
                );
            }
        }
    }

    renderComments = () => {
        return this.state.productInfo.comments.map((comment, i) => {
            return <CommentTile comment={comment} brandNav={this.props.brandNav} key={i} loggedInUser={this.props.loggedInUser} likeAction={this.likeCommentAction} />
        })
    }

    loadUserDescriptions = async (userMerchantProductId, userId) => {
        let userDescriptions = await getRequest(`/Products/GetWithUserDescriptions?userMerchantProductId=${userMerchantProductId}&userId=${userId}`);
        this.setState({ userDescriptions });
    }

    loadLinkedPosts = async (pageNumber) => {
        const token = await authService.getAccessToken();

        const response = await fetch((this.state.loggedInUser ? "/Products/GetLinkedPostsAuthorized" : "/Products/GetLinkedPosts") + "?userMerchantProductId=" + this.state.productInfo.userMerchantProductId + "&page=" + pageNumber, {
            headers: !token ? {} : { Authorization: `Bearer ${token}` },
        });

        let linkedPosts = await response.json();

        linkedPosts = await jsonref.parse(linkedPosts, { scope: window.location });

        if (linkedPosts.length > 0) {
            this.setState({ lastLinkedPostPage: Math.ceil(linkedPosts[0].totalLinkedPosts / 4) - 1, linkedPostPage: pageNumber });
        }

        this.setState({ productInfo: { ...this.state.productInfo, ...{ linkedPosts } } });

        this.setState({ loadingNextLinkedPosts: false });
        this.setState({ loadingPrevLinkedPosts: false });
    }

    prevUserDescriptionPage = async () => {
        let userDescriptionPage = this.state.userDescriptionPage;
        userDescriptionPage--;
        if (userDescriptionPage < 0) {
            userDescriptionPage = this.state.userDescriptions.length - 1;
        }
        let productInfo = this.state.productInfo;
        productInfo.userDescription = this.state.userDescriptions[userDescriptionPage].userDescription;

        let user = await this.getUser(this.state.userDescriptions[userDescriptionPage].userId);
        productInfo.user = user;

        this.setState({ productInfo, userDescriptionPage });
    }

    nextUserDescriptionPage = async () => {
        let userDescriptionPage = this.state.userDescriptionPage;
        userDescriptionPage++;
        if (userDescriptionPage > this.state.userDescriptions.length - 1) {
            userDescriptionPage = 0;
        }
        let productInfo = this.state.productInfo;
        productInfo.userDescription = this.state.userDescriptions[userDescriptionPage].userDescription;

        let user = await this.getUser(this.state.userDescriptions[userDescriptionPage].userId);
        productInfo.user = user;

        this.setState({ productInfo, userDescriptionPage });
    }

    getUser = async (userId) => {
        return await getRequest(`/api/Account/GetById?userId=${userId}`);
    }

    renderUserDescriptionPagination = () => {
        if (this.state.userDescriptions !== null && this.state.userDescriptions !== undefined && this.state.userDescriptions.length > 1) {
            return (
                <div className="row mx-0 pt-0 justify-content-between">
                    <Link to="#"
                        onClick={(e) => {
                            e.preventDefault();
                            this.prevUserDescriptionPage();
                        }}
                        className="position-relative d-flex align-items-left">
                        <div className="small">
                            Previous
                        </div>
                    </Link>
                    <Link to="#"
                        onClick={(e) => {
                            e.preventDefault();
                            this.nextUserDescriptionPage();
                        }}
                        className="position-relative d-flex align-items-right">
                        <div className="small">
                            Next
                        </div>
                    </Link>
                </div>
            );
        }
    }

    renderLinkedPostsPreviousPage = () => {
        if (this.state.lastLinkedPostPage > 0) {
            return (
                <Link to="#"
                    onClick={(e) => {
                        e.preventDefault();

                        this.prevPage();
                    }}
                    className="left-arrow position-absolute d-flex justify-content-center align-items-center"
                ><RightArrowIconSmall />
                </Link>);
        }
        return (<div />);
    }

    prevPage = () => {
        this.setState({ loadingPrevLinkedPosts: true });

        if (this.state.linkedPostPage > 0) {
            this.loadLinkedPosts(this.state.linkedPostPage - 1);
        } else {
            this.loadLinkedPosts(this.state.lastLinkedPostPage);
        }
    }

    nextPage = () => {
        this.setState({ loadingNextLinkedPosts: true });

        if (this.state.linkedPostPage < this.state.lastLinkedPostPage) {
            this.loadLinkedPosts(this.state.linkedPostPage + 1)
        } else {
            this.loadLinkedPosts(0);
        }
    }

    renderLinkedPostsPageCount = () => {
        if (this.state.lastLinkedPostPage > 0) {
            return (<span className="count position-absolute d-flex justify-content-center align-items-center">
                {`${this.state.linkedPostPage + 1}/${this.state.lastLinkedPostPage + 1}`}
            </span>);
        }
    }

    renderLinkedPostsNextPage = () => {
        if (this.state.lastLinkedPostPage > 0) {
            return (
                <Link to="#"
                    onClick={(e) => {
                        e.preventDefault();

                        this.nextPage();
                    }}
                    className="right-arrow position-absolute d-flex justify-content-center align-items-center">

                    <RightArrowIconSmall />
                </Link>);
        }
        return (<div />);
    }

    renderLinkedPosts = () => {
        let linkedPosts = [];
        let count = 4;

        for (var i = 0; i < count; ++i) {
            if (i < this.state.productInfo.linkedPosts.length) {
                linkedPosts.push(<ProductSlide
                    post={this.state.productInfo.linkedPosts[i]}
                    loggedInUser={this.props.loggedInUser}
                    content={this.state.productInfo.linkedPosts[i].properties.$values}
                    key={i}
                    brandNav={this.props.brandNav}
                    deviceSize={this.props.deviceSize}
                    parentModal={this.props.isModal === true ? { type: "product", shareCode: "", parentState: this.state.productInfo } : null}
                />);
            }
        }

        return linkedPosts;
    }

    renderPostComment = () => {
        if (this.props.loggedInUser) {
            return (
                <div className="container comment-block pt-0 pb-0 mb-md-3" id="post-page-comments">
                    <div className="row mt-0">
                        <CommentForm commentsTextArea={this.commentsTextArea} user={this.props.loggedInUser} handleCommentInput={this.handleCommentInput} postComment={this.postComment} commentText={this.state.commentInput} submittingComment={this.state.submittingComment} />
                    </div>
                </div>
            )
        }
    }

    renderBookmarkOverlay = () => {
        const shareCode = this.props?.match?.params?.post?.shareCode;
        return (
            <span className={`${this.props.isBrowse || this.props.isModal ? "product-detail-section-bookmark-overlay" : "product-page-bookmark-overlay"} ${this.props.screenWidth < 576 ? "mobile" : ""} position-absolute`} onClick={(e) => {
                if (this.props.loggedInUser) {
                    e.preventDefault();
                    this.saveAction(shareCode);
                } else {
                    this.props.toggleSignUpModal();
                }

            }} style={{ cursor: "pointer", left: ["sm", "md"].includes(this.props.deviceSize) && this.browse ? "20px" : "" }}>
                <BookmarkIcon height="16" width="16" fill={this.state.productInfo.savedByUser ? "#e55934" : "none"} />
            </span>);
    }

    renderShareOverlay = () => {
        return (<span className={`${this.props.isBrowse || this.props.isModal ? "product-detail-section-share-overlay" : "product-page-share-overlay"} ${this.props.screenWidth < 576 ? "mobile" : ""} position-absolute`} onClick={() => {
            this.toggleModal(this.state.shareLink);
        }} style={{ cursor: "pointer", left: ["sm", "md"].includes(this.props.deviceSize) && this.browse ? "50px" : "" }}>
            <ShareIosIcon color="#E55934" />
        </span>);
    }

    renderThumbsUpOverlay = (product, browse = false) => {
        const productId = !!(product.productId) ? product.productId : product.id;
        if (!(product.creatorCount) || product.creatorCount === 0) {
            return <></>;
        }
        //return <span className="thumbs-up-overlay position-absolute" onClick={() => this.toggleCreatorsModal(productId)} style={{ top: this.props.isModal && this.props.isBrowse ? "6px" : this.props.isModal && this.props.deviceSize === "xl" ? "11px" : this.props.isModal ? "6px" : this.props.deviceSize === "xl"  ? "11px" : "6px", left: "83%", width:"50px", cursor: "pointer" }}>
        if (this.state.showRecommendedFlag) {
            return (
                <span className="thumbs-up-overlay" onClick={() => this.toggleCreatorsModal(productId)} style={{ marginBottom: this.browse || this.state.browse || this.props.isBrowse ? "4px" : "5px", width: !!(product.creatorCount) && product.creatorCount > 99 ? "55px" : "50px", cursor: "pointer", marginLeft: this.props.screenWidth < 576 ? "" : "25px", transform: "translate(0px, 0px)" }}>
                    <ThumbsUpIcon height="16" width="16" fill={"#e55934"} />
                    <span style={{ marginLeft: "5px", paddingTop: "2px" }}>{!!(product.creatorCount) ? product.creatorCount : ""}</span>
                </span>
            )
        }
        return <></>;
    }

    toggleCreatorsModal = (productId) => {
        const followersFollowing = "creators";
        this.props.toggleFollowersFollowingModal(!!(this.props.loggedInUser) ? this.props.loggedInUser.id : -1, followersFollowing, productId);
    }

    displayAddToInventory = () => {

        if (!this.props.isBrowse && this.props.loggedInUser && this.props.loggedInUser.proStatusId === 1 && !(this.state.productInfo.approvedCreatorsOnly)) {
            if (this.state.productInfo?.inUserInventory === false) {
                let prompt = "";
                switch (this.props.deviceSize) {
                    case "sm": break;
                    case "md": { prompt = "Add"; break; }
                    default: { prompt = "Add To Inventory"; break; }
                }
                return (
                    <div style={{ left: ["sm", "md"].includes(this.props.deviceSize) && this.browse ? "80px" : "" }} className={`${this.props.isBrowse || this.props.isModal ? "product-detail-section-tick" : "product-page-tick-overlay"} ${this.props.screenWidth < 576 ? "mobile" : ""} position-absolute d-inline-flex align-items-center`} >
                        <Link to="#"
                            style={{ paddingBottom: "2px", marginTop: "-1px", marginBottom: "-1px" }}
                            onClick={e => {
                                e.preventDefault();
                                this.addProductToInventory();
                            }
                            }
                            onTouchEnd={
                                (e) => {
                                    e.preventDefault();
                                    this.addProductToInventory();
                                }
                            }>
                            <PlusIcon width="16" height="16" fill="#e55934 " />
                            {/*{*/}
                            {/*   prompt */}
                            {/* }*/}
                        </Link>
                    </div>
                )
            }
            else if (this.state.displayAddedToInventoryPrompt === true || this.state.productInfo.inUserInventory === true) {
                let prompt = "";
                switch (this.props.deviceSize) {
                    case "sm": break;
                    case "md": { prompt = "In Inventory"; break; }
                    default: { prompt = "In Inventory"; break; }
                }
                return (
                    <div className={`${this.props.isBrowse || this.props.isModal ? "product-detail-section-tick" : "product-page-tick-overlay"} ${this.props.screenWidth < 576 ? "mobile" : ""} position-absolute d-inline-flex align-items-center`}
                        style={{ paddingLeft: "7px", paddingRight: "7px", left: ["sm", "md"].includes(this.props.deviceSize) && this.browse ? "80px" : "" }}
                    >
                        <Link to="#" onClick={e => e.preventDefault()} style={{ pointerEvents: "none", paddingBottom: "2px", marginTop: "-1px", marginBottom: "-1px" }}>
                            <Tick fill="#e55934" height="16" fillOpacity="0.54" />
                            {/*    {prompt}*/}
                        </Link>
                    </div>
                )
            }
            else {
                return null;
            }
        }
        else {
            return null;
        }
    }

    getPageTitle = () => {
        ;
        let shareCode = this.props.match.params.shareCode;

        return "RockPorch - Product: " + shareCode;
    }

    handleGoogleAnalytics4BuyButton = (browse = false) => {
        window.gtag('event', 'Buy_Now', {
            send_to: 'G-9F2DZRE5FG',
            event_category: 'Button',
            event_label: `Buy Now`,
            value: browse ? "Browse" : "Non-Browse",
        });
    }

    productPageRedirectGoogleAnalytics4Tracking = () => {
        window.gtag('event', 'ProductPageRedirect', {
            send_to: 'G-9F2DZRE5FG',
            event_category: 'Redirect',
            event_label: `Product Page Redirect`,
        });
    }


    render() {
        if (this.state.redirect) {
            if (!this.state.redirectOccurred) {
                this.productPageRedirectGoogleAnalytics4Tracking();
                window.location.replace((this.props.loggedInUser ? "/Products/BuyAuthorized" : "/Products/Buy") + "?shareCode=" + this.state.shareCode + "&referrer=" + document.referrer + "&browse=" + this.browse + "&noCommission=" + this.noCommission);                
                this.setState({ redirectOccurred: true });
            }
            const ogTitle = this.state.productInfo?.merchant || '';
            const ogDescription = this.state.productInfo?.name || this.state.productInfo?.merchant;
            const ogImage = this.state.productInfo?.logoUrl || this.state.productInfo?.imageUrl || '';
            return (
                <>
                    <>
                        <Helmet>
                            <title>{this.getPageTitle()}:</title>
                            <meta name="title" property="og:title" content={ogTitle} />
                            <meta name="description" property="og:description" content={ogDescription} />
                            <meta name="image" property="og:image" content={ogImage} />
                            <meta name="type" property="og:type" content="website" />
                            <meta name="url" property="og:url" content={window.location.href} />
                            <meta name="title" content={ogTitle} />
                        </Helmet>
                    </>
                </>
            )
        }

        if (this.state.loading) {
            return (
                <>
                    < LoadSpinner />
                    <Helmet>
                        <title>{this.getPageTitle()}:</title>
                    </Helmet>
                </>
            )
        }

        let { name, manufacturer, imageUrl, price, ratingCount, description, likes } = this.state.productInfo;
        let { deviceSize, isModal } = this.props;

        if (this.state.showMessageDetails) {
            return <MessageDetails onClickBack={this.onClickBack} user={this.state.productInfo.user} loggedInUser={this.state.loggedInUser} />
        }
        return (
            <section>

                {
                    !!(this.props.isModal) ?
                        <></>
                        :
                        <Helmet>
                            <title>{this.getPageTitle()}:</title>
                        </Helmet>
                }

                {this.renderModal()}
                {this.renderBookmarkOverlay()}
                {this.renderShareOverlay()}
                {this.displayAddToInventory()}
                {(this.browse || this.props.isBrowse) && this.state.products?.length > 0 ?
                    <div>
                        <BrowseProductDetailSection
                            collapsed={this.state.hidePageExtras}
                            product={this.state.productInfo}
                            loadingBuy={this.state.loadingBuy}
                            rating={this.state.productInfo.displayRating}
                            loggedInUser={this.props.loggedInUser}
                            starHover={this.starHover}
                            starHoverOut={this.starHoverOut}
                            starClick={this.starClick}
                            buyAction={(this.props.loggedInUser ? "/Products/BuyAuthorized" : "/Products/Buy") + "?shareCode=" + this.state.shareCode + "&referrer=" + document.referrer + "&browse=true"}
                            deviceSize={this.props.deviceSize}
                            isModal={this.props.isModal}
                            loadingPartial={this.state.loadingPartial}
                            toggleSignUpModal={this.props.toggleSignUpModal}
                            products={this.state.products}
                            productInfo={this.state.productInfo}
                            renderThumbsUpOverlay={this.renderThumbsUpOverlay}
                            handleGoogleAnalytics4BuyButton={this.handleGoogleAnalytics4BuyButton}
                        />
                    </div>
                    :
                    <>

                        <ProductDetailSection
                            collapsed={this.state.hidePageExtras}
                            product={this.state.productInfo}
                            loadingBuy={this.state.loadingBuy}
                            rating={this.state.productInfo.displayRating}
                            loggedInUser={this.props.loggedInUser}
                            starHover={this.starHover}
                            starHoverOut={this.starHoverOut}
                            starClick={this.starClick}
                            buyAction={(this.props.loggedInUser ? "/Products/BuyAuthorized" : "/Products/Buy") + "?shareCode=" + this.state.shareCode + "&referrer=" + document.referrer}
                            deviceSize={this.props.deviceSize}
                            isModal={this.props.isModal}
                            loadingPartial={this.state.loadingPartial}
                            toggleSignUpModal={this.props.toggleSignUpModal}
                            productInfo={this.state.productInfo}
                            renderThumbsUpOverlay={this.renderThumbsUpOverlay}
                            handleGoogleAnalytics4BuyButton={this.handleGoogleAnalytics4BuyButton}
                        />
                    </>
                }
                {
                    this.props.isModal === true ? (
                        <div className="container text-right product-share-info">
                            <Link
                                to="/"
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.props.expandDialog();
                                    this.setState({ hidePageExtras: !this.state.hidePageExtras });

                                }}
                                className="mx-0"
                                style={{ color: "#e55934" }}
                            >
                            </Link>
                        </div>
                    ) : <span></span>
                }

                {
                    this.state.productInfo.userDescription?.length > 0 && this.state.hidePageExtras === false ? (
                        <>
                            <div className="container px-2">
                                <hr className="divider-hr pb-1" />
                            </div>
                            <div className="container">
                                <SwipeableDiv
                                    className="row"
                                    onSwipedLeft={() => this.prevUserDescriptionPage()}
                                    onSwipedRight={() => this.nextUserDescriptionPage()}
                                    preventDefaultTouchmoveEvent={true}
                                    trackMouse={true}>
                                    <div className="col-12 px-3">
                                        <div className="d-flex justify-content-between">
                                            <div className="w-75">
                                                <UserTileGeneral brandNav={this.props.brandNav} user={this.state.productInfo.user} subtitle={" "} showCheckMark={false} />
                                            </div>
                                            <div className="text-right small" style={{ marginTop: "-4px", whiteSpace: "nowrap" }}>
                                                Product Review
                                            </div>
                                        </div>
                                        <div className="linked-posts py-2 position-relative">
                                            <p className="post-page-text">
                                                {this.state.productInfo.userDescription}
                                            </p>
                                            {this.renderUserDescriptionPagination()}
                                        </div>
                                    </div>
                                </SwipeableDiv>
                            </div>
                        </>

                    ) : <span></span>
                }



                {
                    this.state.hidePageExtras === false ? (
                        <div className="container container-top-gap mt-1 px-2">
                            {this.state.productInfo?.linkedPosts?.length > 0 ? (<><hr className="divider-hr pb-1" />
                                <div className="col-12 text-right mb-3 mt-1 pt-0 px-2 small">Related posts</div>
                                <div className="linked-posts position-relative">
                                    {this.renderLinkedPostsPageCount()}
                                    {this.renderLinkedPostsPreviousPage()}
                                    {this.renderLinkedPostsNextPage()}
                                    <SwipeableDiv
                                        className="row mx-0 mb-3 linked-posts-gallery justify-content-center"
                                        onSwipedLeft={() => this.prevPage()}
                                        onSwipedRight={() => this.nextPage()}
                                        preventDefaultTouchmoveEvent={true}
                                        trackMouse={true}>
                                        {this.renderLinkedPosts()}
                                    </SwipeableDiv>
                                </div></>) : <hr className="divider-hr pb-1" />}
                            <div className="row mx-0 px-2 user-comments mt-0 mb-0">
                                <div className="col-12 col-md-12 px-0">
                                    <div className="comments-heading mt-2">
                                        <p className="post-page-text mb-2 mt-0" style={{ display: "inline" }}>

                                            {
                                                this.state.productInfo?.comments?.length === 0 ? "No comments" :
                                                    this.state.productInfo?.comments?.length === 1 ? "1 comment" :
                                                        this.state.productInfo?.comments?.length + " of " + this.state.productInfo.comments[0].totalCount + " comments"
                                            }
                                        </p>
                                        {this.renderLoadPreviousComments()}
                                    </div>
                                    {this.renderComments()}
                                </div>
                            </div>
                            {this.renderPostComment()}
                        </div>
                    ) : null
                }



            </section>
        )
    }
}

function mapStateToProps(storeState, ownProps) {
    return { interactionState: storeState.interactionReducer }
}

export default connect(mapStateToProps, { toggleShareModal, toggleProductPageModal, toggleFollowersFollowingModal, closeAllModals })(ProductPage);