import React from "react";
import AccountBanner from "components/AccountBanner";
import { getRequest, postRequest } from "sharedUtils/httpUtils";
import BrandApplicationActionDropdown from "views/Admin/CreatorApplicationActionDropdown";
import BrandSelectorForm from "views/Admin/BrandSelectorForm";

class BrandApplications extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            applications: [],
            showModal: false,
            application: null
        }
    }

    componentDidMount = async () => {
        let applications = await this.fetchApplications();
        this.setState({ applications });
    }

    breadCrumbList = [
        { link: '/Admin', text: 'Admin' },
        { link: '/Admin/BrandApplications', text: 'Merchant Parnter Applications' }
    ]

    fetchApplications = async () => {
        let url = `api/BrandApplicationAdmin/GetAllUnapprovedApplications`;

        if (this.props.loggedInUser.isAdmin === true) {
            let results = await getRequest(url);
            return results;
        }
        else {
            return [];
        }

    }

    updateApplication = async (application) => {
        let applications = this.state.applications;
        let index = applications.indexOf((app) => { return app.id === application.id });
        applications[index] = application;
        this.setState({ applications });

        let url = `api/BrandApplicationAdmin/UpdateApplication`;
        await postRequest(url, application);
    }

    approveApplication = (application) => {
        this.setState({ application, showModal: true });
    }

    rejectApplication = (application) => {
        application.approved = 0;
        this.updateApplication(application);
    }

    setMerchantUser = (merchant) => {
        let application = this.state.application;
        application.merchantId = merchant.id;
        application.approved = 1;

        this.updateApplication(application);
    }

    toggleModal = () => {
        this.setState({ showModal: !this.state.showModal });
    }

    displayTableRows = () => {
        return this.state.applications.map((application, i) => {
            let jsonApplication = JSON.parse(application.jsonApplication);
            if (application.approved == null) {
                return (
                    <tr key={i}>
                        <td>{jsonApplication?.Name}</td>
                        <td>{jsonApplication?.Email}</td>
                        <td>{jsonApplication?.Phone}</td>
                        <td>{jsonApplication?.CompanyName}</td>
                        <td><a target="blank_" href={jsonApplication?.Website.toLowerCase().startsWith("http") ? jsonApplication?.Website : "https://" + jsonApplication?.Website}>{jsonApplication?.Website}</a></td>
                        <td>{jsonApplication?.AffiliateNetwork === "Other" ? jsonApplication?.OtherAffiliateNetwork : jsonApplication?.AffiliateNetwork}</td>
                        <td>{jsonApplication?.Relationship}</td>
                        <td>{jsonApplication?.RelationshipDetails}</td>
                        <td>
                            <BrandApplicationActionDropdown application={application} approveApplication={this.approveApplication} rejectApplication={this.rejectApplication} />
                        </td>

                    </tr>
                )
            }
        })
    }

    displayTableHeader = () => {
        let headers = ["Name", "Email", "Phone", "Company", "Website", "Affiliate Network", "Relationship", "Details"];
        return headers.map((header, i) => {
            return (
                <th key={i}><strong>{header}</strong></th>
            )
        })
    }

    render() {
        return (
            <div>
                <BrandSelectorForm showModal={this.state.showModal} toggleModal={this.toggleModal} setMerchantUser={this.setMerchantUser}/>
                <section>
                    <AccountBanner breadCrumbList={this.breadCrumbList}>
                        <h1 className="mb-4">Merchant Partner Applications</h1>
                        <p className="mb-0">Manage Merchant Partner applications</p>
                    </AccountBanner>
                </section >

                <section className="gray-bg full-height">
                    <div className="container">
                        <div className="row mt-0 mx-0 py-5">
                            <div className="table-responsive product-table" style={{ overflow: "visible" }}>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            {this.displayTableHeader()}
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {this.displayTableRows()}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}


export default BrandApplications;