import React from "react"


const PenIcon = (props) => {
    return (
        <svg onClick={props.onClick ?? {}} id={props.id ?? ""} width="20" height="20" style={props.style ?? {}} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.5 14.375V17.5H5.625L14.8417 8.28333L11.7167 5.15833L2.5 14.375ZM17.2583 5.86666C17.5833 5.54166 17.5833 5.01666 17.2583 4.69166L15.3083 2.74166C14.9833 2.41666 14.4583 2.41666 14.1333 2.74166L12.6083 4.26666L15.7333 7.39166L17.2583 5.86666Z" fill="black" fillOpacity="0.6" />
        </svg>
    )
}

export default PenIcon;