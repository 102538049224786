import React from "react";

/**
 * 
 * @param {{fill:string, height?:string, width?:string}} props
 */
const ClickBurstIcon = (props) => {
    const svgStyle = {
        color: "#000000",
        enableBackground: "accumulate",
        ...(props.style || {})
    };

    return (
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" viewBox="0 0 100 125" height={props.height || "30"} width={props.width || "30"} style={svgStyle}>
            <g transform="translate(0,-952.36218)">
                <path d="m 42,965.36215 c -1.1046,0 -2,0.8954 -2,2 l 0,16 c 0,1.1046 0.8954,1.99996 2,1.99996 1.1046,0 2,-0.89536 2,-1.99996 l 0,-16 c 0,-1.1046 -0.8954,-2 -2,-2 z m -19.0938,7.90625 c -0.5117,0 -1.0156,0.20335 -1.4062,0.59375 -0.781,0.7812 -0.7811,2.0314 0,2.8125 l 9.9062,9.90626 c 0.7811,0.7812 2.0315,0.781 2.8126,0 0.781,-0.7812 0.7809,-2.03131 0,-2.81251 l -9.9063,-9.90625 c -0.3906,-0.3906 -0.8943,-0.59375 -1.4063,-0.59375 z m 38.1876,0 c -0.512,0 -1.0157,0.20315 -1.4063,0.59375 l -9.9063,9.90625 c -0.7809,0.7812 -0.781,2.03131 0,2.81251 0.7811,0.781 2.0315,0.7812 2.8126,0 L 62.5,976.67465 c 0.7811,-0.7811 0.781,-2.0313 0,-2.8125 -0.3906,-0.3904 -0.8945,-0.59375 -1.4062,-0.59375 z M 15,992.36211 c -1.1046,0 -2,0.8954 -2,2 0,1.1046 0.8954,2 2,2 l 15.9375,0 c 1.1046,0 2,-0.8954 2,-2 0,-1.1046 -0.8954,-2 -2,-2 l -15.9375,0 z m 26.0312,1.0313 17.7813,44.62499 8.375,-14.2187 15.5625,15.5625 4.25,-4.25 -15.5625,-15.5625 14.2187,-8.3751 -44.625,-17.78119 z m -8.2187,8.15619 c -0.5118,0 -1.0157,0.2032 -1.4063,0.5938 l -9.9062,9.9062 c -0.7811,0.7811 -0.781,2.0314 0,2.8126 0.7811,0.7808 2.0313,0.7812 2.8125,0 l 9.9063,-9.9063 c 0.7809,-0.7812 0.781,-2.0313 0,-2.8125 -0.3906,-0.3905 -0.8945,-0.5938 -1.4063,-0.5938 z" fill={props.fill || "#000000"} stroke="none" marker="none" visibility="visible" display="inline" overflow="visible" />
            </g>
        </svg>
    );
}

export default ClickBurstIcon;
