import React from "react"

/**
 * 
 * @param {{fill:string}} props
 */
const BookmarkIcon = (props) => {
    return (
        <svg width={props.width || "12"} height={props.height || "12"} viewBox="0 0 12 12" fill={ props.fill || "none"} xmlns="http://www.w3.org/2000/svg">
            <path
                strokeWidth="1.25" stroke="currentColor" strokeOpacity="0.6"
                d="M8.5 1.5H3.5C2.95 1.5 2.5 1.95 2.5 2.5V10.5L6 9L9.5 10.5V2.5C9.5 1.95 9.05 1.5 8.5 1.5Z" fill={props.fill} fillOpacity="0.54" />
        </svg>
    )
}

export default BookmarkIcon;