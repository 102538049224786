import React from "react";

const VectorIcon = () => {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.33333 12H0V6.66667H1.33333V9.72667L9.72667 1.33333H6.66667V0H12V5.33333H10.6667V2.27333L2.27333 10.6667H5.33333V12Z" fill="black" fillOpacity="0.6" />
        </svg>
    )
}

export default VectorIcon;