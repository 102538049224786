import React from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { Popover, PopoverBody } from "reactstrap"
import PlusIcon from "components/icons/PlusIcon";
import Carousel from "components/Carousel";
import PostAuthorBanner from "../../components/PostAuthorBanner";
import FormInputField from "components/FormInputField";
import PostBanner from "components/PostBanner";
import UserTileGeneral from "components/UserTileGeneral";
import ProductsSelectorModal from "components/UserMerchantProducts/ProductsSelectorModal";
import PostPageProduct from "components/PostPageProduct";
import MentionsInputTextArea from "components/MentionsInputWrappers/MentionsInputTextArea";
import DisplayProducts from "components/DisplayProducts/DisplayProducts"
import LoadSpinner from "components/LoadSpinner";
import SaleIcon from "components/icons/SaleIcon";
import InformationIcon from "components/icons/InformationIcon";

import { getRequest, postRequest } from "sharedUtils/httpUtils";
import { trimProductsArray, getProperties } from "sharedUtils/postFunctions";
import { toggleProductSelectModal, toggleProductPageModal, closeAllModals } from "stateManagement/reducers/modalReducer";
import { Helmet } from 'react-helmet';


class PostPageAuthor extends React.Component {
    state = {
        likes: 0,
        comments: 0,
        isEditing: true,
        isPublished: false,
        isPublishing: false,
        isSaving: false,
        postText: "",
        videoLoaded: true,
        interests: [],
        images: [],
        showModal: false,
        post: {},
        notAuthorized: false,
        postContentChange: "",
        finishedLoading: false,
        currentMediaItemIndex: 0,
        sectors: [],
        deletedPost: false,
        sectorFilter: "",
        mentionSuggestions: [],
        postCount: 1,
        showReminder: false,
        seenReminder: false,
        isForEdit: false,
    }

    async componentDidMount() {
        if (this.props?.location?.search?.toLowerCase().includes("?edit=true")) {
            this.setState({ isForEdit: true })
        }
        this.props.closeAllModals();
        let scrollOptions = {
            left: 0,
            top: 0,
            behavior: 'auto',
            lockerNotFound: true
        }
        window.scrollTo(scrollOptions);

        let isAuthenticated = this.props?.loggedInUser === undefined ? false : true;
        if (this.props.location.state?.post === undefined) {
            let post = await this.fetchPost(this.props.match.params.shareCode, isAuthenticated);

            let isAuthorized = post.user?.id == this.props.loggedInUser.id

            this.setState({ post: post, postContentChange: post.content, finishedLoading: true, notAuthorized: !isAuthorized });
        }
        else {
            let post = this.props.location.state.post;
            let isAuthorized = post.user?.id == this.props.loggedInUser.id;
            this.setState({ post: post, postContentChange: post.content, finishedLoading: true, notAuthorized: !isAuthorized });
        }

        let postCount = await this.getPostCount();
        let sectors = await this.fetchSectors();
        this.setState({ sectors, postCount });
    }

    async componentDidUpdate(prevProps,prevState) {
        if (this.props.match.params.shareCode !== prevProps.match.params.shareCode) {
            this.props.closeAllModals();
            let isAuthenticated = this.props?.loggedInUser === undefined ? false : true;
            this.setState({ finishedLoading: false });
            let post = await this.fetchPost(this.props.match.params.shareCode, isAuthenticated);
            this.setState({ post, postContentChange: post.content, finishedLoading: true });
        }
    }

    addProductsToPost = async (products) => {
        let newState = { ...this.state };

        products.forEach((prod) => prod.coupon = prod.shareCode);
        let productMap = products.map((prod) => {           
            prod.merchant = { name: prod.merchant };
            prod.product = { manufacturer: { name: prod.manufacturer } };
            return {
                userMerchantProduct: { ...prod, product: prod }
            }
        });

        var userMerchantProductIds = products.map((prod) => prod.userMerchantProductId);
        var added = await postRequest(`/api/Post/addPostProducts?shareCode=${this.state.post.shareCode}`, userMerchantProductIds);

        added.forEach((prod) => {
            var index = productMap.findIndex((productItem) => { return productItem.userMerchantProduct.userMerchantProductId === prod.userMerchantProductId });
            productMap[index].id = prod.id;
        })
        productMap = productMap.reverse();
        newState.post.productItems.$values = [...productMap, ...this.state.post.productItems.$values];
        this.setState({ post: newState.post, seenReminder: true });
    }

    addSectorToPost = (sector) => {
        let postCopy = { ...this.state.post };

        let postSector = {
            sector: { ...sector, id: sector.sectorId }
        }
        postCopy.sectors.$values.push(postSector);
        this.setState({ post: postCopy });

    }

    removeSectorFromPost = (sector) => {
        let postCopy = { ...this.state.post };
        let sectorSelected = postCopy.sectors?.$values?.findIndex(sec => sec.sector.id === sector.sectorId);
        postCopy.sectors.$values.splice(sectorSelected, 1);

        this.setState({ post: postCopy });
    }

    deletePost = async () => {
        let url = `/api/Locker/DeleteLocker`;
        let payload = {
            user: {
                id: this.state.post.user.id
            },
            type: 2,
            shareCode: this.state.post.shareCode,
            id: this.state.post.id
        }
        await postRequest(url, payload);
        this.setState({
            deletedPost: true,
            postText: "",
        });
    }

    displayLoadSpinner = () => {
        return (
            <div className="spinner-border spinner-border-sm" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        )

    }

    displayEditBannerOrImage = () => {
        if (this.state.images.length === 0) {
            return <PostAuthorBanner />
        }
        else {
            return <Carousel images={this.state.images} />
        }
    }

    displaySectors = () => {
        let filtered = this.state.sectors.filter(sec => sec.name.toLowerCase()
            .includes(this.state.sectorFilter.toLowerCase()));

        return filtered.map((sector, i) => {
            let sectorSelected = this.state.post.sectors?.$values?.findIndex(sec => sec.sector.id === sector.sectorId);
            if (sectorSelected > -1) {
                return (
                    <button className="btn d-flex align-items-center active" key={i} onClick={() => this.removeSectorFromPost(sector)}>
                        {sector.name}
                    </button>
                )
            }
            else {
                return (
                    <button className="btn d-flex align-items-center" key={i} onClick={() => this.addSectorToPost(sector)}>
                        {sector.name}
                    </button>
                )
            }
        });
    }

    removeCommentUsername = text => {
        const regex = /#!\^.*?\)\)\)/g;
        return text.replace(regex, '');
    }

    toTitleCase = (string) => {
        return string.toLowerCase().replace(/(?:^|\s|-)\w/g, function (match) {
            return match.toUpperCase();
        });
    }   

    displayProducts = () => {
        if (this.state.post?.productItems?.$values) {
            return trimProductsArray(this.state.post?.productItems?.$values).map((productItem, i) => {
                return (
                    <React.Fragment key={i}>
                        <div className="col-4 col-md-3 px-1 py-1">
                            
                            <div className="post-page-product">
                                <div className="product-image-in-page">
                                    <PostPageProduct
                                        key={i}
                                        product={productItem}
                                        productKey={i}
                                        isEditing={false}
                                        deviceSize={this.props.deviceSize}
                                    />
                                    <div>
                                        <button type="button"
                                            className="close"
                                            aria-label="Close"
                                            style={{ display: "inline-block" }}
                                            onClick={() => this.removeProductFromPost(productItem.shareCode)}
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                </div>
                                <div className="d-flex flex-wrap justify-content-between align-items-center py-0 px-1" style={{ background: "white", borderRadius: "0px 0px 15px 15px" }}>
                                    <div className="users user-tile-general py-1 ml-2" style={{ whiteSpace: "nowrap" }}>
                                        <h5 style={{ marginBottom: "0px" }}><div style={{ maxWidth: "1px" }}>{productItem.manufacturer?.toLowerCase().includes("rcktemp") ? productItem.merchant : productItem.manufacturer}{productItem.sale && !!(productItem.salePrice) && !["sm", "md"].includes(this.props.deviceSize) ? <SaleIcon style={{ marginLeft: "3px" }} /> : <></>}</div></h5>
                                    </div>
                                    <div className="users user-tile-general py-1 ml-2" style={{ width: "95%", whiteSpace: "nowrap" }}>
                                        <h5 style={{ marginBottom: "0px" }}>
                                            <div style={{ display: "table", tableLayout: "fixed", width: "100%" }}>
                                                <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", display: "table-cell" }}>
                                                    {this.toTitleCase(productItem.name)}
                                                </div>
                                            </div>
                                        </h5>
                                    </div>
                                    <div className={`users user-tile-general py-1 ml-${productItem.salePrice ? "2" : "1"}`} style={{ whiteSpace: "nowrap" }}>
                                        <h5 style={{ marginBottom: "0px" }}><div className="d-flex" style={{ maxWidth: "1px" }}><div className="mr-1" >{productItem.sale && !!(productItem.salePrice) ? "$" + productItem.salePrice.toFixed(2) : <></>}</div><div style={{ fontSize: productItem.sale && !!(productItem.salePrice) && this.props.deviceSize !== "sm" ? "10px" : "" }} className={`${productItem.sale && !!(productItem.salePrice) ? "on-sale-price" : ""}`}>${productItem.sale && !!(productItem.salePrice) && !!(productItem.originalPrice) ? productItem.originalPrice.toFixed(2) : productItem.price.toFixed(2)}</div></div></h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                )
            })
        }
    }

    fetchPost = async (shareCode, isAuthorized) => {
        let baseUrl = isAuthorized === true ? "/api/post/getPostAuthorized" : "/api/post/getPost";
        let url = `${baseUrl}?shareCode=${shareCode}&referrer=${document.referrer}`;
        let post = await getRequest(url);
        return post;
    }

    fetchSectors = async () => {
        let url = `api/Sector/GetSectors`;
        let sectors = await getRequest(url);
        sectors = sectors.sort((a, b) => { if (a.name < b.name) return -1; });
        if (sectors === undefined) {
            return [];
        }
        else {
            return sectors;
        }
    }

    getPostCount = async () => {
        let url = `api/Post/GetPostCount?userId=${this.props.loggedInUser.id}`;
        let count = await getRequest(url);
        let sum = count.length > 0 ? count.reduce(function (a, b) {
            return { count: a.count + b.count };
        }) : { count: 0 };
        return sum.count;
    }

    fetchUserSuggestions = async (query, callback) => {
        if (!query || query.length < 1) {
            return;
        }

        let url = `/api/Account/GetUserSuggestionsByUsername?username=${query}`;
        let users = await getRequest(url);
        if (users === undefined) {
            this.setState({ mentionSuggestions: [] });
        }
        else {
            this.setState({ mentionSuggestions: users });

        }

        let mappedUsers = this.state.mentionSuggestions.map(user => ({ display: user.userName, id: user.id, name: user.name }));
        callback(mappedUsers);
    }

    handleInput = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    publish = async () => {
        if (this.state.postCount == 1 && !this.state.seenReminder && this.state.post.productItems.$values.length === 0) {
            this.setState({ showReminder: true });
            document.getElementById("addProdButton").scrollIntoView();;
            return;
        }

        let payload = {
            content: this.state.postContentChange,
            title: "",
            user: { id: this.state.post.user.id },
            id: this.state.post.id,
            statusId: 1,
            sectors: this.state.post.sectors.$values.map((value) => {
                return {
                    sector: { id: value.sector.id, name: value.sector.name }
                };
            })
        }

        this.setState({ isPublishing: true })

        var url = `/api/post/EditPost?isPublishing=${true}&isEditing=${this.state.isForEdit}`;
        var published = await postRequest(url, payload);

        if (published.title === "Unauthorized") {
            this.setState({ notAuthorized: true, isPublished: false, isPublishing: false });
            return;
        }
        if (published === undefined) {
            this.setState({ isPublished: false, isPublishing: false })
        }
        else {
            this.setState({ isPublished: true, isPublishing: false });
        }
    }

    removeProductFromPost = async (coupon) => {
        let updatedPost = { ...this.state.post };
        let location = updatedPost.productItems.$values.findIndex(prod => prod.userMerchantProduct.coupon === coupon);
        let product = updatedPost.productItems.$values.find(prod => prod.userMerchantProduct.coupon === coupon);
        if (location > -1) {
            updatedPost.productItems.$values.splice(location, 1);
        }

        this.setState({ post: updatedPost })

        let payload = {
            user: {
                id: this.state.post.user?.id
            }
        };

        await postRequest(`/api/Locker/DeleteLockerProduct?userPostUserMerchantProductId=${product.id}`, payload);
    }

    saveChanges = async () => {
        await this.saveContent();
        await this.saveSectors();
    }

    saveSectors = async () => {
        let url = `/api/Sector/UpdateUserPostSectors?postId=${this.state.post.id}`;
        let sectors = this.state.post.sectors.$values.map(sec => sec.sector.id);
        await postRequest(url, sectors);
    }

    saveContent = async () => {
        this.setState({ isSaving: true })
        let url = `/api/post/EditPost?isPublishing=${false}&isEditing=${this.state.isForEdit}`;
        let payload = {
            content: this.state.postContentChange,
            title: "",
            user: { id: this.state.post.user.id },
            id: this.state.post.id,
            statusId: 0
        }

        var edit = await postRequest(url, payload);

        this.setState({ isSaving: false });
        return edit;
    }

    toggleIsEditing = () => {
        let newIsEditing = !this.state.isEditing;
        this.setState({ isEditing: newIsEditing });
    }

    toggleIsPublished = () => {
        let newIsPublished = !this.state.isPublished;
        this.setState({ isPublished: newIsPublished });
    }

    reorderProductsInPost = (items) => {
        let reorderedItems = items.map((item, index) => {
            item.ordinal = index + 1;
            return item;
        });

        let newValues = this.state.post?.productItems.$values.map(item => {
            for (let i = 0; i < reorderedItems?.length; i++) {
                if (reorderedItems[i].id == item.id) {
                    item.ordinal = reorderedItems[i].ordinal;
                };
            };
            return item;
        }).sort(function (a, b) {
            return a.ordinal - b.ordinal;
        });

        let post = this.state.post;
        post.productItems.$values = newValues;
        this.setState({ post });
    }

    renderInfoOverlay = (shareCode) => {
        return <span className="info-overlay-post-edit position-absolute" onClick={() => {
            this.props.toggleProductPageModal({ shareCode }, this.props.loggedInUser, false, () => null)
        }} style={{ cursor: "pointer" }}>
            <InformationIcon fill="#E55934" />
        </span>
    }

    truncateContentForTitle(str) {
        if (str.length <= 20) {
            return str;
        } else {
            return str.substr(0, 20) + "...";
        }
    }


    getPageTitle = () => {
        const shareCode = this.props.match?.params?.shareCode;

        return "RockPorch - Edit Post: " + shareCode;
    };

    setVideoLoaded = (videoLoaded) => {
        this.setState({ videoLoaded });
    }

    render() {

        let postImages = getProperties(this.state.post?.properties)
            ?.filter(property => property?.parentId == null && (property?.role === 0 || property?.role === 1 || property?.role === 3))
            .map(property => {
                return {
                    url: property?.value,
                    role: property?.role,
                    id: property?.id,
                    width: property?.width,
                    height: property?.height
                }
            }) ?? [];

        let { currentMediaItemIndex } = this.state;

        let searchInterestRowClass = ["sm", "md"].includes(this.props.deviceSize) ? "" : "";

        if (this.state.post === undefined) {
            return <Redirect to="/HomeFeed" />
        }

        if (this.state.finishedLoading === false) {
            return (
                <>
                    < LoadSpinner />
                        <Helmet>
                        <title>{this.getPageTitle()}</title>
                        </Helmet>
                </>
            )
        }

        return (
            <section>
                <Helmet>
                    <title>{this.getPageTitle()}</title>
                </Helmet>
                {
                    this.state.isPublished === true ? (
                        <Redirect to={`/HomeFeed`} />
                    ) : <span></span>
                }

                {
                    this.state.notAuthorized === true ? (<Redirect to={`/Post/PostPage/${this.state.post.shareCode}`} />) : (<span></span>)
                }


                {
                    this.state.deletedPost === true ? (
                        <Redirect to={`/HomeFeed`} />
                    ) : <span></span>
                }

                <ProductsSelectorModal
                    postShareCode={this.state.post.shareCode}
                    existingProducts={this.state.post.productItems?.$values}
                    addToLocker={this.addProductsToPost}
                    deviceSize={this.props.deviceSize}
                />

                <nav className="navbar new-locker-taskbar">
                    <div className="col-12" style={{ display: "flex", justifyContent: "space-between", margin: "auto", width: "85%" }}>
                        <h6 className="mt-3">Draft</h6>
                    </div>
                </nav>

                <div className="container">
                    <div className="row mt-0">
                        <div className={`col-md-8 ${this.props.deviceSize === "sm" ? "mt-0 px-0" : "pr-2 mt-2 ml-0 px-0"}`}>
                            <PostBanner images={postImages} customClass="post-page-banner-modal" setVideoLoaded={this.setVideoLoaded}
                                properties={this.state.post.properties} />
                        </div>

                        <div className="col-md-4 px-2 mt-2">

                            <UserTileGeneral user={this.state.post.user && this.state.post.user} size={"Profile"} subtitle={" "} showCheckMark={false} />

                            <label className="control-label" style={{ fontFamily: "Archivo" }}>
                                <strong>Edit Caption</strong>
                            </label>

                            <MentionsInputTextArea
                                className="caption-textarea"
                                value={this.state.postContentChange}
                                onChange={(e) => this.setState({ postContentChange: e.target.value })}
                                data={this.fetchUserSuggestions}
                            />

                            <div style={{ width: "100%" }}>
                                <FormInputField
                                    title="Search for interests..."
                                    name="sectorFilter"
                                    inputType="text"
                                    onInputChange={this.handleInput}
                                    placeholder="Search for interests..."
                                    value={this.state.sectorFilter}
                                    formGroupClass="mb-0 mt-2"
                                />
                            </div>

                            {/*

                             */}
                            <div className="search-interest mx-auto mt-2">

                                <div className="search-block">

                                    <div className="tag-results-sm d-flex justify-content-center flex-wrap py-0">
                                        {this.displaySectors()}
                                    </div>

                                </div>
                            </div>


                        </div>

                    </div>


                    {/*
                     <div className={`row ${searchInterestRowClass}`}>

                        <div className="col-12">

                            <div className="search-interest mx-auto">

                                <div className="search-block">

                                    <div className="tag-results-sm d-flex justify-content-center flex-wrap py-0">
                                        {this.displaySectors()}
                                    </div>

                                </div>
                            </div>


                        </div>

                    </div>
                     */}
                    

                    <hr className="mt-3 mb-0"/>

                </div>

                <div className="container product-grid pt-0 pb-2 border border-0">
                    <div className="row mt-2">
                        <div className="col-12 mb-1">

                            <button className={`btn btn-primary ${this.props.deviceSize === "sm" ? "my-1" : "my-2"} my-sm-0 ml-0`}
                                id="addProdButton"
                                title="Add your first product"
                                onClick={this.props.toggleProductSelectModal}
                                style={{ fontSize: this.props.deviceSize == "sm" ? "15px" : "" }}
                            >
                                Add Product
                            </button>

                            <div className="action-button-wrapper">
                                
                                {
                                    this.props.location.state?.justCreated === true ? (
                                        <button type="button" className="btn btn-danger my-2 my-sm-0" onClick={this.deletePost}
                                            style={{ fontSize: this.props.deviceSize == "sm" ? "15px" : "" }}
                                        >
                                            Cancel
                                        </button>
                                    ) : (
                                            <button className="btn btn-danger" onClick={this.deletePost} style={{ fontSize: this.props.deviceSize == "sm" ? "15px" : "" }}>
                                            Delete
                                        </button>
                                        )
                                }
                                {
                                    this.state.post.status != 1 ? (
                                        <button className="btn btn-secondary" onClick={this.saveChanges} style={{ fontSize: this.props.deviceSize == "sm" ? "15px" : "" }}>
                                            {
                                                this.state.isSaving === true ? this.displayLoadSpinner() : null
                                            }

                                            Save
                                        </button>
                                    ) : <span></span>
                                }

                                {
                                    this.state.videoLoaded ?
                                        <button className="btn btn-primary" onClick={this.publish} style={{ fontSize: this.props.deviceSize == "sm" ? "15px" : "" }}>
                                            {
                                                this.state.isPublishing === true ? this.displayLoadSpinner() : null
                                            }

                                            Publish
                                        </button>
                                        :
                                        <button className="btn btn-primary" onClick={this.publish} disabled style={{ fontSize: this.props.deviceSize == "sm" ? "15px" : "" }}>
                                            {
                                                this.state.isPublishing === true ? this.displayLoadSpinner() : null
                                            }

                                            Publish
                                        </button>                                }

                            </div>
                        </div>
                    </div>

                    {/*<div className="row product-image-gallery justify-content-center position-relative mt-0 mx-0">*/}
                    {/*    {this.displayProducts()}*/}
                    {/*</div>*/}

                    <div className="row product-image-gallery justify-content-center position-relative mt-0 mx-0">
                        <DisplayProducts
                            products={trimProductsArray(this.state.post?.productItems?.$values)?.sort(function (a, b) {
                                return a.ordinal - b.ordinal;
                            })}
                            removeProductFromPost={this.removeProductFromPost}
                            reorderProductsInPost={this.reorderProductsInPost}
                            deviceSize={this.props.deviceSize}
                            postId={this.state.post?.id}
                            renderInfoOverlay={this.renderInfoOverlay}
                        />
                    </div>

                </div>

                <Popover placement="top"
                    isOpen={this.state.showReminder}
                    target="addProdButton"
                    trigger="legacy"
                    toggle={() => { this.setState({ showReminder: false, seenReminder: true }) }}
                >
                    <PopoverBody>Don't forget to add products to your post!  Click here to add a product.</PopoverBody>
                </Popover>

            </section>
        )
    }
}


export default connect(null, { toggleProductSelectModal, toggleProductPageModal, closeAllModals })(PostPageAuthor);