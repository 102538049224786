import React from "react";

const InformationIcon = (props) => {
    return (
        <svg style={ props.style }width={props.x || "18"} height={props.y || "18"} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.25 5.25H9.75V6.75H8.25V5.25Z" fill={props.fill || "black"} fillOpacity={props.fillOpacity || "0.54"} />
            <path d="M8.25 8.25H9.75V12.75H8.25V8.25Z" fill={props.fill || "black"} fillOpacity={props.fillOpacity || "0.54"} />
            <path fillRule="evenodd" clipRule="evenodd" d="M1.5 9C1.5 4.86 4.85999 1.5 9 1.5C13.14 1.5 16.5 4.86 16.5 9C16.5 13.14 13.14 16.5 9 16.5C4.85999 16.5 1.5 13.14 1.5 9ZM3 9C3 12.3075 5.69247 15 9 15C12.3075 15 15 12.3075 15 9C15 5.6925 12.3075 3 9 3C5.69247 3 3 5.6925 3 9Z" fill={props.fill || "black"} fillOpacity={props.fillOpacity || "0.54"} />
        </svg>
    )
}

export default InformationIcon;