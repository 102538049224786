import * as React from "react";
import { Link } from "react-router-dom";
import SiteMapSection from "../../components/SiteMapSection";
import * as locations from "../sitepaths";

const SiteMap = () => {
    /**
     * @description Takes an array and renders links
     * @param {{Name:string,Path:string}[]} paths
     * @returns {JSX[]}
     */
    const renderPaths = (paths) => {
        return paths.map((location, i) => {
            return (
                <li className="nav-item" key={i}>
                    <Link
                        to={{ pathname: location.Path }}
                        className="nav-link text-dark"
                    >
                        {location.Name}
                    </Link>
                </li>
            );
        });
    };
    return (
        <section className="container">
            <div className="row">
                <h1 className="display-4">RockPorch</h1>
            </div>

            <div className="row">
                <SiteMapSection
                    Title="Account"
                    Paths={renderPaths(locations.default.accountPaths)}
                />

                <SiteMapSection
                    Title="Post"
                    Paths={renderPaths(locations.default.postPaths)}
                />
            </div>

            <hr></hr>

            <div className="row">
                <SiteMapSection
                    Title="Auth & Onboarding"
                    Paths={renderPaths(locations.default.authPaths)}
                />

                <SiteMapSection
                    Title="Products"
                    Paths={renderPaths(locations.default.productPaths)}
                />
            </div>

            <hr></hr>

            <div className="row">
                <SiteMapSection
                    Title="Feeds"
                    Paths={renderPaths(locations.default.feedPaths)}
                />
                <SiteMapSection
                    Title="Profile"
                    Paths={renderPaths(locations.default.profilePaths)}
                />
            </div>

            <hr></hr>

            <div className="row">
                <SiteMapSection
                    Title="Locker"
                    Paths={renderPaths(locations.default.lockerPaths)}
                />
            </div>
        </section>
    );
};

export default SiteMap;
