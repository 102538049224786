import React from "react"


/**
 * 
 * @param {{width:string,height:string, fill:string}} props
 */
const PlusIcon = (props) => {
    return (
        <svg className="follow-icon-sign" width={props.width || "24"} height={props.height || "24"} viewBox="0 0 24 24" fill={props.fill || "none"} xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19 11H5C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13H19C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11Z"
                fill="" fillOpacity={props.fillOpacity || "1"}/>
            <path
                d="M13 19L13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5L11 19C11 19.5523 11.4477 20 12 20C12.5523 20 13 19.5523 13 19Z"
                fill="" fillOpacity={props.fillOpacity || "1"}/> </svg>
    )
}

export default PlusIcon