import React from "react";
import { Link } from "react-router-dom"
class NotFound extends React.Component {

    render() {
        return (
            <div className="container not-found-container">
                <img src="images/NotFound.jpg" className="notfound-bg mt-3" />
                <div className="not-found-text-container">
                    <h6>Not all who wander are lost</h6>
                    <h6>But we can't find the page you're looking for</h6>
                    <h6>
                        Enjoy the view and go <Link to="/Explore">
                            <button className="btn btn-primary btn-sm px-1 py-0">
                                explore
                            </button>
                        </Link>
                    </h6>
                </div>
            </div>
        )
    }
}

export default NotFound;