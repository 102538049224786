import React from "react";
import { Link } from "react-router-dom";


/**
 * 
 * @param {{link:string,icon:JSX,title:string}} props
 */
const DashboardTile = (props) => {
    return (
        <Link to={props.link} className="card-block d-flex align-items-center justify-content-center flex-column">
            {props.icon}
            <h4 className="text-center m-0">
                {props.title}
            </h4>
        </Link>
        )
}

export default DashboardTile;