import React from "react";


const ModalHeader = (props) => {
    return (
        <div className={`modal-header ${props.className}`} style={{ padding: props.creatorApp ? 0 :  "0.1rem 0.1rem", margin: props.creatorApp ? "8px" : "" }}>
            {props.children}
        </div>
    )
}

export default ModalHeader;