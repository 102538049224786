import React from "react";
import { Link } from 'react-router-dom';
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider, withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import _ from "lodash";
import AccountBanner from "components/AccountBanner";
import { getRequest, postRequest } from "sharedUtils/httpUtils";
import LoadSpinnerOverlay from "components/LoadSpinnerOverlay";
import toast, { Toaster } from 'react-hot-toast';
import $ from 'jquery';
import CreatorPaymentRunTotals from "./CreatorPaymentRunTotals"
    
class PaymentRunDetails extends React.Component {

    state = {
        loading: false,
        selectedCase: 1,
        paymentRun: {},
        paymentRunDetailInfos: {},
        affiliateTable: {
            title: "Affiliate Networks",
            columns: [
                {
                    name: "affiliateProgramManagerId",
                    label: "Id",
                    options: {
                        filter: true,
                        sort: true
                    }
                },
                {
                    name: "affiliateProgramManagerName",
                    label: "Affiliate",
                    options: {
                        filter: true,
                        sort: true
                    }
                },
                {
                    name: "totalRecords",
                    label: "Total Records",
                    options: {
                        filter: true,
                        sort: true
                    }
                },
                {
                    name: "pendingCount",
                    label: "Pending Count",
                    options: {
                        filter: true,
                        sort: true,
                        display: false // Column is not visible but can be made visible via the View Columns popover in the Toolbar.
                    }
                },
                {
                    name: "pendingAmount",
                    label: "Pending",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRenderLite: (dataIndex, rowIndex) => {
                            let item = this.state.affiliateTable.data[dataIndex];
                            if (item) {
                                return '$' + item.pendingAmount.toFixed(2);
                            }
                            return '';
                        }
                    }
                },
                {
                    name: "skippedCount",
                    label: "Skipped Count",
                    options: {
                        filter: true,
                        sort: true,
                        display: false // Column is not visible but can be made visible via the View Columns popover in the Toolbar.
                    }
                },
                {
                    name: "skippedAmount",
                    label: "Skipped",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRenderLite: (dataIndex, rowIndex) => {
                            let item = this.state.affiliateTable.data[dataIndex];
                            if (item) {
                                return '$' + item.skippedAmount.toFixed(2);
                            }
                            return '';
                        }
                    }
                },
                {
                    name: "rejectedCount",
                    label: "Rejected Count",
                    options: {
                        filter: true,
                        sort: true,
                        display: false // Column is not visible but can be made visible via the View Columns popover in the Toolbar.
                    }
                },
                {
                    name: "rejectedAmount",
                    label: "Rejected",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRenderLite: (dataIndex, rowIndex) => {
                            let item = this.state.affiliateTable.data[dataIndex];
                            if (item) {
                                return '$' + item.rejectedAmount.toFixed(2);
                            }
                            return '';
                        }
                    }
                },
                {
                    name: "noPaymentEmailCount",
                    label: "No Payment Email Count",
                    options: {
                        filter: true,
                        sort: true,
                        display: false // Column is not visible but can be made visible via the View Columns popover in the Toolbar.
                    }
                },
                {
                    name: "noPaymentEmailAmount",
                    label: "No Payment Email",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRenderLite: (dataIndex, rowIndex) => {
                            let item = this.state.affiliateTable.data[dataIndex];
                            if (item) {
                                return '$' + item.noPaymentEmailAmount.toFixed(2);
                            }
                            return '';
                        }
                    }
                },
                {
                    name: "approvedCount",
                    label: "Approved Count",
                    options: {
                        filter: true,
                        sort: true,
                        display: false // Column is not visible but can be made visible via the View Columns popover in the Toolbar.
                    }
                },
                {
                    name: "approvedAmount",
                    label: "Approved",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRenderLite: (dataIndex, rowIndex) => {
                            let item = this.state.affiliateTable.data[dataIndex];
                            if (item) {
                                return '$' + item.approvedAmount.toFixed(2);
                            }
                            return '';
                        }
                    }
                },
                {
                    name: "paidCount",
                    label: "Paid Count",
                    options: {
                        filter: true,
                        sort: true,
                        display: false // Column is not visible but can be made visible via the View Columns popover in the Toolbar.
                    }
                },
                {
                    name: "paidAmount",
                    label: "Paid",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRenderLite: (dataIndex, rowIndex) => {
                            let item = this.state.affiliateTable.data[dataIndex];
                            if (item) {
                                return '$' + item.paidAmount.toFixed(2);
                            }
                            return '';
                        }
                    }
                },
                {
                    name: "rockPorchCommissions",
                    label: "RockPorch Commissions",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRenderLite: (dataIndex, rowIndex) => {
                            let item = this.state.affiliateTable.data[dataIndex];
                            if (item) {
                                return '$' + item.rockPorchCommissions.toFixed(2);
                            }
                            return '';
                        },
                        display: false // Column is not visible but can be made visible via the View Columns popover in the Toolbar.
                    }
                },
                {
                    name: "creatorCommissions",
                    label: "Creator Commissions",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRenderLite: (dataIndex, rowIndex) => {
                            let item = this.state.affiliateTable.data[dataIndex];
                            if (item) {
                                return '$' + item.creatorCommissions.toFixed(2);
                            }
                            return '';
                        },
                        display: false // Column is not visible but can be made visible via the View Columns popover in the Toolbar.
                    }
                },
                {
                    name: "totalCommissions",
                    label: "Total Commissions",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRenderLite: (dataIndex, rowIndex) => {
                            let item = this.state.affiliateTable.data[dataIndex];
                            if (item) {
                                return '$' + item.totalCommissions.toFixed(2);
                            }
                            return '';
                        }
                    }
                }
            ],
            data: [],
            options: {
                customToolbarSelect: () => { },
                selectableRows: "multiple",
                sortOrder: {
                    name: 'affiliateProgramManagerName',
                    direction: 'asc'
                },
                onRowsSelect: (curRowSelected, allRowsSelected) => {
                    const dataIndecies = allRowsSelected.map((row) => { return row.dataIndex; });
                    let selectedAffiliateProgramManagers = [];

                    dataIndecies.forEach((i) => { selectedAffiliateProgramManagers.push(this.state.affiliateTable.data[i]); });

                    let affiliateDetailTableData = [];
                    const selectedAffiliateProgramManagerIds = selectedAffiliateProgramManagers.map((affiliateProgramManager) => { return affiliateProgramManager.affiliateProgramManagerId; });

                    this.state.paymentRunDetailInfos.forEach(paymentRunDetail => {
                        if (selectedAffiliateProgramManagerIds.includes(paymentRunDetail.affiliateProgramManagerId)) {
                            let item = {
                                paymentRunDetailId: paymentRunDetail.paymentRunDetailId,
                                paymentRunDetailStatusName: paymentRunDetail.paymentRunDetailStatusName,
                                affiliateProgramManagerId: paymentRunDetail.affiliateProgramManagerId,
                                affiliateProgramManagerName: paymentRunDetail.affiliateProgramManagerName,
                                merchantId: paymentRunDetail.merchantId,
                                merchantName: paymentRunDetail.merchantName,
                                campaignMerchantId: paymentRunDetail.campaignMerchantId,
                                campaignMerchantName: paymentRunDetail.campaignMerchantName,
                                userId: paymentRunDetail.userId,
                                userName: paymentRunDetail.userName,
                                userDisplayName: paymentRunDetail.userDisplayName,
                                userEmail: paymentRunDetail.userEmail,
                                userCreatedDate: paymentRunDetail.userCreatedDate,
                                saleAmount: paymentRunDetail.saleAmount,
                                merchantCommissionRate: (paymentRunDetail.merchantCommissionRate * 100).toFixed(0) + '%',
                                actualCommissionRate: (paymentRunDetail.totalAmount / paymentRunDetail.saleAmount * 100).toFixed(0) + '%',
                                userShare: (paymentRunDetail.userShare * 100).toFixed(0) + '%',
                                rockPorchCommissions: Number((paymentRunDetail.totalAmount - paymentRunDetail.amount).toFixed(2)),
                                creatorCommissions: paymentRunDetail.amount,
                                totalCommissions: paymentRunDetail.totalAmount,
                                transactionDate: new Date(paymentRunDetail.transactionDate).toLocaleDateString()
                            };
                            affiliateDetailTableData.push(item);
                        }
                    });

                    const { affiliateDetailTable } = this.state;
                    affiliateDetailTable.data = affiliateDetailTableData;

                    this.setState({ affiliateDetailTable });

                    this.affiliateDetailTableWrapperRef.current.scrollIntoView({ behavior: "smooth", block: "center", inline: "start" });
                },
            },
            getTheme: () => createTheme({
                overrides: {
                    MUIDataTable: {
                        root: {                            
                        },
                        paper: {
                        }
                    },
                    MUIDataTableBodyRow: {
                        root: {
                            '&:nth-child(odd)': {
                                backgroundColor: '#F9F9F9'
                            },
                            cursor: 'pointer'
                        }
                    },
                    MUIDataTableBodyCell: {
                    }
                }
            })
        },
        affiliateDetailTable: {
            title: "Affiliate Details",
            columns: [
                {
                    name: "paymentRunDetailId",
                    label: "Id",
                    options: {
                        filter: true,
                        sort: true
                    }
                },
                {
                    name: "paymentRunDetailStatusName",
                    label: "Status",
                    options: {
                        filter: true,
                        sort: true
                    }
                },
                {
                    name: "affiliateProgramManagerId",
                    label: "Affiliate Id",
                    options: {
                        filter: false,
                        sort: false,
                        display: false // Column is not visible. Adding so that it will be included in CSV export.
                    }
                },
                {
                    name: "affiliateProgramManagerName",
                    label: "Affiliate",
                    options: {
                        filter: false,
                        sort: false,
                        display: false // Column is not visible. Adding so that it will be included in CSV export.
                    }
                },
                {
                    name: "affiliateProgramManagerName",
                    label: "Affiliate",
                    options: {
                        filter: true,
                        sort: true
                    }
                },
                {
                    name: "merchantName",
                    label: "Merchant",
                    options: {
                        filter: true,
                        sort: true
                    }
                },
                {
                    name: "merchantId",
                    label: "Merchant Id",
                    options: {
                        filter: false,
                        sort: false,
                        display: false // Column is not visible. Adding so that it will be included in CSV export.
                    }
                },
                {
                    name: "campaignMerchantName",
                    label: "Campaign Merchant",
                    options: {
                        filter: false,
                        sort: false,
                        display: false // Column is not visible. Adding so that it will be included in CSV export.
                    }
                },
                {
                    name: "campaignMerchantId",
                    label: "Campaign Merchant Id",
                    options: {
                        filter: false,
                        sort: false,
                        display: false // Column is not visible. Adding so that it will be included in CSV export.
                    }
                },
                {
                    name: "userId",
                    label: "User Id",
                    options: {
                        filter: false,
                        sort: false,
                        display: false // Column is not visible. Adding so that it will be included in CSV export.
                    }
                },
                {
                    name: "userDisplayName",
                    label: "Display Name",
                    options: {
                        filter: false,
                        sort: false,
                        display: false // Column is not visible. Adding so that it will be included in CSV export.
                    }
                },
                {
                    name: "userName", // TODO: Add on-hover tooltip thingy and make it a hyperlink that will open user page in new tab
                    label: "Creator",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRenderLite: (dataIndex, rowIndex) => {
                            let item = this.state.affiliateDetailTable.data[dataIndex];
                            if (item) {
                                let userName = item.userName;
                                let url = `/Profile/${userName}`;
                                return <Link to={url}>{userName}</Link>;
                            }
                            return '';
                        }
                    }
                },
                {
                    name: "userEmail",
                    label: "User Email",
                    options: {
                        filter: false,
                        sort: false,
                        display: false // Column is not visible. Adding so that it will be included in CSV export.
                    }
                },
                {
                    name: "userCreatedDate",
                    label: "User Signup Date",
                    options: {
                        filter: false,
                        sort: false,
                        display: false // Column is not visible. Adding so that it will be included in CSV export.
                    }
                },
                {
                    name: "transactionDate",
                    label: "Transaction Date",
                    options: {
                        filter: true,
                        sort: true,
                    }
                },
                {
                    name: "saleAmount",
                    label: "Sale Amount",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRenderLite: (dataIndex, rowIndex) => {
                            let item = this.state.affiliateDetailTable.data[dataIndex];
                            if (item) {
                                return '$' + item.saleAmount.toFixed(2);
                            }
                            return '';
                        }
                    }
                },
                {
                    name: "merchantCommissionRate",
                    label: "Commission Rate",
                    options: {
                        filter: true,
                        sort: true
                    }
                },
                {
                    name: "actualCommissionRate",
                    label: "Calculated Commission Rate",
                    options: {
                        filter: true,
                        sort: true,
                        display: false // Column is not visible but can be made visible via the View Columns popover in the Toolbar.
                    }
                },
                {
                    name: "userShare",
                    label: "Creator Share Rate",
                    options: {
                        filter: true,
                        sort: true,
                        display: false // Column is not visible but can be made visible via the View Columns popover in the Toolbar.
                    },
                },
                {
                    name: "rockPorchCommissions",
                    label: "RockPorch Commissions",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRenderLite: (dataIndex, rowIndex) => {
                            let item = this.state.affiliateDetailTable.data[dataIndex];
                            if (item) {
                                return '$' + item.rockPorchCommissions.toFixed(2);
                            }
                            return '';
                        },
                        display: false // Column is not visible but can be made visible via the View Columns popover in the Toolbar.
                    },
                },
                {
                    name: "creatorCommissions",
                    label: "Creator Commissions",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRenderLite: (dataIndex, rowIndex) => {
                            let item = this.state.affiliateDetailTable.data[dataIndex];
                            if (item) {
                                return '$' + item.creatorCommissions.toFixed(2);
                            }
                            return '';
                        }
                    }
                },
                {
                    name: "totalCommissions",
                    label: "Total Commissions",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRenderLite: (dataIndex, rowIndex) => {
                            let item = this.state.affiliateDetailTable.data[dataIndex];
                            if (item) {
                                return '$' + item.totalCommissions.toFixed(2);
                            }
                            return '';
                        }
                    },
                },
            ],
            data: [],
            options: {
                selectableRows: "multiple",
                sortOrder: {
                    name: 'id',
                    direction: 'asc'
                },
                customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
                    return (
                        <div className="d-flex flex-row align-items-center mx-1">
                            {/* https://v4.mui.com/components/checkboxes/#checkbox-with-formcontrollabel */}
                            <div className="dropdown inline-block mx-1 MuiTypography-subtitle1">
                                <button className="btn btn-primary dropdown-toggle MuiTypography-subtitle1" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Action
                                </button>
                                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                    <button className="dropdown-item" type="button" onClick={(e) => { this.handleChangeStatus("Approved", 2, selectedRows, setSelectedRows); }}>Mark as Approved</button>
                                    <button className="dropdown-item" type="button" onClick={(e) => { this.handleChangeStatus("Pending", 1, selectedRows, setSelectedRows); }}>Mark as Pending</button>
                                    <button className="dropdown-item" type="button" onClick={(e) => { this.handleChangeStatus("Skipped", 5, selectedRows, setSelectedRows); }}>Mark as Skipped</button>
                                    <button className="dropdown-item" type="button" onClick={(e) => { this.handleChangeStatus("Rejected", 6, selectedRows, setSelectedRows); }}>Mark as Rejected</button>
                                </div>
                            </div>
                        </div>
                    )
                },
            },
            getTheme: () => createTheme({
                overrides: {
                    MUIDataTable: {
                        root: {
                        },
                        paper: {
                        }
                    },
                    MUIDataTableBodyRow: {
                        root: {
                            '&:nth-child(odd)': {
                                backgroundColor: '#F9F9F9'
                            }
                        }
                    },
                    MUIDataTableBodyCell: {
                    }
                }
            }),
            
        },
        affiliateDetailTableMessageTextboxEnabled: false
    }

    paymentRunId = this.props.match.params.id;
    affiliateDetailTableWrapperRef = React.createRef();
    affiliateDetailTableMessageTextboxRef = React.createRef();

    breadCrumbList = [
        { link: '/Admin', text: 'Admin' },
        { link: '/Admin/PaymentRuns', text: 'Payment Runs' },
        { link: `/Admin/PaymentRunDetails/${this.paymentRunId}`, text: `${this.paymentRunId}` }
    ]

    AddMessageCheckbox = withStyles({
        root: {
            '&$checked': {
                color: "#e55934",
            },
        },
        checked: {},
    })((props) => <Checkbox color="default" {...props} />);

    componentDidMount = async () => {
        let scrollOptions = {
            left: 0,
            top: 0,
            behavior: 'auto'
        }
        window.scrollTo(scrollOptions);

        const paymentRunDetailInfos = await this.processPaymentRunDetails();

        if (!!(paymentRunDetailInfos)) {
            let creatorPaymentRunTotals = paymentRunDetailInfos.reduce((totals, payment) => {
                const { userId, userName, userEmail, totalAmount } = payment;

                if (!totals[userId]) {
                    totals[userId] = {
                        userId,
                        userName,
                        userEmail,
                        totalAmount: 0
                    };
                }

                totals[userId].totalAmount += totalAmount;

                return totals;
            }, []);

            creatorPaymentRunTotals = Object.values(creatorPaymentRunTotals);
        }
    }

    processPaymentRunDetails = async () => {
        const paymentRunDetailInfos = await this.getPaymentRunDetailInfo(this.paymentRunId);

        const affiliateTableData = this.getAffiliateTableData(paymentRunDetailInfos);

        const { affiliateTable } = this.state;
        affiliateTable.data = affiliateTableData;

        this.setState({ paymentRunDetailInfos, paymentRun: this.props.location.paymentRun });

        return paymentRunDetailInfos;
    }

    displayToggleButtons = () => {
        let options = [
            {
                name: "Payment Run Details",
                case: 1,
                action: () => this.setState({ selectedCase: 1 })
            },
            {
                name: "Creator Payment Run Totals",
                case: 2,
                action: () => this.setState({ selectedCase: 2 })
            },
        ];

        return options.map((toggle, i) => {
            return (
                <button key={i}
                    className={`btn ${this.state.selectedCase === toggle.case ? "btn-primary btn-lg" : ""}`}
                    onClick={toggle.action}
                >
                    {toggle.name}
                </button>
            )
        })
    }

    getAffiliateTableData = (paymentRunDetailInfos) => {
        let affiliateTableData = [];

        paymentRunDetailInfos.forEach(paymentRunDetail => {
            let item = _.find(affiliateTableData, (item) => item.affiliateProgramManagerId == paymentRunDetail.affiliateProgramManagerId);
            if (!item) {
                item = {
                    affiliateProgramManagerId: paymentRunDetail.affiliateProgramManagerId,
                    affiliateProgramManagerName: paymentRunDetail.affiliateProgramManagerName,
                    totalRecords: 0,
                    pendingCount: 0,
                    pendingAmount: 0.00,
                    skippedCount: 0,
                    skippedAmount: 0.00,
                    rejectedCount: 0,
                    rejectedAmount: 0.00,
                    noPaymentEmailCount: 0,
                    noPaymentEmailAmount: 0.00,
                    approvedCount: 0,
                    approvedAmount: 0.00,
                    paidCount: 0,
                    paidAmount: 0.00,
                    rockPorchCommissions: 0.00,
                    creatorCommissions: 0.00,
                    totalCommissions: 0.00
                };

                affiliateTableData.push(item);
            }

            item.totalRecords++;
            item.rockPorchCommissions += Number((paymentRunDetail.totalAmount - paymentRunDetail.amount).toFixed(2));
            item.creatorCommissions += paymentRunDetail.amount;
            item.totalCommissions += paymentRunDetail.totalAmount;

            switch (paymentRunDetail.paymentRunDetailStatusName) {
                case "Pending":
                    item.pendingCount++;
                    item.pendingAmount += paymentRunDetail.amount;
                    break;
                case "Skipped":
                    item.skippedCount++;
                    item.skippedAmount += paymentRunDetail.amount;
                    break;
                case "Rejected":
                    item.rejectedCount++;
                    item.rejectedAmount += paymentRunDetail.amount;
                    break;
                case "NoPaymentEmail":
                    item.noPaymentEmailCount++;
                    item.noPaymentEmailAmount += paymentRunDetail.amount;
                    break;
                case "Approved":
                    item.approvedCount++;
                    item.approvedAmount += paymentRunDetail.amount;
                    break;
                case "Paid":
                    item.paidCount++;
                    item.paidAmount += paymentRunDetail.amount;
                    break;
            }
        });

        return affiliateTableData;
    }

    removePaymentRunDetails = async (selectedRows, setSelectedRows) => {
        let paymentRunDetailIds = [];

        selectedRows.data.forEach(row => {
            var item = this.state.affiliateDetailTable.data[row.dataIndex];
            paymentRunDetailIds.push(item.paymentRunDetailId);
        });

        let request = {
            paymentRunDetailIds: paymentRunDetailIds,
        };

        this.setState({ loading: true });

        let url = 'api/merchant/RemovePaymentRunDetails';
        let updatedPaymentRunDetails = await postRequest(url, request);

        const { paymentRunDetailInfos, affiliateTable, affiliateDetailTable } = this.state;

        let affiliateDetailTableData = [
            ...affiliateDetailTable.data
        ];

        updatedPaymentRunDetails.forEach(paymentRunDetail => {
            let affiliateDetailTableDataItem = _.remove(affiliateDetailTableData, (x) => x.paymentRunDetailId == paymentRunDetail.id);

            let paymentRunDetailInfoItem = _.remove(paymentRunDetailInfos, (a) => a.paymentRunDetailId == paymentRunDetail.id);
        });

        const affiliateTableData = this.getAffiliateTableData(paymentRunDetailInfos);
        affiliateTable.data = affiliateTableData;

        affiliateDetailTable.data = affiliateDetailTableData;

        this.setState({
            loading: false,
            paymentRunDetailInfos,
            affiliateTable,
            affiliateDetailTable
        });

        setSelectedRows([]);

        if (updatedPaymentRunDetails && updatedPaymentRunDetails.length > 0) {
            toast.success(
                <div className="p-2">
                    <h3 className="text-center">Success</h3>
                    <hr />
                    <p><small>Removed {updatedPaymentRunDetails.length} {updatedPaymentRunDetails.length == 1 ? "record" : "records"}</small></p>
                </div>,
                {
                    duration: 4000,
                    position: 'top-center'
                }
            );
        }
    }

    handleChangeStatus = async (statusName, statusId, selectedRows, setSelectedRows) => {
        if (!selectedRows && !selectedRows.data && selectedRows.data.length === 0) {
            window.alert("Nothing to update as there are no selected rows");
        }

        if (statusName === "Paid") {
            if (!window.confirm("Are you sure you wish to update these records to Paid?")) {
                return;
            }
        }

        let alreadyPaidRecordCount = 0;
        let paymentRunDetailIds = [];
        selectedRows.data.forEach(row => {
            var item = this.state.affiliateDetailTable.data[row.dataIndex];
            if (item.paymentRunDetailStatusName === "Paid") {
                alreadyPaidRecordCount++;
            }

            paymentRunDetailIds.push(item.paymentRunDetailId);
        });

        if (statusName === "Paid" && alreadyPaidRecordCount > 0) {
            let msg = `Warning: ${alreadyPaidRecordCount} ${alreadyPaidRecordCount == 1 ? 'record is' : 'records are'} already marked as Paid.`;
            msg += ` Are you sure you wish to update ${alreadyPaidRecordCount == 1 ? 'this record' : 'these records'} to Paid again?`
            if (!window.confirm(msg)) {
                return;
            }
        } else if (statusName !== "Paid" && alreadyPaidRecordCount > 0) {
            let msg = `Warning: ${alreadyPaidRecordCount} ${alreadyPaidRecordCount == 1 ? 'record is' : 'records are'} already marked as Paid.`;
            msg += ` Are you sure you wish to update ${alreadyPaidRecordCount == 1 ? 'this record' : 'these records'} from Paid to ${statusName}?`
            if (!window.confirm(msg)) {
                return;
            }
        }

        let request = {
            paymentRunDetailIds: paymentRunDetailIds,
            paymentRunDetailStatus: statusName,
        };

        this.setState({ loading: true });

        let url = 'api/merchant/UpdatePaymentRunDetailStatuses';
        let updatedPaymentRunDetails = await postRequest(url, request);

        const { paymentRunDetailInfos, affiliateTable, affiliateDetailTable } = this.state;

        let affiliateDetailTableData = [
            ...affiliateDetailTable.data
        ];

        updatedPaymentRunDetails.forEach(paymentRunDetail => {
            let affiliateDetailTableDataItem = _.find(affiliateDetailTableData, (x) => x.paymentRunDetailId == paymentRunDetail.id);
            affiliateDetailTableDataItem.paymentRunDetailStatusName = statusName;

            let paymentRunDetailInfoItem = _.find(paymentRunDetailInfos, (a) => a.paymentRunDetailId == paymentRunDetail.id);
            paymentRunDetailInfoItem.paymentRunDetailStatusName = statusName;
            paymentRunDetailInfoItem.paymentRunDetailStatusId = statusId;
            paymentRunDetailInfoItem.modifiedBy = paymentRunDetail.modifiedBy;
            paymentRunDetailInfoItem.modifiedDate = paymentRunDetail.modifiedDate;

            if (request.shouldUpdateMessage) {
                affiliateDetailTableDataItem.message = request.message;
                paymentRunDetailInfoItem.message = request.message;
            }
        });

        const affiliateTableData = this.getAffiliateTableData(paymentRunDetailInfos);
        affiliateTable.data = affiliateTableData;

        affiliateDetailTable.data = affiliateDetailTableData;

        this.setState({
            loading: false,
            paymentRunDetailInfos,
            affiliateTable,
            affiliateDetailTable            
        });

        setSelectedRows([]);

        if (updatedPaymentRunDetails && updatedPaymentRunDetails.length > 0) {
            toast.success(
                <div className="p-2">
                    <h3 className="text-center">Success</h3>
                    <hr />
                    <p><small>Updated {updatedPaymentRunDetails.length} {updatedPaymentRunDetails.length == 1 ? "record" : "records"} to {statusName}</small></p>
                </div>,
                {
                    duration: 4000,
                    position: 'top-center'
                }
            );
        }
    }

    getPaymentRunDetailInfo = async (id) => {
        this.setState({ loading: true });

        let url = `api/merchant/GetPaymentRunDetailInfo?paymentRunId=${id}`;
        let paymentRunDetailInfos = await getRequest(url);

        this.setState({ loading: false });
        return paymentRunDetailInfos;
    }

    finalizePaymentRun = async () => {
        // this payment run has already been finalized
        if (this.state.paymentRun.paymentRunStatusId === 4) {
            if (!window.confirm('THIS PAYMENT RUN HAS ALREADY BEEN FINALIZED!  Are you sure you want to finalize this payment run?  This will mark commissions as paid and notify Creators.')) {
                return;
            }
        }

        if (!window.confirm('Are you sure you want to finalize this payment run?  This will mark commissions as paid and notify Creators.')) {
            return;
        }

        this.setState({ loading: true });
        var url = `api/merchant/FinalizePaymentRun?paymentRunId=${this.paymentRunId}`
        await postRequest(url);
        this.setState({ loading: false });

        this.props.history.push("/Admin/PaymentRuns");
    }

    createPaymentFile = async () => {
        // this payment run has already been finalized
        if (this.state.paymentRun.paymentRunStatusId === 4) {
            if (!window.confirm('THIS PAYMENT RUN HAS ALREADY BEEN FINALIZED!  Are you sure you wish to create a PayPal Payment File?')) {
                return;
            }
        }
        if (!window.confirm('Are you sure you wish to create a PayPal Payment File?')) {
            return;
        }

        //Create an hidden form
        var url = `api/merchant/CreatePaymentFile?paymentRunId=${this.paymentRunId}`;
        var form = $('<form>', { 'method': 'POST', 'action': url, 'target': '_blank' }).hide();

        $('body').append(form);
        form.submit();

        //Clean up
        form.remove();
    }

    render() {
        return (
            <>
                <div className="gray-bg full-height">
                    <section>
                        <AccountBanner breadCrumbList={this.breadCrumbList}>
                            <h1 className="mb-4">Payment Run Details</h1>
                            <p className="mb-0">Manage payments</p>
                        </AccountBanner>
                    </section>
                    <section className="filter-wrapper" >
                        <div className="container">
                            <div className="row py-2 mt-0">
                                <div className="col-12 d-flex justify-content-between">
                                    <div className="filter-block d-flex">
                                        {this.displayToggleButtons()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <Toaster />

                    {
                        this.state.loading ?
                            <LoadSpinnerOverlay />
                            : <></>
                    }

                    {
                        this.state.selectedCase === 1 ?
                            <>
                                <div className="d-flex m-3">
                                    <div className="dropdown ml-auto">
                                        <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            Action
                                        </button>
                                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                            <button className="dropdown-item" type="button" onClick={(e) => { this.createPaymentFile(); }}>
                                                Create Payment File
                                            </button>
                                            <button className="dropdown-item" type="button" onClick={(e) => { this.finalizePaymentRun(); }}>
                                                Finalize Payment Run
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <section>
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col">
                                                <ThemeProvider theme={this.state.affiliateTable.getTheme()}>
                                                    <MUIDataTable
                                                        title={this.state.affiliateTable.title}
                                                        data={this.state.affiliateTable.data}
                                                        columns={this.state.affiliateTable.columns}
                                                        options={this.state.affiliateTable.options}
                                                    />
                                                </ThemeProvider>
                                            </div>
                                        </div>
                                    </div>
                                </section>

                                <section ref={this.affiliateDetailTableWrapperRef}>
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col">
                                                <ThemeProvider theme={this.state.affiliateDetailTable.getTheme()}>
                                                    <MUIDataTable
                                                        title={this.state.affiliateDetailTable.title}
                                                        data={this.state.affiliateDetailTable.data}
                                                        columns={this.state.affiliateDetailTable.columns}
                                                        options={this.state.affiliateDetailTable.options}
                                                    />
                                                </ThemeProvider>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </>
                            :
                            <CreatorPaymentRunTotals
                                paymentRunDetailInfos={this.state.paymentRunDetailInfos}
                                paymentRunId={this.props.match.params.id}
                            />
                    }
                   
                </div>
            </>
        )
    }
}

export default PaymentRunDetails;