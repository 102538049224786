import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import FeedPost from "components/Feed/FeedPost";
import UserFeedCarousel from "components/Feed/UserFeedCarousel";
import FeedPostGridItem from "components/Feed/FeedPostGridItem";
import { trimProductsArray, getProperties } from "sharedUtils/postFunctions";
import { getRequest, postRequest } from "sharedUtils/httpUtils";
import { fetchUserSuggestions, blankUserSuggestion, mapPostMedia } from "sharedUtils/userSuggestion";
import ProfileInfoCardOnboarding from "components/ProfileInfoCardOnboarding";
import { DateTime } from "luxon";


class UserFeed extends React.Component {

    /**
     * 
     * @param {{
 * isGrid:boolean,
 * loggedInUser:object
 * posts:[object]
 * fetchMore:Function
 * finishedFetching:boolean
 * showFollowSuggestions:boolean
 * deviceSize:string
 * deletePost:Function
 * updateParent:Function
 * showPins:boolean
 * }} props
     */
    constructor(props) {
        super(props);
        this.state = {
            posts: [],
        }
    }

    async componentDidMount() {
        if (this.props.posts) {
            this.setState({ posts: [...this.props.posts] });
        }
        else {
            this.setState({ posts: [] });
        }

    }

    componentDidUpdate = (prevProps) => {
        if (this.props.posts !== null && this.props.posts !== undefined && this.props.posts !== prevProps.posts) {
            this.setState({ posts: [...this.props.posts] })
        }
    }

    likePost = async (shareCode) => {

        let postsCopy = [...this.state.posts];
        let i = postsCopy.findIndex(post => post.shareCode === shareCode);

        let likedByUser = postsCopy[i].likedByUser;
        let num = likedByUser === true ? -1 : 1;

        postsCopy[i].likeCount += num;
        postsCopy[i].likedByUser = !likedByUser;

        await this.setState({ posts: postsCopy }, async () => {
            let url = `/api/post/likePost?shareCode=${shareCode}`
            let data = await postRequest(url);
        });
    }

    savePost = async (shareCode) => {
        let postsCopy = [...this.state.posts];
        let i = postsCopy.findIndex(post => post.shareCode === shareCode);

        let url = this.state.posts[i].savedByUser === false ? `/api/post/savePost` : `/api/post/removeSavedPost`;

        postsCopy[i].savedByUser = !this.state.posts[i].savedByUser;

        this.setState({ posts: postsCopy });

        let reqBody = { shareCode };
        let data = await postRequest(url, reqBody);
    }

    setPost = (post) => {
        let posts = [...this.state.posts];
        let i = posts.findIndex((p) => { return p.id === post.id });
        posts[i] = post;

        this.setState({ posts });
    }

    deletePost = (shareCode) => {
        let locationToDelete = this.props.posts.findIndex((post) => post.shareCode === shareCode);
        let updatedPosts = [...this.props.posts];
        updatedPosts.splice(locationToDelete, 1);
        if (this.props.updateParent) {
            this.props.updateParent(updatedPosts)
        }

    }

    togglePinned = async (shareCode) => {
        let posts = [...this.state.posts];
        let location = posts.findIndex((post) => post.shareCode === shareCode);

        let postRef = posts[location];
        let update = postRef.pinnedDate === null || postRef.pinnedDate === undefined ? DateTime.now().toUTC().toString() : null;
        posts[location].pinnedDate = update;

        this.setState({ posts })

        let updateObj = {
            pinnedDate: postRef.pinnedDate,
            title: postRef.title,
            content: postRef.content,
            id: postRef.id,
            user: {
                id: postRef.user.id,
            },
            status: postRef.status
        }

        let url = `/api/Post/TogglePinnedPost`;

        let res = await postRequest(url, updateObj);

        if (this.props.updateParent) {
            this.props.updateParent(posts)
        }
    }


    mapPosts = () => {
        let { posts } = this.state;
        let { isGrid } = this.props;



        if (posts != undefined || posts != null) {
            return posts.map((post, i) => {
                let redirectUrl = post.type === 2 ? `/Locker/LockerPage/${post?.shareCode}` : `/post/postPage/${post?.shareCode}`

                if (isGrid === true) {                    
                    return (
                        <div className="d-flex">
                            <div className="col-md-6 col-lg-4 px-0 py-0 mx-0 my-0 grid-item" key={i}>
                                <FeedPostGridItem
                                    post={post}
                                    isPictureAndProducts={post.type === 2}
                                    redirectUrl={redirectUrl}
                                    deviceSize={this.props.deviceSize}
                                    loggedInUser={this.props.loggedInUser}
                                    setPost={this.setPost}
                                    showPins={this.props.showPins}
                                    togglePinned={this.togglePinned}
                                    pinnedDate={post.pinnedDate}
                                />
                            </div>
                            <UserFeedCarousel post={post} index={i}/>
                         </div>
                    )
                }
                else {
                    return (
                        <FeedPost
                            post={post}
                            likePost={this.likePost}
                            redirectUrl={redirectUrl}
                            savePost={this.savePost}
                            loggedInUser={this.props.loggedInUser}
                            likeCount={post.likeCount}
                            likedByUser={post.likedByUser}
                            savedByUser={post.savedByUser}
                            commentCount={post.commentCount}
                            key={i}
                            deviceSize={this.props.deviceSize}
                            setPost={this.setPost}
                            deletePost={this.deletePost}
                            togglePinned={this.togglePinned}
                            pinnedDate={post.pinnedDate}
                            showPins={this.props.showPins}
                        />
                            
                    )
                }
            })

        }
    }

    displaySuggestions = () => {
        if (this.props.finishedFetching === true && this.props.showFollowSuggestions === true) {
            return (
                <>
                    <div className="activity-info-block">
                        <div className="d-flex flex-wrap justify-content-between align-items-center py-0">
                            <div className="feed-detail mb-1 py-0">
                                <div className="text-center py-0">
                                    <div className="content" style={{ textAalign: "justify", marginTop: "1em", marginBottom: "1em" }}>
                                        You've reached the end of your feed.  Here are some users we think you might enjoy following.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <ProfileInfoCardOnboarding
                        loggedInUser={this.props.loggedInUser}
                        clickable={true}
                    />
                </>
            )
        }
    }

    render() {
        let { isGrid } = this.props;

        if (isGrid === true) {
            return (
                <div className="row py-0 mt-0 mx-0 grid" style={{ position: "relative", width: "100%" }}>
                    <InfiniteScroll
                        dataLength={this.state.posts?.length}
                        next={this.props.fetchMore}
                        hasMore={!this.props.finishedFetching}
                        style={{ overflow: "hidden" }}
                    >
                        {this.mapPosts()}
                    </InfiniteScroll>

                    <div className="col-12 px-0">
                        {this.displaySuggestions()}
                    </div>
                </div>
            )
        }
        else {
            return (
                <div className="flex-column py-1">
                    <div className="col-12 mb-0 px-0 mx-auto activity-feed-wrapper"
                        style={this.props.deviceSize === "sm" ? { maxWidth: "576px", paddingLeft: "5px", paddingRight: "5px" } : { maxWidth: "576px" }}
                    >
                        <InfiniteScroll
                            dataLength={this.state.posts?.length}
                            next={this.props.fetchMore}
                            hasMore={!this.props.finishedFetching}
                        >
                            {this.mapPosts()}
                        </InfiniteScroll>
                    </div>
                    <div className="col-12 mb-0 px-0 mx-auto activity-feed-wrapper" style={{ maxWidth: "576px" }}>
                        {this.displaySuggestions()}
                    </div>
                </div>
            )

        }
    }
}

export default UserFeed;
