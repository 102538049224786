import React from "react";
import { Link } from "react-router-dom";
import CommentIcon from "../../components/icons/CommentIcon";
import SaleIcon from "../../components/icons/SaleIcon";

import ImageWrapper from "../../components/ImageWrapper";
import { connect } from "react-redux";
import { toggleProductPageModal } from "../../stateManagement/reducers/modalReducer";
import CommentIconPlusSign from "../../components//icons/CommentIconPlusSign";
/**
 * 
 * @param {{
 * product:{coupon:string, id:Number,product:{imageUrl:string, name:string}}
 * productKey:Number,
 * post:object
 * showDescription:Function,
 * comment:string,
 * isEditing:boolean,
 * parentModal:{type:string,shareCode:string,parentState:object}
 * }} props
 */
const BrowsePostPageProduct = (props) => {
    function displayIcon() {
        if (props.isEditing === true) {
            return (
                <span style={{ cursor: "pointer", paddingRight: "2px" }} href="/" className="product-comment position-absolute" onClick={(e) => {
                    e.preventDefault()
                    props.showDescription(props.productKey)
                }}>
                    {props.deviceSize === "sm" ? <CommentIconPlusSign width={12} height={12} /> : <CommentIconPlusSign />}
                </span>
            )
        }
        else if (props.post?.type === 2 && props.comment?.length > 0) {
            return (
                <span style={{ cursor: "pointer", paddingRight: "2px" }} href="/" className="product-comment position-absolute" onClick={(e) => {
                    e.preventDefault()
                    props.showDescription(props.productKey)
                }}>
                    {props.deviceSize === "sm" ? <CommentIcon width={12} height={12} /> : <CommentIcon />}
                </span>
            )
        }
    }

    const renderSaleOverlay = (sale) => {
        if (sale && ["sm", "md"].includes(props.deviceSize)) {
            return (
                <span className="on-sale-icon">
                    <SaleIcon />
                </span >
            );
        }
    }

    const url = "/Product/ProductPage/" + props.product.shareCode + "?r=1&b=1";

    return (
        <>
           

            <Link to={url} onClick={(e) => {
                e.preventDefault();
                props.toggleProductPageModal(props.product, props.loggedInUser, false, () => null, { type: null, shareCode: null, parentState: null }, true)
            }}>
                <ImageWrapper src={props.product.imageUrl} size={`Product`} alt="product" />
            </Link>

            {displayIcon()}

        </>
    )
}

export default connect(null, { toggleProductPageModal })(BrowsePostPageProduct);