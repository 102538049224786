import React, { useState } from "react";
import ActionButton from "components/ActionButton";
import { Collapse } from "reactstrap";
import ImageWrapper from "components/ImageWrapper";
import InformationIcon from "components/icons/InformationIcon";
import ThreeDotsHorizontalIcon from "components/icons/ThreeDotsHorizontalIcon";
import ShareIcon from "components/icons/ShareIcon";
import SaleIcon from "components/icons/SaleIcon";
import MyProductsItemDropdown from "views/Account/MyProductsItemDropdown";

import { currencyFormat } from "sharedUtils/productUtils";
import { UncontrolledPopover, PopoverBody } from "reactstrap";
import { Share } from "../../icons";

/**
 * 
 * @param {{product:object,edit:Function, toggleProductModal:Function, toggleShareModal:Function}} props
 */
const ProductRowMobile = (props) => {
    let [isOpen, setIsOpen] = useState(false);
    let [isLoadingShareCode, setIsLoadingShareCode] = useState(false);
    let { product } = props;
    let pstyle = { fontFamily: "Archivo", fontSize: "12px", width: "96%" }
    let pstylePrice = { ...pstyle, fontSize: product.sale && !!(product.salePrice) ? "8px" : "" }
    let redirectUrl = "/Product/ProductPage/" + product.shareCode;

    const handleGetLink = async () => {
        if (!product.integrated) {
            props.getLinkError();
            return;
        }
        if (props.analytics && !(product.shareCode)) {
            setIsLoadingShareCode(true);
            await props.addUserMerchantProductIfNotAddedAlreadyAndGetLink(product, props.commissionId, props.userId);
            setIsLoadingShareCode(false);
            return;
        }
        let getLinkUrl = "https://";
        if (window.location.host.includes("app.rockp") && !!product.shareSubdomain) {
            getLinkUrl += product.shareSubdomain + ".rockporch.com";
        } else {
            getLinkUrl += window.location.host;
        }

        getLinkUrl = getLinkUrl + "/prdlink/" + product.shareCode;
        props.handleGetLink(getLinkUrl);
    }

    return (
        <div className={`card p-1 ${props.analytics ? "border-0 pt-2" : ""}`}>

            <div className="d-flex justify-content-between align-items-center px-1">
                <ImageWrapper
                    className="mr-1"
                    src={product.imageUrl}
                    style={{ width: props.deviceSize === "sm" ? "50px" : "80px", height: props.deviceSize === "sm" ? "50px" : "80px", objectFit: "contain" }}
                    onClick={() => { if (!!product.shareCode) { window.open(redirectUrl, '_blank') } } }
                />

                <div style={{ flex: 1, marginLeft: "5px" }}>
                    <p className="ml-1 mb-0 one-line-ellipsis" style={{ ...pstyle }}>
                        {product.manufacturer}
                    </p>

                    <p className="ml-1 mb-0 one-line-ellipsis" style={{ ...pstyle, display: "relative", top: "1px" }} onClick={() => { if (!!product.shareCode) { window.open(redirectUrl, '_blank') } }}>
                        {product.name}
                    </p>

                    <p className="ml-1 mb-0 one-line-ellipsis" style={{ ...pstyle, display: props.analytics ? "none" : ""}}>
                        <strong>Retailer</strong>: {product.merchant}
                    </p>

                    <p className="ml-1 mb-0" style={pstyle}>
                        <strong>Price</strong>: {product.sale && !!(product.salePrice) ? <span className="mr-1" style={{ fontSize: props.analytics ? "12px" : "" }}>${product.salePrice.toFixed(2)}</span> : <></>} <span className={`${product.sale && !!(product.salePrice) ? "on-sale-price-single" : ""}`} style={{ fontSize: props.analytics ? "12px" : "", paddingTop: product.sale && !!(product.salePrice) ? "2px" : "" }}>{currencyFormat(product.sale && !!(product.salePrice) && !!(product.originalPrice) ? product.originalPrice : product.price)}</span><span>{props.product.sale && !!(props.product.salePrice) ? <SaleIcon style={{ marginLeft: "3px" }} /> : ""}</span>
                    </p>

                    <p className="ml-1 mb-0" style={pstyle}>
                        <strong>{props.analytics ? "Estimated" : "Est."} Commission</strong>
                        <sup>*</sup>
                        : <span style={{ fontSize: "12px" }}>{currencyFormat(product.estimatedCommission)}</span>
                    </p>
                </div>


                {/**/}
                <div className={`d-flex ${props.analytics ? "" : "flex-column"}`}>
                    <ActionButton
                        className={`btn btn-${product.integrated ? "primary" : "secondary"} btn-sm ${props.analytics && props.deviceSize === "sm" ? "mx-2" : ""}`}
                        onClick={handleGetLink}
                        style={{ padding: "4px 0", width: "65px", fontSize: "14px" }}
                        isLoading={isLoadingShareCode}
                    >
                        Get Link
                    </ActionButton>

                    {
                        props.analytics ?
                            <></>
                            :
                            <MyProductsItemDropdown
                                editClick={props.editClick}
                                hideClick={props.hideClick}
                                toggleShareModal={props.toggleShareModal}
                                product={product}
                                isUserMerchant={props.isUserMerchant}
                                forMobile={true}
                                style={{ width: "65px", padding: "4px 0", fontSize: "14px" }}
                            />
                    }
                </div>

            </div>

        </div>
    )
}

export default ProductRowMobile;