import React from "react";
import { toggleShareModal, togglePostPageModal, toggleBlogPageModal, toggleLockerPageModal, toggleProductPageModal } from "stateManagement/reducers/modalReducer";
import UserTileGeneral from "components/UserTileGeneral";
import { connect } from "react-redux";

/**
 * @description A post component for a three column feed grid
 * @param {{
 * post?:object,
 * isPictureAndProducts:boolean,
 * showPins:boolean
 * redirectUrl:string
 * togglePinned : (shareCode)=>
 * isPinned:boolean
 * showUserTile: boolean
 * }} props
 */
const CurrentDealFeedPostGridItem = (props) => {

    let renderUserTile = () => {
        if (props.showUserTile === true) {
            return (<div className="d-flex flex-wrap justify-content-between align-items-center py-0 px-0 mx-0" style={{ background: "white" }}>
                <UserTileGeneral className={`py-1 ${props.brandEmbed && props.isBrandSmall ? "" : "ml-2"}`} size={"Profile"} brandEmbed={props.disableRedirects} user={props.post.user} subtitle={" "} showCheckMark={false} />
            </div>);
        }
    }


    const userId = props.loggedInUser?.proStatusId === 1 ? props.loggedInUser.id : -1;

    const redirectToMerchatUrl = `/m/${props.deal?.merchantId}?userId=${userId}&redirectUrl=${props.deal?.url}&currentdeal=true`;

    let renderPost = () => {
        if (props.screenWidth < 480) {
            return (
                <>
                    <a href={redirectToMerchatUrl} style={{ cursor: "pointer" }} target="_blank">
                        <div className="h-100 bg-white position-relative" style={{ paddingTop: "32px", paddingBottom: "135%" }}>
                            <div className="card-body d-flex flex-column justify-content-around align-items-center" style={{ position: "absolute", height: "70%", top: "60%", left: "50%", transform: "translate(-50%, -60%)", minWidth: "90%" }}>
                                <h4 className="card-title" style={{ color: "black", display: !!(props.deal.merchantLogoUrl) ? "none" : "" }}>{props.deal.merchantName}</h4>
                                <div className="d-flex align-items-center brands-new-wrapper" >
                                    <img style={{ display: !!(props.deal.merchantLogoUrl) ? "" : "none", objectFit: "contain", height: "50px", maxWidth: "100%", filter: "grayscale(100%) contrast(2) brightness(1)" }} src={props.deal.merchantLogoUrl} alt="Merchant Logo" />
                                </div>
                                <p className="card-text mt-2 mb-2 text-center" style={{ fontFamily: "'Archivo', sans-serif", fontSize: "18px", fontWeight: "bold" }}>{props.deal.description}</p>
                                <div className="position-relative d-flex flex-column align-items-center">
                                    <a href={redirectToMerchatUrl} className="mt-auto" target="_blank" style={{ fontSize: "20px", fontWeight: "bold" }}>Shop Now</a>
                                    <div
                                        className="new-button current-deal-share-btn mt-3 btn-sm mt-1"
                                        style={{ cursor: "pointer", width: "62px", fontSize: "16px" }}
                                        onClick={(e) => { e.preventDefault(); props.toggleProductShareModal(redirectToMerchatUrl); }}
                                    >Share</div>
                                </div>
                            </div>
                        </div>
                    </a>
                </>
            );
        }

        return (
            <>
                    <a href={redirectToMerchatUrl} style={{ cursor: "pointer" }} target="_blank">
                        <div className="h-100 bg-white position-relative" style={{ paddingTop: "32px", paddingBottom: "135%" }}>
                            <div className="card-body d-flex flex-column justify-content-around align-items-center" style={{ position: "absolute", height: "70%", top: "50%", left: "50%", transform: "translate(-50%, -50%)", minWidth: props.deviceSize === "sm" ? "85%" : "70%" }}>
                                       <h4 className="card-title mb-4" style={{ color: "black", display: !!(props.deal.merchantLogoUrl) ? "none" : "" }}>{props.deal.merchantName}</h4>
                                    <div className="d-flex align-items-center brands-new-wrapper mb-3" >
                                <img style={{ display: !!(props.deal.merchantLogoUrl) ? "" : "none", objectFit: "contain", height: props.deviceSize === "sm" ? "80px" : "90px", maxWidth: "100%", filter: "grayscale(100%) contrast(2) brightness(1)" }} src={props.deal.merchantLogoUrl} alt="Merchant Logo" />
                                    </div>
                            <p className="card-text mb-4 text-center" style={{ fontFamily: "'Archivo', sans-serif", fontSize: props.deviceSize === "sm" ? "20px" : "26px", fontWeight: "bold" }}>{props.deal.description}</p>
                            <div className="position-relative d-flex flex-column align-items-center">
                                    <a href={redirectToMerchatUrl} className="mb-4 mt-auto" style={{ fontSize: "30px", fontWeight: "bold" }} target="_blank" >Shop Now</a>
                                    <div
                                        className="new-button current-deal-share-btn mt-2 btn-sm"
                                        style={{ cursor: "pointer", width: "57px" }}
                                        onClick={(e) => { e.preventDefault(); props.toggleProductShareModal(redirectToMerchatUrl); }}
                                    >Share</div>                                        
                                </div>
                            </div>
                        </div>
                    </a>
            </>
        );
    }

    let gridItem = () => {
        return (
            <div style={{ borderRadius: "15px", overflow: "hidden" }}>

                {renderUserTile()}
                <div className="image-container">
                    {renderPost()}
                </div>
            </div>

        )
    }

        return (
            gridItem()
    )
}

const mapDispatch = { toggleShareModal, togglePostPageModal, toggleBlogPageModal, toggleLockerPageModal, toggleProductPageModal }

export default connect(null, mapDispatch)(CurrentDealFeedPostGridItem);