import React, { useState, useEffect } from "react";
import AccountSidebar from "../../components/AccountSidebar";
import { Form, FormGroup, CustomInput } from "reactstrap";
import PropTypes from "prop-types";
import { getRequest, postRequest } from "sharedUtils/httpUtils";
import { User } from "oidc-client";
import ActionButton from "components/ActionButton";
import AccountBanner from "../../components/AccountBanner";
import { Helmet } from 'react-helmet';

function CommunicationSettings(props) {
    const [loading, setLoading] = useState(false);
    const [userCommunicationPreference, setUserCommunicationPreference] = useState({ paymentEmail: false, salesEmail: false });

    useEffect(() => {
        const fetchUserCommunicationPreference = async () => {
            setLoading(true);

            const url = `api/Account/GetUserCommunicationPreference`
            const ucp = await getRequest(url);
            if (ucp != undefined && ucp != null) {
                setUserCommunicationPreference(ucp);
            }

            setLoading(false);
        };

        fetchUserCommunicationPreference();
    }, []);


    const onInputChange = async (e) => {
        let name = e.target.name;
        let value = e.target.value;

        const ucp = { ...userCommunicationPreference };
        ucp[name] = !ucp[name];
        setUserCommunicationPreference(ucp);
    }

    const updateSettings = async () => {
        setLoading(true);

        const ucpObject = {
            paymentEmail: userCommunicationPreference.paymentEmail,
            salesEmail: userCommunicationPreference.salesEmail,
        }

        const userCommunicationPreferenceUrl = `api/Account/UpdateUserCommunicationPreference`;
        const ucp = await postRequest(userCommunicationPreferenceUrl, ucpObject);

        if (ucp != undefined && ucp != null) {
            setUserCommunicationPreference(ucp);
        }

        setLoading(false);
    }

    const breadCrumbList = [{ link: '/Account/Dashboard', text: 'Account' }, { link: '/Account/CommunicationSettings', text: 'Communication Preference', active: true }];


    return (
        <section>
            <Helmet>
                <title>RockPorch - Communication Preferences</title>
            </Helmet>
            <AccountBanner breadCrumbList={breadCrumbList}>
                <h1 className="mb-4">Communications Preferences</h1>
                <p className="mb-0">Manage what information is sent to you</p>
            </AccountBanner>
            <div className="gray-bg full-height">
                <div className="container">
                    <div className="row py-5 mt-0">
                        {
                            ["sm", "md"].includes(props.deviceSize) === false ? (
                                <div className="col-md-5 col-lg-3 side-nav">
                                    <AccountSidebar loggedInUser={props.loggedInUser} setLoggedInUser={props.setLoggedInUser} />
                                </div>
                            ) : <span></span>
                        }
                        <div className="col-md-12 col-lg-9">
                            <div className="user-detail-form">
                                <Form>
                                    <div className="detail-block">

                                        <p>Email me when: <br /></p>
                                        {/*<FormGroup>*/}
                                        {/*    <CustomInput type="switch" name="mentionsMe" label="Someone mentions me" id="mentionsMeSwitch" />*/}
                                        {/*</FormGroup>*/}

                                        {/*<FormGroup>*/}
                                        {/*    <CustomInput type="switch" name="followsMe" label="Anyone follows me" id="followsMeSwitch" />*/}
                                        {/*</FormGroup>*/}

                                        {/*<FormGroup>*/}
                                        {/*    <CustomInput type="switch" name="comments" label="Someone comments on one of my posts" id="commentsSwitch" />*/}
                                        {/*</FormGroup>*/}
                                        {
                                            props.loggedInUser.proStatusId === 1 ?

                                                <FormGroup>
                                                    <CustomInput type="switch" name="salesEmail" checked={userCommunicationPreference.salesEmail} onChange={(e) => onInputChange(e)} label="A sale is made" id="salesEmailSwitch" className="salesEmailSwitch" />
                                                </FormGroup>
                                                :
                                                <></>
                                        }


                                        {/*<h3>Newsletter</h3>*/}
                                        {/*<hr />*/}
                                        {/*<FormGroup>*/}
                                        {/*    <CustomInput type="switch" name="companyNews" label="Send me Company news" id="comanyNewsSwitch" />*/}
                                        {/*</FormGroup>*/}

                                        <FormGroup>
                                            <ActionButton className="btn btn-primary" isLoading={loading}
                                                onClick={() => updateSettings()}
                                            >Save</ActionButton>

                                        </FormGroup>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default CommunicationSettings;

CommunicationSettings.propTypes = {
    Settings: PropTypes.shape({
        mentionsMe: PropTypes.bool,
        followsMe: PropTypes.bool,
        comments: PropTypes.bool,
    }),
};
