import React, { useEffect, useState} from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import SafariShareIcon from "components/icons/SafariShareIcon";
import ThreeDotsHorizontalIcon from "components/icons/ThreeDotsHorizontalIcon";
import ThreeDotsVerticalIcon from "components/icons/ThreeDotsVerticalIcon";
import { Link } from "react-router-dom";
import RockporchIcon from "components/icons/RockporchMountainIcon";

/**
 * 
 * @param {{isIOS:boolean,showPrompt:boolean, closePrompt:function}} props
 */
const AddToHomeScreenIOS = (props) => {
    const [modalOpen, setModalOpen] = useState(true);

    function toggleModal() {
        setModalOpen(!modalOpen);
    }


    function showIosMessage() {
        if (props.isIOS) {
            return <p>Tap <img src = "./images/appleShareIcon.png" width="12em"/> then select "Add To Homescreen"</p>
        }
        else {

            return (
                <p>
                    Tap <ThreeDotsVerticalIcon /> or <span className="mx-3">
                        <ThreeDotsHorizontalIcon/></span>
                    and select "Add To Homescreen"
                </p>
            )
        }
    }

    function doNotRemind() {
        //const optOutKey = "rockporch.InstallPrompt.OptOut";
        //localStorage.setItem(optOutKey, true);
        props.closePrompt();      

    }

    return (
        <Modal isOpen={props.showPrompt} toggle={props.closePrompt} backdrop={false} modalClassName="addToHomescreen">
            <ModalHeader>
                <RockporchIcon width={50} height={ 50 }/>
               
            </ModalHeader>
            <button type="button" className="close py-0 px-0" aria-label="Close" onClick={() => {
                props.closePrompt()
                doNotRemind()
            }
            }>
                <span aria-hidden="true">&times;</span>
            </button>

            <ModalBody>
                <p>Add RockPorch to your homescreen.</p>
                {showIosMessage()}
            </ModalBody>
        </Modal>
        )
}

export default AddToHomeScreenIOS;