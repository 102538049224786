import React from "react";

const Grid = ({ children }) => {
    return (
        <div
            style={{
                display: "flex",
                padding: 10,
                flexFlow: "wrap",
                overflow: "auto",
                height: "500px",
                width: "100%",
                justifyContent: "center",
            }}
            className="post-edit-products-scroll"
        >
            {children}
        </div>
    );
}


export default Grid;