import React from "react"
import { useState, useEffect } from "react";
import { getRequest } from "sharedUtils/httpUtils";

import TwitterIcon from "components/icons/TwitterIcon";
import FacebookIcon from "components/icons/FacebookIcon";
import MobileShare from "components/MobileShare";
import MailIcon from 'components/icons/MailIcon';


/** 
 * @param {{loggedInUser: Object, title:string, shareCode:string?, customStyle:Object, shareBlockStyle:Object}} props 
 */
const ShareBlock = (props) => {

    const [showCopied, setCopiedText] = useState(false);
    const [showCopied2, setCopiedText2] = useState(false);
    const [showCopied3, setCopiedText3] = useState(false);
    const [showCopied4, setCopiedText4] = useState(false);
    const [renderDirect, setRenderDirect] = useState(false);

    const [postEmbedCode, setPostEmbedCode] = useState(null);
    const [lockerEmbedCode, setLockerEmbedCode] = useState(null);
    const [productEmbedCode, setProductEmbedCode] = useState(null);
    const [postEmbedBrandCode, setPostEmbedBrandCode] = useState(null);
    const [blogEmbedCode, setBlogEmbedCode] = useState(null);
    const [lockerEmbedBrandCode, setLockerEmbedBrandCode] = useState(null);
    const [productEmbedBrandCode, setProductEmbedBrandCode] = useState(null);
    const [profileEmbedCode, setProfileEmbedCode] = useState(null);
    const [referralShareCode, setReferralShareCode] = useState(null);
    const [shareCode, setShareCode] = useState(null);
    const [shareType, setShareType] = useState("");

    useEffect(() => {
        setShareCode(props.shareCode);
        const isBrowse = !!(props.shareCode) && props.shareCode.toLowerCase().includes("b=1");
        const isReferral = !!(props.shareCode) && props.shareCode.toLowerCase().includes("referraluser");

        if (isReferral) {
            setReferralShareCode(props.shareCode)
        }

        let embedHost;
        let parsedShareCode;

        if (props.isUserMerchant) setRenderDirect(props.isUserMerchant);
        if (props.shareCode.includes(".ch/")) {
            embedHost = props.shareCode.slice(0, props.shareCode.indexOf(".ch/") + ".ch/".length)
        } else if (props.shareCode.includes(".com/")) {
            embedHost = props.shareCode.slice(0, props.shareCode.indexOf(".com/") + ".com/".length)
        } else if (props.shareCode.includes("44334/")) {
            embedHost = props.shareCode.slice(0, props.shareCode.indexOf("44334/") + "44334/".length)
        }

        parsedShareCode = props.shareCode.slice(props.shareCode.lastIndexOf("/") + 1);
        if (parsedShareCode.includes("?")) {
            parsedShareCode = parsedShareCode.slice(0, parsedShareCode.indexOf("?"));
        }

        if (props.shareCode?.toLowerCase().includes("/p/") || props.shareCode?.toLowerCase().includes("/plink/")) {
            setShareType("Post");

            let index = props.shareCode.toLowerCase().includes("/p/") ? props.shareCode.toLowerCase().indexOf("/p/") : props.shareCode.toLowerCase().indexOf("/plink/");
            let embedUrl = props.shareCode.slice(0, index) + "/embed" + props.shareCode.slice(index);
            let brandUrl = embedUrl.slice(0, embedUrl.indexOf("/embed") + "/embed".length) + "/brand" + embedUrl.slice(embedUrl.indexOf("/embed") + "/embed".length);
            let iframe = `<iframe id="rpFrame-${parsedShareCode}" src="${embedUrl}" frameborder="0" allowfullscreen="" scrolling="no" style="width:100%;height:770px;min-height:770px;overflow:hidden;"></iframe><script>window.addEventListener('message',function(e){if(e.data&&e.data.id==="rpFrame-${parsedShareCode}"){var t=e.data.height+'px';document.getElementById("rpFrame-${parsedShareCode}").style.height=t;document.getElementById("rpFrame-${parsedShareCode}").style.minHeight=t}},!1);</script>`;
            let brandIframe = `<iframe onload="sendMessage()" id="rpFrame-${parsedShareCode}" src="${brandUrl}" frameborder="0" allowfullscreen style="width:100%;height:100%;min-height:450px;"></iframe><script crossorigin="">function sendMessage(){document.querySelector("#rpFrame-${parsedShareCode}").contentWindow.postMessage("rpMessage","${embedHost}")}window.addEventListener("message",function(e){let isJson=!1;let rpJson;try{rpJson=JSON.parse(e.data);isJson=!0}catch{isJson=!1}if(isJson){if(typeof rpJson.shareCode==="string"){if(typeof rpJson.height==="number"){if(rpJson.height>0){const elements=document.querySelectorAll('iframe[id=rpFrame-'+rpJson.shareCode+']');elements.forEach(element=>{element.style.minHeight=rpJson.height+60+"px"})}}}}})</script>`;
            setPostEmbedCode(iframe);
            setPostEmbedBrandCode(brandIframe);
        } else if (props.shareCode?.toLowerCase().includes("/b/") || props.shareCode?.toLowerCase().includes("/blink/")) {
            setShareType("Blog");

            let index = props.shareCode.toLowerCase().includes("/b/") ? props.shareCode.toLowerCase().indexOf("/b/") : props.shareCode.toLowerCase().indexOf("/blink/");
            let embedUrl = props.shareCode.slice(0, index) + "/embed" + props.shareCode.slice(index);
            let iframe = `<iframe id="rpFrame-${parsedShareCode}" src="${embedUrl}" frameborder="0" allowfullscreen="" scrolling="no" style="width:100%;height:770px;min-height:770px;overflow:hidden;"></iframe><script>window.addEventListener('message',function(e){if(e.data&&e.data.id==="rpFrame-${parsedShareCode}"){var t=e.data.height+'px';document.getElementById("rpFrame-${parsedShareCode}").style.height=t;document.getElementById("rpFrame-${parsedShareCode}").style.minHeight=t}},!1);</script>`;
            setBlogEmbedCode(iframe);
        } else if (props.shareCode?.toLowerCase().includes("/l/") || props.shareCode?.toLowerCase().includes("/llink/")) {
            setShareType("Locker");

            let index = props.shareCode.toLowerCase().includes("/l/") ? props.shareCode.toLowerCase().indexOf("/l/") : props.shareCode.toLowerCase().indexOf("/llink/");
            let embedUrl = props.shareCode.slice(0, index) + "/embed" + props.shareCode.slice(index);
            let brandUrl = embedUrl.slice(0, embedUrl.indexOf("/embed") + "/embed".length) + "/brand" + embedUrl.slice(embedUrl.indexOf("/embed") + "/embed".length);
            let iframe = `<iframe id="rpFrame-${parsedShareCode}" src="${embedUrl}" frameborder="0" allowfullscreen="" scrolling="no" style="width:100%;height:770px;min-height:770px;overflow:hidden;"></iframe><script>window.addEventListener('message',function(e){if(e.data&&e.data.id==="rpFrame-${parsedShareCode}"){var t=e.data.height+'px';document.getElementById("rpFrame-${parsedShareCode}").style.height=t;document.getElementById("rpFrame-${parsedShareCode}").style.minHeight=t}},!1);</script>`;
            let brandIframe = `<iframe onload="sendMessage()" id="rpFrame-${parsedShareCode}" src="${brandUrl}" frameborder="0" allowfullscreen style="width:100%;height:100%;min-height:450px;"></iframe><script crossorigin="">function sendMessage(){document.querySelector("#rpFrame-${parsedShareCode}").contentWindow.postMessage("rpMessage","${embedHost}")}window.addEventListener("message",function(e){let isJson=!1;let rpJson;try{rpJson=JSON.parse(e.data);isJson=!0}catch{isJson=!1}if(isJson){if(typeof rpJson.shareCode==="string"){if(typeof rpJson.height==="number"){if(rpJson.height>0){const elements=document.querySelectorAll('iframe[id=rpFrame-'+rpJson.shareCode+']');elements.forEach(element=>{element.style.minHeight=rpJson.height+60+"px"})}}}}})</script>`;
            setLockerEmbedCode(iframe);
            setLockerEmbedBrandCode(brandIframe);
        } else if (props.shareCode?.toLowerCase().includes("/m/") || props.shareCode?.toLowerCase().includes("/mlink/")) {
            setShareType("Merchant");

            addShareSubdomainMerchant(parsedShareCode)
        } else if (props.shareCode?.toLowerCase().includes("/prd/")) {
            setShareType("Product");

            addShareSubdomainProduct(parsedShareCode);
            let index = props.shareCode.toLowerCase().indexOf("/prd/");
            let embedUrl = props.shareCode.slice(0, index) + "/embed" + props.shareCode.slice(index);

            let brandUrl = embedUrl.slice(0, embedUrl.indexOf("/embed") + "/embed".length) + "/brand" + embedUrl.slice(embedUrl.indexOf("/embed") + "/embed".length);
            let iframe = `<iframe src="${embedUrl}" frameborder="0" allowfullscreen style="width:100%;height:100%;min-height:320px;"></iframe>`
            let brandIframe = `<iframe onload="sendMessage()" id="rpFrame-${parsedShareCode}" src="${brandUrl}" frameborder="0" allowfullscreen style="width:100%;height:100%;min-height:450px;"></iframe><script crossorigin="">function sendMessage(){document.querySelector("#rpFrame-${parsedShareCode}").contentWindow.postMessage("rpMessage","${embedHost}")}window.addEventListener("message",function(e){let isJson=!1;let rpJson;try{rpJson=JSON.parse(e.data);isJson=!0}catch{isJson=!1}if(isJson){if(typeof rpJson.shareCode==="string"){if(typeof rpJson.height==="number"){if(rpJson.height>0){const elements=document.querySelectorAll('iframe[id=rpFrame-'+rpJson.shareCode+']');elements.forEach(element=>{element.style.minHeight=rpJson.height+60+"px"})}}}}})</script>`;
            setProductEmbedCode(iframe);
            setProductEmbedBrandCode(brandIframe);
        } else if (props.shareCode?.toLowerCase().includes("/prdlink/")) {
            setShareType("Product");

            addShareSubdomainProduct(parsedShareCode);
            let index = props.shareCode.toLowerCase().indexOf("/prdlink/");
            let embedUrl = props.shareCode.slice(0, index) + "/embed" + props.shareCode.slice(index);
            embedUrl = embedUrl.replace("/prdlink/", "/prd/");

            let brandUrl = embedUrl.slice(0, embedUrl.indexOf("/embed") + "/embed".length) + "/brand" + embedUrl.slice(embedUrl.indexOf("/embed") + "/embed".length);
            let iframe = `<iframe src="${embedUrl}" frameborder="0" allowfullscreen style="width:100%;height:100%;min-height:320px;"></iframe>`
            let brandIframe = `<iframe onload="sendMessage()" id="rpFrame-${parsedShareCode}" src="${brandUrl}" frameborder="0" allowfullscreen style="width:100%;height:100%;min-height:450px;"></iframe><script crossorigin="">function sendMessage(){document.querySelector("#rpFrame-${parsedShareCode}").contentWindow.postMessage("rpMessage","${embedHost}")}window.addEventListener("message",function(e){let isJson=!1;let rpJson;try{rpJson=JSON.parse(e.data);isJson=!0}catch{isJson=!1}if(isJson){if(typeof rpJson.shareCode==="string"){if(typeof rpJson.height==="number"){if(rpJson.height>0){const elements=document.querySelectorAll('iframe[id=rpFrame-'+rpJson.shareCode+']');elements.forEach(element=>{element.style.minHeight=rpJson.height+60+"px"})}}}}})</script>`;
            setProductEmbedCode(iframe);
            setProductEmbedBrandCode(brandIframe);
        }

    }, []);

    useEffect(() => {
        setShareCode(props.shareCode);

        if (props.shareCode?.toLowerCase().includes("/prf/")) {
            setShareType("Profile");

            let index = props.shareCode.toLowerCase().indexOf("/prf/");
            let embedUrl = props.shareCode.slice(0, index) + "/embed" + props.shareCode.slice(index);
            let iframe = `<iframe src="${embedUrl}" frameborder="0" allowfullscreen style="width:100%;"></iframe>`
            setProfileEmbedCode(iframe);
        }
        if (props.shareCode?.toLowerCase().includes("/prflink/")) {
            setShareType("Profile");

            let index = props.shareCode.toLowerCase().indexOf("/prflink/");
            let embedUrl = props.shareCode.slice(0, index) + "/embed" + props.shareCode.slice(index);
            embedUrl = embedUrl.replace("/prflink/", "/prf/");

            let iframe = `<iframe src="${embedUrl}" frameborder="0" allowfullscreen style="width:100%;"></iframe>`
            setProfileEmbedCode(iframe);
        }
    }, [props.shareCode]);

    const addShareSubdomainMerchant = async (id) => {
        let route = '/api/Merchant/GetMerchant?id=' + id;

        let merchant = await getRequest(route);

        if (!!(merchant) && window.location.host.toLowerCase().includes("app.rockp") && !!(merchant.shareSubdomain)) {
            let url = new URL(props.shareCode);
            url.hostname = `${merchant.shareSubdomain}.rockporch.com`;
            setShareCode(url.toString());
        }
    };

    const handleGoogleAnalytics4 = () => {
        window.gtag('event', 'Share_Modal_' + shareType, {
            send_to: 'G-9F2DZRE5FG',
            event_category: 'Button',
            event_label: `Share Modal ` + shareType,
        });
        console.log("Share Modal " + shareType)
    }

    const addShareSubdomainProduct = async (shareCode) => {
        let route = '/api/Merchant/GetMerchantByProductShareCode?shareCode=' + shareCode;

        let merchant = await getRequest(route);

        if (!!(merchant) && window.location.host.toLowerCase().includes("app.rockp") && !!(merchant.shareSubdomain)) {
            let url = new URL(props.shareCode);
            url.port = "";
            url.hostname = `${merchant.shareSubdomain}.rockporch.com`;
            setShareCode(url.toString());
        }
    }

    let renderCopiedText = () => {
        if (showCopied) {
            setTimeout(() => { setCopiedText(false) }, 500);
            return (<i className="fas fa-clipboard-check"></i>);
        }
        return ("Copy");
    }

    let renderCopiedText2 = () => {
        if (showCopied2) {
            setTimeout(() => { setCopiedText2(false) }, 500);
            return (<i className="fas fa-clipboard-check"></i>);
        }
        return ("Copy");
    }

    let renderCopiedText3 = () => {
        if (showCopied3) {
            setTimeout(() => { setCopiedText3(false) }, 500);
            return (<i className="fas fa-clipboard-check"></i>);
        }
        return ("Copy");
    }

    let renderCopiedText4 = () => {
        if (showCopied4) {
            setTimeout(() => { setCopiedText4(false) }, 500);
            return (<i className="fas fa-clipboard-check"></i>);
        }
        return ("Copy");
    }

    if (!!(referralShareCode)) {
        return (

            <div className="col-md-6 text-center" style={props.customStyle}>
                <div className="share-block" style={props.shareBlockStyle}>


                    <h5 className="mb-3">Copy and Send the Personalized Link Below to Refer your Creator Friends to RockPorch</h5>
                    <h6 className="mb-3 font-italic" style={{ fontSize: "14px" }}>Get paid for referring new Creators!</h6>

                    <p className="mb-4" style={{ fontSize: "12px" }}>
                        When you refer a fellow passion-driven Creator to RockPorch, we'll pay you 2.5% of the sales they generate through RockPorch for 12 MONTHS <img style={{ verticalAlign: "top" }} src="/images/flame.png" />
                    <br/>
                    <i>(don't worry... it doesn't come out of their commissions!)</i>
                    </p>

                    <div className="clipboard mb-4">
                        <input type="text" disabled defaultValue={referralShareCode} id="myInput" onChange={() => null} />
                        <button className="btn" onClick={() => { navigator.clipboard.writeText(referralShareCode); setCopiedText(true); handleGoogleAnalytics4(); }}>{renderCopiedText()}</button>
                    </div>
                    
                </div>

            </div>
        )
    }

    return (

        <div className="col-md-6 text-center" style={props.customStyle}>
            <div className="share-block" style={props.shareBlockStyle}>


                <h5 className="mb-4">Share it with the world!</h5>
                {
                    postEmbedCode != null || lockerEmbedCode != null ?
                        <h6 className="text-uppercase">Link</h6>
                        : <></>
                }
                <div className="clipboard mb-4">
                    <input type="text" disabled defaultValue={shareCode} id="myInput" onChange={() => null} />
                    <button className="btn" onClick={() => { navigator.clipboard.writeText(shareCode); setCopiedText(true); handleGoogleAnalytics4(); }}>{renderCopiedText()}</button>
                </div>

                {props.children}

                {
                    postEmbedCode != null ?
                        <>
                            <h6 className="text-uppercase">Embed Code</h6>

                            <div className="clipboard mb-4">
                                <input type="text" disabled defaultValue={postEmbedCode} id="myInput" onChange={() => null} />
                                <button className="btn" onClick={() => { navigator.clipboard.writeText(postEmbedCode); setCopiedText2(true); }}>{renderCopiedText2()}</button>
                            </div>
                        </> : <></>
                }

                {
                    props.loggedInUser?.isAdmin && postEmbedBrandCode != null ?
                        <>
                            <h6 className="text-uppercase">ADMIN Embed Code</h6>

                            <div className="clipboard mb-4">
                                <input type="text" disabled defaultValue={postEmbedBrandCode} id="myInput" onChange={() => null} />
                                <button className="btn" onClick={() => { navigator.clipboard.writeText(postEmbedBrandCode); setCopiedText4(true); }}>{renderCopiedText4()}</button>
                            </div>
                        </> : <></>
                }

                {
                    blogEmbedCode != null ?
                        <>
                            <h6 className="text-uppercase">Embed Code</h6>

                            <div className="clipboard mb-4">
                                <input type="text" disabled defaultValue={blogEmbedCode} id="myInput" onChange={() => null} />
                                <button className="btn" onClick={() => { navigator.clipboard.writeText(blogEmbedCode); setCopiedText2(true); }}>{renderCopiedText2()}</button>
                            </div>
                        </> : <></>
                }

                {
                    lockerEmbedCode != null ?
                        <>
                            <h6 className="text-uppercase">Embed Code</h6>

                            <div className="clipboard mb-4">
                                <input type="text" disabled defaultValue={lockerEmbedCode} id="myInput" onChange={() => null} />
                                <button className="btn" onClick={() => { navigator.clipboard.writeText(lockerEmbedCode); setCopiedText2(true); }}>{renderCopiedText2()}</button>
                            </div>
                        </> : <></>
                }

                {
                    props.loggedInUser?.isAdmin && lockerEmbedBrandCode != null ?
                        <>
                            <h6 className="text-uppercase">ADMIN Embed Code</h6>

                            <div className="clipboard mb-4">
                                <input type="text" disabled defaultValue={lockerEmbedBrandCode} id="myInput" onChange={() => null} />
                                <button className="btn" onClick={() => { navigator.clipboard.writeText(lockerEmbedBrandCode); setCopiedText4(true); }}>{renderCopiedText4()}</button>
                            </div>
                        </> : <></>
                }

                {
                    productEmbedBrandCode != null ?
                        <>
                            <h6 className="text-uppercase">Embed Code</h6>

                            <div className="clipboard mb-4">
                                <input type="text" disabled defaultValue={productEmbedBrandCode} id="myInput" onChange={() => null} />
                                <button className="btn" onClick={() => { navigator.clipboard.writeText(productEmbedBrandCode); setCopiedText3(true); }}>{renderCopiedText3()}</button>
                            </div>
                        </> : <></>
                }

                {
                    props.loggedInUser?.isAdmin && profileEmbedCode != null ?
                        <>
                            <h6 className="text-uppercase">ADMIN Embed Code</h6>

                            <div className="clipboard mb-4">
                                <input type="text" disabled defaultValue={profileEmbedCode} id="myInput" onChange={() => null} />
                                <button className="btn" onClick={() => { navigator.clipboard.writeText(profileEmbedCode); setCopiedText4(true); }}>{renderCopiedText4()}</button>
                            </div>
                        </> : <></>
                }
                
                <div className="social-share d-flex justify-content-center">
                    <TwitterIcon shareCode={props.shareCode} />
                    <FacebookIcon shareCode={props.shareCode} />
                    <MailIcon shareCode={props.shareCode} />
                    <MobileShare shareCode={props.shareCode} />
                </div>
            </div>

        </div>
    )
}

export default ShareBlock;