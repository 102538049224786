let blankFeedPost = {
    id: 0,
    content: "",
    postedOn: "",
    shareCode: "",
    title: "",
    status: 1,
    type: 1,
    likedByUser: false,
    savedByUser:false,
    user: {
        name: "",
        imageUrl: "",
        userName: "",
        merchantProducts: {
            values: []
        }
    },
    proStatusId: 1,
    productItems: {
        $values: [
            {
                comment: "",
                id: 0,
                ordinal: 0,
                type: 1,
                userMerchantProduct: {
                    id: 0,
                    product: {
                        merchant: {
                            id: 0,
                            name: "",
                            hostname: "",
                            integrated: false
                        },
                        product: {
                            id: 0,
                            manufacturer: {
                                id: 0,
                                name: ""
                            },
                            productCode: ""
                        },
                        name: "",
                        description: "",
                        url: "",
                        imageUrl: "",
                        merchantProductCode: "",
                        price: 0
                    },
                    coupon: ""
                }
            }
        ]
    },
    likes: {
        values: []
    },
    comments: {
        values: []
    },
    commentCount: 0,
    likeCount:0,
    properties: {
        $type: "",
        $values: [
            {
                $id: "",
                userPost: {
                    $ref: ""
                },
                role: 0,
                reference: "",
                id: 0
            }
        ]
    },
    flags: {
        values: []
    },
    sectors: {
        $values: []
    }
}

export default blankFeedPost