import React from "react";
import { NavLink } from "react-router-dom";
import { Navbar } from "reactstrap";
import CloseIcon from "components/icons/CloseIcon";
import FormInputField from "components/FormInputField";
import { Input } from "reactstrap";

/**
 * 
 * @param {{toggleModal:Function, searchHandle:Function}} props
 */
const ProductSelectorModalHeader = (props) => {
    return (
        <div className="modal-header">
            <header style={{ width: "100%",border:"none" }}>

                <Navbar className="navbar-expand-lg" light>
                    <div className="col-10 col-lg-11 middle-column d-flex align-items-center justify-content-between">

                        <NavLink to="#" onClick={(e) => e.preventDefault()}>My Products</NavLink>
                        <Input
                            className="form-control mr-sm-2 p-1 w-100"
                            type="search"
                            placeholder="Search for products..."
                            aria-label="Search"
                            onChange={(e) => props.searchHandle(e)}
                            style={{ backgroundColor: "#e9ecef" }}
                        />
                    </div>
                    <div className="col-2 col-lg-1">
                        <button className="btn btn-light float-right" onClick={props.toggleModal} style={{ backgroundColor: "transparent", border: "0px" }}>
                            <CloseIcon /> 
                        </button>
                    </div>
                </Navbar>
            </header>
        </div>
    )
}

export default ProductSelectorModalHeader;