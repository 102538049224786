import React from "react"

const GridIcon = (props) => {
    return (
        <svg version="1.1" style={props.style} width={props.x || "18"} height={props.y || "18"} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000" enableBackground="new 0 0 1000 1000" xmlSpace="preserve">
            <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
            <g><path d="M500,10C229.4,10,10,229.4,10,500c0,270.6,219.4,490,490,490c270.6,0,490-219.4,490-490C990,229.4,770.6,10,500,10z M351.4,744.3l-184.4,0.4c0,0,0.9-273.5,0.9-307.2c0-33.7,27-47.2,27-47.2h158.6C354,390.6,351.4,744.3,351.4,744.3z M808.3,444c15.7,8.2,26.9,12,27.7,46.5c0.6,23.6-18.6,32-27.5,36.5c-9,4.5-9.3,18.9,7.3,25.9c16.9,7.1,22.7,27.2,23.1,38.2c0.6,18-3.4,29.8-19.1,40.5c0,0-31.5,13.5-17.4,30.5c18.7,22.6,30.7,65.2-40.5,75c-67.2,9.2-172.4,15.7-260.9-11.2l-113.2-29.2l-0.6-276.1c0,0,140.3-158.2,165.6-220.6c25.3-62.4,23.6-92.8,33.7-99.5c10.1-6.7,81-10.1,84.3,74.2c3.4,84.3-59,153.5-59,153.5h188.9c0,0,37.6,2.7,39.1,52.2c0.8,25.5-33.7,43.5-33.7,43.5S793,436.1,808.3,444z" fill={props.fill || "black"} fillOpacity="0.54" /></g>
        </svg>
    )
}

export default GridIcon;