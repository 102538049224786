import * as React from "react";
import { NavLink } from "react-router-dom";
import { postRequest } from "sharedUtils/httpUtils";

import ProfileInfoCardOnboarding from "components/ProfileInfoCardOnboarding";

class OnboardWhoToFollow extends React.Component {

    state = {
        whoToFollow: [],
    }

    componentDidMount = async () => {
        this.props.setShowTopNav(false);
    }

    onSubmitClick = async () => {
        this.props.setShowTopNav(true);
        await this.saveUser();
        this.props.history.push('/HomeFeed');
    }

    convertToUserModel = () => {
        return {
            name: this.props.loggedInUser.name,
            firstName: this.props.loggedInUser.firstName,
            lastName: this.props.loggedInUser.lastName,
            bio: this.props.loggedInUser.bio,
            tagLine: this.props.loggedInUser.tagLine,
            website: this.props.loggedInUser.website,
            googleUsername: this.props.loggedInUser.googleUsername,
            facebookUsername: this.props.loggedInUser.facebookUsername,
            instagramUsername: this.props.loggedInUser.instagramUsername,
            imageUrl: this.props.loggedInUser.imageUrl,
            userName: this.props.loggedInUser.userName,
            email: this.props.loggedInUser.email,

            phoneNumber: this.props.loggedInUser.phoneNumber,
            proStatusId: this.props.loggedInUser.proStatusId,
            address: this.props.loggedInUser.address,
            city: this.props.loggedInUser.city,
            state: this.props.loggedInUser.state,
            postalCode: this.props.loggedInUser.postalCode,
            country: this.props.loggedInUser.country,

            onboardingComplete: this.props.loggedInUser.onboardingComplete,
            profileBannerUrl: this.props.loggedInUser.profileBannerUrl,

            quickStart: this.props.loggedInUser.quickStart
        }
    }

    saveUser = async () => {
        let userUrl = '/api/Account/PostUser';
        this.props.loggedInUser.onboardingComplete = true;
        let user = await postRequest(userUrl, this.convertToUserModel());
        await this.props.setLoggedInUser(user)
    }

    render = () => {
        return (
            <div className="container-fluid gray-bg full-height">
                <div className="row mt-0">
                    <div className="col-12 px-0 top-banner position-relative">
                        <img src="images/top-banner.png" alt="" className="w-100 h-100" />
                            <div className="interest-steps position-absolute text-center w-100">
                                <h1 className="mb-4">Who To Follow</h1>
                                <p>Build your feed by following Creators</p>
                                <ul className="steps-count pl-0">
                                    <li className="d-inline-flex justify-content-center align-items-center active">1</li>
                                    <li className="d-inline-flex justify-content-center align-items-center active">2</li>
                                    <li className="d-inline-flex justify-content-center align-items-center active">3</li>
                                </ul>
                            </div>
                    </div>

                    <div className="col-12 who-to-follow py-4">
                        <div className="container">
                            <div className="row pt-0 mt-0 pb-5">
                                <div className="col-md-3 col-lg-3 side-nav">
                                </div>

                                <div className="col-md-6 col-lg-12 px-0">
                                    <ProfileInfoCardOnboarding loggedInUser={this.props.loggedInUser} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row py-4 position-fixed white-bg secondary-footer">
                    <div className="col-12 text-center text-lg-right">
                        <NavLink to="/HomeFeed">
                            <button className="btn btn-primary" onClick={(e) => { e.preventDefault(); this.onSubmitClick() }} >Continue to Your Feed</button>
                        </NavLink>
                    </div>
                </div>
            </div>)
    }
}

export default OnboardWhoToFollow;