import React from "react";
import { getRequest } from "sharedUtils/httpUtils";
import { Form, FormGroup, Label, Input, Popover, PopoverBody, CustomInput } from "reactstrap";
import { isValidPassword } from "sharedUtils/validationUtils"
class ResetPasswordForm extends React.Component {

    constructor(props) {
        super(props);
            this.state = {
                newPassword: "",
                newPasswordConfirmed: "",
                newPasswordConfirmedValidation: "",
                newPasswordValidation:""
            }
        
    }

    submitPassword = async () => {
        let { user } = this.props;
        let passwordsMatch = this.state.newPassword === this.state.newPasswordConfirmed && this.state.newPassword.length > 4 && this.state.newPasswordConfirmed.length > 4

        if (passwordsMatch) {
            let url = `/api/Account/ResetPassword?userIdToUpdate=${user.id}&password=${this.state.newPasswordConfirmed}`;

            await getRequest(url);

            this.setState({ newPassword: "", newPasswordConfirmed: "", passwordsMatch:false })
        }
    }

    displaySubmitButton = () => {
        let passwordsMatch = this.state.newPassword === this.state.newPasswordConfirmed && this.state.newPassword.length >= 8 && this.state.newPasswordConfirmed.length >= 8

        let isValid = isValidPassword(this.state.newPasswordConfirmed) && isValidPassword(this.state.newPassword);
        if (passwordsMatch && isValid) {
            return (
                <button className="btn btn-primary" onClick={this.submitPassword}>
                    Submit
                </button>
            )
        }
        else {
            return (
                <button className="btn btn-primary" disabled>
                    Submit
                </button>
                )
        }
    }

    onInputChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });

        let isValid = isValidPassword(e.target.value);

        if (e.target.name === "newPassword") {
            if (e.target.value.length < 8) {
                this.setState({ newPasswordValidation: "Password should be at least 8 characters long" });
            }
            else if (isValid == false) {
                this.setState({ newPasswordValidation: "Password should contain an uppercase character, lowercase character and a number" });
            }
            else {
                this.setState({ newPasswordValidation: "" });
            }
        }

        if (e.target.name === "newPasswordConfirmed") {
            if (e.target.value !== this.state.newPassword ) {
                this.setState({ newPasswordConfirmedValidation: "Passwords should match" });
            }
            else {
                this.setState({ newPasswordConfirmedValidation:"" })
            }
        }
    }


    displayValidation = () => {
        return (
            <small>
                Minimum of 8 characters with 1 uppercase, 1 lowercase and 1 number. Passwords must match 
            </small>
            
            )
    }

    render() {
        return (
            <div className="share-block" style={{ border: "none", backgroundColor: "transparent" }}>
                <FormGroup className="form-group">
                    <Label className="control-label">New Password</Label>
                    <Input
                        className="form-control"
                        name={"newPassword"}
                        onChange={(e) => this.onInputChange(e)}
                        value={this.state.newPassword}
                        type="password"
                    />
                    <small>
                        
                        {this.state.newPasswordValidation}
                    </small>
                </FormGroup>


                <FormGroup  className="form-group">
                    <Label className="control-label">Confirm New Password</Label>
                    <Input
                        className="form-control"
                        name={"newPasswordConfirmed"}
                        onChange={(e) => this.onInputChange(e)}
                        value={this.state.newPasswordConfirmed}
                        type="password"
                    />
                    <small>
                 
                        {this.state.newPasswordConfirmedValidation}
                    </small>
                </FormGroup>

                {this.displaySubmitButton()}
            
            </div>
            
            )
    }
}

export default ResetPasswordForm;