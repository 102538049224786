import React, { useState } from "react";
import ActionButton from "components/ActionButton";
import { isValidUrl } from "sharedUtils/validationUtils";
import { useEffect, useRef } from "react";
import { getRequest } from "sharedUtils/httpUtils";

/**
 * 
 * @param {{advance:Function,productUrl:string,handleInput:Function, isLoading:boolean, productNotFound:boolean, isModal:boolean}} props 
 */
const ShareABlogLookupUrlForm = (props) => {

    const inputRef = useRef(null);
    const [urlInput, setUrlInput] = useState("");
    const [invalidUrl, setInvalidUrl] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("Blog URL will not process. Try again.");

    useEffect(() => {
        if (inputRef && inputRef.current) {
            inputRef.current.focus();
        }
        if (!!(props.validUrl)) {
            setUrlInput(props.validUrl);
            setInvalidUrl(false);
        }
    }, [])

    const fetchLinkPreview = async  () => {
        const url = "/api/LinkPreview/Get?url=" + encodeURIComponent(urlInput);
        const linkPreview = await getRequest(url);
        setIsLoading(false);
        if (!(linkPreview)) {
            setShowError(true);
        } else {
            props.handleUrlSubmit(linkPreview, urlInput);
        }
    }

    const handleOnChange = (event) => {
        let url = event.target.value;
        setUrlInput(url);

        let isValid = url.length > 0 ? isValidUrl(url) : false;
        if (isValid) {
            setInvalidUrl(false);
        } else {
            setInvalidUrl(true);
        }
    }

    const handleSubmit = () => {
        setIsLoading(true);
        fetchLinkPreview();
    }

    const handleKeypress = (e) => {
        if (e.key === 'Enter') {
            handleSubmit();
        }
    }


    return (
        <div className="col-md-6 text-center" style={props.customStyle}>
            <div className="share-block" style={{ ...props.shareBlockStyle }}>

                <div style={{ textAlign: "left" }}>
                    <h5 className="mb-4">Share a Blog Article</h5>
                    <h6 className="text">Paste blog URL here.</h6>

                    <div className="mb-4 d-flex justify-content-around" style={{ marginLeft: "0px" }}>

                        <input
                            ref={inputRef}
                            className="w-75 mr-3 pl-2   "
                            type="text"
                            placeholder={"Paste Blog URL here"}
                            value={urlInput}
                            id="blogInput"
                            onChange={(e) => handleOnChange(e)}
                            onKeyPress={handleKeypress}
                            style={{ backgroundColor: "#e9ecef", border: "none" }}
                        />
                        <ActionButton onClick={handleSubmit} className="btn btn-primary" isLoading={isLoading} isDisabled={invalidUrl}>Submit</ActionButton>
                    </div>

                    {
                        showError ? (
                            <div className="w-100 text-center">
                                <small style={{ color: "#e55934" }}>
                                    Sorry!<br />
                                    {errorMessage}
                                </small>
                                <p>&nbsp;</p>
                            </div>
                        ) : <span></span>
                    }

                </div>

            </div>
        </div>
    )


}


export default ShareABlogLookupUrlForm;