import React from "react";
import AccountBanner from "../../components/AccountBanner"
import { getRequest } from "sharedUtils/httpUtils";
import ProductNotFound from "../../components/ItOperations/ProductNotFound";
import ProductFeeds from "../../components/ItOperations/ProductFeeds";
import ProductUpdate from "../../components/ItOperations/ProductUpdate";
import ElmahLogs from "../../components/ItOperations/ElmahLogs";
import ElmahLog from "../../components/ItOperations/ElmahLog";


class ItOperations extends React.Component {

    state = {
        selectedCase: 1,
        log: null,
    }

    breadCrumbList = [{ link: '/Admin', text: 'Admin' }, { link: '/Admin/ItOperations', text: 'IT Operations' }];
    productNotFoundBreadCrumbList = [
        ...this.breadCrumbList,
        { link: '/Admin/ItOperations', text: 'Products Not Found', active: true }
    ];
    productFeedsBreadCrumbList = [
        ...this.breadCrumbList,
        { link: '/Admin/ItOperations', text: 'Product Feeds', active: true }
    ];
    productUpdateBreadCrumbList = [
        ...this.breadCrumbList,
        { link: '/Admin/ItOperations', text: 'Product Update', active: true }
    ];
    elmahLogsBreadCrumbList = [
        ...this.breadCrumbList,
        { link: '/Admin/ItOperations', text: 'Elmah Logs', active: true }
    ];

    componentDidMount = async () => {
        let scrollOptions = {
            left: 0,
            top: 0,
            behavior: 'auto'
        }
        window.scrollTo(scrollOptions);
    }


    displayToggleButtons = () => {
        let options = [
            {
                name: "Product Not Found",
                case: 1,
                action: () => this.setState({ selectedCase: 1 })
            },
            {
                name: "Product Feeds",
                case: 2,
                action: () => this.setState({ selectedCase: 2 })
            },
            {
                name: "Product Update",
                case: 3,
                action: () => this.setState({ selectedCase: 3 })
            },
            {
                name: "Elmah Logs",
                case: 4,
                action: () => this.setState({ selectedCase: 4 })
            },

        ];

        return options.map((toggle, i) => {
            return (
                <button key={i}
                    className={`btn ${this.state.selectedCase === toggle.case ? "btn-primary btn-lg" : ""}`}
                    onClick={toggle.action}
                >
                    {toggle.name}
                </button>
            )
        })
    }

    toggleErrorLog = (log = null) => {
        if (this.state.selectedCase === 4) {
            this.setState({ selectedCase: 5 })
        } else if (this.state.selectedCase === 5) {
            this.setState({ selectedCase: 4 })
        }
        if (log) {
            this.setState({ log });
        }
    }

    render() {
        return (
            <>
                <section>
                    <AccountBanner breadCrumbList={this.state.selectedCase === 1 ? this.productNotFoundBreadCrumbList :
                        this.state.selectedCase === 2 ? this.productFeedsBreadCrumbList : this.state.selectedCase === 3
                            ? this.productUpdateBreadCrumbList : this.breadCrumbList}>
                        <h1 className="mb-4">IT Operations</h1>
                        <p className="mb-0">Manage IT Operations</p>
                    </AccountBanner>
                </section>
                <section className="filter-wrapper" >
                    <div className="container">
                        <div className="row py-2 mt-0">
                            <div className="col-12 d-flex justify-content-between">
                                <div className="filter-block d-flex">
                                    {this.displayToggleButtons()}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="gray-bg full-height pt-1">
                    <ProductNotFound
                        display={this.state.selectedCase === 1}
                        loggedInUser={this.props.loggedInUser}
                    />

                    <ProductFeeds
                        display={this.state.selectedCase === 2}
                        loggedInUser={this.props.loggedInUser}
                    />

                    <ProductUpdate
                        display={this.state.selectedCase == 3}
                        loggedInUser={this.props.loggedInUser}
                    />
                    <ElmahLogs
                        display={this.state.selectedCase == 4}
                        loggedInUser={this.props.loggedInUser}
                        toggleErrorLog={this.toggleErrorLog}
                    />
                    <ElmahLog
                        display={this.state.selectedCase == 5}
                        loggedInUser={this.props.loggedInUser}
                        toggleErrorLog={this.toggleErrorLog}
                        log={this.state.log}
                    />

                </section>
            </>
        )
    }
}

export default ItOperations;