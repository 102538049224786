import React from "react";

/**
 * 
 * @param {{width:Number,height:Number,fill:string}} props
 */
const CommissionsIcon = (props) => {
    return (
        <svg onClick={props.onClick || (() => { })} width={props.width || "20"} height={props.height || "20"} xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" viewBox="0 0 256 256" enableBackground="new 0 0 256 256" xmlSpace="preserve">
            <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
            <g>
                <g>
                    <g>
                        <path className={props.fillClass || ""} fill="#000000" d="M52.5,10.9c-20.1,2.2-36.3,10.2-41,20.3l-1.2,2.5v14.3v14.3l1.2,2.5C21,85.2,69.6,92.5,98.8,78.1c6.9-3.4,11.9-7.8,14.4-12.7l1.3-2.5V48.1V33.3l-1.3-2.6c-4.3-8.8-17.4-15.9-34.7-18.8C72.2,10.8,58.7,10.3,52.5,10.9z" />
                        <path className={props.fillClass || ""} fill="#000000" d="M180.4,65.7c-15,2.1-26.6,6.8-33.5,13.8c-5.6,5.6-6.2,7.8-6.2,22.9c0,10.5,0.4,14.7,1.7,17.3c3.9,7.6,14.2,14.1,27.8,17.5c25.6,6.4,56.5,1.2,69.5-11.6c1.9-1.9,3.5-4,4.5-6l1.5-3.1l0.1-12c0.2-16.4-0.3-18.8-5.3-24.3c-6-6.5-16.4-11.2-30.3-13.8C203.4,65,187.5,64.7,180.4,65.7z" />
                        <path className={props.fillClass || ""} fill="#000000" d="M10.1,102.9c0.1,12.1,0.1,12.1,1.4,14.8c7.4,15.9,40.6,24.7,70.3,18.7c13-2.6,24.4-8.4,29.4-15c3.5-4.6,3.6-5.6,3.6-18.7c0-6.4,0-11.5-0.1-11.5c-0.1,0-2.3,1.1-5,2.5c-9.1,4.8-19.5,8-31.1,9.8c-7.6,1.2-24,1.2-31.8,0c-13-1.9-26.2-6.2-34.5-11.4L10,90.8L10.1,102.9z" />
                        <path className={props.fillClass || ""} fill="#000000" d="M10.1,155.7c0.1,11.9,0.1,11.9,1.4,14.6c2.5,5.3,6.4,8.9,13.9,12.8c10,5.2,22.3,7.8,37.3,7.8c24,0,45-8.4,50.6-20.3c1.2-2.5,1.2-2.7,1.3-14.7c0.1-6.6,0-12.1,0-12.1c-0.1,0-1.8,0.9-3.8,2.1c-7.8,4.4-20,8.4-31.7,10.2c-4.1,0.6-7.8,0.8-17.2,0.8c-10.8,0-12.7-0.1-18.7-1.2c-11.1-2-22.3-5.8-29.6-10.1c-1.7-1-3.2-1.8-3.4-1.8C10.1,143.9,10,149.2,10.1,155.7z" />
                        <path className={props.fillClass || ""} fill="#000000" d="M140.8,157.2l0.1,11.9l1.4,3c1.9,4.1,4.5,6.8,9.3,10.1c19.2,12.8,54.9,14.2,77.9,3.1c7.5-3.6,12.3-8,14.9-13.4c1.3-2.7,1.3-2.7,1.4-14.6l0.2-11.8l-6.3,3.1c-13.9,7.1-28.6,10.3-46.4,10.3c-17.9,0-32.4-3.2-46.5-10.3l-6.2-3.1L140.8,157.2z" />
                        <path className={props.fillClass || ""} fill="#000000" d="M10.1,208.6l0.1,12.1l1.3,2.7c4.2,8.7,17.7,16.2,34,18.9c29,4.9,59.7-3.6,67.7-18.7l1.3-2.5l0.1-12.3l0.1-12.3l-6.3,3.1c-9.9,5-21.6,8.3-33.6,9.6c-5.8,0.6-19.4,0.6-25.2,0c-11.9-1.3-23.7-4.7-33.6-9.7l-6.2-3.1L10.1,208.6z" />
                        <path className={props.fillClass || ""} fill="#000000" d="M140.8,210l0.1,11.7l1.5,3.1c10.6,22.4,67.3,28.1,93.6,9.4c3.9-2.7,6.5-5.8,8.2-9.4l1.5-3.1l0.1-11.7c0.1-7.8,0-11.6-0.3-11.6c-0.2,0-1.7,0.7-3.2,1.6c-8.5,5-21.8,9.2-34.7,11c-5.7,0.7-22.9,0.7-28.6,0c-12.9-1.8-26.2-6-34.7-11c-1.5-0.9-3-1.6-3.2-1.6C140.8,198.3,140.6,202.2,140.8,210z" />
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default CommissionsIcon;