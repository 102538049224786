import React from "react"
/**
 * 
 * @param {{callback:Function}} props 
 */
const ShareIosIcon = (props) => {
    return (
        <svg width={props.width || "16"} height={props.height || "16"} x="0px" y="0px" viewBox="0 0 1000 1000" enableBackground="new 0 0 1000 1000" style={{ marginBottom: "2px" }} fill={props.color} >
<metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
            <g><g transform="translate(0.000000,511.000000) scale(0.100000,-0.100000)">
                <path strokeWidth={props.strokeWidth || "1"} stroke={props.color || "currentColor"} fill={props.color} fillOpacity="0.6" d="M4884.1,4997.4c-68.9-18.2-188.6-127.7-813.1-754.3c-707.7-707.7-734-736.1-758.4-835.4c-73-316.3,223-553.6,517.1-415.7c48.7,24.3,217,174.4,425.8,385.3c188.6,190.6,350.8,346.7,358.9,346.7c8.1,0,18.2-1082.8,20.3-2406.9l6.1-2408.9l46.6-81.1c71-123.7,168.3-180.4,306.2-182.5c148,0,261.6,68.9,324.4,196.7l42.6,89.2v2408.9v2408.9l381.2-385.3c358.9-365,385.3-387.3,482.6-409.6c186.6-42.6,358.9,40.5,436,212.9c42.6,93.3,46.7,150.1,16.2,263.6c-18.3,69-135.9,194.7-723.9,786.7c-387.3,387.3-730,721.9-764.4,744.2C5109.2,5007.5,4985.5,5023.7,4884.1,4997.4z" />
                <path strokeWidth={props.strokeWidth || "1"} stroke={props.color || "currentColor"} fill={props.color} fillOpacity="0.6" d="M1791.9,2519.5c-219-60.8-375.1-186.6-464.3-377.2l-52.7-111.5v-3122.7v-3122.7l46.6-115.6c58.8-146,221-326.5,356.9-397.4l103.4-54.8l3177.4-6.1c3167.3-4.1,3177.4-4.1,3288.9,36.5c129.8,48.7,292,186.5,362.9,310.2c123.7,208.8,117.6,16.2,111.5,3400.4l-6.1,3092.2l-48.7,91.3c-75,141.9-123.7,198.7-233.2,273.7c-182.5,123.7-267.6,133.8-1050.3,127.7l-695.5-6.1l-95.3-54.8c-156.1-93.3-217-271.7-148-436c42.6-101.4,85.2-146,182.5-190.6c73-34.5,141.9-38.5,673.2-38.5h592.1l56.8-48.6l56.8-48.7l6.1-2790.1l4.1-2792.1l-48.7-48.7l-50.7-50.7H4999.7c-2844.9,0-2919.9,0-2966.5,38.5l-48.7,38.5v2802.3v2802.3l56.8,48.7l56.8,48.6h584h582l111.5,56.8c294,148,257.5,541.4-60.8,648.9C3215.3,2558.1,1915.6,2554,1791.9,2519.5z" /></g></g>
</svg>
    )
}

export default ShareIosIcon;