import * as React from "react";
import { Form } from "reactstrap";
import FormInputField from "../../components/FormInputField";

import AccountSidebar from "../../components/AccountSidebar";
import AccountBanner from "../../components/AccountBanner";

import { postRequest } from "../../sharedUtils/httpUtils";
import { debounce } from "throttle-debounce";
import { isValidPassword } from "sharedUtils/validationUtils";
import { Helmet } from 'react-helmet';

class AccountSettings extends React.Component {

    minLengthMessage = 'Minimum 8 characters';
    doesNotMatchMessage = 'Password does not match'
    requiredCharactersMessage = 'Password must contain one uppercase letter, one lowercase letter and one number'

    debounceEmailLookup = debounce(300, async () => {
        let emailRegex = /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/
        let validEmail = emailRegex.test(this.state.formData.email);

        if (validEmail) {
            if (this.state.formData.email === this.props.loggedInUser.email) {
                this.setState({ enableEmailSubmit: false, emailMessageStyle: "", emailMessage: "" });
                return;
            }

            var checkEmailUrl = "api/Account/CheckEmail";
            let response = await postRequest(checkEmailUrl, this.state.formData);

            if (response) {
                this.setState({ enableEmailSubmit: true, emailMessageStyle: "", emailMessage: "" });
            } else {
                this.setState({ enableEmailSubmit: false, emailMessageStyle: "", emailMessage: "That email address is in use by another user" });
            }
        } else {
            this.setState({ enableEmailSubmit: false, emailMessageStyle: "", emailMessage: "Invalid email address" });
        }
    });

    state = {
        formData: {
            email: ""
        },
        minLengthMessage: "",
        doesNotMatchMessage: "",
        requiredCharactersMessage:"",
        saving: false,
        password: "",
        newPassword: "",
        confirmPassword: "",
    }

    componentDidMount = async () => {
        let scrollOptions = {
            left: 0,
            top: 0,
            behavior: 'auto'
        }
        window.scrollTo(scrollOptions);

        let formData = {};
        formData.email = this.props.loggedInUser.email;
        this.setState({ formData: formData });
    }

    onInputChange = (e) => {
        let formkey = e.target.name;
        let newData = e.target.value;
        let formData = this.state.formData;
        formData[formkey] = newData;

        if (formkey === "newPassword") {
            if (newData.length < 8 && newData.length > 0) {
                this.setState({ minLengthMessage: this.minLengthMessage, requiredCharactersMessage: "" });
            }
            else if (isValidPassword(newData) === false) {
                this.setState({ requiredCharactersMessage: this.requiredCharactersMessage, minLengthMessage:"" })
            }
            else {
                this.setState({ minLengthMessage: "", requiredCharactersMessage:"" });
            }
        }

        if (formkey === "confirmPassword" || formkey === "newPassword") {
            if (formData.newPassword !== formData.confirmPassword) {
                this.setState({ doesNotMatchMessage: this.doesNotMatchMessage });
            } else {
                this.setState({ doesNotMatchMessage: "" });
            }
        }
        this.setState({ formData: formData });
    };

    onSubmit = async (event) => {
        event.preventDefault();
        this.setState({ saving: true });
        let userUrl = '/api/Account/UpdatePassword';
        let result = await postRequest(userUrl, { password: this.state.formData.password, newPassword: this.state.formData.newPassword });
        if (result) {
            this.setState({ passwordMessageStyle: "", passwordMessage: "Password updated successfully" });
        } else {
            this.setState({ passwordMessageStyle: "form-text-alert", passwordMessage: "Unable to update password.  Please try again." })
        }
        this.setState({ saving: false });
    };

    onEmailSubmit = async (event) => {
        event.preventDefault();
        this.setState({ saving: true });
        let userUrl = '/api/Account/UpdateEmail';
        let result = await postRequest(userUrl, this.state.formData);
        if (result) {
            this.setState({ emailMessageStyle: "", emailMessage: "We've sent a confirmation email to the address you specified." });
        } else {
            this.setState({ emailMessageStyle: "form-text-alert", emailMessage: "Unable to update email.  Please try again." })
        }
        this.setState({ saving: false });
    };


    renderPasswordSubmit = () => {
        let isValid = isValidPassword(this.state.formData.password) && isValidPassword(this.state.formData.newPassword);
        if (this.state.formData?.password?.length > 0 && this.state.formData?.newPassword?.length >= 8 && this.state.formData?.confirmPassword === this.state.formData?.newPassword && isValid) {
            return (<button type="submit" className="btn btn-secondary mt-3" onClick={(e) => this.onSubmit(e)}>
                {this.state.saving ?
                    <div className="spinner-border spinner-border-sm" role="status">
                        <span className="sr-only">Loading...</span>
                    </div> : ""} Submit</button>)
        }
        return <button type="submit" className="btn btn-secondary mt-3" disabled onClick={(e) => this.onSubmit(e)}>Submit</button>
    }

    renderEmailSubmit = () => {
        if (this.state.enableEmailSubmit) {
            return (<button type="submit" className="btn btn-secondary mt-3" onClick={(e) => this.onEmailSubmit(e)}>
                {this.state.saving ?
                    <div className="spinner-border spinner-border-sm" role="status">
                        <span className="sr-only">Loading...</span>
                    </div> : ""} Submit</button>)
        }
        return <button type="submit" className="btn btn-secondary mt-3" disabled onClick={(e) => this.onEmailSubmit(e)}>Submit</button>
    }

    onEmailChange = async (e) => {
        e.preventDefault();
        let formData = this.state.formData;
        formData.email = e.target.value;
        this.setState({ formData: formData });

        this.debounceEmailLookup();
    }

    renderEmail = () => {
        return (<div className="detail-block">
            <h5>Account Settings</h5>

            <FormInputField
                title="Email"
                name="email"
                inputType="email"
                value={this.state.formData.email}
                onInputChange={(e) => this.onEmailChange(e)}
                smallText={this.state.emailMessage}
                smallTextStyle={this.state.emailMessageStyle}
            />

            {this.renderEmailSubmit()}
        </div>);
    }

    renderPassword = () => {
        return (<div className="detail-block">
            <h5>Change your password</h5>

            <FormInputField title="Current Password"
                name="password"
                inputType="password"
                value={this.state.formData.password}
                onInputChange={this.onInputChange}
                smallText={this.state.passwordMessage}
                smallTextStyle={this.state.passwordMessageStyle}
            />

            <FormInputField title="New Password"
                name="newPassword"
                inputType="password"
                onInputChange={this.onInputChange}
                value={this.state.formData.newPassword}
                smallText={this.state.minLengthMessage + this.state.requiredCharactersMessage}
            />

            <FormInputField title="Confirm New Password"
                name="confirmPassword"
                inputType="password"
                value={this.state.formData.confirmPassword}
                onInputChange={this.onInputChange}
                smallText={this.state.doesNotMatchMessage}
            />
            {this.renderPasswordSubmit()}
        </div>);
    }

    breadCrumbList = [{ link: '/Account/Dashboard', text: 'Account' }, { link: '/Account/AccountSettings', text: 'Account Settings', active: true }];

    render() {
        return (
            <section>
                <Helmet>
                    <title>RockPorch - Account Settings</title>
                </Helmet>
                <AccountBanner breadCrumbList={this.breadCrumbList}>
                    <h1 className="mb-4">Account Settings</h1>
                    <p className="mb-0">Manage login settings</p>
                </AccountBanner>

                <div className="gray-bg full-height">
                    <div className="container">
                        <div className="row py-5 mt-0">
                            {
                                ["sm", "md"].includes(this.props.deviceSize) === false ? (
                                    <div className="col-md-5 col-lg-3 side-nav">
                                        <AccountSidebar loggedInUser={this.props.loggedInUser} setLoggedInUser={this.props.setLoggedInUser} />
                                    </div>
                                ) : <span></span>
                            }


                            <div className="col-md-12 col-lg-9">
                                <div className="user-detail-form">
                                    <Form>
                                        {this.renderEmail()}
                                        {this.renderPassword()}

                                        <div className="detail-block">
                                            <h5>Delete your account</h5>
                                            <button type="submit" className="btn close-btn mt-4" onClick={async (e) => {

                                                await postRequest("/api/Account/DeleteUser");
                                                this.props.history.push('/authentication/logout')
                                            }}>Close account</button>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );

    }

};

export default AccountSettings;
