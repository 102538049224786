import React from "react"



const FollowSuggestions = (props) => {
    return (
            <div className="follow-list px-3 py-3">
                <h6 className="mb-3">Who to follow</h6>
                <ul className="mb-0">
                    {props.children}
                </ul>
            </div>
    )
}

export default FollowSuggestions;