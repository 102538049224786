import React from "react";
import { Link } from "react-router-dom";

import PenIcon from "components/icons/PenIcon";
import { Form, FormGroup, Label, Input, CustomInput } from "reactstrap";

import AccountBanner from "components/AccountBanner";
import AmazonCommissionUpload from "views/Admin/AmazonCommissionUpload"
import ActionButton from "components/ActionButton";
import FormInputField from "components/FormInputField";
import Modal from "components/Modal";
import toast, { Toaster } from 'react-hot-toast';

import { getRequest, postRequest } from "sharedUtils/httpUtils";


class AffiliateNetwork extends React.Component {

    state = {
        affiliateProgramManager: {},
        nameChange: "",
        apiUrlChange: "",
        settlementPeriodChange: 0,
        isSaving: false,
        affiliateTracking: [],
        modalType: 1,
        show: false,
        createEditTracking: { hostname: "", trackingUrl: "" },
        isSubmittingTracking: false,
    }

    componentDidMount = async () => {
        let scrollOptions = {
            left: 0,
            top: 0,
            behavior: 'auto'
        }
        window.scrollTo(scrollOptions);

        let affiliateProgramManager = await this.fetchAffiliateProgramManager(this.props.match.params.id);

        let affiliateTracking = [];
        if (affiliateProgramManager.name.toLowerCase() === "cjaffiliate") {
            affiliateTracking = await this.fetchTracking(this.props.match.params.id);
        }

        this.setState({
            affiliateProgramManager,
            nameChange: affiliateProgramManager?.name,
            apiUrlChange: affiliateProgramManager?.apiUrl,
            settlementPeriodChange: affiliateProgramManager?.settlementPeriod,
            affiliateTracking
        });
    }

    breadCrumbList = [
        { link: '/Admin', text: 'Admin' },
        { link: '/Admin/AffiliateNetworks', text: 'Affiliate Networks' },
        { link: `/Admin/AffiliateNetworks/${this.props.match.params.id}`, text: this.state.affiliateProgramManager.name }
    ]

    fetchTracking = async (apmId) => {
        let url = "api/AffiliateProgramManager/GetMerchantAffiliateTracking?apmId=" + apmId;
        let tracking = await getRequest(url);
        return tracking;
    }

    fetchAffiliateProgramManager = async (id) => {
        let url = `api/AffiliateProgramManager/GetAffiliateProgramManager?id=${id}`;
        let affiliateProgramManager = await getRequest(url);
        return affiliateProgramManager;
    }

    showModal = (e) => {
        this.setState({ show: !this.state.show });
    };

    handleTrackingSubmit = async () => {
        this.setState({ isSubmittingTracking: true });
        let url = `api/AffiliateProgramManager/UpdateMerchantAffiliateTracking`;
        let requestBody = { hostname: this.state.createEditTracking.hostname.trim(), trackingUrl: this.state.createEditTracking.trackingUrl.trim(), affiliateProgramManagerId: this.state.affiliateProgramManager.id }

        let result = await postRequest(url, requestBody)
        if (!(result)) {
            this.postTrackingError();
            this.setState({ isSubmittingTracking: false });
            return;
        }
        let affiliateTracking = [];
        if (this.state.affiliateProgramManager.name.toLowerCase() === "cjaffiliate") {
            affiliateTracking = await this.fetchTracking(this.props.match.params.id);
        }

        this.setState({ isSubmittingTracking: false, affiliateTracking, show: false });
        this.resetTrackingState();
    }

    hideModal = () => {
        this.setState({ show: false });
    }

    getModalTitle = () => {
        switch (this.state.modalType) {
            case 1:
                return "Create New Merchant Tracking";
            default:
                return "Edit Merchant Tracking";
        }

    }

    openTableRowModal = (hostname, trackingUrl) => {
        const createEditTracking = { ...this.state.createEditTracking }
        createEditTracking.hostname = hostname;
        createEditTracking.trackingUrl = trackingUrl;
        this.setState({ createEditTracking, modalType: 2, show: true });
    }

    displayRows = () => {
        return this.state.affiliateTracking?.map((item, i) => {
            return (
                <tr key={i}>
                    <td>
                        {item?.hostname}
                    </td>

                    <td className="text-left">
                        {item?.trackingUrl}
                    </td>

                    <td>
                        <div >
                            <PenIcon style={{ cursor: "pointer" }} onClick={() => this.openTableRowModal(item.hostname, item.trackingUrl)} />
                        </div>
                    </td>
                </tr>
            )
        })
    }


    displayHeaders = () => {
        let headers = ["Merchant Hostname", "Redirect Url"];
        return headers.map((header, i) => {
            if (i === 2 || i === 3) {
                return (
                    <th key={i} style={{ width: "110px" }}>
                        <strong>{header}</strong>
                    </th>
                )
            }
            return (
                <th key={i}>
                    <strong>{header}</strong>
                </th>
            )

        })
    }

    resetState = () => {
        this.setState({
            nameChange: this.state.affiliateProgramManager.name,
            apiUrlChange: this.state.affiliateProgramManager.apiUrl,
            settlementPeriodChange: this.state.affiliateProgramManager.settlementPeriod
        })
    }

    resetTrackingState = () => {
        this.setState({
            createEditTracking: { hostname: "", trackingUrl: "" }, modalType: 1
        })
    }

    newMerchantTracking = () => {
        this.setState({ modalType: 1 });
        this.showModal();
    }

    saveChanges = async () => {
        this.setState({ isSaving: true });
        let url = `api/AffiliateProgramManager/UpdateAffiliateProgramManager`;
        let requestBody = { ...this.state.affiliateProgramManager, name: this.state.nameChange, apiUrl: this.state.apiUrlChange, settlementPeriod: this.state.settlementPeriodChange }

        var updated = await postRequest(url, requestBody)


        this.setState({ isSaving: false });
    }

    onInputChange = (e) => {
        let formKey = e.target.name;
        let newData = e.target.value;

        let createEditTracking = { ...this.state.createEditTracking };
        createEditTracking[formKey] = newData;

        this.setState({ createEditTracking });
    }; 

    postTrackingError = () => {
        toast.error(
            <div className="p-2">
                <h4 className="text-center">Error submitting tracking info. Check Hostname and try again.</h4>
            </div>,
            {
                duration: 3000,
                position: 'top-center',
                icon: null,
            }
        );
    }

    render() {
        return (
            <>
                <Modal isOpen={this.state.show} modalHeaderClass={"current-deals-modal-header"}
                    style={
                        {
                            overlay: {
                                position: 'fixed',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                backgroundColor: 'lightgray',
                                zIndex: 9998,
                            },
                            content: {
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                background: 'lightgray',
                                overflow: 'auto',
                                WebkitOverflowScrolling: 'touch',
                                borderRadius: '4px',
                                outline: 'none',
                                padding: '50px',
                                minWidth: '300px',
                                height: '350px',
                                zIndex: 1060,
                            }
                        }
                    }
                    onRequestClose={() => { this.setState({ show: false }); this.resetTrackingState(true); }}
                    toggleModal={() => { this.setState({ show: false }); this.resetTrackingState(true); }}
                >

                    <section className="gray-bg" style={{ marginBottom: "-20px", marginTop: "-5px" }}>
                        <div className="container pt-0 pb-5" >
                            <div className="d-flex flex-column pt-4 mb-2">
                                <h4>{this.getModalTitle()}</h4>
                            </div>
                            <FormGroup className="form-group" >
                                <Label className="control-label">Merchant Hostname:</Label>
                                <Input
                                    className="form-control"
                                    name="hostname"
                                    type="text"
                                    disabled={this.state.modalType > 1}
                                    value={this.state.createEditTracking.hostname}
                                    placeholder="Enter Merchant hostname here..."
                                    onChange={this.onInputChange}
                                />
                            </FormGroup>
                            <FormGroup className="form-group" >
                                <Label className="control-label">Tracking URL:</Label>
                                <Input
                                    className="form-control"
                                    name="trackingUrl"
                                    type="text"
                                    value={this.state.createEditTracking.trackingUrl}
                                    placeholder="Enter Tracking URL here..."
                                    onChange={this.onInputChange}
                                />
                            </FormGroup>
                            <div className="d-flex">
                                <ActionButton className="btn btn-primary mt-3"
                                    onClick={this.handleTrackingSubmit}
                                    isLoading={this.state.isSubmittingTracking}
                                    isDisabled={this.state.createEditTracking.hostname.trim().length === 0 || this.state.createEditTracking.trackingUrl.trim().length === 0}
                                >
                                    Submit
                                </ActionButton>

                            </div>
                        </div>

                    </section>
                    </Modal>
                    <Toaster />

                <section>
                    <AccountBanner breadCrumbList={this.breadCrumbList}>
                        <h1 className="mb-4">{this.state.affiliateProgramManager.name}</h1>
                        <p className="mb-0">Edit Affiliate Network details</p>
                    </AccountBanner>
                </section>
                <section className="gray-bg full-height">
                    <div className="container py-5">
                        <div className="col-12">
                            <Form onAbort={(e) => e.preventDefault()} onSubmit={(e) => e.preventDefault()} >

                                <FormInputField
                                    value={this.state.nameChange}
                                    title="Name"
                                    onInputChange={(e) => this.setState({ nameChange:e.target.value })}
                                />

                                <FormInputField
                                    value={this.state.apiUrlChange}
                                    title="Url"
                                    onInputChange={e => this.setState({ apiUrlChange:e.target.value })}
                                />

                                <FormInputField
                                    value={this.state.settlementPeriodChange === 0 ? '0' : this.state.settlementPeriodChange}
                                    title="Settlement Period (in days)"
                                    onInputChange={e => this.setState({ settlementPeriodChange: Number(e.target.value) })}
                                    inputStyle={{ width: "185px" }}
                                    inputType="number"
                                />

                              
                                <button className="btn btn-danger mr-1" onClick={this.resetState}>
                                    Cancel
                                </button>

                                <ActionButton className="btn btn-primary"
                                    isLoading={this.state.isSaving}
                                    onClick={this.saveChanges}
                                >
                                    Submit
                                </ActionButton>

                            
                            </Form>
                            {
                                this.state.affiliateTracking.length > 0 ?
                                    <>
                                        <h6 className="mt-5 mb-3" style={{ fontSize: "14px", fontWeight: "bold" }}>Merchants With Tracking URLs</h6>

                                        <div className="table-responsive product-table mb-5">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        {this.displayHeaders()}
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {this.displayRows()}
                                                </tbody>
                                            </table>


                                        </div>
                                        <ActionButton className="btn btn-outline-brand"
                                            onClick={this.newMerchantTracking}
                                        >
                                            Add Merchant
                                        </ActionButton>
                                    </>
                                    :
                                    <></>
                            }

                            {
                                this.state.affiliateProgramManager?.name?.toLowerCase() === "amazon" ?
                                    <AmazonCommissionUpload affiliateProgramManager={this.state.affiliateProgramManager} />
                                    :
                                    <></>
                            }
                        </div>
                    </div>
                </section>
            </>
            
            )
    }
}

export default AffiliateNetwork;