import React from "react";
import AccountBanner from "components/AccountBanner";
import { getRequest } from "sharedUtils/httpUtils";
import Feed from "components/Feed/Feed";
import FeedFilterBar from "components/FeedFilterBar";
import { Helmet } from 'react-helmet';
import { getQueryStringParams } from "sharedUtils/navUtils"

class Drafts extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isGrid: false,
            selectedCase: 1,
            posts: [],
            lockers: [],
            finishedFetchingDrafts: false,
        }
    }

    async componentDidMount() {
        let scrollOptions = {
            left: 0,
            top: 0,
            behavior: 'auto'
        }
        window.scrollTo(scrollOptions);

        const params = getQueryStringParams(window.location.search);
        const tab = Math.floor(params.tab);

        if (!isNaN(tab) && Number.isInteger(tab) && tab >= 1 && tab <= 2) {
            this.setState({ selectedCase: Math.floor(tab) });
        }

        let postResults = await this.fetchDrafts(0, 24);

        this.setState({ posts: postResults });
    }

    displayToggleButtons = () => {
        let options = [
            {
                name: "Posts",
                case: 1,
                action: () => this.setState({ selectedCase: 1 })
            },
            {
                name: "Lockers",
                case: 2,
                action: () => this.setState({ selectedCase: 2 })
            }
        ];

        return options.map((toggle, i) => {
            return (
                <button key={i}
                    className={`btn ${this.state.selectedCase === toggle.case ? "btn-primary btn-lg" : ""}`}
                    onClick={toggle.action}
                >
                    {toggle.name}
                </button>
            )
        })
    }

    fetchDrafts = async (skip, take) => {
        let posts = await getRequest(`/api/Post/GetDraftPosts?skip=${skip}&take=${take}`)

        if (!(posts) || posts.length < take) {
            this.setState({ finishedFetchingDrafts: true });
        }

        if (posts === undefined || posts === null) {
            return [];
        } else {
            return posts;
        }
    }

    fetchMoreDrafts = async () => {
        let moreDrafts = await this.fetchDrafts(this.state.posts.length, 24);
        
        if (!(moreDrafts) || moreDrafts.length < 24) {
            this.setState({ finishedFetchingDrafts: true });
        }
        else {
            let updatedPosts = [...this.state.posts, ...moreDrafts];
            // De-duplicate as sometimes on mobile the Draft > Feed > InfiniteScroll component will run
            // the fetchMore() function before the original fetchDrafts has completed resulting in duplicates
            var uniquePosts = [];
            updatedPosts.forEach(post => {
                var uniquePost = uniquePosts.find(x => x.id === post.id);
                if (!uniquePost) {
                    uniquePosts.push(post);
                }
            });
            this.setState({ posts: uniquePosts });
        }
    }

    updateFeed = (posts) => {
        this.setState({posts:posts})
    }

    deletePost = (shareCode) => {
        let locationToDelete = this.state.posts.findIndex((post) => post.shareCode === shareCode);
        let updatedPosts = [...this.state.posts];
        updatedPosts.splice(locationToDelete, 1);
        this.setState({ posts: updatedPosts });
    }

    render() {
        let breadCrumbList = [{ link: '/CreatorDashboard', text: 'Creator Dashboard' }, { link: '/Account/Drafts', text: 'Drafts', active: true }];
        let { loggedInUser } = this.props;
        let { posts, isGrid, selectedCase } = this.state;

        return (

            <>
                <Helmet>
                    <title>RockPorch - Drafts</title>
                </Helmet>
                <section>
                    <AccountBanner breadCrumbList={breadCrumbList} creatorCentric={true} deviceSize={this.props.deviceSize}>
                        <h1 className="mb-4">Drafts</h1>
                        <p className="mb-0">View and edit content in process</p>
                    </AccountBanner>
                </section>

                <section className="gray-bg full-height">


                    <FeedFilterBar toggleAction={() => this.setState({ isGrid: !this.state.isGrid })}
                        isGrid={this.state.isGrid}
                        innerClassName="row py-2 mt-0"
                    >
                        {this.displayToggleButtons()}
                    </FeedFilterBar>



                    <div className="container explore-feed-wrapper px-0">
                        <Feed
                            posts={selectedCase === 1 ? posts.filter(post => post.type !== 2) : selectedCase === 2 ? posts.filter(post => post.type === 2) : posts.filter(post => post.type === 2)}
                            isGrid={isGrid}
                            loggedInUser={loggedInUser}
                            finishedFetching={this.state.finishedFetchingDrafts}
                            fetchMore={this.fetchMoreDrafts}
                            deletePost={this.deletePost}
                            updateParent={this.updateFeed}
                        />
                    </div>

                </section>


            </>

        )
    }
}


export default Drafts;