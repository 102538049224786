import React from "react";
import AuthorizeRoute from "components/api-authorization/AuthorizeRoute";
import { Redirect } from "react-router-dom"
import authService from './AuthorizeService'



class AdminRoute extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ready:false,
        }
    }

    async componentDidMount() {
        this._subscription = authService.subscribe(() => this.authenticationChanged());
        await this.populateAuthenticationState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    async authenticationChanged() {
        this.setState({ ready: false, authenticated: false });
        await this.populateAuthenticationState();
    }

    async populateAuthenticationState() {
        const authenticated = await authService.isAuthenticated();
        this.setState({ ready: true, authenticated });
    }

    render() {
        const { loggedInUser, component, componentProps, ...rest } = this.props;
        const { ready } = this.state;

        if (!ready) {
            return <div></div>
        }
        else {
            if (loggedInUser?.isAdmin == true) {
                return <AuthorizeRoute {...rest} component={component} componentProps={componentProps} />
            }
            else {
                return <Redirect to = "/NotFound" />
            }
        }
      

    }
}

export default AdminRoute