// queryString window.location.search
export function showBrandNav(queryString) {
    const params = new Proxy(new URLSearchParams(queryString), {
        get: (searchParams, prop) => {
            const paramName = Array.from(searchParams.keys()).find(
                key => key.toLowerCase() === prop.toLowerCase()
            );
            return paramName ? searchParams.get(paramName) : undefined;
        }
    });

    const showBrandNav = params.showbrandnav;    

    return !!(showBrandNav) && typeof showBrandNav === "string" && showBrandNav.toLowerCase() === "true";
}   

export function getQueryStringParams(queryString) {
    const params = new Proxy(new URLSearchParams(queryString), {
        get: (searchParams, prop) => {
            const paramName = Array.from(searchParams.keys()).find(
                key => key.toLowerCase() === prop.toLowerCase()
            );
            return paramName ? searchParams.get(paramName) : undefined;
        }
    });

    return params;
}   