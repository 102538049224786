import React, { Component } from "react";
import TextField from '@material-ui/core/TextField';
import _, { cloneWith } from "lodash";
import { asDate, convertToLocaleDateTimeString, convertToNumericMonthAndDayAndYear, convertToNumericMonthAndDay, convertToAbbreviatedMonthAndDay } from "sharedUtils/timeUtils";
import LoadSpinner from "./LoadSpinner";
const { DateTime } = require("luxon");

class TransactionsChart extends Component {

    constructor(props) {
        super(props);

        this.state = {
            currentBalance: null,
            previousBalance: null,
            width: 0,
        };

    }

    componentDidMount = () => {
        this.props.getTransactions(this.props.startDate, this.props.endDate);
    };

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.startDate !== this.props.startDate || prevProps.endDate !== this.props.endDate) {
            if (this.props.startDate <= this.props.endDate) {
                this.setState({ dateError: false });
                this.props.getTransactions();
            } else {
                this.setState({ dateError: true });
            }
        }
        if (prevProps.display != this.props.display && this.props.display) {
            this.props.getTransactions();
        }
        if (this.props.transactions !== prevProps.transactions) {
            let total = this.props.previousBalance;
            this.props.transactions.forEach(item => total += item.amount);
            this.setState({ currentBalance: total, previousBalance: this.props.previousBalance });
        }

        if (this.props.transactions !== prevProps.transactions && this.props.transactions.length == 0) {
            this.setState({ currentBalance: 0, previousBalance: 0 });
            return;
        };

        if (prevProps.userChanged != null && prevProps.userChanged != this.props.userChanged && this.props.userChanged)
            if (prevProps.loggedInUser !== this.props.loggedInUser) {
                this.props.getTransactions();
            }
        if (prevProps.aggregateChanged != null && prevProps.aggregateChanged !== this.props.aggregateChanged && this.props.display) {
            this.props.getTransactions();
        }
    };

    displayTableHeader = () => {
        let headers = this.props.aggregateChanged != null && this.props.aggregateChanged ? ["Date", "Description", "Credit", "Debit", "Balance", "Username"] : ["Date", "Description", "Credit", "Debit", "Balance"];
        let infoIcon = ["","","","",""];

        return headers.map((header, i) => {
            if (i === 0) {
                return (
                    <td key={i}
                        style={{ borderLeft: 0, minWidth: "0", paddingLeft: "4px", paddingRight: "4px", overflow: "hidden" }}
                    >
                        <strong>{header}</strong>
                    </td>
                 );
            };

            if (i === headers.length - 1) {
                return (
                    <td key={i}
                        style={{ borderRight: 0, minWidth: "0", paddingLeft: "4px", paddingRight: "4px", textAlign: "left" }}
                    >
                        <strong>{header}</strong>
                    </td>
                );
            };

            return (
                <td key={i}
                    style={{ minWidth: "0", paddingLeft: "4px", paddingRight: "4px" }}
                >
                    <strong>{header}</strong>                    
                </td>
            )
        })
    }

    displayTableRows = () => {
        const previousBalance = this.props.previousBalance;
        let runningBalance = previousBalance;

        if (!this.props.transactions || this.props.transactions.length === 0) {
            return (
                <tr key={0}>
                    <td colSpan="7" style={{ borderLeft:0, borderRight:0, textAlign: "center" }}>No data for this time frame</td>
                </tr>
            );
        }

        if (this.props.aggregateChanged != null && this.props.aggregateChanged) {
            return this.props.transactions.map((data, i) => {
                runningBalance += data.amount;

                return (
                    <tr key={i}>
                        <td style={{ paddingLeft: "4px", paddingRight: "4px", borderLeft: "none" }}>
                            <span>{convertToNumericMonthAndDayAndYear(new Date(data.transactionDate), false)}</span>
                        </td>
                        <td style={{ paddingLeft: "4px", paddingRight: "4px", overflow: "hidden" }}>
                            <span>{data.description}</span>
                        </td>
                        <td style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                            <span>{data.amount >= 0 ? `$${data.amount.toFixed(2)}` : ""}</span>
                        </td>
                        <td style={{ paddingLeft: "4px", paddingRight: "4px", textAlign: "left" }}>
                            <span>{data.amount < 0 ? `($${Math.abs(data.amount).toFixed(2)})` : ""}</span>
                        </td>
                        <td style={{ paddingLeft: "4px", paddingRight: "4px", textAlign: "left", borderRight: "none" }}>
                            <span>{runningBalance >= 0 ? `$${runningBalance.toFixed(2)}` : `($${Math.abs((runningBalance).toFixed(2))})`}</span>
                        </td>
                        <td style={{ paddingLeft: "4px", paddingRight: "4px", textAlign: "left", borderRight: "none" }}>
                            <span>{data.username}</span>
                        </td>
                    </tr>);
            });
        }


        return this.props.transactions.map((data, i) => {
            runningBalance += data.amount;

            return (
                <tr key={i}>
                    <td style={{ paddingLeft: "4px", paddingRight: "4px", borderLeft: "none" }}>
                        <span>{convertToNumericMonthAndDayAndYear(new Date(data.transactionDate), false)}</span>
                    </td>
                    <td style={{ paddingLeft: "4px", paddingRight: "4px", overflow: "hidden" }}>
                        <span>{data.description}</span>
                    </td>
                    <td style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                        <span>{data.amount >= 0 ? `$${data.amount.toFixed(2)}` : ""}</span>
                    </td>
                    <td style={{ paddingLeft: "4px", paddingRight: "4px", textAlign: "left" }}>
                        <span>{data.amount < 0 ? `($${Math.abs(data.amount).toFixed(2)})` : ""}</span>
                    </td>
                    <td style={{ paddingLeft: "4px", paddingRight: "4px", textAlign: "left", borderRight: "none" }}>
                        <span>{runningBalance >= 0 ? `$${runningBalance.toFixed(2)}` : `($${Math.abs((runningBalance).toFixed(2))})`}</span>
                    </td>
                </tr>);
        });
    }
                    
    render() {
        return (
            <div className="container pt-0 pb-5 px-0 px-md-3" style={this.props.display ? {} : { display: 'none' }}>

                {
                    this.props.transactionsLoading ?
                        <LoadSpinner noPaddingTop={true} /> :
                        <div className="row mx-0 my-0">
                            <div className="table-responsive product-table">
                                <table className="table mt-0 border-0" style={{ tableLayout: "fixed", marginTop: 0, borderTop: "none" }}>
                                    {this.props.aggregateChanged != null && this.props.aggregateChanged ?
                                        <colgroup>
                                            <col span="1" style={{ width: "10%" }} />
                                            <col span="1" style={{ width: "16%" }} />
                                            <col span="1" style={{ width: "16%" }} />
                                            <col span="1" style={{ width: "16%" }} />
                                            <col span="1" style={{ width: "22%" }} />
                                            <col span="1" style={{ width: "20%" }} />
                                        </colgroup>
                                        : <colgroup>
                                            <col span="1" style={{ width: "14%" }} />
                                            <col span="1" style={{ width: "20%" }} />
                                            <col span="1" style={{ width: "22%" }} />
                                            <col span="1" style={{ width: "22%" }} />
                                            <col span="1" style={{ width: "22%" }} />
                                        </colgroup>
                                    }

                                    <thead style={{ borderTop: "none" }}>
                                        {this.props.aggregateChanged != null && this.props.aggregateChanged ?
                                            <tr>
                                                <th style={{ minWidth: "0", paddingLeft: "4px", paddingRight: "4px", paddingBottom: 0, borderLeft: "none", borderRight: 0, borderTop: "none", borderBottom: "none" }}>
                                                    <h5><strong>Previous Balance:</strong></h5>
                                                </th>
                                                <th style={{ border: "none" }}></th>
                                                <th style={{ border: "none" }}></th>
                                                <th style={{ border: "none" }}></th>
                                                <th style={{ wordBreak: "break-word", borderTop: "none", borderRight: "none", borderBottom: "none", textAlign: "left", paddingBottom: 0 }}>
                                                    <p><strong>
                                                        {
                                                            this.state.previousBalance == null ||
                                                                this.state.previousBalance >= 0 ? `$${(Math.abs(this.state.previousBalance).toFixed(2))}` : `($${Math.abs(this.state.previousBalance).toFixed(2)})`
                                                        }
                                                    </strong></p>
                                                </th>
                                            </tr>
                                            : <tr>
                                                <th style={{ minWidth: "0", paddingLeft: "4px", paddingRight: "4px", paddingBottom: 0, borderLeft: "none", borderRight: 0, borderTop: "none", borderBottom: "none" }}>
                                                    <h5><strong>Previous Balance:</strong></h5>
                                                </th>
                                                <th style={{ border: "none" }}></th>
                                                <th style={{ border: "none" }}></th>
                                                <th style={{ border: "none" }}></th>
                                                <th style={{ wordBreak: "break-word", borderTop: "none", borderRight: "none", borderBottom: "none", textAlign: "left", paddingBottom: 0 }}>
                                                    <p><strong>
                                                        {
                                                            this.state.previousBalance == null ||
                                                                this.state.previousBalance >= 0 ? `$${(Math.abs(this.state.previousBalance).toFixed(2))}` : `($${Math.abs(this.state.previousBalance).toFixed(2)})`
                                                        }
                                                    </strong></p>
                                                </th>
                                            </tr>
                                        }
                                    </thead>

                                    <tbody>
                                        <tr>
                                            {this.displayTableHeader()}
                                        </tr>
                                        {this.displayTableRows()}
                                        <tr>
                                            <td style={{ minWidth: "0", paddingBottom: 0, paddingLeft: "4px", paddingRight: "4px", borderLeft: "none", borderRight: "none", borderTop: "none" }}>
                                                <h5> <strong>Current Balance:</strong></h5>
                                            </td>
                                            <td style={{ borderTop: "none", borderLeft: "none", borderRight: "none" }} ></td>
                                            <td style={{ borderTop: "none", borderLeft: "none", borderRight: "none" }} ></td>
                                            <td style={{ borderTop: "none", borderLeft: "none", borderRight: "none" }} ></td>
                                            <td style={{ wordBreak: "break-word", borderRight: "none", borderTop: "none", textAlign: "left", paddingBottom: 0 }}>
                                                <p><strong>
                                                    {
                                                        this.state.currentBalance == null ||
                                                            this.state.currentBalance >= 0 ? `$${(Math.abs(this.state.currentBalance).toFixed(2))}` : `($${Math.abs(this.state.currentBalance).toFixed(2)})`
                                                    }
                                                </strong></p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                }
            </div>
        );
    }
}

export default TransactionsChart;