import React, { Component } from "react";
import AccountBanner from "../../components/AccountBanner";
import FeedFilterBar from "components/FeedFilterBar";
import { getRequest } from "sharedUtils/httpUtils";
import Feed from "components/Feed/Feed";
import ProductFeed from "components/Feed/ProductFeed";
import NoResults from "components/NoResults";
import LoadSpinner from "components/LoadSpinner";
import GridIconAlternate from "components/icons/GridIconAlternate";
import DoubleBarIcon from "components/icons/DoubleBarIcon";
import { Helmet } from 'react-helmet';

export default class Saves extends Component {

    constructor(props) {
        super(props);
        this.state = {
            lockers: [],
            products: [],
            posts: [],
            isGrid: true,
            selectedCase: 2,
            isLoading: true,
            finishedFetchingPosts: false,
            finishedFetchingLockers: false,
            finishedFetchingProducts: false
        }
    }

    async componentDidMount() {
        let scrollOptions = {
            left: 0,
            top: 0,
            behavior: 'auto'
        }
        window.scrollTo(scrollOptions);
        //let data = await this.fetchSavedPosts();
        let products = await this.fetchSavedProducts(0, 12);
        let finishedFetchingProducts = !(products) || products?.length < 12;
        let posts = await this.fetchSavedPosts([1, 3, 4], 0, 12);
        let lockers = await this.fetchSavedPosts([2], 0, 12);

        this.setState({
            products: products, finishedFetchingProducts, posts: posts, lockers: lockers, isLoading: false,
            selectedCase: products.length > 0 ? 2 : posts.length > 0 ? 3 : 1
        });
    }

    /**
     * 
     * @param {Array<number>} postTypes
     * @param {number} skip
     * @param {number} take
     * @returns {Array}
     */
    fetchSavedPosts = async (postTypes, skip, take) => {
        let url = `/api/post/getSavedPosts`;

        let params = new URLSearchParams();
        if (postTypes?.length > 0) {
            postTypes.forEach((postType) => params.append("postTypeIds", postType));
        }
        params.append("skip", skip);
        params.append("take", take);

        let fullUrl = params.toString().length > 0 ? `${url}?${params.toString()}` : url;
        let data = await getRequest(fullUrl);
        if (data === undefined || data === null) {
            return [];
        }
        else {
            return data;
        }

        //this.setState({ posts: posts,lockers:lockers });

    }

    fetchSavedProducts = async (skip, take) => {
        let url = `/Products/GetSavedProducts?skip=${skip}&take=${take}`;
        let data = await getRequest(url);
        if (data === undefined || data === null) {
            return [];
        }
        else {
            return data;
        }
        //this.setState({ products: data });
    }

    displayToggleButtons = () => {
        let options = [
            {
                name: "Products",
                case: 2,
                action: () => this.setState({ selectedCase: 2 })
            },
            {
                name: "Posts",
                case: 3,
                action: () => this.setState({ selectedCase: 3 })
            },
            {
                name: "Lockers",
                case: 1,
                action: () => this.setState({ selectedCase: 1 })
            }
        ]

        return options.map((toggle, i) => {
            return (
                <button key={i}
                    className={`btn ${this.state.selectedCase === toggle.case ? "btn-primary btn-lg" : ""}`}
                    onClick={toggle.action}
                >
                    {toggle.name}
                </button>
            )
        })
    }

    fetchMoreProducts = async () => {
        let skip = this.state.posts.length;

        let newProducts = await this.fetchSavedProducts(skip, 24);
        let finishedFetching = newProducts?.length < 24
        let updatedProducts = [...this.state.products, ...newProducts];

        this.setState({products: updatedProducts, finishedFetchingProducts: finishedFetching})
    }

    fetchMorePostsOrLockers = async () => {
        let { selectedCase } = this.state;
        let postTypes = (selectedCase === 3) ? [1, 3, 4] : (selectedCase === 1) ? [2] : [1, 2, 3];

        let postsToChange = (selectedCase === 3) ? this.state.posts : this.state.lockers;
        let skip = postsToChange.length;

        let newPosts = await this.fetchSavedPosts(postTypes, skip, 24);

        let updatedPosts = [...postsToChange, ...newPosts];
        let finishedFetching = newPosts?.length < 10
        if (selectedCase === 3) {

            this.setState({ posts: updatedPosts, finishedFetchingPosts: finishedFetching })
        }
        else if (selectedCase === 1) {
            this.setState({ lockers: updatedPosts, finishedFetchingLockers: finishedFetching })
        }

    }

    displayFeed = () => {
        let { selectedCase } = this.state;

        if (selectedCase === 2) {
            if (this.state.products?.length === 0) {
                return <NoResults />;
            }
            if (this.state.isGrid === true) {
                return (
                    <div className="product-grid">
                        <div className="row mx-0 mt-0">
                            <ProductFeed products={this.state.products} deviceSize={this.props.deviceSize} isGrid={this.state.isGrid} loggedInUser={this.props.loggedInUser} fetchMore={this.fetchMoreProducts} finishedFetching={this.state.finishedFetchingProducts} />
                        </div>
                    </div>
                )
            } else {
                return <ProductFeed products={this.state.products} deviceSize={this.props.deviceSize} isGrid={this.state.isGrid} loggedInUser={this.props.loggedInUser} fetchMore={this.fetchMoreProducts} finishedFetching={this.state.finishedFetchingProducts} />
            }

        } else if (selectedCase === 1 || selectedCase === 3) {
            let postsToShow = (selectedCase === 1) ? this.state.lockers : this.state.posts;
            if (postsToShow?.length === 0) {
                return (
                    <NoResults />
                )
            } else {
                let finishedFetching = (selectedCase === 1) ? this.state.finishedFetchingLockers : this.state.finishedFetchingPosts;
                return (
                    <Feed loggedInUser={this.props.loggedInUser}
                        posts={postsToShow}
                        showFollowSuggestions={false}
                        finishedFetching={finishedFetching}
                        fetchMore={this.fetchMorePostsOrLockers}
                        isGrid={this.state.isGrid}
                        updateParent={this.updatePosts}
                        showUserTile={true}
                    />

                )
            }

        }

    }

    updatePosts = (newPosts) => {
        let { selectedCase } = this.state;

        if (selectedCase === 1) {
            this.setState({ lockers: newPosts });
        }
        else {
            this.setState({ posts: newPosts });
        }
        
    }

    render() {
        let breadCrumbList = [{ link: '/Account/Dashboard', text: 'Account' }, { link: '/Account/Saves', text: 'Saves', active: true }];
        let { deviceSize } = this.props;
        let { isGrid } = this.state;
        let isSmOrMd = deviceSize == "sm" || deviceSize == "md";
        if (this.state.isLoading === true) {
            return (
                <>
                    < LoadSpinner />
                    <Helmet>
                        <title>RockPorch - Saves</title>
                    </Helmet>
                </>
            )
        }
        else {
            return (
                <>
                    <Helmet>
                        <title>RockPorch - Saves</title>
                    </Helmet>
                    <section>
                        <AccountBanner breadCrumbList={breadCrumbList}>
                            <h1 className="mb-4">My Saves</h1>
                            <p className="mb-0">View saved content here</p>
                        </AccountBanner>
                    </section>

                    <section className="gray-bg full-height">
                        <section className="save-list">
                            <div className="container">
                                <div className="row activity-list pb-0 pt-2 mt-0">
                                    <div className="col-10">
                                        <ul className="mb-2 d-flex flex-wrap justify-content-center justify-content-md-start col-12">
                                            {this.displayToggleButtons()}
                                        </ul>
                                    </div>
                                    <div className="col-2">
                                        <button className="btn btn-sm filter-toggle-btn" onClick={() => this.setState({ isGrid: !this.state.isGrid })}>
                                            {
                                                this.state.isGrid === true ? <DoubleBarIcon /> : <GridIconAlternate/>
                                            }
                                        </button>
                                        
                                    </div>
                                </div>
                            </div>
                        </section>

                        

                        <div className="container explore-feed-wrapper">
                            <div className="row py-0 grid mt-1">       
                                
                                <div className={`col-12 px-0`} style={{ margin: "auto" }}>
                                    {this.displayFeed()}
                                </div>
                               
                            </div>
                        </div>
                    </section>
                </>
            )
        }

    }
}
