import * as React from "react";
import AccountBanner from "../../components/AccountBanner";

import { Form, FormGroup, Label, Input, PopoverBody } from "reactstrap";
import { getRequest, postRequest } from "../../sharedUtils/httpUtils";

let readOnlyFields = ["profileUrl"];

let userFormData = {
    addTitle: "",
    addLinkUrl: "",
//    addImageUrl: "",
};

const Links = (props) => {

    const [formData, setFormData] = React.useState(userFormData);
    const [savingLink, setSavingLink] = React.useState(false);
    const [uploadingImage, setUploadingImage] = React.useState(false);
    const [links, setLinks] = React.useState([]);
    const [deleteIndex, setDeleteIndex] = React.useState(0);

    React.useEffect(async () => {
        const ownerUsername = props.match.params.userName;
        const viewerUserId = props.loggedInUser.id;

        const trackUrl = `api/Account/TrackUserLinkView?ownerUsername=${ownerUsername}&viewerUserId=${viewerUserId}`;
        await getRequest(trackUrl);

        await getLinks();
    }, []);

    //React.useEffect(() => {
    //    updateLinks();
    //}, [links]);

    const getLinks = async () => {
        const ownerUsername = props.match.params.userName;

        const linksUrl = `api/Account/GetUserLinksByUserId?username=${ownerUsername}`;
        const links = await getRequest(linksUrl);

        setLinks(links);
    //    mapInputFieldsPublic();
    }

    const updateLinks = async (links) => {
        if (links != null && links!= undefined && links.length > 0) {
            const url = `api/Account/UpdateUserLinks`;

            const ordinanceLinks = links.map((link, index) => ({
                userId: link.userId,
                id: link.id,
                linkUrl: link.linkUrl,
                imageUrl: link.imageUrl,
                title: link.title,
                ordinance: index,
                createdBy: link.createdBy,
                createdDate: link.createdDate,
                modifiedDate: link.modifiedDate,
                modifiedBy: link.modifiedBy
            }));
            const newLinks = await postRequest(url, ordinanceLinks);

        }
    }

    const trackUserLinkClick = async (index) => {
        const userLinkId = links[index].id;
        const userId = props.loggedInUser.id;

        const trackingUrl = `api/Account/TrackUserLinkClick?userLinkId=${userLinkId}&userId=${userId}`;
        await getRequest(trackingUrl);
    }

    const onInputChange = (e) => {
        let formKey = e.target.name;
        let newData = e.target.value;

        if (formKey === 'bio') {
            e.target.style.height = 'inherit';
            e.target.style.height = `${e.target.scrollHeight}px`;
        }

        setFormData({ ...formData, [formKey]: newData });

    };

    const onSubmit = async (event) => {
        event.preventDefault();
        setSavingLink(true);

        const userId = props.loggedInUser.id;
        const title = formData.addTitle || "";
        const linkUrl = formData.addLinkUrl || "";
        //const imageUrl = formData.addImageUrl || "";

        let createUserLinkUrl = `/api/Account/CreateUserLink?userId=${userId}&title=${title}&linkUrl=${linkUrl}`;
        const newLink = await getRequest(createUserLinkUrl);

        setFormData(userFormData);

        updateLinks([newLink, ...links])

        await setLinks([newLink, ...links]);

        setSavingLink(false);
    };

    const mapInputFields = (fields) => {
        return fields.map((field, i) => {
            if (readOnlyFields.includes(field.name) || uploadingImage) {
                return (
                    <FormGroup key={i} className="form-group">
                        <Label className="control-label">{field.title}</Label>
                        <Input
                            className="form-control"
                            name={field.name}
                            onChange={(e) => onInputChange(e)}
                            value={formData[field.name]}
                            readOnly="readonly"
                        />
                    </FormGroup>
                );
            } else {
                if (field.name === "bio") {
                    return (
                        <FormGroup key={i} className="form-group">
                            <Label className="control-label">{field.title}</Label>
                            <textarea
                                className="form-control"
                                name={field.name}
                                onChange={(e) => onInputChange(e)}
                                value={formData[field.name]}
                                style={{ overflow: "hidden" }}
                            />
                        </FormGroup>);
                }
                if (field.prepend) {
                    return (
                        <FormGroup key={i} className="form-group">
                            <Label className="control-label">{field.title}</Label>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">{field.prepend}</span>
                                </div>
                                <Input
                                    className="form-control"
                                    name={field.name}
                                    onChange={(e) => onInputChange(e)}
                                    value={formData[field.name]}
                                    maxLength={field.name === "displayName" ? 20 : undefined}
                                />
                            </div>
                        </FormGroup>
                    );
                }
                return (
                    <FormGroup key={i} className="form-group">
                        <Label className="control-label">{field.title}</Label>
                        <Input
                            className="form-control"
                            name={field.name}
                            onChange={(e) => onInputChange(e)}
                            value={formData[field.name]}
                            maxLength={field.name === "displayName" ? 20 : undefined}
                        />
                    </FormGroup>
                );
            }
        });
    }

    const mapInputFieldsSocial = () => {
        let fields = [
            { name: "addTitle", title: "Title", prepend: '' },
            { name: "addLinkUrl", title: "Link URL", prepend: 'https://' },
        //    { name: "addImageUrl", title: "Image URL", prepend: 'https://' },
        ];
        return mapInputFields(fields);
    };

    const editLink = async (e, index) => {
        e.preventDefault();
    }

    const deleteLink = async (e, index) => {
        e.preventDefault();
        setSavingLink(true);
        setDeleteIndex(index);
        const id = links[index].id;

        const linksUrl = `api/Account/DeleteUserLinkById?id=${id}`;
        await getRequest(linksUrl);

        const newLinks = links.filter(link => link.id !== id);

        setLinks(newLinks);
        setSavingLink(false);
    }

    const renderButtons = (index) => {
        if (savingLink) {
            return (
                <>
                    <button className="btn btn-secondary ml-2 mr-2 mb-2 p-1"
                        onClick={(e) => editLink(e, index)}
                    >Edit</button>
                    <button className="btn btn-primary ml-2 mb-2 p-1"
                        onClick={(e) => deleteLink(e, index)}
                    >
                        {savingLink && deleteIndex === index ?
                        <div className="spinner-border spinner-border-sm" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                        : ""}Delete</button>
                </>
            )
        } else {
            return (
                <>
                    <button className="btn btn-secondary ml-2 mr-2 mb-2 p-1"
                        onClick={(e) => editLink(e, index)}
                    >Edit</button>
                    <button className="btn btn-primary ml-2 mb-2 p-1"
                        onClick={(e) => deleteLink(e, index)}
                    >Delete</button>
                </>
            )
        }
    }

    const redirectToLink = (e, url, index) => {
        e.preventDefault();

        if (url.length > 9) {
            window.open(url, '_blank');
            trackUserLinkClick(index);
        }

    }

    const mapInputFieldsPublic = () => {
        if (links != null) {
            return links.map((link, index) => {
                let formattedLinkUrl = "";
                let formattedImageUrl = "";
                let linkUrlStyle = {};
                let imageUrlStyle = {};
                if (link.linkUrl?.length > 0) {
                    formattedLinkUrl = link.linkUrl.search("http://") >= 0 || link.linkUrl.search("https://") >= 0 ?
                        link.linkUrl : "https://" + link.linkUrl;
                    linkUrlStyle = { cursor: "pointer" }
                }
                if (link.imageUrl?.length > 0) {
                    formattedImageUrl = link.imageUrl.search("http://") >= 0 || link.imageUrl.search("https://") >= 0 ?
                        link.imageUrl : "https://" + link.imageUrl;
                    imageUrlStyle = { cursor: "pointer" };
                }
           
                //const linkUrl = <a href={formattedUrl}>{link.linkUrl}</a>;
                return (
                    <FormGroup key={link.id} className="form-group pb-2">
                        <Label className="control-label">{link.title}</Label>
                        {props.loggedInUser?.userName === props.match.params.userName ?
                            renderButtons(index)
                            :
                            <></>}
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text">{`Link URL`}</span>
                            </div>
                            <Input
                                className="form-control"
                                name={link.linkUrl}
                                value={link.linkUrl}
                                style={linkUrlStyle}
                                onClick={(e) => redirectToLink(e, formattedLinkUrl, index)}
                            />
                        </div>
                        {/*<div className="input-group">*/}
                        {/*    <div className="input-group-prepend">*/}
                        {/*        <span className="input-group-text">{`Image URL`}</span>*/}
                        {/*    </div>*/}
                        {/*    <Input*/}
                        {/*        className="form-control"*/}
                        {/*        name={link.imageUrl}*/}
                        {/*        value={link.imageUrl}*/}
                        {/*        style={imageUrlStyle}*/}
                        {/*        onClick={(e) => redirectToLink(e, formattedImageUrl, index)}*/}
                        {/*    />*/}
                        {/*</div>*/}
                    </FormGroup>
                );
            })
        }
    };

    const renderSubmit = () => {
        let submitDisabled = true;

        for (let key in formData) {
            if (formData[key] !== null && formData[key] !== "") {
                submitDisabled = false;
                break;
            }
        }

        if (submitDisabled) {
            return (
                <button type="submit" className="btn btn-secondary mt-3" id="submitProfileChanges" disabled onClick={(e) => { onSubmit(e); }}>
                    {savingLink ?
                        <div className="spinner-border spinner-border-sm" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                        : ""} Submit
                </button>
            );
        }

        return (
            <button type="submit" id="submitProfileChanges" className="btn btn-secondary mt-3" onClick={(e) => { onSubmit(e); }}>
                {savingLink ?
                    <div className="spinner-border spinner-border-sm" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                    : ""} Submit
            </button>
        );
    }

    const breadCrumbList = [{ link: '/Account/Dashboard', text: 'Account' }, { link: '/Account/Links', text: 'Links', active: true }];
    return (
        <section>
            <AccountBanner breadCrumbList={breadCrumbList}>
                <h1 className="mb-4">Links</h1>
                <p className="mb-0">Here's a subtitle for the Links page</p>
            </AccountBanner>

            <div className="gray-bg full-height">
                <div className="container">
                    <div className="row py-5 mt-0">
                        <div className="col-md-12">
                            <div className="user-detail-form">
                                <Form>
                                    <div className="detail-block">
                                        <h5>Links</h5>

                                        {mapInputFieldsPublic()}

                                        {props.loggedInUser?.userName === props.match.params.userName ?
                                            <h5 className="pt-4">Add Link</h5>
                                            :
                                            <></>}
                                        {props.loggedInUser?.userName === props.match.params.userName ?
                                             mapInputFieldsSocial() 
                                            :
                                            <></>}
                                        {props.loggedInUser?.userName === props.match.params.userName ?
                                             renderSubmit() 
                                            :
                                            <></>}
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Links;
