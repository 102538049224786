import React, { useState } from "react";
import ImageWrapper from "components/ImageWrapper";
import PriceTagIcon from "components/icons/PriceTagIcon";
import toast from 'react-hot-toast';
import { currencyFormat } from "sharedUtils/productUtils";

/**
 * 
 * @param {{product:object,edit:Function, toggleProductModal:Function, toggleShareModal:Function}} props
 */
const CommissionDetailItemRowMobile = (props) => {
    let [isLoadingShareCode, setIsLoadingShareCode] = useState(false);
    let { item } = props;
    let pstyle = { fontFamily: "Archivo", fontSize: "14px", width: "96%" }

    const fixedWidthStyle = { width: "80px", textAlign: "center", fontFamily: "Archivo", fontSize: "12px", position: "relative" }; // Fixed width style

    return (
        <div className={`card p-1 ${props.analytics ? "border-0 pt-2" : ""} ${item.clicks === "clicks" ? "" : "hover-border"}`}>
            {
                item.clicks === "clicks" ?
                    <div className="d-flex justify-content-between align-items-center px-1">
                        <p className="ml-1 mb-0" style={{ fontSize: "14px", cursor: "pointer" }} onClick={() => props.handleSortSelect({ value: 1, label: "Name" })}>
                            <strong>Product</strong>
                            <span className={`brand-nav-dropdown-toggle position-relative mr-2`} style={{ top: props.sortDescending ? "-4px" : "9px", left: props.sortDescending ? "" : "6px", }}>
                                <span className="brand-nav-chevron-iconset brand-nav-chevron-span" style={{ transform: props.sortDescending ? "" : "rotate(180deg)" }}>
                                    <svg aria-hidden="true" className="brand-nav-chevron-svg" fill="rgb(119,119,119)" version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 20 20">
                                        <title>Expand</title>
                                        <path className={`brand-nav-lower-menu-hover${props.selectOptions.currentValue.label === "Name" ? "" : "-transparent"}`} d="M5.293 9.707l6 6c0.391 0.391 1.024 0.391 1.414 0l6-6c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0l-5.293 5.293-5.293-5.293c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414z"></path>
                                    </svg>
                                </span>
                            </span>
                        </p>

                        <div style={{ flex: 1, marginLeft: "5px" }}>

                            <p className="ml-1 mb-0 one-line-ellipsis" style={{ ...pstyle, display: "none", top: "1px" }} >
                                filler
                            </p>

                            <p className="ml-1 mb-0" style={{ ...pstyle, display: "none" }} >
                                <strong>Price</strong>: <span>0</span>
                            </p>

                            <p className="ml-1 mb-0 one-line-ellipsis" style={{ ...pstyle, display: "none" }} >
                                <strong>Merchant</strong>: filler
                            </p>
                        </div>

                        <div style={{ ...fixedWidthStyle, textAlign: "revert", left: "51px", top: "6px", cursor: "pointer" }} onClick={() => props.handleSortSelect({ value: 4, label: "Sales" })}>
                            <p><strong>$</strong><span className={`brand-nav-dropdown-toggle position-relative mr-2`} style={{ top: props.sortDescending ? "-4px" : "9px", left: props.sortDescending ? "" : "6px", }}>
                                <span className="brand-nav-chevron-iconset brand-nav-chevron-span" style={{ transform: props.sortDescending ? "" : "rotate(180deg)" }}>
                                    <svg aria-hidden="true" className="brand-nav-chevron-svg" fill="rgb(119,119,119)" version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 20 20">
                                        <title>Expand</title>
                                        <path className={`brand-nav-lower-menu-hover${props.selectOptions.currentValue.label === "Sales" ? "" : "-transparent"}`} d="M5.293 9.707l6 6c0.391 0.391 1.024 0.391 1.414 0l6-6c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0l-5.293 5.293-5.293-5.293c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414z"></path>
                                    </svg>
                                </span>
                            </span>
                            </p>
                        </div>

                        <div style={{ ...fixedWidthStyle, textAlign: "revert", left: "25px", top: "5px", cursor: "pointer" }} onClick={() => props.handleSortSelect({ value: 5, label: "Estimated Commission" })}>
                            <p><PriceTagIcon /><span className={`brand-nav-dropdown-toggle position-relative mr-2`} style={{ top: props.sortDescending ? "-2px" : "11px", left: props.sortDescending ? "" : "6px", }}>
                                <span className="brand-nav-chevron-iconset brand-nav-chevron-span" style={{ transform: props.sortDescending ? "" : "rotate(180deg)" }}>
                                    <svg aria-hidden="true" className="brand-nav-chevron-svg" fill="rgb(119,119,119)" version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 20 20">
                                        <title>Expand</title>
                                        <path className={`brand-nav-lower-menu-hover${props.selectOptions.currentValue.label === "Estimated Commission" ? "" : "-transparent"}`} d="M5.293 9.707l6 6c0.391 0.391 1.024 0.391 1.414 0l6-6c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0l-5.293 5.293-5.293-5.293c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414z"></path>
                                    </svg>
                                </span>
                            </span>
                            </p>
                        </div>

                    </div>
                    :
                    <div className="d-flex justify-content-between align-items-center px-1" onClick={() => props.addUserMerchantProductIfNotAddedAlreadyAndGetLink(item.merchantProductId) } style={{ cursor: "pointer" }}>
                        <ImageWrapper
                            className="mr-1"
                            src={item.imageUrl}
                            style={{ width: props.deviceSize === "sm" ? "50px" : "80px", height: props.deviceSize === "sm" ? "50px" : "80px", objectFit: "contain" }}
                        />

                        <div style={{ flex: 1, marginLeft: "5px", minWidth: "150px" }}>

                            <p className="ml-1 mb-0 one-line-ellipsis" style={{ ...pstyle, display: "relative", top: "1px", wordBreak: "break-all" }}>
                                {item.name}
                            </p>

                            <p className="ml-1 mb-0" style={{ ...pstyle }} >
                                <strong>Merchant</strong>: <span>{item.merchant}</span>
                            </p>

                             <p className="ml-1 mb-0" style={{ ...pstyle }} >
                                <strong>Price</strong>: <span>${item.price.toFixed(2)}</span>
                            </p>

                        </div>


                        <div className={`d-flex mr-3 ${props.analytics ? "" : "flex-column"} brand-primary-color`} onClick={(e) => {}} style={{ ...fixedWidthStyle, width: "25px", top: "6px" }} >
                            <p style={{ fontSize: "14px" }}>{item.sales}</p>
                        </div>

                        <div className={`d-flex mr-3 ${props.analytics ? "" : "flex-column"}`} style={{ ...fixedWidthStyle, width: "59px", top: "6px" }} >
                            <p style={{ fontSize: "14px" }}>${item.estimatedCommission.toFixed(2)}</p>
                        </div>

                    </div>
            }
        </div>
    )
}

export default CommissionDetailItemRowMobile;
