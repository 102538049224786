import React, { useEffect } from "react";
import Modal from "components/Modal";
import BlogPage from "views/Blog/BlogPage";
import { connect } from "react-redux";
import { toggleBlogPageModal, toggleProductPageModal } from "stateManagement/reducers/modalReducer";


/**
 * 
 * @param {{post:object,loggedInUser:object,showModal:boolean, deviceSize:string, parentModal:{type:string,parentState:object}}} props
 */
const BlogPageModal = (props) => {
    let paramProps = {
        params: {
            post: props.post,
            loggedInUser: props.loggedInUser,
            setPost: props.setPost
        }
    }

    useEffect(() => {
        if (props.showModal) {
            document.querySelector("body").style.overflow = "hidden";
        } else {
            document.querySelector("body").style.overflow = "";
        }
    }, [props.showModal]);

    return (
        <Modal
            isOpen={props.showModal}
            hideOpenAsPage={true}
            toggleModal={() => {

                if (props.parentModal !== null) {
                    switch (props.parentModal?.type) {
                        case "product": {
                            props.toggleProductModal(props.parentModal.parentState, props.loggedInUser, false, null, null);
                            break;
                        }
                        default: {
                            props.toggleModal(props.post, props.loggedInUser, false, props.setPost);
                            break;
                        }
                    }
                }
                else {
                    props.toggleModal(props.post, props.loggedInUser, false, props.setPost)
                }

            }}
            showHeader={true}
            showThreeDotMenu={props.loggedInUser ? true : false}
            loggedInUser={props.loggedInUser}
            post={props.post}
            modalDialogCustomClass="modal-dialog-md"
            modalHeaderRedirect={`/Blog/BlogPage/${props.post?.shareCode}`}
            state={paramProps}
        >
            <BlogPage
                match={paramProps}
                isModal={true}
                deviceSize={props.deviceSize}
                loggedInUser={props.loggedInUser}
                isCommenting={props.isCommenting}
                toggleSignUpModal={props.toggleSignUpModal}
            />
        </Modal>
    )
}

function mapStateToProps(storeState) {
    return {
        showModal: storeState.modalReducer.blogPageModal.showBlogPageModal,
        shareCode: storeState.modalReducer.blogPageModal.shareCode,
        post: storeState.modalReducer.blogPageModal.post,
        loggedInUser: storeState.modalReducer.blogPageModal.loggedInUser,
        isCommenting: storeState.modalReducer.blogPageModal.isCommenting,
        setPost: storeState.modalReducer.blogPageModal.setPost,
        parentModal: storeState.modalReducer.blogPageModal.parentModal
    }
}

const mapDispatch = {
    toggleModal: toggleBlogPageModal,
    toggleProductModal: toggleProductPageModal
};

export default connect(mapStateToProps, mapDispatch)(BlogPageModal);