import React from "react";
import AccountBanner from "../../components/AccountBanner"
import { getRequest } from "sharedUtils/httpUtils";
import LoadSpinner from "../../components/LoadSpinner";
import BrandAnalyticsChart from "../../components/BrandAnalyticsChart";
import BrandCommissionsChart from "../../components/BrandCommissionsChart";

const { DateTime } = require("luxon");

class BrandAnalytics extends React.Component {

    state = {
        selectedCase: 1,
        filter: "",
        filteredPartners: [],
        partners: [],
        loading: false,
        analyticsLoading: false,
        commissionsLoading: false,
        sectors: [],
        partnerSectors: [],
        selectedSectors: [],
        analytics: [],
        commissions: []
    }

    breadCrumbList = [{ link: '/Account/CreatorDashboard', text: 'Creator Dashboard' }, { link: '/Account/BrandAnalytics', text: 'Analytics', active: true }];

    componentDidMount = async () => {
        let scrollOptions = {
            left: 0,
            top: 0,
            behavior: 'auto'
        }
        window.scrollTo(scrollOptions);
    }

    getCommissions = async (startDate, endDate) => {
        let now = DateTime.now();
        let offset = now.offset;

        let getCommissionsUrl = '/api/Merchant/GetBrandCommissions';

        if (startDate) {
            getCommissionsUrl += `?startDate=${startDate.toSQL({ includeOffset: false })}`
        }

        if (endDate) {
            getCommissionsUrl += getCommissionsUrl.includes('?') ? `&endDate=${endDate.toSQL({ includeOffset: false })}` : `?endDate=${endDate.toSQL({ includeOffset: false })}`;
        }

        getCommissionsUrl += `&offset=${offset}`

        this.setState({ commissionsLoading: true });
        let commissions = await getRequest(getCommissionsUrl) ?? [];

        this.setState({ commissions: commissions, loading: false, commissionsLoading: false });
    }

    getDaysBetweenDates = function (start, end) {
        for (var arr = [], dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
            arr.push(new Date(dt));
        }
        return arr;
    };

    getMockAnalytics = (startDate, endDate) => {
        var mockAnalytics = [];
        var dates = this.getDaysBetweenDates(startDate, endDate);
        dates.forEach((date, i) => {
            var shouldInclude = Math.random() < 0.5; // 50% probability of getting true
            if (shouldInclude) {
                const formattedDate = date.toISOString().split('T')[0] + 'T00:00:00';
                const postViews = Math.floor(Math.random() * 100 + 1); // Random integer from 0 to 100
                const productViews = Math.floor(Math.random() * postViews + 1);
                const productClicks = Math.floor(Math.random() * productViews + 1);
                const salesCount = Math.floor(Math.random() * productClicks + 1);
                const salesConversionPercentage = salesCount / productClicks;
                const commissionsEarned = parseInt(Math.floor(Math.random() * salesCount + 1));
                mockAnalytics.push({
                    "$id": (mockAnalytics.length + 1).toString(),
                    date: formattedDate,
                    postViews: postViews,
                    productViews: productViews,
                    productClicks: productClicks,
                    salesCount: salesCount,
                    salesConversionPercentage: salesConversionPercentage,
                    commissionsEarned: commissionsEarned
                });
            }
        });
        return mockAnalytics;
    }

    getAnalytics = async (startDate, endDate) => {
        let getAnalyticsUrl = '/api/Merchant/GetBrandAnalytics';
        let now = DateTime.now();
        let offset = now.offset;

        if (startDate) {
            getAnalyticsUrl += `?startDate=${startDate.toSQL({ includeOffset: false })}`
        }

        if (endDate) {
            getAnalyticsUrl += getAnalyticsUrl.includes('?') ? `&endDate=${endDate.toSQL({ includeOffset: false })}` : `?endDate=${endDate.toSQL({ includeOffset: false })}`;
        }

        getAnalyticsUrl += `&offset=${offset}`

        this.setState({ analyticsLoading: true });

        const urlParams = new URLSearchParams(window.location.search);
        const useMockData = urlParams.get('useMockData');

        let analytics = useMockData ? this.getMockAnalytics(startDate, endDate) : await getRequest(getAnalyticsUrl) ?? [];
        this.setState({ analytics: analytics, loading: false, analyticsLoading: false });
    };

    displayToggleButtons = () => {
        let options = [
            {
                name: "Analytics",
                case: 1,
                action: () => this.setState({ selectedCase: 1 })
            },
            {
                name: "Commissions",
                case: 2,
                action: () => this.setState({ selectedCase: 2 })
            }
        ];

        return options.map((toggle, i) => {
            return (
                <button key={i}
                    className={`btn ${this.state.selectedCase === toggle.case ? "btn-primary btn-lg" : ""}`}
                    onClick={toggle.action}
                >
                    {toggle.name}
                </button>
            )
        })
    }

    render() {
        if (this.state.loading) {
            return <LoadSpinner />
        }
        return (
            <>
                <section>
                    <AccountBanner breadCrumbList={this.breadCrumbList}>
                        <h1 className="mb-4">Analytics</h1>
                        <p className="mb-0">Reporting on traffic and commissions</p>
                    </AccountBanner>
                </section>
                <section className="filter-wrapper" >
                    <div className="container">
                        <div className="row py-2 mt-0">
                            <div className="col-12 d-flex justify-content-between">
                                <div className="filter-block d-flex">
                                    {this.displayToggleButtons()}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="gray-bg full-height">
                    <BrandAnalyticsChart
                        display={this.state.selectedCase === 1}
                        loggedInUser={this.props.loggedInUser}
                        analytics={this.state.analytics}
                        analyticsLoading={this.state.analyticsLoading}
                        getAnalytics={this.getAnalytics} />

                    <BrandCommissionsChart
                        display={this.state.selectedCase === 2}
                        loggedInUser={this.props.loggedInUser}
                        commissions={this.state.commissions}
                        commissionsLoading={this.state.commissionsLoading}
                        getCommissions={this.getCommissions} />
                </section>
            </>
        )
    }
}

export default BrandAnalytics;