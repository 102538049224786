import React from "react";

/**
 * 
 * @param {{isSmall:boolean, style:object}} props
 */
const LoadSpinnerOverlay = (props) => {
    return (
        <div className="overlay">
            <div className={props.className || "container h-100 centerHorizontalVertical"} style={props.style || {}}>
                <div className="row h-100 justify-content-center align-items-center pt-2">
                    <div className={`spinner-border ${props.isSmall === true ? "spinner-border-sm" : ""}`} role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
        </div>
        )
}

export default LoadSpinnerOverlay;