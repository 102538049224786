import React from "react";

const TwitterIcon = (props) => {
    return (
        <a href={"https://twitter.com/intent/tweet?url=" + encodeURI(props.shareCode)} className="twitter d-flex justify-content-center align-items-center mx-2" target="_blank" rel="noopener noreferrer">
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0)">
                    <path
                        d="M25.7511 5.07357C24.8071 5.4885 23.7991 5.77117 22.7378 5.90024C23.8194 5.2485 24.6514 4.2213 25.045 2.9957C24.0306 3.5877 22.9063 4.01864 21.7095 4.25864C20.7538 3.2357 19.3917 2.5957 17.8791 2.5957C14.981 2.5957 12.6311 4.94557 12.6311 7.8405C12.6311 8.2565 12.6791 8.6565 12.7666 9.03944C8.40393 8.83357 4.53726 6.73864 1.94953 3.5717C1.49406 4.34184 1.23913 5.23677 1.23913 6.2117C1.23913 8.0357 2.16713 9.6389 3.57299 10.5808C2.71219 10.553 1.9026 10.3162 1.19646 9.9237V9.98877C1.19646 12.5328 3.00233 14.6544 5.40553 15.1376C4.96499 15.256 4.49993 15.32 4.02313 15.32C3.68819 15.32 3.36713 15.288 3.04606 15.2282C3.71913 17.3114 5.65406 18.8304 7.95699 18.873C6.16499 20.28 3.89406 21.1184 1.4482 21.1184C1.0322 21.1184 0.617262 21.0938 0.200195 21.0469C2.53513 22.5338 5.28606 23.4032 8.26099 23.4032C17.9186 23.4032 23.1933 15.4074 23.1933 8.48477C23.1933 8.26184 23.1933 8.03677 23.1773 7.81277C24.2023 7.07784 25.0973 6.14877 25.8013 5.0949L25.7511 5.07357Z"
                        fill="#212529" />
                </g>
                <defs>
                    <clipPath id="clip0">
                        <rect width="25.6" height="25.6" fill="white"
                            transform="translate(0.200195 0.200195)" />
                    </clipPath>
                </defs>
            </svg>
        </a>

    )
}

export default TwitterIcon;