import React from "react"
import { useState } from "react";

import { isValidUrl } from "sharedUtils/validationUtils";

/** 
 * @param {{loggedInUser: Object, title:string, shareCode:string?, customStyle:Object, shareBlockStyle:Object}} props 
 */
const MerchantShareBlock = (props) => {

    const [showCopied2, setCopiedText2] = useState(false);
    const [invalidUrl, setInvalidUrl] = useState(false);
    const [redirectUrl, setRedirectUrl] = useState("");
    const [urlInput, setUrlInput] = useState("");

    let renderCopiedText2 = () => {
        if (showCopied2) {
            setTimeout(() => { setCopiedText2(false) }, 500);
            return (<i className="fas fa-clipboard-check"></i>);
        }
        return ("Copy");
    }

    let handleDeepLinkChange = (event) => {
        let url = event.target.value;
        setUrlInput(url);

        let isValid = url.length > 0 ? isValidUrl(url) : false;
        if (isValid) {
            setRedirectUrl(props.shareCode + "&redirectUrl=" + url);
            setInvalidUrl(false);
        } else {
            setInvalidUrl(true);
            setRedirectUrl("");
        }
    };

    return <></>
}

export default MerchantShareBlock;