

/**
 * 
 * @param {string} url
 */
function isValidUrl(url, allowNoPath = false) {

    //source : https://regexr.com/3ouav
    let rx = allowNoPath ? /^(https?:\/\/)([\da-z.-]+)\.([a-z]{2,6}).*/g :  /(https?:\/\/)([\da-z\.-]+)\.([a-z]{2,6})((\/([\.-\w])+)+|\/?\?[\.-\w])+/g;
    let isValid = rx.test(url);

    return isValid;
}

function isValidPassword(password) {
    var rx = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{3,}$/g;
    var isValid = rx.test(password);

    return isValid;
}
module.exports = {
    isValidUrl,
    isValidPassword
}