import React, { useState } from "react";
import ImageWrapper from "components/ImageWrapper";
import RightArrowIcon from "components/icons/RightArrowIcon";
import LeftArrowIcon from "components/icons/LeftArrowIcon";
import { useSwipeable } from "react-swipeable";
import { getThumbnail } from "../sharedUtils/postFunctions";

/**
 * 
 * @param {{images:{url:string, role:Number}[], customClass:string, imageStyle:object}} props
 */
const PostBanner = (props) => {

    let [currentMedia, setMedia] = useState(0);

    const mediaPageHandlers = useSwipeable({
        onSwipedLeft: () => nextMedia(),
        onSwipedRight: () => prevMedia(),
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
    });

    let nextMedia = () => {
        if (currentMedia < props.images.length - 1) {
            let newMedia = currentMedia + 1;
            setMedia(newMedia);
        }
        else {
            setMedia(0);
        }
    }

    let prevMedia = () => {
        if (currentMedia > 0) {
            let newMedia = currentMedia - 1;
            setMedia(newMedia);
        }
        else {
            setMedia(props.images.length - 1);
        }
    }

    let postIndicators = () => {
        return props.images.map((pic, i) => {
            return (
                <li className={`${currentMedia === i ? "active" : ""}`}
                    key={i}
                    style={
                        {
                            backgroundColor: "black",
                            width: "7px",
                            height: "7px",
                            borderRadius: "100%"
                        }
                    }
                />
            )
        })
    }

    return (
        <div className={`text-center ${props.customClass || ""} post-page-banner`}>


            <div className="carousel slide">
                
                <div {...mediaPageHandlers} className="carousel-inner post-banner-media-wrapper">
                    <ImageWrapper
                        src={props.images[currentMedia]?.url || "images/blank-banner.png"}
                        className={`post-banner-media${props.blogEmbed  ? "-blog-embed" : ""}`}
                        alt="Responsive"
                        postBanner={true}
                        role={props.images[currentMedia]?.role}
                        poster={getThumbnail(props.properties, props.images[currentMedia]?.id)}
                        width={props.images[currentMedia]?.width}
                        height={props.images[currentMedia]?.height}
                        style={props?.imageStyle}
                        onClick={props.onClick}
                        postPage={props.postPage}
                        setVideoLoaded={props.setVideoLoaded}
                    />
                </div>

                {
                    props.images.length > 1 ? (
                        <>
                            <a
                                href="/"
                                className="left-arrow position-absolute d-flex justify-content-center align-items-center"
                                onClick={(e) => {
                                    e.preventDefault();
                                    prevMedia()
                                }}
                            >
                                <span>
                                    <LeftArrowIcon x={16} y={16}/>
                                </span>
                            </a>

                            <ol className="post-banner-indicators carousel-indicators mb-0">
                                {postIndicators()}
                            </ol>

                            <a
                                href="/"
                                className="right-arrow position-absolute d-flex justify-content-center align-items-center"
                                onClick={(e) => {
                                    e.preventDefault();
                                    nextMedia()
                                }}
                            >
                                <span>
                                    <RightArrowIcon x={16} y={16}/>
                                </span>
                            </a>
                        </>
                    ) : null
                }
            </div>
            
        </div>

    )
}

export default PostBanner;