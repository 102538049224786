import React from "react";
import { postRequest } from "sharedUtils/httpUtils";
import LoadSpinner from "./LoadSpinner"
import StackTrace from "stacktrace-js";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: "", errorInfo: "" };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        StackTrace.fromError(error).then(async (err) => {
            await postRequest('/api/ErrorLogger/LogError', { error: error.toString(), stack: err.toString() } );
            window.location = "/ErrorPage" });
        this.setState({ error, errorInfo });
    }

    render() {
        const { error } = this.state

        // if there's an error render the fallback UI
        // otherwise render the child component(s)
        return error ? (
            <LoadSpinner />
        ) : (
            <>
                {this.props.children}
            </>
        )
    }
}

export default ErrorBoundary;