const feedLayoutState = {
    isGrid: false
}


const TOGGLE_FEED_LAYOUT = "TOGGLE_FEED_LAYOUT"

export function toggleFeedLayout() {
    return { type: TOGGLE_FEED_LAYOUT }
}

export default function feedLayoutReducer(state = feedLayoutState,action) {
    switch (action.type) {
        case TOGGLE_FEED_LAYOUT: {
            let newIsGrid = !state.isGrid;
            return { ...state, isGrid: newIsGrid }
        }
        default: {
            return state;
        }
    }
}