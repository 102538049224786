import * as React from "react";
import AccountSidebar from "../../components/AccountSidebar";
import AccountBanner from "../../components/AccountBanner";
import Modal from "../../components/Modal";
import CreatorApplication from "../../components/CreatorApplication";

import { getRequest } from "sharedUtils/httpUtils";

import { Form, FormGroup, Label, Input } from "reactstrap";
import { postRequest } from "../../sharedUtils/httpUtils";

let readOnlyFields = ["profileUrl"];

let userFormData = {
    youtube: "",
    facebook: "",
    instagram: "",
    realName: "",
    audience: "",
    postFrequency: "",
    timeCommitment: "",
    platform: "",
    platformUsername: "",
    followers: ""
};

const GoPro = (props) => {
    const [formData, setFormData] = React.useState(userFormData);

    const [savingProfile, setSavingProfile] = React.useState(false);
    const [modalOpen, setModalOpen] = React.useState(false);

    const toggleModal = () => {
        setModalOpen(!modalOpen);
    }

    const getData = async () => {
        let scrollOptions = {
            left: 0,
            top: 0,
            behavior: 'auto'
        }
        window.scrollTo(scrollOptions);

        setFormData(convertToFormData(props.loggedInUser));
    };

    React.useEffect(() => { getData() }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const convertToFormData = (user) => {
        return {
            youtube: user.googleUsername,
            facebook: user.facebookUsername,
            instagram: user.instagramUsername,
            realName: "",
            audience: "",
            postFrequency: "",
            timeCommitment: "",
            platform: "",
            platformUsername: "",
            followers: ""
        };
    }

    const convertToUserModel = () => {
        return {
            name: props.loggedInUser.name,
            firstName: props.loggedInUser.firstName,
            lastName: props.loggedInUser.lastName,
            bio: props.loggedInUser.bio,
            tagLine: props.loggedInUser.tagLine,
            website: props.loggedInUser.website,
            googleUsername: formData.youtube,
            facebookUsername: formData.facebook,
            instagramUsername: formData.instagram,
            imageUrl: props.loggedInUser.imageUrl,
            userName: props.loggedInUser.userName,
            email: props.loggedInUser.email,
            phoneNumber: props.loggedInUser.phoneNumber,
            proStatusId: props.loggedInUser.proStatusId,
            address: props.loggedInUser.address,
            city: props.loggedInUser.city,
            state: props.loggedInUser.state,
            postalCode: props.loggedInUser.postalCode,
            country: props.loggedInUser.country,
            onboardingComplete: props.loggedInUser.onboardingComplete,
            profileBannerUrl: props.loggedInUser.profileBannerUrl
        }
    }

    const onInputChange = (e) => {
        let formKey = e.target.name;
        let newData = e.target.value;

        setFormData({ ...formData, [formKey]: newData });
    };

    const saveUser = async () => {
        setSavingProfile(true);

        let userUrl = '/api/Account/PostUser';
        let user = await postRequest(userUrl, convertToUserModel());

        props.setLoggedInUser(user);
        setSavingProfile(false);
    };

    const mapInputFields = (fields) => {
        return fields.map((field, i) => {
            if (readOnlyFields.includes(field.name)) {
                return (
                    <FormGroup key={i} className="form-group">
                        <Label className="control-label">{field.title}</Label>
                        <Input
                            className="form-control"
                            name={field.name}
                            onChange={(e) => onInputChange(e)}
                            value={formData[field.name]}
                            readonly="readonly"
                        />
                    </FormGroup>
                );
            } else {
                return (
                    <FormGroup key={i} className="form-group">
                        <Label className="control-label">{field.title}</Label>
                        <Input
                            className="form-control"
                            name={field.name}
                            onChange={(e) => onInputChange(e)}
                            value={formData[field.name]}
                        />
                    </FormGroup>
                );
            }
        });
    }

    const renderModal = () => {
        return (
            <Modal isOpen={modalOpen} toggleModal={toggleModal}>
                <CreatorApplication isOpen={modalOpen} toggleModal={toggleModal} onInputChange={onInputChange} formData={formData} saveUser={saveUser} />
            </Modal>
        )
    }

    const breadCrumbList = [{ link: '/Account/Dashboard', text: 'Account' }, { link: '/Account/GoPro', text: 'Apply as Creator', active: true }];
    return (
        <section>
            <AccountBanner breadCrumbList={breadCrumbList}>
                <h1 className="mb-4">Apply as Creator</h1>
                <p className="mb-0">Apply to share content and earn commissions</p>
            </AccountBanner>

            <div className="gray-bg full-height">
                {renderModal()}
                <div className="container">
                    <div className="row py-5 mt-0">
                        {
                            ["sm", "md"].includes(props.deviceSize) === false ? (
                                <div className="col-md-5 col-lg-3 side-nav">
                                    <AccountSidebar loggedInUser={props.loggedInUser} setLoggedInUser={props.setLoggedInUser} />
                                </div>
                            ) : <span></span>
                        }

                        <div className="col-md-7 col-lg-9">
                            <div className="user-detail-form">
                                <Form>
                                    <div className="detail-block">
                                        <h2>Why Apply as Creator</h2>
                                        <ul className="user-type-features">
                                            <li className="user-type-features-item">Apply to be a Creator on the RockPorch platform to gain instant access to the highest quality active lifestyle and outdoor brands.</li>
                                            <li className="user-type-features-item">Monetize your content across multiple channels like RockPorch, Facebook, YouTube, and even locked platforms like Instagram.</li>
                                            <li className="user-type-features-item">Product-specific linking allows you to spend more time creating and less time answering one-off questions. Plus, at RockPorch, your answers can lead to sales -- meaning you get paid for what you share.</li>
                                        </ul>
                                        <h2>Creator Requirements</h2>
                                        <ul className="user-type-features">
                                            <li className="user-type-features-item">Requires RockPorch Approval</li>
                                            <li className="user-type-features-item">Content aligned with RockPorch</li>
                                            <li className="user-type-features-item">Demonstrates a positive influence</li>
                                            <li className="user-type-features-item">Strong follower engagement</li>
                                        </ul>
                                        <div className="text-center">
                                            <button className="btn btn-primary" onClick={(e) => { e.preventDefault(); toggleModal() }}>Apply Now</button>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default GoPro;
