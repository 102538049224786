import React from "react";
import { Link } from "react-router-dom";
import ImageWrapper from "components/ImageWrapper";
import CheckIcon from "icons/CheckIcon";
import DummyProfilePicture from "components/DummyProfilePicture";
import PenIcon from "components/icons/PenIcon";
import CameraIcon from "components/icons/CameraIcon";
import { Popover, PopoverBody } from "reactstrap";
import { postRequest } from "sharedUtils/httpUtils";
import Modal from "../../components/Modal";
import ShareBlock from "components/Share/ShareBlock";
import { toggleProductPageModal, toggleShareModal } from "stateManagement/reducers/modalReducer";
import { connect } from "react-redux";
import { getMerchantShareLink } from "../../sharedUtils/shareLinkPrefixes"
import ShareIcon from "components/icons/ShareIcon";
import PlusIcon from "components/icons/PlusIcon";
import MinusIcon from "components/icons/MinusIcon";
import FacebookIcon from "components/icons/FacebookIcon";
import InstagramIcon from "components/icons/InstagramIcon";
import TikTokIcon from "components/icons/TikTokIcon";
import StravaIcon from "components/icons/StravaIcon";
import YoutubeIcon from "components/icons/YoutubeIconLink";
import WebsiteIcon from "components/icons/WebsiteLinkIcon";

/**
 * 
 * @param {{
 * userInformation:object
 * deviceSize:string
 * isAuthenticated:boolean
 * profileBanner:string,
 * productCount:Number, 
 * postCount:Number, 
 * fetchProducts:Function,
 * fetchActivityFeed:Function,
 * fetchAbout:Function,
 * fetchLockers:Function
 * onClickMessage:Function
 * selectedCase: Number,
 * productCount:Number,
 * lockerCount:Number,
 * isFollowing:boolean,
 * isLoggedInUser:boolean,
 * followAction: Function,
 * showFollowersOrFollowing:Function
 * }} props 
 */

const ProfileHeader = (props) => {
    let { profileBanner, selectedCase, uploadingProfileBanner } = props;
    let { bio, email, followers, following, id, imageUrl, name, proStatusId, tagLine, userName } = props.userInformation;

    const readMoreRef = React.useRef(null);

    const [isRendered, setIsRendered] = React.useState(false);
    const [modalOpen, setModalOpen] = React.useState(false);
    const [shareLink, setShareLink] = React.useState("");
    const [readMore, setReadMore] = React.useState(false);
    const [showReadMore, setShowReadMore] = React.useState(false);
    const [readMoreHeight, setReadMoreHeight] = React.useState(0);

    React.useLayoutEffect(() => {
        setIsRendered(true);
    }, []);

    React.useEffect(() => {
        if (!!(readMoreRef) && !!(readMoreRef.current) && !!(readMoreRef.current.offsetHeight)) {
            readMoreRef.current.classList.remove('profile-header-read-more');

            if (readMoreRef.current.offsetHeight !== readMoreHeight) {
                if (readMoreRef.current.offsetHeight > 80) {
                    setShowReadMore(true);
                    readMoreRef.current.classList.add('profile-header-read-more');
                } else {
                    setShowReadMore(false);
                    readMoreRef.current.classList.remove('profile-header-read-more');
                }
                setReadMoreHeight(readMoreRef.current.style.offsetHeight);
            }
        }
    }, [readMoreRef, readMoreHeight, props.screenWidth]);

    let saveUser = async (loggedInUser) => {
        let serializedUser = {
            name: loggedInUser.name,
            firstName: loggedInUser.firstName,
            lastName: loggedInUser.lastName,
            bio: loggedInUser.bio,
            tagLine: loggedInUser.tagLine,
            website: loggedInUser.website,
            imageUrl: loggedInUser.imageUrl,
            userName: loggedInUser.userName,
            email: loggedInUser.email,

            phoneNumber: loggedInUser.phoneNumber,
            proStatusId: loggedInUser.proStatusId,
            address: loggedInUser.address,
            city: loggedInUser.city,
            state: loggedInUser.state,
            postalCode: loggedInUser.postalCode,
            country: loggedInUser.country,

            onboardingComplete: loggedInUser.onboardingComplete,
            profileBannerUrl: loggedInUser.profileBannerUrl,
            quickStart: loggedInUser.quickStart
        };

        let userUrl = '/api/Account/PostUser';
        let user = await postRequest(userUrl, serializedUser);
        props.setLoggedInUser(user);
    }

    let renderFollowButtonIcon = () => {
        let buttonText = props.isFollowing != undefined && props.isFollowing != null && props.isFollowing === true ? "Unfollow " : "Follow "
        let buttonSymbol = props.isFollowing != undefined && props.isFollowing != null && props.isFollowing === true ? "-" : "+";
        if (proStatusId === 1 && (props.loggedInUser == undefined || props.loggedInUser == null || !props.isLoggedInUser)) {
            return (
                <button className="btn follow-icon" style={{ marginBottom: "3px", border: "2px solid #191D30", height: "18px", width: "18px", padding: 0 }} onClick={props.followAction}>
                    <div style={{ position: "relative", right: "5px", bottom: "6px" }} onClick={props.toggleSignUpModal}>
                        <PlusIcon className="follow-icon" fill="#191D30" height="12"  />
                    </div>
                </button>)
        }
        if (proStatusId === 1) {
            if (buttonSymbol === "+") {
                return (
                    <button className="btn follow-icon" style={{ marginBottom: "3px", border: "2px solid #191D30", height: "18px", width: "18px", padding: 0 }} onClick={props.followAction}>
                        <div style={{ position: "relative", right: "5px", bottom: "6px" }}>
                            <PlusIcon className="follow-icon" fill="#191D30" height="12" onClick={props.followAction} />
                        </div>
                    </button>)
            } else {
                return (
                    <button className="btn follow-icon" style={{ marginBottom: "3px", border: "2px solid #191D30", height: "18px", width: "18px", padding: 0}} onClick={props.followAction}>
                        <div style={{ position: "relative", right: "5px", bottom: "6px" }}>
                            <MinusIcon className="follow-icon" fill="#191D30" height="12" onClick={props.followAction} />
                        </div>
                    </button>)
            }
        }
    }

    let renderFollowButton = () => {
        let buttonText = props.isFollowing === true ? "Unfollow " : "Follow "
        let buttonSymbol = props.isFollowing === true ? "-" : "+";
        if (proStatusId === 1 && props.loggedInUser == undefined || props.loggedInUser == null) {
            return (
                <button className="btn follow-btn" onClick={() => props.toggleSignUpModal()}>
                    {buttonText}
                    <span style={{ fontSize: "1.5em !important" }}>
                        {buttonSymbol}
                    </span>
                </button>)
        }
        if (proStatusId === 1) {
            return (
                <button className="btn follow-btn" onClick={props.followAction}>
                    {buttonText}
                    <span style={{ fontSize: "1.5em !important" }}>
                        {buttonSymbol}
                    </span>
                </button>)
        }

    }

    let mapToggleButtons = () => {
        let buttons = [{ name: "About", action: props.fetchAbout, case: 5 }];

        if (props.userInformation.proStatusId === 1) {
            buttons = [
                { name: "Activity", action: props.fetchActivityFeed, case: 1, content: null },
                { name: "Lockers", action: props.fetchLockers, case: 2, content: props.lockerCount || null },
                { name: "Products", action: props.fetchProducts, case: 3, content: props.productCount || null }
            ];
        }

        return buttons.map((button, i) => {
            let selectedCaseClass = selectedCase === button.case ? "btn-primary" : "";
            let smallSizeClass = props.deviceSize === "sm" ? "btn-sm" : "";
            return (
                <button key={i}
                    onClick={button.action}
                    className={`btn ${smallSizeClass} ${selectedCaseClass} profile-toggle-buttons`}
                >
                    {button.name} <span>{button.content}</span>
                </button>
            )
        })
    }

    let displayEditOrMessageButtons = () => {
        const displaySmall = props.deviceSize === "sm";

        if ((props.isLoggedInUser != undefined && props.loggedInUser != null && props.isLoggedInUser !== true && props.isAuthenticated === true) ||
            props.loggedInUser == undefined || props.loggedInUser == null) {
            return (
                <div className="text-right button-block d-flex justify-content-start align-items-start">
                    <div className={`btn-block d-flex align-items-center justify-content-end profile-header-buttons`} style={{ position: displaySmall ? "" : "relative", right: props.deviceSize === "sm" ? "-19%" : "-1%", top: props.deviceSize === "sm" ? "-8px" : "2px" }}>
                        {renderFollowButton()}
                    </div>
                </div>
            )
        }            
    }

    let displayRightSideFunctionalityButtons = () => {
        if (props.isLoggedInUser != undefined && props.loggedInUser != null && props.isLoggedInUser === true) {
            return (
                <div className="d-flex align-items-center profile-header-icons-right">
                    <div>
                        {displayShareButton()}
                    </div>
                </div>
            );
        } else if (props.isLoggedInUser != undefined && props.loggedInUser != null && props.isLoggedInUser !== true && props.isAuthenticated === true) {
            return (
                <div className="d-flex align-items-center profile-header-icons-right">
                    {/*<div>*/}
                    {/*    {renderFollowButton()}*/}
                    {/*</div>*/}
                    <div>
                        {displayShareButton()}
                    </div>
                </div>
            );
        } else {
            return (
            <div className="d-flex align-items-center profile-header-icons-right">
                {/*<div>*/}
                {/*    {renderFollowButton()}*/}
                {/*</div>*/}
                <div>
                    {displayShareButton()}
                </div>
            </div>
        )}
    }

    let displayShareButton = () => {
        let path = props.location.pathname;
        let username = path.slice(path.lastIndexOf("/") + 1);
        let sharePath = "/prflink/" + username;
        return (

            <div onClick={() => toggleProductShareModal(sharePath)} style={{ cursor: "pointer", marginBottom: "5px" }}>
                <img src="images/share-icon-ios.svg" alt="Share" style={{ borderRadius: "0", height: "20px", width: "18px", marginBottom: "2px", paddingRight: "2px" }}/>
            </div>
        )
    }

    let displayBanner = () => {
        if (props.userInformation?.profileBannerUrl?.length === 0 || props.userInformation?.profileBannerUrl === undefined) {
            return (
                <>
                    <ImageWrapper src={"images/blank-banner.png"} alt="" className="img-fluid" lazy={false} />
                    {
                        props.isLoggedInUser === true ? (
                            <>
                                <button id="cameraButton" className="btn btn-secondary profile-banner-button" onClick={() => document.getElementById("uploadProfileBanner").click()}>
                                    {uploadingProfileBanner ?
                                        <div className={`spinner-border spinner-border-sm`} role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                        : <CameraIcon />} Add Cover Image
                                </button>

                            </>
                        ) : null
                    }
                </>
            )
        } else {
            return (
                <>
                    <ImageWrapper src={props.userInformation?.profileBannerUrl} alt="" className="img-fluid" lazy={false} />
                    {
                        props.isLoggedInUser === true ? (
                            <button className="btn btn-secondary profile-banner-button" onClick={() => document.getElementById("uploadProfileBanner").click()}>
                                {uploadingProfileBanner ?
                                    <div className={`spinner-border spinner-border-sm`} role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                    : <PenIcon />}
                            </button>) : null
                    }
                </>
            )
        }
    }

    function followersFollowingSection() {
        let addedStyles = props.deviceSize === "sm" ? "d-flex px-0 justify-content-around" : "";
        return (
            <div className={`col-md-4 py-0 text-center text-md-left ${addedStyles}`}
                style={
                    props.deviceSize === "sm" ? { width: "50%", margin: "auto" } : null
                }
            >
                <Link to="#" onClick={(e) => {
                    e.preventDefault();
                    props.showFollowersOrFollowing("following")
                }}>
                    <h5>
                        <strong> {following} </strong>Following
                    </h5>
                </Link>



                {
                    props.userInformation.proStatusId === 1 ?
                        (
                            <Link to="#"
                                onClick={(e) => {
                                    e.preventDefault();
                                    props.showFollowersOrFollowing("followers");
                                }}>
                                <h5>
                                    <strong> {followers} </strong>Followers
                                </h5>


                            </Link>
                        ) : <></>
                }


            </div>


        )
    }

    let avatarStyle = props.deviceSize === "sm" ? { width: "75px", height: "75px" } : {};

    let backgroundImageStyle = props.deviceSize === "sm" ? {
        backgroundImage: `url(${props.userInformation?.profileBannerUrl || "./images/blank-banner.png"})`,
        width: "100%",
        height: "100%",
        backgroundPosition: "center",
        backgroundOrigin: "border-box"
    } : {};

    let bannerRowStyle = props.deviceSize === "sm" ? { backgroundColor: "rgba(255,255,255,0.5)", backdropFilter: "blur(2px)" } : {};

    const displayShareModal = () => {
        return (
            <Modal isOpen={modalOpen} toggleModal={toggleProductShareModal}>
                <ShareBlock shareCode={shareLink}
                    title="Profile share"
                    customStyle={{ maxWidth: "100%", backgroundColor: "white" }}
                    shareBlockStyle={{ border: "none", backgroundColor: "transparent" }}
                    loggedInUser={props.loggedInUser}
                />
            </Modal>
        )
    }

    const toggleProductShareModal = async (urlFragment) => {
        let showModal = !modalOpen;
        let shareLink = await getMerchantShareLink(urlFragment);

        if (showModal) {
            setModalOpen(showModal);
            setShareLink(shareLink);
        }
        else {
            setModalOpen(showModal);
            setShareLink("");
        }
    }

    const popoverCallbackSaveUser = () => {
            let loggedInUser = props.loggedInUser;

            if (props.loggedInUser.quickStart == null) {
                loggedInUser.quickStart = JSON.stringify({ seenMyFeed: true });
            } else {
                let quickStart = JSON.parse(props.loggedInUser.quickStart);
                quickStart.seenMyFeed = true;
                loggedInUser.quickStart = JSON.stringify(quickStart);
                props.loggedInUser.quickStart = JSON.stringify(quickStart);
            }

            saveUser(loggedInUser);
    }

    const notLoggedInUserPaddingTopStyle = { paddingTop: props.isLoggedInUser ? "" : "15px" }

    return (
        <>
            {displayShareModal()}
            <div className='container'>

                <div className="row m-0 user-activity align-items-center" style={notLoggedInUserPaddingTopStyle, { paddingTop: "0" }}>

                    <div className={`d-flex flex-wrap align-items-around justify-content-around py-0 px-0 text-left user-activity-info mt-3 w-100`}>
                        {imageUrl?.length > 0 ? < ImageWrapper src={imageUrl} alt="" lazy={false} /> : < ImageWrapper src="/images/avatar2.svg" alt="" style={avatarStyle} lazy={false} />}
                        <div className={`d-flex flex-column px-3 pt-2 ${props.deviceSize === "sm" ? "justify-content-around" : "" }`} style={{ flex: 1 }}>
                            <div className="d-flex justify-content-between">
                                <h5 className="mb-2">{userName}</h5>
                                {
                                    props.deviceSize !== "sm" ?

                                        displayEditOrMessageButtons()
                                        :
                                        <></>
                                }
                            </div>
                            <h6 className="mb-3">{tagLine}</h6>
                            {
                                props.deviceSize !== "sm" ?
                                    <div>
                                        <p ref={readMoreRef} className={`${showReadMore && !readMore ? "profile-header-read-more" : ""} mb-0`} style={{ display: ["sm"].includes(props.deviceSize) ? "none" : "" }}>{bio}</p>
                                        <a href="#" style={{ display: props.deviceSize !== "sm" && showReadMore ? "" : "none" }} onClick={(e) => { e.preventDefault(); setReadMore(!readMore); }}>{readMore ? "read less" : "read more"}</a>
                                    </div>
                                        :
                                    <></>
                            }
                            
                        </div>
                    </div>
                    <div className="row m-0 w-100 user-activity align-items-center" style={notLoggedInUserPaddingTopStyle, { paddingTop: "0" }}>
                        {
                            props.deviceSize === "sm" ?
                                <div className="w-100">
                                    <div>
                                        {displayEditOrMessageButtons()}
                                    </div>
                                    <div className="mt-3">
                                        <p ref={readMoreRef} className={`${showReadMore && !readMore ? "profile-header-read-more" : ""} mb-0`} style={{ display: ["sm"].includes(props.deviceSize) ? "" : "none" }}>{bio}</p>
                                        <a href="#" style={{ display: props.deviceSize === "sm" && showReadMore ? "" : "none" }} onClick={(e) => { e.preventDefault(); setReadMore(!readMore); }}>{readMore ? "read less" : "read more"}</a>
                                    </div>
                                </div>
                                :
                                <></>
                        }
                        <div className="col-12 mt-2 mb-2 d-flex align-items-center justify-content-between pl-0">
                            <div className="pt-1 pb-1 d-flex align-items-center">
                                {props.userInformation?.facebookUsername != null && props.userInformation?.facebookUsername != undefined && props.userInformation?.facebookUsername !== "" ?
                                    <div style={{ }}>
                                        <FacebookIcon height={18} width={18} fill="#4267B2" username={props.userInformation?.facebookUsername} />
                                    </div> :
                                    <></>
                                }
                                {props.userInformation?.instagramUsername != null && props.userInformation?.instagramUsername != undefined && props.userInformation?.instagramUsername !== "" ?
                                    <div style={{ margin: "-5px -5px -6px -5px"  }}>
                                        <InstagramIcon fill="#191D30" height={27} width={27} username={props.userInformation?.instagramUsername} style={{ marginRight: "10px" }} />
                                    </div> :
                                    <></>
                                }
                                {props.userInformation?.tikTokUsername != null && props.userInformation?.tikTokUsername != undefined && props.userInformation?.tikTokUsername !== "" ?
                                    <div>
                                        <TikTokIcon style={{ background: "#191D30", padding: "3px" }} username={props.userInformation?.tikTokUsername} height={12} width={12} fill="#fff" />
                                    </div> :
                                    <></>
                                }
                                {props.userInformation?.stravaUsername != null && props.userInformation?.stravaUsername != undefined && props.userInformation?.stravaUsername !== "" ?
                                    <div style={{ marginBottom: "3px" }}>
                                        <StravaIcon style={{ background: "#191D30", padding: "3px" }} username={props.userInformation?.stravaUsername} height={18} width={18} />
                                    </div> :
                                    <></>
                                }
                                {props.userInformation?.googleUsername != null && props.userInformation?.googleUsername != undefined && props.userInformation?.googleUsername !== "" ?
                                    <div>
                                        <YoutubeIcon username={props.userInformation?.googleUsername} fill="#FF0000" height={18} width={18} />
                                    </div> :
                                    <></>
                                }
                                {props.userInformation?.website != null && props.userInformation?.website != undefined && props.userInformation?.website !== "" ?
                                    <div style={{ }}>
                                        <WebsiteIcon website={props.userInformation?.website} fill="#191D30" height={18} width={18} />
                                    </div> :
                                    <></>
                                }
                            </div>
                            <div className="">
                                {displayRightSideFunctionalityButtons()}
                            </div>
                        </div>
                    </div>
                </div>

                {props.userInformation.proStatusId === 1 ?
                    <div className={`row activity-list profile-header-buttons-bar py-1 m-0`}
                        style={{ paddingBottom: "5px !important" }}
                    >
                        <div className={`d-flex flex-wrap justify-content-center col-12 ${props.deviceSize == "sm" ? "px-0" : ""}`}>
                            {mapToggleButtons()}
                        </div>
                    </div>
                    : <></>}
            </div>
        </>
    )
}

function mapStateToProps(storeState, ownProps) {
    return { interactionState: storeState.interactionReducer }
}

export default connect(mapStateToProps, { toggleProductPageModal, toggleShareModal })(ProfileHeader);