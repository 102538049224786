import React from "react"
import BlueTickIcon from "./icons/BlueTickIcon";
//import ThreeDotsHorizontalIcon from "./icons/ThreeDotsHorizontalIcon";
import { Link } from "react-router-dom";
import UserTileGeneral from "components/UserTileGeneral";
import { connect } from "react-redux";
import {togglePostPageModal,toggleLockerPageModal, toggleBlogPageModal } from "stateManagement/reducers/modalReducer";
/**
 * 
 * @param {{post:Object,className:string,parentModal:{type:string,shareCode:string,parentState:object}}} props
 */
const ProductSlide = (props) => {

    let renderPost = () => {
        return (
            <Link to={{
                pathname: "/Post/PostPage/" + props.post.shareCode, state: {
                    destinationState: { params: { post: props.post, loggedInUser: props.loggedInUser } }
                }
            }}
                onClick={(e) => {
                    e.preventDefault();
                    if (props.post.type === 2) {
                        props.toggleLockerPageModal(props.post, props.loggedInUser, false, null, props.parentModal)
                    } else if (props.post.type === 4) {
                        props.toggleBlogPageModal(props.post, props.loggedInUser, false, null, props.parentModal)
                    }
                    else {
                        props.togglePostPageModal(props.post, props.loggedInUser, false, null, props.parentModal);
                    }
                }}
            >
                <div className="post-image">                    
                    {props.content && props.content.length > 0 &&
                        props.content.some(property => property.role === 0 || property.role === 8) ?
                        <img className={`${props.post.type === 2 || props.post.type === 4? "feed-post-grid-locker-image" : ""}`} src=
                            {props.content.filter(property => property?.role === 0 || property?.role === 8)[0].value} alt = "" />
                        : props.content && props.content.length > 0 &&
                            props.content.some(property => property?.role === 1) ?
                            <video controls
                                preload="metadata"
                                    controlsList="nodownload"
                                    disablePictureInPicture>
                                <source src={props.content.filter(property => property?.role === 1)[0].value.split('#')[0] + "#t=0.001"} alt="" />
                            </video>
                            : <img src="images/blank-banner.png" />}
                    {props.post.type === 2 || props.post.type === 4 ?
                        <>
                            <h5 className={`position-absolute ${props.post.type === 4 ? "blog-feed-post-grid-item-title-hidden" : ""}`} style={{ cursor: "pointer", transform: "translate(-50%, -50%)", textAlign: "center", top: "50%", left: "50%", fontSize: ["sm", "md"].includes(props.deviceSize) ? "10px" : "17px", color: "#fff" }} >{props.post.title}</h5>
                            <p className="product-slide-read" style={{ display: props.post.type === 4 ? "" : "none" }}>
                                Read more
                            </p>
                            <span class="item-count position-absolute"
                            style={{
                                    bottom: 0,
                                    left: '50%',
                                    marginBottom: 0,
                                    transform: 'translate(-50%,-50%)',
                                    width: '40%',
                                    borderRadius: '999px',
                                    textAlign: 'center',
                                    backgroundColor: '#e55934',
                                    fontFamily: '"Archivo", sans-serif',
                                    fontSize: '.75rem',
                                    fontWeight: 500,
                                    color: '#fff',
                                    opacity: .8,
                                    visibility: ["sm", "md"].includes(props.deviceSize) || props.post.productItems?.$values?.length === 0 ? "hidden" : "",
                                    cursor: "pointer"
                                }}

                            >{props.post.productItems?.$values.length} product{props.post.productItems?.$values?.length > 1 ? "s" : ""}</span>
                        </>
                        :
                        <></>
}
                </div>
            </Link>
        );
    }

    return (
        <div className={`${props.className || ""} col-3 px-1 position-relative`}>
            <div className="wrapper">
                {renderPost()}
                {props.deviceSize === "lg" || props.deviceSize === "xl" || props.deviceSize === "md" ?
                    <UserTileGeneral className="pt-1 px-1" brandNav={props.brandNav} user={props.post.user} subtitle={" "} showCheckMark={false} truncateUsername={true} /> : <></>}
            </div>
            {
                /*
                 *             

                 * <div className="users d-flex align-items-center">
                <Link to={"/Profile/" + props.post.user.userName}>
                    <img src={props.post.user.imageUrl} alt="" className="user-image" />
                    <h5 className="d-inline-flex align-items-center">
                        {props.post.user.userName.substring(0,15)} <BlueTickIcon proStatusId={ props.post.user.proStatusId }/>
                    </h5>
                </Link>
            </div>
                 
                 */

            }
            

            {renderContentCount(props.post.properties.$values.length)}
        </div>

    )
}


function renderContentCount(count) {
    /*
    if (count > 1) {
        return (
            <>
            <span className="count position-absolute d-flex justify-content-center align-items-center">1/{count}</span>
            <a href="/" className="arrow-right position-absolute">
                <RightArrowIconSmall />
                </a>
                </>
        )
    }
    */
}
export default connect(null, { togglePostPageModal, toggleLockerPageModal, toggleBlogPageModal })(ProductSlide)