import React from "react";
import { Form, FormGroup, Label, Input, Button } from "reactstrap";
import ActionButton from "../../components/ActionButton";
import { getRequest, postRequest } from 'sharedUtils/httpUtils';
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import LoadSpinner from "../../components/LoadSpinner";
import Modal from "components/Modal";

import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

class FeaturedCreators extends React.Component {
    state = {
        options: [],
        value: [],
        id: 0,
        selected: [],
        selectedEditFeaturedCreatorId: 0,
        addShareCode: "",
        addUserName: "",
        addUserText: "",
        addUserImage: "",
        addSector: "",
        addPostProduct: [],
        newPostProductType: 'post',
        newPostProductShareCode: '',
        sectors: [],
        isSubmitting: false,
        isDeleting: false,
        isFetching: false,
        isLoading: false,
        show: false,
        modalType: 1,
        selectedCase: 1,
        addSubmitError: false,
        topCreatorsFromDb: [],
        deleteError: false,
        FeaturedCreatorTable: {
            title: "Featured Creators",
            columns: [
                {
                    name: "id",
                    label: "Featured Creator Id",
                    options: {
                        filter: true,
                        sort: true,
                        display: false // Column is not visible. Adding so that it will be included in CSV export.
                    },
                },
                {
                    name: "userName",
                    label: "Creator",
                    options: {
                        filter: true,
                        sort: true,
                        display: true // Column is not visible. Adding so that it will be included in CSV export.
                    },
                },
                {
                    name: "userImage",
                    label: "Image",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            const url = value;
                            return <img src={'/images/' + url} style={{ height: "100px", width: "100px", objectFit: "contain" }} />;
                        },
                    }
                },
                {
                    name: "userText",
                    label: "User Text",
                    options: {
                        filter: true,
                        sort: true,
                        setCellProps: () => ({ style: { maxWidth: "200px", overflowWrap: "anywhere", wordBreak: "break-all" } }),
                    }
                },
                {
                    name: "sectors",
                    label: "Sectors",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (!!(value) && value.length > 0) {
                                return value.join(", ");
                            }
                        },
                    }
                },
                {
                    name: "published",
                    label: "Published",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value) {
                                return String(value);
                            }
                            return "false"                        },
                    }
                },
            ],
            options: {
                customToolbarSelect: () => { },
                selectableRows: "multiple",
                sortOrder: {
                    name: 'userId',
                    direction: 'asc'
                },
                onRowClick: async (rowData, rowMeta) => {
                    const id = rowData[0];
                    const selectedEditFeaturedCreator = this.state.topCreatorsFromDb.filter(tc => tc.id === id)[0];
                    var topContent = selectedEditFeaturedCreator.featuredContent.postsProducts.map(p => { 
                        return { type: !!(p.userMerchantProductId) ? "product" : "post", shareCode: p.shareCode }
                    });
                    const sectorsArray = Object.values(selectedEditFeaturedCreator.sectors).filter((item) => typeof item === "string");

                    this.setState({
                        selectedEditFeaturedCreatorId: selectedEditFeaturedCreator.id,
                        modalType: 2,
                        selectedEditFeaturedCreator,
                        show: true,
                        addUserName: selectedEditFeaturedCreator.userName,
                        addUserImage: selectedEditFeaturedCreator.userImage,
                        addUserText: selectedEditFeaturedCreator.userText,
                        sectors: sectorsArray,
                        addPostProduct: topContent
                        
                    });
                },
                customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
                    return (
                        <div className="d-flex flex-row align-items-center mx-1">
                            {/* https://v4.mui.com/components/checkboxes/#checkbox-with-formcontrollabel */}
                            <div className="dropdown inline-block mx-1 MuiTypography-subtitle1">
                                <button className="btn btn-primary dropdown-toggle MuiTypography-subtitle1" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Action
                                </button>
                                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                    <button className="dropdown-item" type="button" onClick={(e) => { this.confirmDelete(selectedRows, displayData); }}>Delete Selected Content</button>
                                    <button className="dropdown-item" type="button" onClick={(e) => { this.confirmPublishSelected(selectedRows, displayData); }}>Publish Selected Content</button>
                                </div>
                            </div>
                        </div>
                    )
                },
            },
            getTheme: () => createTheme({
                overrides: {
                    MUIDataTable: {
                        root: {
                        },
                        paper: {
                        },
                    },
                    MUIDataTableBodyRow: {
                        root: {
                            '&:nth-child(odd)': {
                                backgroundColor: '#F9F9F9'
                            },
                            cursor: 'pointer'
                        }
                    },
                    MUIDataTableBodyCell: {
                        root: {
                        }
                    },
                }
            })
        },
    };

    async componentDidMount() {
        this.setState({ isFetching: true });

        await this.fetchFeaturedCreators();

        this.setState({ isFetching: false });
    }


    fetchFeaturedCreators = async () => {
        const getUrl = "api/Featured/GetFeaturedPostsAndProducts?admin=true";
        const topCreatorsFromDb = await getRequest(getUrl);
        this.setState({ topCreatorsFromDb });
    }

    confirmPublishAll = () => {
        const confirmDelete = window.confirm("ALL ITEMS will be published...");
        if (confirmDelete) {
            this.handlePublish();
        }
    }

    confirmPublishSelected = (selectedRows, displayData) => {
        const confirmDelete = window.confirm("SELECTED ITEMS will be published...");
        if (confirmDelete) {
            this.handlePublish(selectedRows, displayData);
        }
    }


    handlePublish = async (selectedRows = null, displayData = null) => {
        this.setState({ loading: true });
        let featuredCreatorIds = [];
        if (!!(selectedRows) && !!(selectedRows.data) && selectedRows.data.length > 0) {
            const selectedRowsData = selectedRows.data;
            featuredCreatorIds = selectedRowsData.map(row => displayData[row.index].data[0]);
        }

        const deleteUrl = "api/Featured/PublishFeaturedCreator";

        const payload = {
            featuredCreatorIds
        }

        const publish = await postRequest(deleteUrl, payload);

        if (!!(publish)) {
            this.setState({ topCreatorsFromDb: publish, publishError: false });
        } else {
            this.setState({ publishError: true });
        }

        this.setState({ loading: false });
    };

    getModalTitle = () => {
        if (this.state.modalType === 1) {
            return "Add Featured Creator";
        } else {
            return "Edit Featured Creator";
        }
    }

    handleAddContentButton = () => {
        this.setState({ modalType: 1, show: true })
    }

    breadCrumbList = [
        { link: "/Admin", text: "Admin" },
        { link: "/Admin/Featured", text: "Featured" },
    ];

    handleShareCode = (e) => {
        this.setState({ addShareCode: e.target.value });
    }

    fetchUsers = async (query) => {
        this.setState({ isLoading: true });
        let url = `/api/Account/GetUserSuggestionsByUsername?username=${query}`;

        let users = await getRequest(url);
        this.setState({ options: users, isLoading: false });
    }

    setSelected = async (selected) => {
        this.setState({ selected });
    }

    handleUserName = (e) => {
        this.setState({ addUserName: e.target.value });
    }

    handleUserText = (e) => {
        this.setState({ addUserText: e.target.value });
    }

    handleUserImage = (e) => {
        this.setState({ addUserImage: e.target.value });
    }

    handleAddSector = (e) => {
        this.setState({ addSector: e.target.value });
    }

    handlePostProductTypeChange = (e) => {
        this.setState({ newPostProductType: e.target.value });
    };

    handlePostProductShareCodeChange = (e) => {
        this.setState({ newPostProductShareCode: e.target.value });
    };

    addPostProductToState = () => {
        const { newPostProductType, newPostProductShareCode } = this.state;
        if (newPostProductType.length > 0 && newPostProductShareCode.length > 0) {
            const newPostProduct = { type: newPostProductType, shareCode: newPostProductShareCode };

            this.setState((prevState) => ({
                addPostProduct: [...prevState.addPostProduct, newPostProduct],
                newPostProductType: 'post',
                newPostProductShareCode: '',
            }));
        }
    };

    addSectorToSectors = () => {
        const { addSector, sectors } = this.state;
        if (addSector.trim() !== "") {
            this.setState({
                sectors: [...sectors, addSector],
                addSector: ""
            });
        }
    };

    removePostProduct = (index) => {
        this.setState((prevState) => ({
            addPostProduct: prevState.addPostProduct.filter((_, i) => i !== index),
        }));
    };

    removeSector = (sector) => {
        const { sectors } = this.state;
        const updatedSectors = sectors.filter(item => item !== sector);
        this.setState({ sectors: updatedSectors });
    };

    handleSubmit = async () => {
        this.setState({ isSubmitting: true });

        const addUrl = "api/Featured/AddFeaturedCreator";

        const payload = {
            userName: this.state.selected[0].userName,
            userText: this.state.addUserText,
            userImage: this.state.addUserImage,
            sectors: this.state.sectors,
            topContent: this.state.addPostProduct
        }

        const topCreatorsFromDb = await postRequest(addUrl, payload);
        if (!!(topCreatorsFromDb)) {

            this.setState({ topCreatorsFromDb, show: false });
            this.resetState();
        } else {
            this.setState({ addSubmitError: true });
        }

        this.setState({ isSubmitting: false });
    };

    handleEditSubmit = async () => {
        this.setState({ isSubmitting: true });

        const editUrl = "api/Featured/EditFeaturedCreator";
        const payload = {
            id: this.state.selectedEditFeaturedCreatorId,
            userText: this.state.addUserText,
            userImage: this.state.addUserImage,
            sectors: this.state.sectors,
            topContent: this.state.addPostProduct
        }

        const topCreatorsFromDb = await postRequest(editUrl, payload);
        if (!!(topCreatorsFromDb)) {

            this.setState({ topCreatorsFromDb, show: false });
            this.resetState();
        } else {
            this.setState({ addSubmitError: true });
        }

        this.setState({ isSubmitting: false });
    };

    confirmDelete = (selectedRows, displayData) => {
        const confirmDelete = window.confirm("Are you sure you want to DELETE these Creators? This will be permanent...");
        if (confirmDelete) {
            this.handleDelete(selectedRows, displayData);
        }
    }

    handleDelete = async (selectedRows, displayData) => {
        this.setState({ loading: true });

        const selectedRowsData = selectedRows.data;
        const featuredCreatorIds = selectedRowsData.map(row => displayData[row.index].data[0]);
        const deleteUrl = "api/Featured/DeleteFeaturedCreatorsByFeaturedCreatorId";

        const payload = {
            featuredCreatorIds
        }

        const topCreatorsFromDb = await postRequest(deleteUrl, payload);

        if (!!(topCreatorsFromDb)) {
            this.setState({ topCreatorsFromDb, deleteError: false });
        } else {
            this.setState({ deleteError: true });
        }

        this.setState({ loading: false });
    }

    resetState = () => {
        this.setState({
            sectors: [], selected: [], addShareCode: "", addUserName: "", addUserText: "", addUserImage: "", addSector: "", addSubmitError: false, addPostProduct: [], newPostProductType: 'post', newPostProductShareCode: ''
        });
    }

    render() {
        const submitDisabled = (this.state.selected.length !== 1 && this.state.addUserName.length === 0)
            || this.state.addUserText.length === 0
            || this.state.addUserImage.length === 0
            || this.state.sectors.length === 0
            || this.state.addPostProduct.length === 0;

        return (
            <>
                <Modal isOpen={this.state.show} modalHeaderClass={"current-deals-modal-header"}
                    style={
                        {
                            overlay: {
                                position: 'fixed',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                backgroundColor: 'lightgray',
                                zIndex: 9998,
                            },
                            content: {
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                background: 'lightgray',
                                overflow: 'auto',
                                WebkitOverflowScrolling: 'touch',
                                borderRadius: '4px',
                                outline: 'none',
                                padding: '50px',
                                minWidth: '300px',
                                height: '350px',
                                zIndex: 1060,
                            }
                        }
                    }
                    onRequestClose={() => { this.setState({ show: false }); this.resetState(true); }}
                    toggleModal={() => { this.setState({ show: false }); this.resetState(true); }}
                >

                    <section className="gray-bg" style={{ marginBottom: "-20px", marginTop: "-5px" }}>
                        <div className="container pt-0 pb-5" >
                            <div className="d-flex flex-column pt-4 mb-2">
                                <h4>{this.getModalTitle()}</h4>
                            </div>
                            {
                                this.state.modalType === 1 ?
                                    <FormGroup className="form-group" >
                                        <Label className="control-label">UserName:</Label>
                                        <AsyncTypeahead
                                            id="userSearch"
                                            labelKey="userName"
                                            defaultSelected={this.state.value}
                                            clearButton
                                            onSearch={this.fetchUsers}
                                            onChange={selected => this.setSelected(selected)}
                                            isLoading={this.state.isLoading}
                                            options={this.state.options}
                                            clearButtonProps={{ style: { color: 'red', fontSize: '14px' } }}
                                            placeholder="Search for users..." />
                                    </FormGroup>
                                    :
                                    <FormGroup className="form-group" >
                                        <Label className="control-label">UserText:</Label>
                                        <Input
                                            className="form-control"
                                            name="addUserText"
                                            type="text"
                                            value={this.state.addUserName}
                                            readOnly
                                        />
                                    </FormGroup>
                            }
                           

                            <FormGroup className="form-group" >
                                <Label className="control-label">UserText:</Label>
                                <Input
                                    className="form-control"
                                    name="addUserText"
                                    type="text"
                                    value={this.state.addUserText}
                                    placeholder="Enter User Text..."
                                    onChange={this.handleUserText}
                                />
                            </FormGroup>

                            <FormGroup className="form-group" >
                                <Label className="control-label">Image:</Label>
                                <Input
                                    className="form-control"
                                    name="addUserImage"
                                    type="text"
                                    value={this.state.addUserImage}
                                    placeholder="Enter URL for user image..."
                                    onChange={this.handleUserImage}
                                />
                            </FormGroup>

                            <FormGroup className="form-group">
                                <Label className="control-label">Sectors:</Label>
                                <div className="input-group">
                                    <Input
                                        className="form-control"
                                        name="addSector"
                                        type="text"
                                        value={this.state.addSector}
                                        placeholder="Enter a sector for this Creator..."
                                        onChange={this.handleAddSector}
                                    />
                                    <div className="input-group-append">
                                        <Button color="primary" onClick={this.addSectorToSectors}>Add</Button>
                                    </div>
                                </div>
                            </FormGroup>
                            <div className="sectors-container">
                                {!!(this.state.sectors) && this.state.sectors.map((sector, index) => (
                                    <div key={index} className="sector-item">
                                        <span>{sector}</span>
                                        <Button className="btn-sm" style={{ background: "transparent", border: "none" }} onClick={() => this.removeSector(sector)}>x</Button>
                                    </div>
                                ))}
                            </div>

                            <div>
                                {/* Your existing Sector part */}
                                <FormGroup className="form-group">
                                    {/* ... */}
                                </FormGroup>
                                <div className="sectors-container">
                                    {/* ... */}
                                </div>

                                {/* New Posts/Products section */}
                                <FormGroup className="form-group">
                                    <Label className="control-label">Posts (incl. Lockers & Blogs)/Products:</Label>
                                    <div className="input-group">
                                        {/* Dropdown to select type */}
                                        <Input
                                            style={{ maxWidth: "102px" }}
                                            type="select"
                                            value={this.state.newPostProductType}
                                            onChange={this.handlePostProductTypeChange}
                                        >
                                            <option value="post">Post</option>
                                            <option value="product">Product</option>
                                        </Input>
                                        {/* Text input for share code */}
                                        <Input
                                            className="form-control"
                                            name="addPostProductShareCode"
                                            type="text"
                                            value={this.state.newPostProductShareCode}
                                            placeholder="Enter share code..."
                                            onChange={this.handlePostProductShareCodeChange}
                                        />
                                        {/* Add button */}
                                        <div className="input-group-append">
                                            <Button color="primary" onClick={this.addPostProductToState}>Add</Button>
                                        </div>
                                    </div>
                                </FormGroup>
                                {/* Display added Posts/Products */}
                                <div className="post-product-container">
                                    {this.state.addPostProduct.map((postProduct, index) => (
                                        <div key={index} className="post-product-item">
                                            {postProduct.type === 'post' ? 'Post:' : 'Product:'} {postProduct.shareCode}
                                            <Button
                                                className="btn-sm"
                                                style={{ background: 'transparent', border: 'none' }}
                                                onClick={() => this.removePostProduct(index)}
                                            >
                                                x
                                            </Button>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <p style={{ color: "red", display: this.state.addSubmitError ? "" : "none" }}>ERROR: check shareCode and try again</p>
                            <div className="d-flex">
                                {
                                    this.state.modalType === 1 ?
                                        <ActionButton className="btn btn-primary mt-3"
                                            onClick={this.handleSubmit}
                                            isLoading={this.state.isSubmitting}
                                            isDisabled={submitDisabled}
                                        >
                                            Add
                                        </ActionButton>
                                        :
                                        <ActionButton className="btn btn-primary mt-3"
                                            onClick={this.handleEditSubmit}
                                            isLoading={this.state.isSubmitting}
                                            isDisabled={submitDisabled}
                                        >
                                            Submit Edit
                                        </ActionButton>
                                }
                            </div>
                        </div>

                    </section>
                </Modal>
                <section>
                    <div className="container-fluid">
                        {
                            this.state.isFetching ?
                                <LoadSpinner />
                                :
                                <div className="row">
                                    <div className="col">
                                        <div className="text-right mb-2">
                                            <ActionButton className="btn btn-primary mt-3 mr-2"
                                                onClick={this.confirmPublishAll}
                                            >
                                                Publish
                                            </ActionButton>
                                            <ActionButton className="btn btn-primary mt-3"
                                                onClick={this.handleAddContentButton}
                                            >
                                                Add Featured Creator
                                            </ActionButton>
                                        </div>
                                        <p style={{ color: "red", display: this.state.deleteError ? "" : "none" }} className="text-right">ERROR DELETING CREATOR(S): try again</p>
                                        <p style={{ color: "red", display: this.state.publishError ? "" : "none" }} className="text-right">ERROR PUBLISHING CREATOR(S): try again</p>
                                        <ThemeProvider theme={this.state.FeaturedCreatorTable.getTheme()}>
                                            <MUIDataTable
                                                title={this.state.FeaturedCreatorTable.title}
                                                data={this.state.topCreatorsFromDb}
                                                columns={this.state.FeaturedCreatorTable.columns}
                                                options={this.state.FeaturedCreatorTable.options}
                                            />
                                        </ThemeProvider>
                                    </div>
                                </div>
                        }
                    </div>
                </section>
            </>
        )
    }
}

export default FeaturedCreators;