import React, { Component } from 'react';
import { Navbar, NavbarBrand, NavItem } from 'reactstrap';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Link, NavLink, withRouter } from 'react-router-dom';
import LoginMenu from './api-authorization/BrandLoginMenu';
import BrandNavLowerMenu from './BrandNavLowerMenu';
import './NavMenu.css';
import CloseIcon from "components/icons/CloseIcon";
import { ApplicationPaths, QueryParameterNames } from './api-authorization/ApiAuthorizationConstants';
import NotificationDotIcon from './icons/NotificationDotIcon';
import { toggleShareModal, toggleAddProductModal, toggleCreateLockerModal, toggleFollowersFollowingModal, toggleFetchFollowing } from "stateManagement/reducers/modalReducer";
import { connect } from "react-redux";
import { getReferralShareLink } from "../sharedUtils/shareLinkPrefixes"
import { getRequest } from "sharedUtils/httpUtils";
import rpLogo from "./icons/images/rockporch-logo.svg";

class NavMenu extends Component {
    static displayName = NavMenu.name;
    totalHeight = React.createRef();

    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            collapsed: true,
            showMobileSearchBar: false,
            searchInput: "",
            searchProp: "",
            redirectToSearch: false,
            submenuOpen: true,
            hover: false,
            isMobile: false,
            showBlogPostsFlag: false,
            pathname: window.location.pathname,
            browseSearchMounted: false,
            navLong: ["browse", "explore", "homefeed", "profile"].some(item => window.location.pathname.toLowerCase().includes(item)),
            isSearchResults: ["searchresults"].some(item => window.location.pathname.toLowerCase().includes(item)),
            dropdownOptions: [],
            showDropdown: false,
            creatorCentricView: ["/creatordashboard", "/account/drafts", "/account/analytics"].some(item => window.location.pathname.toLowerCase().includes(item)),
        };

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.collapseMenu = this.collapseMenu.bind(this);
    }

    componentDidMount = async () => {
        this.setState({ searchInput: this.props.searchInput, searchProp: this.props.searchInput, showMobileSearchBar: (this.props.location.pathname.toLowerCase().startsWith("/browse") && (!!(this.props.searchInput) && this.props.searchInput.length > 0)) || this.props.location.pathname.toLowerCase().startsWith("/searchresults"), isMobile: window.innerWidth - document.documentElement.clientWidth < 5 });
        if (!!(this.props.searchInput) && this.props.searchInput.length > 0) {
            this.searchOptions(this.props.searchInput);
            this.setState({ showDropdown: true })
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (window.location.pathname !== this.state.pathname) {
            this.setState({
                pathname: window.location.pathname, navLong: ["browse", "explore", "homefeed", "profile"].some(item => window.location.pathname.toLowerCase().includes(item)),
                isSearchResults: ["searchresults"].some(item => window.location.pathname.toLowerCase().includes(item)),
                creatorCentricView: ["/creatordashboard", "/account/drafts", "/account/analytics"].some(item => window.location.pathname.toLowerCase().includes(item)),
            });
        }
        if (prevState.collapsed !== this.state.collapsed) {
            this.props.hamburgerOpen(!this.state.collapsed);
        }
        if (this.props.toggleMenu != undefined && this.props.toggleMenu != null && prevProps.toggleMenu !== this.props.toggleMenu) {
            this.setState({ collapsed: false });
        }
        if (!this.state.browseSearchMounted && !!(this.props.searchInput) && this.props.searchInput.length > 0 && this.state.searchInput !== this.props.searchInput) {
            this.setState({ searchInput: this.props.searchInput, browseSearchMounted: true });
        }
        if (prevProps.screenWidth !== this.props.screenWidth) {
            const isMobile = window.innerWidth - document.documentElement.clientWidth < 5;
            this.setState({ isMobile });
        }
        if (!!(this.props.searchInput) && prevProps.searchInput !== this.props.searchInput && this.state.searchInput !== this.props.searchInput) {
            this.setState({ searchInput: this.props.searchInput, searchProp: this.props.searchInput, showMobileSearchBar: true });
            this.searchOptions(this.props.searchInput);
        }
        if (this.props.resetSearchBar) {
            this.props.handleResetSearchBar(false);
            this.setState({ searchInput: "", searchProp: "*&^reset", showMobileSearchBar: false, showDropdown: false })
        }
        if (prevState.showMobileSearchBar !== this.state.showMobileSearchBar) {
            this.props.handleMobileSearchOpen(this.state.showMobileSearchBar);
        }
    }

    resetSearchProp = (resetSearchInput = false) => {
        if (this.state.searchProp.length > 0) {
            this.setState({ searchProp: "" });
            //if (resetSearchInput) {
            //    this.setState({ searchValue: "", searchInput: "" })
            //}
        }
    }

    updateSearchInput = (searchInput) => {
        this.setState({ searchInput });
    }

    handleSearchInputChange = (event) => {
        this.setState({ showDropdown: true });

        const searchInput = event.target.value;
        this.setState({ searchInput });
        this.searchOptions(searchInput);
    }

    searchOptions(searchInput) {
        const dropdownOptions = [
            {
                label: `Most Endorsed Products`,
                path: `/Browse?search=${searchInput}`,
            },
            {
                label: `Lockers (Gear Lists)`,
                path: `/SearchResults/${searchInput}?tab=locker&showBrandNav=true`,
            },
            {
                label: `Related Social Posts and Videos`,
                path: `/SearchResults/${searchInput}?tab=posts&showBrandNav=true`,
            },
            {
                label: `Relevant Creators`,
                path: `/SearchResults/${searchInput}?tab=people&showBrandNav=true`,
            },
        ];
        this.setState({ dropdownOptions });
    }

    setDropdownOptions = (dropdownOptions) => {
        this.setState({ dropdownOptions });
    }

    handleDropdownOptionClick(option) {
        this.setState({ showDropdown: false });
        this.props.history.push(option.path);
    }

    submitSearch = (e) => {
        if (e.keyCode === 13 && this.state.searchInput.length > 1) {
            this.setState({ showDropdown: false });

            this.props.populateSearch(this.state.searchInput);
            //this.setState({ searchProp: this.state.searchInput });
            document.getElementById("searchSubmitMobile").click();

        }
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    }

    collapseMenu() {
        this.setState({
            collapsed: true
        });
    }

    brandMobileDropdown = () => {
        let menu = !(this.props.loggedInUser) ? [
            { title: "Shop", path: "/Browse" },
            { title: "Resource", path: "https://www.rockporch.com/CREATOR-RESOURCES/", target: "blank_" },
        ] :
            [
                { title: "Shop", path: "/Browse" },
                { title: "Resource", path: "https://www.rockporch.com/CREATOR-RESOURCES/", target: "blank_" },
            ];

        return menu.map((menuItem, i) => {
            let nestedItems = menuItem?.nestedPaths?.map((nested, i) => {
                return (
                    <li key={i}>
                        <a className="dropdown-item nav-link override-active" onClick={() => { this.collapseMenu() }} href={nested.path}>{nested.title}</a>
                    </li>
                )
            })
            if (menuItem.title === "Explore" || menuItem.title === "Shop") {
                return (
                    <li key={i}>
                        <NavLink className="dropdown-item nav-link override-active" to={menuItem.path} onClick={(e) => { this.collapseMenu() }}>{menuItem.title}</NavLink>
                    </li>
                );
            }
            return (
                <li key={i}>
                    {
                        menuItem.target ?
                            <div className="d-flex align-items-center">
                                <a className="dropdown-item nav-link override-active mr-1" href={menuItem.path} onClick={() => { this.collapseMenu() }} target={menuItem.target}>{menuItem.title}</a>
                            </div>
                            :
                            <a className="dropdown-item nav-link override-active" href={menuItem.path} onClick={() => { this.collapseMenu() }}>{menuItem.title}</a>
                    }
                    {menuItem?.nestedPaths !== undefined ? (
                        <ul className="submenu dropdown-menu">
                            {nestedItems}
                        </ul>
                    ) : null}
                </li>
            )
        })
    }

    mapThreeDotIconDropdown = () => {
        let menu = !(this.props.loggedInUser) ? [,
            { title: "Explore", path: "/Nav/Explore?showBrandNav=true" },
            { title: "Home", path: "https://www.rockporch.com/home", target: "blank_" },
            { title: "Become a Creator", path: "https://" + window.location.host + '/Identity/Account/Register?returnUrl=/Account/CreatorApp', target: "blank_" },
        ] :
            [,
                { title: "Explore", path: "/Nav/Explore?showBrandNav=true" },
                { title: "Home", path: "https://www.rockporch.com/home", target: "blank_" },
            ];

        return menu.map((menuItem, i) => {
            let nestedItems = menuItem?.nestedPaths?.map((nested, i) => {
                return (
                    <li key={i}>
                        <a className="dropdown-item nav-link override-active" onClick={() => { this.collapseMenu() }} href={nested.path}>{nested.title}</a>
                    </li>
                )
            })
            if (menuItem.title === "Explore") {
                return (
                    <li key={i}>
                        <NavLink className="dropdown-item nav-link override-active" to={menuItem.path} onClick={(e) => { this.collapseMenu() }}>{menuItem.title}</NavLink>
                    </li>
                );
            }
            return (
                <li key={i}>
                    {
                        menuItem.target ?
                            <a className="dropdown-item nav-link override-active" href={menuItem.path} onClick={() => { this.collapseMenu() }} target={menuItem.target}>{menuItem.title}</a>
                            :
                            <a className="dropdown-item nav-link override-active" href={menuItem.path} onClick={() => { this.collapseMenu() }}>{menuItem.title}</a>
                    }
                    {menuItem?.nestedPaths !== undefined ? (
                        <ul className="submenu dropdown-menu">
                            {nestedItems}
                        </ul>
                    ) : null}
                </li>
            )
        })
    }

    mapCreatorMenu = () => {
        let menu = [
            { title: "Referral Link", path: "" },
            { title: "My Feed", path: "/Nav/HomeFeed" },
            { title: "My Saves", path: "/Account/Saves" },
            { title: "Account Settings", path: "/Account/AccountSettings" },
        ];


        return menu.map((menuItem, i) => {
            let nestedItems = menuItem?.nestedPaths?.map((nested, i) => {
                return (
                    <li key={i}>
                        <NavLink className="dropdown-item nav-link override-active" to={nested.path} onClick={() => { this.collapseMenu() }}>{nested.title}</NavLink>
                    </li>
                )
            })

            if (menuItem.title === "Referral Link") {
                return (
                    <li key={i} className="notification-menu-messages-wrapper" style={{ display: this.props.screenWidth > 991 ? "none" : "" }}>
                        <a href="#" className="dropdown-item nav-link override-active">
                            onClick={(e) => {
                                e.preventDefault();
                                this.collapseMenu();
                                this.props.toggleShareModal(getReferralShareLink(this.props.loggedInUser?.userName))
                            }}
                        >{menuItem.title}</a>
                    </li>
                )
            }

            return (
                <li key={i}>
                    {
                        menuItem.target ?
                            <NavLink className="dropdown-item nav-link override-active" to={menuItem.path} target={menuItem.target} onClick={() => { this.collapseMenu() }}>{menuItem.title}</NavLink>
                            :
                            <NavLink className="dropdown-item nav-link override-active" to={menuItem.path} onClick={() => { this.collapseMenu() }}>{menuItem.title}</NavLink>
                    }
                    {menuItem?.nestedPaths !== undefined ? (
                        <ul className="submenu dropdown-menu">
                            {nestedItems}
                        </ul>
                    ) : null}
                </li>
            )
        })
    }

    mapNonCreatorMenu = () => {
        let menu = [];

        if (this.props?.loggedInUser?.proStatusId === 1) {
            menu = [
                { title: "Referral Link", path: "" },
                { title: "My Feed", path: "/Nav/HomeFeed" },
                { title: "My Saves", path: "/Account/Saves" },
                { title: "My Creators", path: "/" },
                { title: "Account Settings", path: "/Account/AccountSettings" },
            ];
        } else {

            menu = [
                { title: "My Feed", path: "/Nav/HomeFeed" },
                { title: "My Saves", path: "/Account/Saves" },
                { title: "Account Settings", path: "/Account/AccountSettings" },
                { title: "My Creators", path: "/" }
            ];
        }

        return menu.map((menuItem, i) => {
            let nestedItems = menuItem?.nestedPaths?.map((nested, i) => {
                return (
                    <li key={i}>
                        <NavLink className="dropdown-item nav-link override-active" to={nested.path} onClick={() => { this.collapseMenu() }}>{nested.title}</NavLink>
                    </li>
                )
            })

            if (menuItem.title === "My Creators") {
                return (
                    <li key={i} className="notification-menu-messages-wrapper">
                        <a href="#" className="dropdown-item nav-link override-active"
                            onClick={(e) => {
                                e.preventDefault();
                                this.collapseMenu();
                                this.showFollowersFollowing("following");
                            }}
                        >{menuItem.title}</a>
                    </li>
                )
            }

            if (menuItem.title === "Referral Link") {
                return (
                    <li key={i} className="notification-menu-messages-wrapper" style={{ display: this.props.screenWidth > 991 ? "none" : "" }}>
                        <a href="#" className="dropdown-item nav-link override-active"
                            onClick={(e) => {
                                e.preventDefault();
                                this.collapseMenu();
                                this.props.toggleShareModal(getReferralShareLink(this.props.loggedInUser?.userName))
                            }}
                        >Refer A Creator</a>
                    </li>
                )
            }

            if (menuItem.title === "My Feed") {
                return (
                    <li key={i} className="notification-menu-messages-wrapper" id="my-feed-menu" >
                        <NavLink className="dropdown-item nav-link override-active" to={menuItem.path} onClick={() => { this.collapseMenu() }}>{menuItem.title}</NavLink>
                    </li>
                )
            }

            return (
                <li key={i}>
                    {
                        menuItem.target ?
                            <NavLink className="dropdown-item nav-link override-active" to={menuItem.path} target={menuItem.target} onClick={() => { this.collapseMenu() }}>{menuItem.title}</NavLink>
                            :
                            <NavLink className="dropdown-item nav-link override-active" to={menuItem.path} onClick={() => { this.collapseMenu() }}>{menuItem.title}</NavLink>
                    }
                    {menuItem?.nestedPaths !== undefined ? (
                        <ul className="submenu dropdown-menu">
                            {nestedItems}
                        </ul>
                    ) : null}
                </li>
            )
        })
    }

    showFollowersFollowing = (followersFollowing) => {
        this.props.toggleFollowersFollowingModal(this.props.loggedInUser.id, followersFollowing, null);
    }

    handleMouseEnter = (e) => {
        if (this.state.hover !== true) {
            this.setState({ hover: true });
        }
    }

    handleMouseLeave = (e) => {
        if (this.state.hover !== false) {
            this.setState({ hover: false });
        }
    }

    toggleSearchBar = () => {
        this.setState({ showMobileSearchBar: !this.state.showMobileSearchBar });
    }

    handleInputOnBlur = (e) => {
        if (!!(e.relatedTarget) && !e.relatedTarget.classList.contains("search-dropdown")) {
            this.setState({ showDropdown: false })
        }
    }

    toggleShowDropdown = (isOpen = false) => {
        this.setState({ showDropdown: isOpen })
    }

    displayHamburgerMenu = () => {
        let showMobileSearchBar = (["sm", "md"].includes(this.props.deviceSize) && this.state.showMobileSearchBar === true);
        const logoutPath = {
            pathname: `${ApplicationPaths.LogOut}`, state: { local: true }
        };
        const registerPath = `${ApplicationPaths.Register}`;
        const link = document.location;
        const returnUrl = `${link.pathname}${link.search}${link.hash}`;
        const redirectUrl = `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${returnUrl}`;
        const showSubmenu = { listStyle: "none", position: "relative", right: "20%", marginTop: "3px", marginRight: "-40px", marginLeft: "25px" };
        const breakpointWidth = this.props.screenWidth < 991;

        return (
            <div className={`${["sm", "md"].includes(this.props.deviceSize) ? "" : "mr-2"}`} style={{ position: "relative", top: ["sm", "md"].includes(this.props.deviceSize) && !!(this.props.loggedInUser) && this.props.loggedInUser.proStatusId === 1 ? "-1px" : !(this.props.loggedInUser) && ["sm", "md"].includes(this.props.deviceSize) ? "-2px" : ["sm","md"].includes(this.props.deviceSize) ? "-6px" : "8px", display: this.state.creatorCentricView ? "none" : "" }} >

            <ButtonDropdown isOpen={!this.state.collapsed} toggle={this.toggleNavbar} style={{ position: "relative", right: this.props.deviceSize === "lg" ? "12%" : this.props.deviceSize === "xl" ? "12%" : "0", display: this.props.deviceSize === "lg" ? "block" : "block", zIndex: "1000", margin: "0 5px" }}>
                <DropdownToggle className="navbar-toggler" tag="button" type="button" >
                    <span className="navbar-toggler-icon" ></span>
                </DropdownToggle>

                <ul className={`dropdown-menu ${this.state.collapsed ? "" : "show"} sm-menu-dropdown`}
                    style={{}}
                    aria-labelledby="menu-dropdown" >

                    {
                        breakpointWidth ?
                            <>
                                {this.brandMobileDropdown()}
                                <li className="menu-hr">
                                    <hr />
                                </li>                                                        </>
                            :
                            <></>

                    }

                    {this.mapThreeDotIconDropdown()}


                    {this.props?.loggedInUser == null || this.props?.loggedInUser == undefined ?
                        <></> :
                        <>
                            <li className="menu-hr">
                                <hr />
                            </li>

                            <li>
                                <span style={{ fontWeight: "bold", marginLeft: "27px", paddingRight: "10px" }}>
                                    {this.props?.loggedInUser?.userName == null || this.props?.loggedInUser?.userName == undefined ?
                                        this.props?.loggedInUser?.email : this.props?.loggedInUser?.userName}
                                </span>
                                <ul className="test" style={showSubmenu}>
                                    {this.mapNonCreatorMenu()}
                                </ul>
                            </li>

                        </>
                    }

                    <li className="menu-hr menu-hr-bottom">
                        <hr />
                    </li>

                    {
                        this.props?.loggedInUser?.isAdmin === true ? (
                            <li>
                                <NavLink tag={Link} to="/Admin" className="dropdown-item override-active" onClick={() => { this.collapseMenu() }}>Admin</NavLink>
                            </li>
                        ) : <span></span>
                    }

                    <li>
                        <a className="dropdown-item nav-link override-active" onClick={() => { this.collapseMenu() }} href="https://www.rockporch.com/terms/" target="blank_">Terms of Service</a>
                    </li>
                    <li>
                        <a className="dropdown-item nav-link override-active" onClick={() => { this.collapseMenu() }} href="https://www.rockporch.com/privacy-policy/" target="blank_">Privacy Policy</a>
                    </li>

                    {this.props?.loggedInUser == null || this.props?.loggedInUser == undefined ?
                        <></> :
                        <>
                            <li>
                                <NavLink tag={Link} to="/Contact" className="dropdown-item override-active" onClick={() => { this.collapseMenu() }}>Contact</NavLink>
                            </li>

                            <li>
                                <NavLink tag={Link} to={logoutPath} className="dropdown-item override-active" onClick={() => { this.props.setShowTopNav(false) }}>Log Out</NavLink>
                            </li>
                        </>
                    }

                    {this.props?.loggedInUser?.proStatusId === 0 ?
                        <li>
                            <NavLink tag={Link} to="/Account/CreatorApp" className="dropdown-item override-active" onClick={() => { this.collapseMenu() }}>Apply as Creator</NavLink>
                        </li> :
                        <></>
                    }

                    {this.props?.loggedInUser == null || this.props?.loggedInUser == undefined ?
                        <div>
                            <li>
                                <NavLink tag={Link} className="dropdown-item" to={redirectUrl}>Login</NavLink>
                            </li>
                        </div> :
                        <></>
                    }

                </ul>
                </ButtonDropdown>
            </div>
        );
    }
        
    render() {
        if (!this.props.showTopNav) {
            return <></>;
        }


        let showMobileSearchBar = (["sm", "md"].includes(this.props.deviceSize) && this.state.showMobileSearchBar === true);
        const logoutPath = {
            pathname: `${ApplicationPaths.LogOut}`, state: { local: true }
        };
        const registerPath = `${ApplicationPaths.Register}`;
        const link = document.location;
        const returnUrl = `${link.pathname}${link.search}${link.hash}`;
        const redirectUrl = `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${returnUrl}`;
        const showSubmenu = { listStyle: "none", position: "relative", right: "20%", marginTop: "3px", marginRight: "-40px", marginLeft: "25px" };
        const breakpointWidth = this.props.screenWidth < 991;
        const anonymousRpLogoStyle = { position: "absolute", top: 2, left: "50%", transform: "translate(-53%, 0)" }

        const dropdownStyle = {
            zIndex: 99999,
            lineHeight: "1.4rem",
            boxShadow: "rgba(229, 89, 52, 0.4) 0px 5px 15px",
            border: "1px solid lightgray",
            borderRadius: "15px",
            padding: "20px",
            backgroundColor: "white",
            top: "30",
            width: this.props.screenWidth < 991 ? "87%" : "70%",
            margin: "0 auto",
            padding: "20px",
            position: "fixed",
            cursor: "pointer",
            transform: this.props.screenWidth < 991 ? "translate(9%, -10px)" : "translate(22%, -10px)",
        };
        return (
            <React.Fragment>
                {/*<div style={this.props.showTopNav ? { marginBottom: !(this.props.loggedInUser) && this.state.pathname.toLowerCase().includes("/searchresults") && this.props.screenWidth > 991 ? 36 : !(this.props.loggedInUser) && this.state.pathname.toLowerCase().includes("/searchresults") && this.props.screenWidth < 991 ? 28 : (this.state.pathname.toLowerCase().includes("/searchresults") || this.state.pathname.toLowerCase().includes("/account/saves")) && this.props.screenWidth > 991 ? 42 : (this.state.pathname.toLowerCase().includes("/searchresults") || this.state.pathname.toLowerCase().includes("/account/saves")) && this.props.screenWidth < 991 ? 34 : ["/browse"].includes(this.state.pathname.toLowerCase()) && !(this.props.loggedInUser) && this.props.screenWidth < 756 ? 101 : ["/browse"].includes(this.state.pathname.toLowerCase()) && this.props.screenWidth < 756 ? 103 : ["/browse"].includes(this.state.pathname.toLowerCase()) && this.props.screenWidth > 991 ? 90 : !(this.props.loggedInUser) && this.state.pathname.toLowerCase().includes("/browse") && this.props.screenWidth < 991 ? 77 : ["/browse"].includes(this.state.pathname.toLowerCase()) ? 79 : this.props.screenWidth < 991 && (!(this.props.loggedInUser) || this.props.loggedInUser?.proStatusId !== 1) ? 53 : this.props.screenWidth > 991 ? 63 : !(this.props.loggedInUser) ? 58 : 53 } : { display: "none" }}>*/}
                <div style={this.props.showTopNav ? { marginBottom: this.state.pathname.toLowerCase().includes("/browse") ? 47 : this.props.screenWidth < 991 ?  59 : 63 } : { display: "none" }}>

                    {/*<header className="sticky-nav" style={{ height: this.state.pathname.toLowerCase().includes("/browse") && this.props.screenWidth > 991 ? "90px" : this.state.pathname.toLowerCase().includes("/browse") ? "56px" : this.props.screenWidth < 991 && (!(this.props.loggedInUser) || this.props.loggedInUser?.proStatusId !== 1) ? "53px" : "58px", paddingRight: !this.state.isMobile && this.props.browseFilterOpen && this.props.screenWidth <= 991 ? "17px" : "", borderBottom: 0, paddingTop: this.props.screenWidth > 991 ? "2px" : "", width: "100%", zIndex: 1000 }} ref={this.totalHeight}>*/}
                    <header className="sticky-nav" style={{ height: this.state.creatorCentricView && this.props.screenWidth > 991 ? "66px" : this.state.creatorCentricView ? "55px" :  this.props.screenWidth < 991 ? "59px" :"63px", paddingRight: !this.state.isMobile && this.props.browseFilterOpen && this.props.screenWidth <= 991 ? "17px" : "", borderBottom: this.state.creatorCentricView ? "1px solid lightgray" : 0, paddingTop: this.props.screenWidth > 991 ? "2px" : "", width: "100%", zIndex: 997 }} ref={this.totalHeight}>

                        <Navbar className={`navbar-expand-lg navbar-main-wrapper d-flex justify-content-between pb-0 brand-login-navbar`} onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave} style={{ }} light>
                            <div className={`d-flex align-items-between`}>
                                {this.displayHamburgerMenu()}    
                                {(this.props.deviceSize === "sm" || this.props.deviceSize === "md") ?
                                    <div style={{ flexGrow: ["sm", "md"].includes(this.props.deviceSize) ? 2 : "", textAlign: ["sm", "md"].includes(this.props.deviceSize) ? "center" : "", position: "absolute", top: ["sm", "md"].includes(this.props.deviceSize) && this.state.creatorCentricView ? 9 : 13, left: "50%", transform: !this.state.creatorCentricView && this.props.loggedInUser?.proStatusId === 1 ? "translate(-58%, 0)" : "translate(-50%, 0)" }}>
                                        <a href={`https://www.rockporch.com/`} id="rp-nav-logo" rel="noreferrer"  target="_blank" style={{ position: "relative", left: !(this.props.loggedInUser) || (!!(this.props.loggedInUser) && ["sm", "md"].includes(this.props.deviceSize)) ? "" : "-10%", width: "100px", margin: this.props?.loggedInUser?.proStatusId === 1 || ["sm", "md"].includes(this.props?.deviceSize) ? "" : "0 0 0 30%" }}>
                                            <img src={rpLogo} alt='' />
                                        </a>
                                    </div> :
                                    <div>
                                        <a href={`https://www.rockporch.com/`} id="rp-nav-logo" rel="noreferrer" target="_blank" className="navbar-brand mr-4" id="rp-nav-logo" style={{ }}>
                                            <img src={rpLogo} alt='' />
                                        </a>
                                    </div>
                                }
                            </div>

                            <div className={`${["sm", "md"].includes(this.props.deviceSize) ? "" : "d-flex" } justify-content-around align-items-center mx-auto`}
                                style={{
                                    fontWeight: 500,
                                    height: breakpointWidth ? 0 : "",
                                    gap: "20px",
                                    width: this.state.creatorCentricView ? "65%" : "78%",
                                    display: ["sm", "md"].includes(this.props.deviceSize) ? "none" : "'",
                                    marginTop: "4px",
                                }}
                            >

                                <Link to={`/Browse`} style={{ color: 'black' }}>
                                    <span>Shop</span>
                                </Link>
                                <a href="https://www.rockporch.com/CREATOR-RESOURCES/" rel="noreferrer" target="_blank" style={{ color: 'black' }}>
                                    <span>Resources</span>
                                </a>
                                <a href="#"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.collapseMenu();
                                        this.props.toggleShareModal(getReferralShareLink(this.props.loggedInUser?.userName))
                                    }}
                                    style={{ display: !!(this.props.loggedInUser) && this.props.loggedInUser.proStatusId === 1 ? "" : "none", color: 'black' }}
                                >
                                    <span className="brandOrange">Refer A Creator</span>
                                </a>
                                <Link to={`/Account/CreatorApp`} style={{ display: !!(this.props.loggedInUser) && this.props.loggedInUser.proStatusId !== 1 ? "" : "none", color: 'black' }}>
                                    <span className="brandOrange">Become A Creator</span>
                                </Link>
                               
                            </div>

                            <LoginMenu
                                setShowTopNav={this.props.setShowTopNav}
                                loggedInUser={this.props.loggedInUser}
                                setLoggedInUser={this.props.setLoggedInUser}
                                deviceSize={this.props.deviceSize}
                                collapseMenu={this.collapseMenu}
                                showMobileSearchBar={this.state.showMobileSearchBar}
                                style={{ zIndex: 1000 }}
                                embed={this.props.embed}
                                searchProp={this.state.searchProp}
                                resetSearchProp={this.resetSearchProp}
                                populateSearch={this.props.populateSearch}
                                updateSearchInput={this.updateSearchInput}
                                resetBrowseSearch={this.props.resetBrowseSearch}
                                handleResetBrowseSearch={this.props.handleResetBrowseSearch}
                                showDropdown={this.state.showDropdown}
                                dropdownOptions={this.state.dropdownOptions}
                                toggleShowDropdown={this.toggleShowDropdown}
                                handleDropdownOptionClick={this.handleMobileDropdownClick}
                                searchOptions={this.searchOptions}
                                setDropdownOptions={this.setDropdownOptions}
                                screenWidth={this.props.screenWidth}
                                creatorCentricView={this.state.creatorCentricView}
                                getReferralShareLink={getReferralShareLink}
                            />  
                        </Navbar>

                    </header>
                </div>

                {
                    !this.state.creatorCentricView ? (
                        <>
                            {/*style={*/}
                            {/*    {*/}
                            {/*        height: "53px", overflowX: "hidden", position: "sticky", top: ["/browse"].includes(this.state.pathname.toLowerCase()) && this.props.screenWidth < 756 ? 101 : this.props.screenWidth < 754 && this.state.pathname.toLowerCase().includes("/browse") ? 103 : !(this.props.loggedInUser) && this.state.pathname.toLowerCase().includes("/browse") && this.props.screenWidth < 991 ? 77 : this.state.pathname.toLowerCase().includes("/browse") && this.props.screenWidth < 991 ? 53 : this.state.pathname.toLowerCase().includes("/browse") && (!!(this.props.loggedInUser) || this.props.loggedInUser?.proStatusId !== 1) ? 90 : this.state.pathname.toLowerCase().includes("/browse") ? 87 : this.props.screenWidth < 991 && (!(this.props.loggedInUser) || this.props.loggedInUser?.proStatusId !== 1) ? 53 : 58, zIndex: 999, backgroundColor: "#fff", paddingTop: "5px !important", paddingBottom: "5px !important", display: this.state.collapsed === true ? "block" : "none"*/}
                            {/*    }*/}

                            <div className="form-group w-100 mx-0 my-0 py-0 d-flex justify-content-center"
                                
                                style={
                                    {
                                        borderBottom: this.state.creatorCentricView ? "" : "1px solid lightgray", zIndex: 99, height: this.state.pathname.toLowerCase().includes("/browse") ? "66px" : this.props.screenWidth < 991 ? "53px" : "58px", overflowX: "hidden", position: "sticky", top: this.props.screenWidth < 991 ? 59 : 63, backgroundColor: "#fff", paddingTop: "5px !important", paddingBottom: "5px !important", display: this.state.collapsed === true ? "block" : "none"
                                    }
                                }>
                                <div style={{ position: "relative" }}>
                                    <img src={process.env.PUBLIC_URL + '/images/search-icon.svg'} style={{ position: "absolute", top: "15px", left: ["sm"].includes(this.props.deviceSize) ? "20px" : ["md"].includes(this.props.deviceSize)  ? "25px" : "32px",zIndex: 2 }} alt="Search" />
                                </div>
                                <input className="form-control mr-sm-2 search-dropdown"
                                    type="search"
                                    placeholder="Search..."
                                    aria-label="Search"
                                    id="mobile-search-bar"
                                    onChange={this.handleSearchInputChange}
                                    value={this.state.searchInput}
                                    onClick={() => this.setState({ showDropdown: !this.state.showDropdown })}
                                    onKeyUp={(e) => this.submitSearch(e)}
                                    style={{
                                        display: "inline",
                                        backgroundColor: "#e9ecef",
                                        border: "none",
                                        paddingLeft: "40px",
                                        paddingBottom: "7px",
                                        borderRadius: "30px",
                                        width: this.props.screenWidth < 991 ?  "87%" : "70%",
                                        marginLeft: "1.5%",
                                        marginTop: "5px",
                                        marginBottom: "5px",
                                        zIndex: "1",
                                    }}
                                />
                                <button className="close mr-2 mt-2" id="search-close-button"
                                    style={{ position: "absolute", right: this.props.screenWidth < 991 ? "8%" : "16%", bottom: this.state.pathname.toLowerCase().includes("/browse") ? "34px" : this.props.screenWidth < 991 ? "21px" : "26px", zIndex: 1 }}
                                    onClick={() => {
                                        this.props.populateSearch("");
                                        this.props.handleResetBrowseSearch(true);
                                        this.setState({ searchInput: "", searchProp: "*&^reset", showMobileSearchBar: false })
                                    }}
                                >
                                    <CloseIcon />
                                </button>

                                <Link to={`/Browse?search=${this.state.searchInput}&searchBar=true`} id="searchSubmitMobile"></Link>
                            </div>
                            {(this.state.searchInput.length > 0 && this.state.dropdownOptions?.length > 0 && this.state.showDropdown) && (
                                <ul className="mt-2 mb-2 search-dropdown" style={dropdownStyle} >
                                    {this.state.dropdownOptions.map((option, index) => (
                                        <li
                                            key={option.label}
                                            onClick={() => this.handleDropdownOptionClick(option)}
                                            style={{ listStyleType: "none", fontFamily: "'Archivo', sans-serif" }}
                                        >
                                            <div style={{ fontWeight: "bold" }}>{`${this.state.searchInput}`}</div>
                                            <div style={{ color: "#6c757d" }}>{`in ${option.label}`}</div>
                                            {index < this.state.dropdownOptions.length - 1 && <hr style={{ marginTop: "5px", marginBottom: "4px" }} />}
                                        </li>
                                    ))}
                                </ul>
                            )}
                            <div style={{ display: this.state.pathname.toLowerCase() === "/browse" ? "" : "none", backgroundColor: "white", zIndex: 99, position: "sticky", top: this.props.screenWidth < 991 ? "117px" : "113px", height: this.props.screenWidth < 754 ?  "54px" : "30px" }}>
                                <BrandNavLowerMenu loggedInUser={this.props.loggedInUser} showBlogPostsFlag={this.state.showBlogPostsFlag} screenWidth={this.props.screenWidth} navHover={this.state.hover} deviceSize={this.props.deviceSize} categories={this.props.categories} />
                            </div>
                        </>
                    ) : <span></span>
                }

            </React.Fragment>
        );
    }
}


function mapStateToProps(storeState, componentProps) {
    let result = { feedLayout: storeState.feedLayoutReducer, fetchFollowing: storeState.modalReducer.fetchFollowing }
    return result;
}

export default withRouter(connect(mapStateToProps, { toggleShareModal, toggleAddProductModal, toggleCreateLockerModal, toggleFollowersFollowingModal, toggleFetchFollowing })(NavMenu));