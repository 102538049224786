import React, { useState } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

/**
 * 
 * @param {{editClick:Function,deleteClick:Function,product:object, toggleShareModal:Function}} props
 */
const LinkItemDropdown = (props) => {
    const [isOpen, setIsOpen] = useState(false);

    const getUrl = () => {
        let url = "https://";
        if (window.location.host.includes("app.rockp") && !!props.link.shareSubdomain) {
            url += props.link.shareSubdomain + ".rockporch.com/mlink/" + props.link.merchantId + "?userId=" + props.loggedInUser.id + "&redirectUrl=" + encodeURIComponent(props.link.referralUrl);
        } else {
            url += window.location.host + "/mlink/" + props.link.merchantId + "?userId=" + props.loggedInUser.id + "&redirectUrl=" + encodeURIComponent(props.link.referralUrl);
        }

        return url;
    }
    
    return (
        <Dropdown isOpen={isOpen} toggle={() => setIsOpen(!isOpen)} className={`${props.forMobile ? "mt-2" : ""}`}>
            <DropdownToggle caret style={props.style ?? {}}>
                Action
            </DropdownToggle>

            <DropdownMenu>
                <DropdownItem className="p-1" onClick={() => props.toggleShareModal(getUrl())}>
                    Share
                </DropdownItem>

                <DropdownItem onClick={props.editClick} className="p-1">
                    Edit
                </DropdownItem>

                <DropdownItem onClick={() => props.hideClick(props.link)} className="p-1">
                    Delete
                </DropdownItem>
            </DropdownMenu>

        </Dropdown>

    )
}


export default LinkItemDropdown;