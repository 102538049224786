import React from "react"

/**
 * 
 * @param {{proStatusId:Number}} props 
 */
const BlueTickIconXY = (props) => {
    if (props.proStatusId > 0) {
        return (
            <svg style={props.style} width={props.x} height={props.y} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.1663 9.99987L17.133 7.6832L17.4163 4.61654L14.408 3.9332L12.833 1.2832L9.99967 2.49987L7.16634 1.2832L5.59134 3.9332L2.58301 4.6082L2.86634 7.67487L0.833008 9.99987L2.86634 12.3165L2.58301 15.3915L5.59134 16.0749L7.16634 18.7249L9.99967 17.4999L12.833 18.7165L14.408 16.0665L17.4163 15.3832L17.133 12.3165L19.1663 9.99987ZM8.33301 14.1665L4.99967 10.8332L6.17467 9.6582L8.33301 11.8082L13.8247 6.31654L14.9997 7.49987L8.33301 14.1665Z" fill={props.proStatusId === 1 ? "#1676EE" : "#E55934"} />
            </svg>
        )
    }
    return null;
}

export default BlueTickIconXY;