import React from "react";

/**
 * 
 * @param {{width:Number,height:Number,fill:string}} props
 */
const SpreadsheetIcon = (props) => {
    return (
        <svg onClick={props.onClick || (() => { })} width={props.width || "20"} height={props.height || "20"} xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" viewBox="0 0 256 256" enableBackground="new 0 0 256 256" xmlSpace="preserve">
            <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
            <g>
                <g>
                    <g>
                        <path className={props.fillClass || ""} fill="#000000" d="M20.2,25.6c-3.5,1.2-6,3-7.6,5.5c-2.9,4.6-2.7-2-2.6,98l0.1,91.1l1.5,3.1c1.6,3.2,3.3,4.9,7,6.9l2.1,1.1h107.1h107.1l2.6-1.2c3.3-1.5,5.8-4,7.3-7.3l1.2-2.6v-92.1V36l-1-2.2c-1.7-3.7-3.6-5.7-7-7.3l-3.1-1.5l-106.1-0.1C27.7,24.7,22.5,24.7,20.2,25.6z M113.3,69v14.8H76.4H39.5V69V54.2h36.9h36.9V69z M216.7,69v14.8h-36.9h-36.9V69V54.2h36.9h36.9V69z M113.3,128.1v14.8H76.4H39.5v-14.8v-14.8h36.9h36.9V128.1z M216.7,128.1v14.8h-36.9h-36.9v-14.8v-14.8h36.9h36.9V128.1z M113.3,187.1v14.8H76.4H39.5v-14.8v-14.8h36.9h36.9V187.1z M216.7,187.1v14.8h-36.9h-36.9v-14.8v-14.8h36.9h36.9V187.1z" />
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default SpreadsheetIcon;