import React, { useState } from "react";
import { Popover, PopoverBody } from "reactstrap"

/**
 * 
 * @param {{
 * onClick:Function,
 * isLoading:boolean,
 * isDisabled:boolean?,
 * notification:string,
 * className:string,
 * onMouseEnter:Function,
 * message:{showMessage:boolean, messageText:string}
 * }} props
 * @description A wrapper for buttons that would need to indicate that an action is being performed i.e fetching and posting data.
 */
const ActionButton = (props) => {

    let [buttonId] = useState(`actionButton_` + Math.floor(Math.random() * 100).toString());

    function displayPrompt() {
        if (props?.message?.showMessage === true) {
            return (
                <Popover placement="bottom" isOpen={props.message.showMessage} target={buttonId}>
                    <PopoverBody>
                        {props.message.messageText}
                    </PopoverBody>
                </Popover>
            )
        }
    }

    if (props.isLoading === true) {
        return (
            <>
                <button className={props.className} onClick={props.onClick} style={props.style ? props.style : {}} id={buttonId} disabled>
                    <div className={`spinner-border spinner-border-sm`} role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                    {props.children}
                </button>
                {displayPrompt()}
            </>
        )
    }
    else if (props.isDisabled === true) {
        return (
            <>
                <button className={props.className} onClick={props.onClick} id={buttonId} style={props.style ? props.style : {}} onMouseEnter={() => props.onMouseEnter()} disabled>
                    {props.children}
                </button>
                {displayPrompt()}
            </>
        )
    }
    else if (!!(props.type)) {
        return (
            <>
                <button type={props.type} className={props.className} onClick={props.onClick} id={buttonId} style={props.style ? props.style : {}}>
                    {props.children}
                </button>
                {displayPrompt()}
            </>
        )
    }
    else {
        return (
            <>
                <button className={props.className} onClick={props.onClick} id={buttonId} style={props.style ? props.style : {} }>
                    {props.children}
                </button>
                {displayPrompt()}
            </>
        )
    }

}


export default ActionButton;