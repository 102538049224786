import React from "react"

const RatingIconHalf = (props) => {
    return (
        <svg height={props.height || "24"} width={props.width || "24"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ cursor: props.cursor ? props.cursor : "pointer"}} >
            <path opacity="0.3" fillRule="evenodd" clipRule="evenodd" d="M12 4.25977C12.1489 4.25965 12.3 4.29291 12.4426 4.36325C12.6398 4.46058 12.7994 4.62021 12.8967 4.81742L14.9389 8.95535L19.5054 9.6189C20.0519 9.69832 20.4306 10.2058 20.3512 10.7523C20.3196 10.9699 20.2171 11.1711 20.0596 11.3246L16.7553 14.5455L17.5353 19.0935C17.6287 19.6379 17.2631 20.1548 16.7188 20.2482C16.502 20.2854 16.279 20.2501 16.0844 20.1477L12 18.0004V4.25977Z" fill="#FFC107"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M12.0003 4.25977V18.0004L7.9159 20.1477C7.42705 20.4047 6.82242 20.2168 6.56542 19.7279C6.46308 19.5333 6.42777 19.3103 6.46495 19.0935L7.24499 14.5455L3.94066 11.3246C3.54518 10.9391 3.53708 10.306 3.92259 9.91049C4.07609 9.75301 4.27724 9.65052 4.49487 9.6189L9.06135 8.95535L11.1035 4.81742C11.2776 4.4647 11.6319 4.26004 12.0003 4.25977Z" fill="#FFC107"/>
        </svg>
    )
}

export default RatingIconHalf;