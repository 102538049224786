import React from "react";
import Modal from "components/Modal"

/**
 * 
 * @param {{coupon:string,confirmDelete:Function,cancelDelete:Function,showDeletePrompt:boolean}} props
 */
const DeleteProductConfirm = (props) => {
    return (
        <Modal isOpen={props.showDeletePrompt} toggleModal={props.cancelDelete}>
            <p> Are you sure you want to delete the product?</p>
            <button className="btn btn-secondary ml-2 mt-2">Cancel</button>
            <button className="btn btn-primary ml-2 mt-2" onClick={() => props.confirmDelete(props.coupon)}>Confirm</button>
        </Modal>
       )
}


export default DeleteProductConfirm;