import * as React from "react";

function SvgCheckIconBlack(props) {
  return (
    <svg
      width={18}
      height={18}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.59 4.058a.754.754 0 011.128-.1.933.933 0 01.091 1.234l-6.8 8.75a.754.754 0 01-1.14.087l-3.6-3.5a.933.933 0 01-.067-1.235.753.753 0 011.13-.073l2.988 2.906 6.27-8.069z"
        fill="#000"
        fillOpacity={0.8}
      />
    </svg>
  );
}

export default SvgCheckIconBlack;
