import React from "react";
import UserTileSmall from "components/UserTileSmall";
import PropTypes from "prop-types";
import InfiniteScroll from "react-infinite-scroll-component";
import ProfileInfoCardOnboarding from "components/ProfileInfoCardOnboarding";
import UserTileGeneral from "components/UserTileGeneral";
import { getRequest } from "sharedUtils/httpUtils";


class PeopleFeed extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            people: []
        }
    }

    componentDidMount() {
        this.setState({ people: this.props.people })
    }

    followOrUnfollow = async(person, personIndex) => {
        let url = person.isFollowing === true ? `api/Account/Unfollow?id=${person.id}` : `api/Account/Follow?id=${person.id}`;

        let people = [...this.state.people];
        people[personIndex].isFollowing = !this.state.people[personIndex].isFollowing;

        this.setState({ people });

        await getRequest(url);

    }

    mapPeople = () => {
        return this.props.people.map((person, i) => {
            return (
                <div className="user-follow" key={i}>
                    <div className="follow-info w-100 d-flex justify-content-between">
                        <UserTileGeneral user={person} subtitle={" "} showCheckMark={false} useLargeAvatars={true}/>
                        {this.displayFollowButton(person, i)}
                    </div>
                </div>
            )
        })
    }

    displayFollowButton = (person,personIndex) => {
        if (person.proStatusId === 1 && (this.props.loggedInUser == undefined || this.props.loggedInUser == null)) {
            let buttonText = person?.isFollowing === true ? "Unfollow -" : "Follow +";
            return (
                <button className="btn follow-btn d-flex justify-content-center"
                    onClick={() => this.props.toggleSignUpModal()}
                >
                    {buttonText}
                </button>
            )
        }
        if (person.proStatusId === 1) {
            let buttonText = person?.isFollowing === true ? "Unfollow -" : "Follow +";
            return (
                <button className="btn follow-btn d-flex justify-content-center"
                    onClick={() => this.followOrUnfollow(person, personIndex)}
                >
                    {buttonText}
                </button>
            )
        }
        else {
            return <span></span>
        }
        
    }

    render() {
        return (
            <div style={{ maxWidth: "600px", margin: "auto" }}>
                <InfiniteScroll
                    dataLength={this.props.people.length}
                    next={this.props.fetchMore}
                    hasMore={!this.props.finishedFetching}
                    style={{ overflow: "hidden" }}
                >
                    {this.mapPeople()}
                </InfiniteScroll>
            </div>
        )
    }
}

PeopleFeed.propTypes = {
    people: PropTypes.array,
    fetchMore: PropTypes.func,
    finishedFetching: PropTypes.bool,
    loggedInUser: PropTypes.object
}

export default PeopleFeed;