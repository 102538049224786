import React from "react";
import { FormGroup, Label, Input } from "reactstrap";


/**
 * 
 * @param {{name:string, 
 * title:string, 
 * inputType:string, 
 * onInputChange:Function, 
 * smallText:string?, 
 * value:any, 
 * inputStyle:object, 
 * placeholder:string,
 * id:string, 
 * formGroupClass:string
 * }} props
 */
const FormInputField = (props) => {
    let showSmallText = () => {
        if (props.smallText != null) {
            return <small className={props.smallTextStyle ? props.smallTextStyle : "form-text"}>{props.smallText}</small>
        }
    }

    return (
        <FormGroup className={props.formGroupClass}>
            <Label className="control-label" style={{ fontFamily: "Archivo" }}>
                <strong>{props.title}</strong>
            </Label>

            <Input
                className={props.className ? props.className : "form-control"}
                id={props.id || ""}
                name={props.name}
                type={props.inputType}
                onChange={(e) => props.onInputChange(e)}
                value={props.value || ""}
                style={props.inputStyle}
                placeholder={props.placeholder}
                readOnly={props.readOnly || ""}
            />
            {showSmallText()}
        </FormGroup>
    )
}

export default FormInputField;