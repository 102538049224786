import React, { Component } from "react";
import BlogFeedPostGridItem from "./BlogFeedPostGridItem";
import PostFeedPostGridItem from "./PostFeedPostGridItem";
import LockerFeedPostGridItem from "./LockerFeedPostGridItem";
import CurrentDealFeedPostGridItem from "./CurrentDealFeedPostGridItem";

class FeedPostGridItem extends Component {
    constructor(props) {
        super(props);

    }

    render() {
        const { post, loggedInUser, redirectUrl, deviceSize, showUserTile, togglePinned, toggleSignUpModal, savePost, showPins } = this.props;

        let content;

        if (post.type === 2) {
            content = (
                <LockerFeedPostGridItem
                    post={post}
                    isPictureAndProducts={true}
                    redirectUrl={redirectUrl}
                    deviceSize={deviceSize}
                    loggedInUser={loggedInUser}
                    setPost={this.props.setPost}
                    showPins={showPins}
                    togglePinned={togglePinned}
                    pinnedDate={post.pinnedDate}
                    showUserTile={showUserTile}
                    savePost={savePost}
                    toggleSignUpModal={toggleSignUpModal}
                    savedByUser={post.savedByUser}
                    brandEmbed={this.props.brandEmbed}
                    aboveTheFold={this.props.aboveTheFold}
                    disableRedirects={this.props.brandEmbed}
                />
            );
        } else if (post.type === 5) {
            content = (
                <CurrentDealFeedPostGridItem
                    loggedInUser={loggedInUser}
                    deal={post}
                    deviceSize={this.props.deviceSize}
                    screenWidth={this.props.screenWidth}
                    toggleProductShareModal={this.props.toggleProductShareModal}
                    aboveTheFold={this.props.aboveTheFold}
                />
            );
        } else if (post.type === 4) {
            content = (
                <BlogFeedPostGridItem
                    post={post}
                    isPictureAndProducts={false}
                    redirectUrl={redirectUrl}
                    deviceSize={deviceSize}
                    loggedInUser={loggedInUser}
                    setPost={this.props.setPost}
                    showPins={showPins}
                    togglePinned={togglePinned}
                    pinnedDate={post.pinnedDate}
                    showUserTile={showUserTile}
                    savePost={savePost}
                    toggleSignUpModal={toggleSignUpModal}
                    savedByUser={post.savedByUser}
                    brandEmbed={this.props.brandEmbed}
                    aboveTheFold={this.props.aboveTheFold}
                    disableRedirects={this.props.brandEmbed}
                />
            );
        } else {
            content = (
                <PostFeedPostGridItem
                    post={post}
                    isPictureAndProducts={false}
                    redirectUrl={redirectUrl}
                    deviceSize={deviceSize}
                    loggedInUser={loggedInUser}
                    setPost={this.props.setPost}
                    showPins={showPins}
                    togglePinned={togglePinned}
                    pinnedDate={post.pinnedDate}
                    showUserTile={showUserTile}
                    savePost={savePost}
                    toggleSignUpModal={toggleSignUpModal}
                    savedByUser={post.savedByUser}
                    brandEmbed={this.props.brandEmbed}
                    aboveTheFold={this.props.aboveTheFold}
                    disableRedirects={this.props.brandEmbed}
                />
            );
        }

        return (
            <div className="feed-post-grid-item">
                {content}
            </div>
        );
    }
}

export default FeedPostGridItem;
