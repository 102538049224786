import React, { useState } from "react";
import ImageWrapper from "components/ImageWrapper";
import { NavLink } from "react-router-dom";
import BlueTickIconXY from "components/icons/BlueTickIconXY";
import { connect } from "react-redux";


/**
 * 
 * @param {{user:object,subtitle:any, showCheckMark:boolean, truncateUsername:boolean, style: object}} props
 */
const UserTileGeneral = (props) => {

    let { subtitle, truncateUsername } = props;

    let displayUserName = (userName) => {
        if (truncateUsername === true) {
            return userName.length > 13 ? userName.substring(0, 13) + ".." : userName;
        }
        else {
            return userName;
        }
       
    }

    const disableLink = (e) => {
        if (!!(props.disableLink)) {
            e.preventDefault();
        }
    }
    //let imageUrl = "https://evolvedoutdoorsdev.blob.core.windows.net:443/users/100054/Profile/1fab44af-2b75-4c6a-b8ab-72a9d8ccd6e1.jpg";

    return (
        <div className={`users ${props.useLargeAvatars ? "user-tile-general-large" : "user-tile-general" } ${props.className || ""}`}>
            <div>
            <ImageWrapper
                    className="float-left"
                    alt="avatar"
                    src={props.user?.imageUrl || "/images/avatar2.svg"}
                    style={props.style || props.brandEmbed ? {cursor: "pointer"} : {}}
                    brandEmbed={props.brandEmbed}
                    size={props.size}
                    originalImageUrl={props.user?.imageUrl}
                    onClick={props.brandEmbed ? (e) => { e.preventDefault(); window.open(`/Profile/${props.user?.userName}?showBrandNav=true`, '_blank'); } : ()=>{} }
                />
            </div>
            {
                props.brandNavState || props.brandEmbed ?
                    <a href={`/Profile/${props.user?.userName}?showBrandNav=true`} target="_blank">
                        <h5>
                            <div>
                                <div style={{ display: "table", tableLayout: "fixed", width: "100%" }}>
                                    <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", display: "table-cell" }}>
                                        {props.user?.userName}
                                    </div>
                                </div> 
                                {
                                    (props?.showCheckMark === true && (props.user?.proStatusId === 1 || props.user?.prostatusId === 2)) ? (
                                        <span style={{ marginLeft: "1px" }}>
                                            <BlueTickIconXY proStatusId={props.user?.proStatusId} x={12} y={12} />
                                        </span>
                                    ) : null
                                }
                            </div>
                        </h5>
                    </a>
                    :
                    props.brandNav ?
                        <a href={`/Profile/${props.user?.userName}?showBrandNav=true`}>
                            <h5>
                                <div>
                                    <div style={{ display: "table", tableLayout: "fixed", width: "100%" }}>
                                        <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", display: "table-cell" }}>
                                            {props.user?.userName}
                                        </div>
                                    </div>
                                    {
                                        (props?.showCheckMark === true && (props.user?.proStatusId === 1 || props.user?.prostatusId === 2)) ? (
                                            <span style={{ marginLeft: "1px" }}>
                                                <BlueTickIconXY proStatusId={props.user?.proStatusId} x={12} y={12} />
                                            </span>
                                        ) : null
                                    }
                                </div>
                            </h5>
                        </a>
                        :
                    <NavLink to={`/Profile/${props.user?.userName}`} onClick={(e) => disableLink(e)}>
                        <h5>
                            <div>
                                <div style={{ display: "table", tableLayout: "fixed", width: "100%" }}>
                                        <div style={{ color: props.blogSmall ? "white" : "", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", display: "table-cell" }}>
                                        {props.user?.userName}
                                    </div>
                                </div>                                {
                                    (props?.showCheckMark === true && (props.user?.proStatusId === 1 || props.user?.prostatusId === 2)) ? (
                                        <span style={{ marginLeft: "1px" }}>
                                            <BlueTickIconXY proStatusId={props.user?.proStatusId} x={12} y={12} />
                                        </span>
                                    ) : null
                                }
                            </div>
                        </h5>
                    </NavLink>
            }
          
            <span>{(subtitle || props.user?.name || props.user?.displayName) ? (subtitle || props.user?.name || props.user?.displayName) + " " : "" }</span>
        </div>
    )
}

function mapStateToProps(storeState, ownProps) {
    return { brandNavState: storeState.brandNavReducer.brandNavState }
}


export default connect(mapStateToProps)(UserTileGeneral);