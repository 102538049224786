import React, { Component } from "react";
import ActionButton from "components/ActionButton";

class ProductsWithoutCategoriesExcel extends Component {
    render() {
        return (
            <>
                <section className="gray-bg pb-3 px-4">
                    <div className="d-flex flex-column align-items-center">
                        <p className="mb-0 mt-3" style={{ color: "red", display: this.props.error ? "" : "none" }}>There was an error downloading the spreadsheet. Try again.</p>
                        <ActionButton className="btn btn-primary mt-3" style={{ width: "215px" }}
                            onClick={this.props.getProductsWithoutCategoriesExcel}
                            isLoading={this.props.isLoading}
                        >
                            Download Spreadsheet
                        </ActionButton>
                    </div>
                </section>
            </>
        );
    }
}

export default ProductsWithoutCategoriesExcel;