import React from "react";
import AccountBanner from "../../components/AccountBanner"
import { getRequest } from "sharedUtils/httpUtils";
import CreateCommission from "./CreateCommission";
import UpdateAffiliateNetworkPaidDate from "./UpdateAffiliateNetworkPaidDate"

class CommissionAdmin extends React.Component {

    state = {
        selectedCase: 1,
        merchantCommissionOverridesLoading: false,
        userMerchantCommissionOverridesLoading: false,
        userCommissionOverrides: [],
        merchantCommissionOverrides: [],
        userMerchantCommissionOverrides: []
    }

    breadCrumbList = [{ link: '/Admin', text: 'Admin' }, { link: '/Admin/ManageCommissions', text: 'Manage Commissions' }];
    createCommissionBreadCrumbList = [
        ...this.breadCrumbList,
        { link: '/Admin/ManageCommissions', text: 'Create Commission', active: true }
    ];
    createAffiliateNetworkPaidDateBreadCrumbList = [
        ...this.breadCrumbList,
        { link: '/Admin/ManageCommissions', text: 'Update Affiliate Network Paid Date', active: true }
    ];
    //userMerchantCommissionOverridesBreadCrumbList = [
    //    ...this.breadCrumbList,
    //    { link: '/Admin/CommissionOverrides', text: 'User/Merchant Commission Overrides', active: true }
    //];

    componentDidMount = async () => {
        let scrollOptions = {
            left: 0,
            top: 0,
            behavior: 'auto'
        }
        window.scrollTo(scrollOptions);
    }

   

    //getUserMerchantCommissionOverrides = async () => {
    //    let getUserMerchantCommissionOverridesUrl = '/api/Merchant/GetUserMerchantCommissionOverrides';

    //    this.setState({ userMerchantCommissionOverridesLoading: true });
    //    let userMerchantCommissionOverrides = await getRequest(getUserMerchantCommissionOverridesUrl) ?? [];
    //    this.setState({ userMerchantCommissionOverrides: userMerchantCommissionOverrides, userMerchantCommissionOverridesLoading: false });
    //}

    displayToggleButtons = () => {
        let options = [
            {
                name: "Create Commission",
                case: 1,
                action: () => this.setState({ selectedCase: 1 })
            },
            {
                name: "Update Affiliate Network Paid Date",
                case: 2,
                action: () => this.setState({ selectedCase: 2 })
            },
        ];

        return options.map((toggle, i) => {
            return (
                <button key={i}
                    className={`btn ${this.state.selectedCase === toggle.case ? "btn-primary btn-lg" : ""}`}
                    onClick={toggle.action}
                >
                    {toggle.name}
                </button>
            )
        })
    }

    render() {
        return (
            <>
                <section>
                    <AccountBanner breadCrumbList={this.state.selectedCase === 1 ? this.createCommissionBreadCrumbList : this.state.selectedCase === 2 ? this.createAffiliateNetworkPaidDateBreadCrumbList :this.breadCrumbList}>
                        <h1 className="mb-4">Manage Commissions</h1>
                        <p className="mb-0">Create and manage commissions</p>
                    </AccountBanner>
                </section>
                <section className="filter-wrapper" >
                    <div className="container">
                        <div className="row py-2 mt-0">
                            <div className="col-12 d-flex justify-content-between">
                                <div className="filter-block d-flex">
                                    {this.displayToggleButtons()}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="gray-bg full-height pt-1">
                    <CreateCommission
                        display={this.state.selectedCase === 1}
                        loggedInUser={this.props.loggedInUser}
                    />
                    <UpdateAffiliateNetworkPaidDate
                        display={this.state.selectedCase === 2}
                        loggedInUser={this.props.loggedInUser}
                    />
                </section>
            </>
        )
    }
}

export default CommissionAdmin;