import React from "react";
import { Link } from "react-router-dom";
import { NavbarBrand } from 'reactstrap';
import UserTileGeneral from "components/UserTileGeneral";
import { handleCommentMarkup, replaceUrls } from "sharedUtils/markupUtils";

import RatingIcon from "components/icons/RatingIcon";
import RatingIconHalf from "components/icons/RatingIconHalf";
import RatingIconEmpty from "components/icons/RatingIconEmpty";
import StarRatingComponent from 'react-star-rating-component';

import authService from "../../components/api-authorization/AuthorizeService";
import { getRequest, postRequest } from "sharedUtils/httpUtils";
import { getProductShareLink } from "../../sharedUtils/shareLinkPrefixes"
import PostPageProduct from "../../components/PostPageProduct";
import SaleIcon from "../../components/icons/SaleIcon";
import SwipeableProducts from "./SwipeableProducts";

var jsonref = require('jsonref');

class ProductPageEmbedBrand extends React.Component {
    browse = false;

    state = {
        displayStarRatings: false,
        redirect: false,
        loadingPartial: true,
        loading: true,
        isFollowing: false,
        shareCode: "",
        linkedPostPage: 0,
        productInfo: {
            userMerchantProductId: 0,
            userDescription: "",
            merchantProductId: 0,
            userRating: 0,
            rating: 0,
            displayRating: 0,
            ratingCount: 0,
            description: "",
            referalLink: {},
            manufacturer: "",
            name: "",
            price: 0,
            likes: 0,
            likedByUser: false,
            savedByUser: false,
            imageUrl: "",

            user: {
                name: "",
                userName: "",
                imageUrl: "",
                tagLine: "",
                bio: "",
                proStatusId: 0
            },
            comments: [],
            linkedPosts: []
        },
        shareLink: "",
    }

    totalHeight = React.createRef();
    manufacturerRef = React.createRef();



    async componentDidMount() {
        const messageTotalHeight = () => {
            let totalHeight = this.totalHeight?.current?.offsetHeight;
            if (totalHeight > 100) {
                const messageObj = { shareCode: this.state.shareCode, height: totalHeight };
                const messageString = JSON.stringify(messageObj);

                window.parent.postMessage(messageString, "*");
            }
        }

        window.addEventListener('message', function (event) {
            messageTotalHeight();
        });

        this.props.setShowTopNav(false);

        let search = decodeURIComponent(this.props.location?.search);
        let params = new URLSearchParams(search);

        let pathname = decodeURIComponent(this.props.location?.pathname);

        let search2 = pathname.split('?');
        if (search == "" && search2.length > 0) {
            params = new URLSearchParams('?' + search2[1]);
        }

        let redirect = !this.props.isModal && (params.get('r') === '1' || params.get('r') == null);
        let browse = params.get('b') === '1';

        this.browse = browse;

        let shareCode = "";
        let productInfo = null;
        let loggedInUser = null;

        // feed
        if (this.props.match.params != undefined && this.props.match.params.product != undefined) {
            shareCode = this.props.match.params.product.shareCode;
            productInfo = this.props.match.params.product;
            productInfo.comments = [];
            productInfo.linkedPosts = [];
            loggedInUser = this.props.match.params.loggedInUser;
            // modal
        } else if (this.props.location.state != undefined && this.props.location.state.destinationState != undefined && this.props.location.state.destinationState.params &&
            this.props.location.state.destinationState.params.product != undefined) {
            shareCode = this.props.location.state.destinationState.params.product.shareCode;
            productInfo = this.props.location.state.destinationState.params.product;
            loggedInUser = this.props.location.state.destinationState.params.loggedInUser;
            // direct
        } else {
            shareCode = this.props.match.params.shareCode;
            loggedInUser = this.props.loggedInUser;
        }

        shareCode = decodeURIComponent(shareCode).split('?')[0];

        await this.setState({ shareCode });

        if (redirect) {
            await this.populateData(); // this will track the product view

            if (this.state.productInfo.integrated) {
                this.setState({ shareCode, redirect: true });
            }
            return;
        };

        let shareLink = await getProductShareLink(shareCode);

        await this.setState({ shareCode, productInfo, loggedInUser, shareLink, redirect }, () => {
            this.populateData();
        });
    }

    async componentDidUpdate(prevProps, prevState) {
        if (this.props.match.params.shareCode != prevProps.match.params.shareCode) {
            this.setState({ loading: true });
            let isAuthenticated = this.state.loggedInUser ? true : await authService.isAuthenticated();

            let shareCode = this.props.match.params.shareCode;

            let product = this.props.location.state.destinationState.params.product;

            if (product == null || product == undefined) {

                product = await this.fetchProduct(shareCode);

                if (product == null || product == undefined) {
                    this.props.history.push("/NotFound");
                    return -1;
                }
                else {
                    await this.getRating(isAuthenticated, product.userMerchantProductId);


                }
            }

            this.setState({ productInfo: product, loading: false });
        }
    }

    fetchProduct = async (shareCode) => {
        let isAuthenticated = this.state.loggedInUser ? true : await authService.isAuthenticated();
        let browse = this.browse ? "&browse=true" : "";
        let baseUrl = isAuthenticated === true ? `/Products/GetProductAuthorized` : `/Products/Get`;
        let productUrl = `${baseUrl}?shareCode=${shareCode}&referrer=${document.referrer}${browse}`;

        let product = await getRequest(productUrl);


        return product;

    }

    async populateData() {
        var isAuthenticated = this.state.loggedInUser ? true : await authService.isAuthenticated();
        let browse = this.browse ? "&browse=true" : "";

        let baseUrl = isAuthenticated === true ? `/Products/GetProductAuthorized` : `/Products/Get`;
        let shareCode = this.props.match.params.shareCode;
        let productUrl = `${baseUrl}?shareCode=${shareCode}&referrer=${document.referrer}${browse}`;

        var data;
        if (this.state.productInfo && this.state.productInfo.userMerchantProductId && this.state.productInfo.userMerchantProductId !== 0) {
            data = this.state.productInfo;

            let trackBaseUrl = isAuthenticated ? `/Products/TrackProductViewAuthorized` : `/Products/TrackProductView`
            let trackProductViewUrl = `${trackBaseUrl}?userMerchantProductId=${data.userMerchantProductId}&referrer=${document.referrer}${browse}`;
            getRequest(trackProductViewUrl);
        } else {
            data = await getRequest(productUrl);

            if (data == null) {
                data = await getRequest(`/Products/Get?shareCode=${shareCode}&referrer=${document.referrer}${browse}`)
            }
        }

        if (data === undefined || data === null) {
            this.setState({ productInfo: null });
            this.props.history.push("/NotFound");
            return -1;
        }

        await this.setState({ productInfo: data, loading: false });

        if (data.ratingAverage == null || data.ratingAverage === 0) {
            this.getRating(isAuthenticated, data.userMerchantProductId);
        }

        this.setState({ loadingPartial: false });
    }

    getRating = async (isAuthenticated, userMerchantProductId) => {
        let url = isAuthenticated ? `/Products/GetRatingAuthorized/${userMerchantProductId}` : `/Products/GetRating/${userMerchantProductId}`;

        var rating = await getRequest(url);
        if (rating == null) {
            rating = await getRequest(`/Products/GetRating/${userMerchantProductId}`);
        }
        let productInfo = { ...this.state.productInfo };
        productInfo.userRating = rating?.userRating;
        productInfo.rating = rating?.average;
        productInfo.ratingCount = rating?.count;
        productInfo.displayRating = rating?.average;

        this.setState({ productInfo });
    }

    redirectToProductPage = () => {
        let url = `/prd/${this.state.shareCode}?r=0`;
        window.open(url);
    }

    dummyProductData = [{
        description: "",
        imageUrl: "~/images/Logo.svg",
        merchantProductId: 3788,
        name: "Name",
        price: 12,
        originalPrice: 12,
        salePrice: 12,
        sale: false,
        shareCode: "a1",
        url: "https://www.rockporch.com",
        user: { user: "noone" },
        comment: "",
        userDescription: "",
        userMerchantProductId: 1,
        manufacturer: "No one",
        merchant: "No one",
        integrated: true,
        ordinal: 0,
        id: 1,
    }];

    render() {
        const product = this.state.productInfo;

        const productInFeedStyle = {
            paddingBottom: this.totalHeight?.current?.offsetWidth <= 125 ? "77%" :
                this.totalHeight?.current?.offsetWidth <= 125 ? "82.17%" :
                    this.totalHeight?.current?.offsetWidth <= 140 ? "84.97%" :
                        this.totalHeight?.current?.offsetWidth <= 190 ? "88.45%" :
                            "92.04%",
            position: "relative", top: "10%"
        }

        if (this.state.loading) {
            return (
                <></>);
        }


        return (
            <div className="container product-grid px-0" style={{ maxWidth: "500px", width: this.totalHeight?.current?.offsetWidth > 300 ? "94%" : this.totalHeight?.current?.offsetWidth > 200 ? "88%" : "82%", marginTop: "10px" }} ref={this.totalHeight}>
                <div className="row mx-0 mt-0 product-image-gallery" style={{ position: "relative" }}>
                    <div key={0} className="col-12" style={{ padding: "1px", border: "solid", borderWidth: "thin", borderRadius: "20px" }}>
                        <div className="product-in-feed" style={productInFeedStyle}>
                            <PostPageProduct
                                product={product}
                                loggedInUser={this.props.loggedInUser}
                                brandEmbed={true}
                                style={{ padding: 0 }}
                                saleIconNever={true}
                            />
                        </div>
                        <div>
                            <SwipeableProducts brand={true} redirect={this.redirectToProductPage} style={{ paddingBottom: 0, visibility: "hidden" }} productItems={this.dummyProductData} product={true} />
                        </div>

                        {this.state.displayStarRatings ? 
                            <div className="d-flex flex-wrap justify-content-between align-items-center py-0 px-1" style={{ position: "absolute", bottom: "48px", background: "white", borderRadius: "0px 0px 15px 15px" }}>
                                <div className="d-flex align-items-end">
                                    <div className="ml-1" style={{ marginBottom: "-16px" }}>
                                        <StarRatingComponent
                                            name="starRating"
                                            starCount={5}
                                            value={product.rating}
                                            renderStarIcon={(index, value) => {
                                                if (index <= value) {
                                                    return (<span className="ratingIcon" key={index}><RatingIcon height={this.totalHeight?.current?.offsetWidth < 150 ? 12 : 16} width={this.totalHeight?.current?.offsetWidth < 150 ? 12 : 16} /></span>);
                                                } else {
                                                    return (<span className="ratingIcon" key={index}><RatingIconEmpty height={this.totalHeight?.current?.offsetWidth < 150 ? 12 : 16} width={this.totalHeight?.current?.offsetWidth < 150 ? 12 : 16} /></span>);
                                                }
                                            }}
                                            renderStarIconHalf={(index, value) => {
                                                return (<span className="ratingIcon" key={index}><RatingIconHalf height={this.totalHeight?.current?.offsetWidth < 150 ? 12 : 16} width={this.totalHeight?.current?.offsetWidth < 150 ? 12 : 16} /></span>);
                                            }}
                                            onStarClick={() => {
                                                if (product.ratingCount > 0) {
                                                    this.redirectToProductPage();
                                                }
                                            }} />
                                    </div>
                                    <span style={{ color: "#e55934", marginLeft: "3px", fontSize: "12px" }}>
                                        <Link to="/" onClick={e => {
                                            e.preventDefault();
                                            this.redirectToProductPage();
                                        }}>
                                            {product.ratingCount > 0 ? `(${product.ratingCount})` : null}
                                        </Link>
                                    </span>
                                </div>
                            </div>
                            : <></>
                        }
                       
                        <div className="d-flex flex-wrap justify-content-between align-items-center py-0 px-1" style={{ background: "white", borderRadius: "0px 0px 15px 15px" }}>
                            <div className="users user-tile-general-brand-embed py-1 ml-2" style={{ whiteSpace: "nowrap", minHeight: "25px" }}>
                                <h5 style={{ marginBottom: "0px" }}><div className="position-relative" style={{ maxWidth: "1px" }}><span ref={this.manufacturerRef}>{product.manufacturer?.toLowerCase().includes("rcktemp") ? product.merchant : product.manufacturer}</span>{product.sale && this.totalHeight?.current?.offsetWidth - this.manufacturerRef?.current?.offsetWidth > 43 ? <SaleIcon style={{ marginLeft: "3px" }} /> : <></>}</div></h5>
                            </div>
                            <div className="users user-tile-general py-1 ml-2" style={{ whiteSpace: "nowrap" }}>
                                <h5 style={{ marginBottom: "0px" }}><div className="d-flex" style={{ maxWidth: "1px" }}><div className="mr-1">{product.sale && !!(product.salePrice) ? "$" + product.salePrice.toFixed(2) : <></>}</div><div className={`${product.sale && !!(product.salePrice) ? "on-sale-price" : ""}`}>${product.sale && !!(product.salePrice) && !!(product.originalPrice) ? product.originalPrice.toFixed(2) : product.price.toFixed(2)}</div></div></h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ProductPageEmbedBrand;