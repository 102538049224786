import React from "react";
import Modal from "../../components/Modal";
import AddProductForm from "components/AddProduct/AddProductForm";
import LookupProductUrlForm from "components/AddProduct/LookupProductUrlForm";
import { connect } from "react-redux";
import { toggleAddProductModal, toggleProductPageModal } from "stateManagement/reducers/modalReducer";
import ShareBlock from "components/Share/ShareBlock";
import { getRequest } from "sharedUtils/httpUtils";
import { Link, Redirect } from "react-router-dom";
import ProductPage from "views/Product/ProductPage";
import { withRouter } from "react-router";

class AddProductModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            stage: 1,
            productUrl: "",
            productDetails: {
                name: ""
            },
            isLoading: false,
            productShareCode: "",
            success: false,
            productNotFound: false,
            productNotFoundMessage:"",
            addedProductDetails: {},
        }
    }

    componentWillUnmount() {
        document.querySelector("body").style.overflow = "";
    }

    async componentDidMount() {
       
        if (this.props.isModal === false) {
            this.props.setShowTopNav(false);
            if(window.location.search.length>0)
                //if (this.props.match.params?.productUrl)
            {
                let productUrl = window.location.search.split("=")[1];
                //let decodedUrl = decodeURIComponent(this.props.match.params.productUrl);
                let decodedUrl = decodeURIComponent(productUrl);
                await this.setState({ productUrl: decodedUrl });
                this.advanceStage();
            }
            //this.setState({ productUrl: document.referrer + document. });
        }
    } 
   

    advanceStage = async () => {
        let newStage = this.state.stage + 1;
        let data = await this.fetchProductInformation(this.state.productUrl);
        if (this.state.isLoading && (data != null || data != undefined)) {
            this.setState({ productDetails: data, stage: newStage, isLoading: false, productNotFound:false })
        } 
    }

    closeWindow = () => {
        window.close()
        //window.top.postMessage("productAdded", "*");
    }


    finishedCreating = async (result) => {
        
        if (result != undefined || result != null) {
            let fullProduct = await getRequest(`/Products/GetProductAuthorized?shareCode=${result.shareCode}`);
            fullProduct.manufacturer = this.state.productDetails?.manufacturer;

            this.setState({ productShareCode: result.shareCode, success: true, addedProductDetails: fullProduct, productUrl: "", stage: this.props.isModal === true ? 1 : 2 });
                       
            if (this.props.isModal === true) {
                this.toggleModal();
                this.props.history.push('/Account/Products')
            //    this.props.toggleProductPageModal(fullProduct, this.props.loggedInUser);
            }

            // use local storage if we are executing from another tab
            if ('localStorage' in window && !this.props?.isModal) {
                localStorage.setItem('productAdded', new Date);
            } else {
                let event = new Event("_productAddedEvent");
                document.dispatchEvent(event);
            }
        }
    }

    fetchProductInformation = async (url) => {
        let localUrl = url;
        this.setState({ isLoading: true });
        let encoded = encodeURIComponent(url.trim());
        let data = await getRequest(`/Products/GetProductByUrl?url=${encoded}`, true);
        // this request was cancelled so we can ignore
        if (localUrl !== this.state.productUrl) {
            return null;
        }
        if (this.state.isLoading && data.errorMessage) {
            this.setState({ productNotFound: true, productNotFoundMessage: data.errorMessage === "no message" ? "" : data.errorMessage, isLoading: false });
            return null;
        }
        return data;
    }

    inputUrl = (e) => {
        this.setState({ productUrl: e.target.value })
    }

    onKeypress = (e) => {
        if (e.key === 'Enter') this.advanceStage();
    }

    toggleModal = () => {
        document.querySelector("body").style.overflow = "";
        this.setState({ stage: 1, productUrl: "" });
        this.props.toggleAddProductModal();
        this.setState({ productNotFound: false, isLoading: false })
        //let newState = !this.state.showModal;
        //this.setState({ showModal: newState, stage: 1 });
    }
    

    renderForm = () => {
        if (this.state.stage === 1) {
            return (
                <LookupProductUrlForm
                    customStyle={{ maxWidth: "100%", backgroundColor: "white" }}
                    shareBlockStyle={{ backgroundColor: "transparent", border: "0px transparent", padding: "0px" }}
                    advance={this.advanceStage}
                    productUrl={this.state.productUrl}
                    handleInput={this.inputUrl}
                    onKeypress={this.onKeypress}
                    isLoading={this.state.isLoading}
                    productNotFound={this.state.productNotFound}
                    productNotFoundMessage={this.state.productNotFoundMessage}
                    isModal={this.props?.isModal}
                    toggle={this.toggleModal}
                    url={this.state.productUrl}
                />
            )
        } else if (this.state.stage === 2) {
            if (this.props.isModal === true) {
                document.querySelector("body").style.overflow = "hidden";    
            }
                    
            return (
                <AddProductForm product={this.state.productDetails}
                    advance={this.finishedCreating}
                    cancel={this.props.isModal === false ? this.closeWindow : this.toggleModal}
                    loggedInUser={this.props.loggedInUser}
                    isModal={this.props.isModal}
                    success={this.state.success}
                    closeWindow={this.closeWindow}
                />
            )
        }
        /*else if (this.state.stage === 3) {
           

            return <ShareBlock customStyle={{ maxWidth: "100%", backgroundColor: "white" }}
                shareBlockStyle={{ border: "none", backgroundColor: "transparent" }}
                shareCode={`https://rock.prch/product/${this.state.productShareCode}`}
            />
        }*/
    }


    render() {
        if (this.props?.isModal === false) {
            return (
                <div>
                    {
                        this.state.finishedCreating === true ? <h1 id="productAdded" style={{ display: "hidden" }}></h1> : <span></span>}
                    {this.renderForm()}
                </div>
            )
        } else {
            if (this.props.showModal) {
                document.querySelector("body").style.overflow = "hidden";
            } else {
                document.querySelector("body").style.overflow = "";
            }

            return (
                <div>
                    {/*<button onClick={this.props.toggleAddProductModal}>Show Modal</button>*/}
                    <Modal
                        isOpen={this.props.showModal}
                        toggleModal={this.toggleModal}
                        modalUseCase={this.state.stage > 1 ? "product" : ""}
                        showHeader={false}
                        modalDialogCustomClass={this.state.stage === 2 ? "modal-dialog-lg modal-dialog-md" : ""}
                        modalBodyCustomClass={this.state.stage === 2 ? "modal-body pt-0 px-0 mt-3 mt-md-0" : ""}
                    >
                        {this.renderForm()}
                    </Modal>

                </div>
            )
        }
    }
}

function mapStateToProps(storeState, ownProps) {
    return { showModal: storeState.modalReducer.showAddProductModal }
}

export default withRouter(connect(mapStateToProps, { toggleAddProductModal, toggleProductPageModal })(AddProductModal));