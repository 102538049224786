import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";
import InformationIcon from "components/icons/InformationIcon";
import { UncontrolledPopover, PopoverBody } from "reactstrap";
import _ from "lodash";
import { asDate, convertToLocaleDateTimeString, convertToNumericMonthAndDayAndYear, convertToNumericMonthAndDay, convertToAbbreviatedMonthAndDay } from "sharedUtils/timeUtils";
import LoadSpinner from "./LoadSpinner";
import Modal from 'react-modal';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { getRequest } from "sharedUtils/httpUtils"; 
import ActionButton from "components/ActionButton";
import TextField from '@material-ui/core/TextField';
import toast, { Toaster } from 'react-hot-toast';
import ProductRowMobile from "views/Account/ProductRowMobile";
import ThreeDotsHorizontalIcon from "components/icons/ThreeDotsHorizontalIcon";
import { number } from "prop-types";
import { Share } from "../icons";

const { DateTime } = require("luxon");


class CommissionsChart extends Component {
    constructor(props) {
        super(props);
       
        this.state = {
            openAccordianCommissionIds: [],
            copyCheck: false,
            reassignLoading: false,
            userBlank: false,
            show: false,
            modalCommissionId: 0,
            modalUserId: 0,
            loading: false,
            value: [],
            userOptions: [],
            reassignCreateMessage: { showMessage: false, messageText: "Commission reassigning..." },           
            series: [
                {
                    name: 'Estimated Commissions',
                    type: 'bar',
                    data: []
                }
            ],
            options: {
                chart: {
                    height: 350,
                    type: 'bar',
                    toolbar: {
                        show: false
                    },
                    zoom: {
                        enabled: false
                    }
                },
                dataLabels: {
                    enabled: false
                },
                markers: {
                    size: [2],
                },
                stroke: {
                    width: [2]
                },
                labels: [],
                xaxis: {
                    type: 'datetime'
                },
                yaxis: [
                    {
                        seriesName: 'Estimated Commissions',
                        title: {
                            text: '$',
                        },
                        labels: {
                            // Formats the number displayed on the y-axis label
                            formatter: function (val) {
                                return val.toFixed(2);
                            }
                        }
                    }
                ],
            },
        };
    }

    componentDidMount = async () => {
        this.props.getCommissions(this.props.startDate, this.props.endDate);
    }   

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.startDate !== this.props.startDate || prevProps.endDate !== this.props.endDate) {
            if (this.props.startDate <= this.props.endDate) {
                this.setState({ dateError: false });
                this.props.getCommissions(this.props.startDate, this.props.endDate);
            } else {
                this.setState({ dateError: true });
            }
        }
        if (!_.isEqual(prevProps.openAccordianCommissionIds, this.props.openAccordianCommissionIds)) {
            this.forceUpdate();
        }

        if (prevProps.display != this.props.display && this.props.display) {
            this.props.getCommissions(this.props.startDate, this.props.endDate);
        }
        if (prevProps.userChanged != null && prevProps.userChanged != this.props.userChanged && this.props.userChanged)
            if (prevProps.loggedInUser !== this.props.loggedInUser) {
                this.props.getCommissions(this.props.startDate, this.props.endDate);
            }
        if (prevProps.aggregateChanged != null && prevProps.aggregateChanged !== this.props.aggregateChanged && this.props.display) {
            this.props.getCommissions(this.props.startDate, this.props.endDate);
        }
        if (prevState.show === true && this.state.show === false) {
            this.setState({ userBlank: false });
        }
        if (prevProps.date !== this.props.date && this.props.date != null && this.props.date != undefined) {
            const option = this.state.selectOptions.options.filter(option => option.value === this.props.date)[0];
            this.props.getCommissions(this.props.startDate, this.props.endDate);

            const selectOptions = { ...this.state.selectOptions };
            selectOptions.currentValue = option;

            this.setState({ selectOptions });
        }
    }
   
    commissionReassignModal = (commissionId) => {
        this.setState({ modalCommissionId: commissionId, show: true });
    }

    toggleCommissionId = async (commissionId) => {

        const openAccordianCommissionIds = this.state.openAccordianCommissionIds;
        const index = openAccordianCommissionIds.findIndex(obj => obj.commissionId === commissionId);

        if (index === -1) {
            // If commissionId is not found, add a new object with show set to true
            this.setState({ openAccordianCommissionIds: [...openAccordianCommissionIds, { commissionId, show: true, loading: true, commissionDetailItems: [] }] });
            var commissionDetailItems = await this.props.fetchCommissionDetailItemsByCommissionId(commissionId);
            this.setState((prevState) => ({
                openAccordianCommissionIds: prevState.openAccordianCommissionIds.map(obj =>
                    obj.commissionId === commissionId ? { ...obj, commissionDetailItems, loading: false } : obj
                )
            }));
        } else {
            // If commissionId is found
            const updatedCommissionIds = [...openAccordianCommissionIds];
            const existingItem = updatedCommissionIds[index];

            if (existingItem.hasOwnProperty('show')) {
                existingItem.show = !existingItem.show;

            } else {
                existingItem.show = true;
            }

            this.setState({ openAccordianCommissionIds: updatedCommissionIds });
        }
    };

    addUserMerchantProductIfNotAddedAlreadyAndGetLink = async (product, commissionId, userId) => {
        const url = 'Products/AddUserMerchantProductForAnalyticsCommissionDetailItem?merchantProductId=' + product.merchantProductId + '&userId=' + userId;

        let result = await getRequest(url);
        if (!!(result)) {
            product.shareCode = result.shareCode;
        } else {
            this.getLinkError();
        }

        this.setState(prevState => {
            const updatedOpenAccordianCommissionIds = prevState.openAccordianCommissionIds.map(commission => {
                if (commission.commissionId === commissionId) {
                    const updatedCommissionDetailItems = commission.commissionDetailItems.map(detailItem => {
                        if (detailItem.merchantProductId === product.merchantProductId) {
                            detailItem.productInfo = product;
                        }
                        return detailItem;
                    });

                    commission.commissionDetailItems = updatedCommissionDetailItems;
                }
                return commission;
            });

            return {
                openAccordianCommissionIds: updatedOpenAccordianCommissionIds
            };
        });

        this.handleGetLink("https://" + window.location.host + "/prdlink/" + result.shareCode);
    };


    displayProductsMobile = (product, commissionId, userId, i, lastItem) => {
        return (
            <div key={i} className={`text-left ${lastItem ? "" : "mb-2"} ${lastItem ? "" : "commissions-chart-detail"}`} style={{ maxWidth: this.props.deviceSize === "sm" && this.props.screenWidth < 500 ? "98vw" : this.props.deviceSize === "sm" ? "95vw" : "", margin: this.props.deviceSize === "sm" && this.props.screenWidth > 499 ? "0 auto" : "" }}>
                <ProductRowMobile
                    handleGetLink={this.handleGetLink}
                    getLinkError={this.getLinkError}
                    product={product}
                    productKey={i}
                    key={i}
                    analytics={true}
                    deviceSize={this.props.deviceSize}
                    addUserMerchantProductIfNotAddedAlreadyAndGetLink={this.addUserMerchantProductIfNotAddedAlreadyAndGetLink}
                    commissionId={commissionId}
                    userId={userId}
                />
            </div>
        )
    }

    handleGetLink = (shareLink) => {
        navigator.clipboard.writeText(shareLink);

        toast.success(
            <div className="p-2">
                <h4 className="text-center">Commissionable Link Copied to Clipboard!</h4>
                <p className="text-center mx-auto px-2 pt-2 pb-">Your commissionable link has been copied to clipboard and is ready to be shared</p>
            </div>,
            {
                duration: 3000,
                position: 'top-center',
                icon: null,
            }
        );
    }

    getLinkError = () => {
        toast.error(
            <div className="p-2">
                <h4 className="text-center">Product currently unavailable</h4>
            </div>,
            {
                duration: 3000,
                position: 'top-center',
                icon: null,
            }
        );
    }

    renderCommissionDetailItems = (commissionId) => {
        const cdiObject = this.state.openAccordianCommissionIds.find(item => item.commissionId === commissionId);

        if (!cdiObject || !cdiObject.hasOwnProperty("commissionDetailItems")) {
            return <div className="mt-3" style={{ textAlign: this.props.deviceSize === "sm" ? "" : "left", margin: "0 20px", borderTop: "1px solid lightgray", borderBottom: "1px solid lightgray", padding: "10px 0" }}>Additional Product Information Unavailable</div>;
        }

        const commissionDetailItems = cdiObject.commissionDetailItems;
        const noProductInfo = commissionDetailItems.some(item => !(item.productInfo));
        const numberOfItemsWithProductInfo = commissionDetailItems.filter(item => !!(item.productInfo)).length;

        if (commissionDetailItems.length > 0) {
            return (
                <div>
                    {commissionDetailItems.map((item, i) => {
                        if (!!(item.productInfo)) {
                            return this.displayProductsMobile(item.productInfo, item.commissionId, item.userId, i, i === numberOfItemsWithProductInfo - 1 && !noProductInfo);                           
                        } else {
                            return <></>;
                        }
                    })}
                    {
                        noProductInfo ?
                            <div className={`${numberOfItemsWithProductInfo > 0 ? "mt-3" : ""}`} style={{ textAlign: this.props.deviceSize === "sm" ? "" : "left", margin: "0 12px", padding: "10px 0" }}>{noProductInfo}Additional Product Information Unavailable</div>
                            :
                            <>{ noProductInfo}</>
                    }
                </div>
            );
        } else {
            return <div className="mt-3" style={{ textAlign: this.props.deviceSize === "sm" ? "" : "left", margin: "0 12px", padding: "10px 0" }}>Additional Product Information Unavailable</div>;
        }
    };




    displayTableRows = () => {
        if (this.props.commissions.length === 0) {
            return (
                <tr key={0}>
                    <td colSpan="6" style={{ textAlign: "center"}}>No data for this time frame</td>
                </tr>
            );
        }

        if (this.props.aggregateChanged != null && this.props.aggregateChanged) {
            return this.props.commissions.map((data, i) => {
                const mayDisplay = data.status === "Paid" ? true : false;
                return (
                    <tr key={data.commissionId}>
                        <td style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                            <span>{convertToNumericMonthAndDayAndYear(new Date(data.createdDate), false)}</span>
                        </td>
                        <td style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                            <span>{data.merchantName}</span>
                        </td>
                        <td style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                            <span>${data.purchaseAmount.toFixed(2)}</span>
                        </td>
                        <td style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                            <span>${data.returnAmount.toFixed(2)}</span>
                        </td>
                        <td style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                            <span>${data.qualifiedCommissionAmount.toFixed(2)}</span>
                        </td>
                        <td style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                            <span>{data.status}</span>
                        </td>
                        <td style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                            <span>{data.username}</span>
                        </td>
                        <td style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                            <span>
                                <button className="btn"
                                    disabled={mayDisplay}
                                    onClick={(e) => this.commissionReassignModal(data.commissionId)}
                                    style={{ border: "none", backgroundColor: "#e55934", color: "#fff", borderRadius: "3px"}}
                                >Reassign</button>
                            </span>
                        </td>
                    </tr>);
            });
        }

        return this.props.commissions.map((data, i) => {
            return (
                <React.Fragment key={data.commissionId}>
                    <tr key={data.commissionId} onClick={() => this.toggleCommissionId(data.commissionId)} style={{ cursor: "pointer" }}>
                        <td style={{ paddingLeft: "4px", paddingRight: "4px", minWidth: "67px" }}>
                            <span className={`brand-nav-dropdown-toggle position-relative mr-2`} style={{ top: this.state.openAccordianCommissionIds.some(item => item.commissionId === data.commissionId && item.show) ? "9px" : "-4px", left: this.state.openAccordianCommissionIds.some(item => item.commissionId === data.commissionId && item.show) ? "6px" : "", }}>
                                <span className="brand-nav-chevron-iconset brand-nav-chevron-span" style={{ transform: this.state.openAccordianCommissionIds.some(item => item.commissionId === data.commissionId && item.show) ? "rotate(180deg)" : "" }}>
                                    <svg aria-hidden="true" className="brand-nav-chevron-svg" fill="rgb(119,119,119)" version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 20 20">
                                        <title>Expand</title>
                                        <path className={`brand-nav-lower-menu-hover`} d="M5.293 9.707l6 6c0.391 0.391 1.024 0.391 1.414 0l6-6c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0l-5.293 5.293-5.293-5.293c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414z"></path>
                                    </svg>
                                </span>
                            </span>
                            <span>{convertToNumericMonthAndDayAndYear(new Date(data.createdDate), false)}</span>
                        </td>
                        <td style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                            <span>{data.merchantName}</span>
                        </td>
                        <td style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                            <span>${data.purchaseAmount.toFixed(2)}</span>
                        </td>
                        <td style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                            <span>${data.returnAmount.toFixed(2)}</span>
                        </td>
                        <td style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                            <span>${data.qualifiedCommissionAmount.toFixed(2)}</span>
                        </td>

                        <td style={{ display: this.props.deviceSize === "sm" ? "none" : "",  paddingLeft: "4px", paddingRight: "4px" }}>
                            <span>{data.status}</span>
                        </td>
                    </tr>
                    <tr style={{ display: this.state.openAccordianCommissionIds.some(item => item.commissionId === data.commissionId && item.show) ? "" : "none" }}>
                        <td className="pl-2" style={{ height: "75px", backgroundColor: "white" }} colspan="7">
                            <div className="commissions-eligible-status">
                                <div className={`d-flex justify-content-around commissions-chart-detail mb-3`} style={{ fontSize: "15px", textAlign: "left", margin: "0 12px", padding: "10px 0", paddingTop: this.state.openAccordianCommissionIds.filter(item => item.commissionId === data.commissionId)[0]?.loading ? "35px" : "10px" }}>
                                    <div><strong>Eligible Date</strong>: {convertToNumericMonthAndDayAndYear(new Date(data.createdDate), false)}</div>
                                    <div><strong>Status</strong>: {data.status}</div>
                                </div>
                            </div>
                            {
                                this.state.openAccordianCommissionIds
                                    .filter(item => item.commissionId === data.commissionId)[0]?.loading  ?

                                    <LoadSpinner className="h-75 pb-3" innerClassName={"justify-content-center align-items-center"} style={{ maxWidth: this.props.deviceSize === "sm" ? "100vw" : "" }} />
                                    :
                                    this.renderCommissionDetailItems(data.commissionId)
                            }
                        </td>
                    </tr>
                </React.Fragment>
            );
        });
    }
    
    displayTableHeader = () => {
        let headers = this.props.aggregateChanged != null && this.props.aggregateChanged ? ["Date", "Brand", "Sales Amount", "Returns", "Estimated Commission", "Status", "Username"] : this.props.deviceSize === "sm" ? ["Date", "Brand", "Sales Amount", "Returns", "Est. Comm.", "Status"] : ["Date", "Brand", "Sales Amount", "Returns", "Estimated Commission", "Status"];
        let infoIcon = ["", "", "The sales dollars generated after a User clicked one of your Buy Now links.", "The dollar amount of returns on the sale", "The estimated commission earned by the order. See RockPorch ToS for more detail.",

            ["Qualified - The commission was generated by a Qualified Purchase and is held by the brand or other parties for a period to account for returns, refunds, or other adjustments.",
                "Eligible - The net commission eligible for payment.Eligible commissions are paid at each RockPorch payment interval so long as the total Eligible Commissions exceed $100.",
                "Pending - The commission is ready for payment and will be paid at the next RockPorch payment interval.",
                "Paid - The commission payment has been remitted."]]

        return headers.map((header, i) => {
            const aggregate = this.props.aggregateChanged != null && this.props.aggregateChanged;

            if (aggregate || this.props.deviceSize != "sm" || (header != "Status"))
            return (
                <th key={i} style={{ position: "relative", minWidth: "0", paddingLeft: "4px", paddingRight: "4px" }}>
                    <strong>{header}</strong>
                    {i > 1 && header !== "Username" ?
                        <>
                            <small className="d-inline-block ml-1" style={{ position: "absolute", top: "1px", right: "5px" }}>
                                <button style={{ cursor: "pointer", outline: "none", border: 0, padding: 0, backgroundColor: "white" }}
                                    id={'cInfoIcon' + i}
                                >
                                    <InformationIcon />
                                </button>
                            </small>
                            <UncontrolledPopover
                                placement={i === 5 ? 'left' : 'bottom'}
                                target={'cInfoIcon' + i}
                                trigger="legacy"
                            >
                                {i !== 5 ? 
                                    <PopoverBody>{infoIcon[i]}</PopoverBody>
                                    : <PopoverBody>{infoIcon[i].map((item) => { return <>{item}<br /></> })}</PopoverBody>}
                            </UncontrolledPopover>
                        </>
                        : null}

                </th>
            )
        })
    }

    getDaysBetweenDates = function (start, end) {
        for (var arr = [], dt = new DateTime.fromISO(start, { zone: 'utc' }); dt < end; dt = dt.plus({ days: 1 })) {
            const date = dt.toJSDate();
            const offset = date.getTimezoneOffset();

            const utcDate = new Date(date.getTime() + (!!(offset) ? offset : 0) * 60000);

            arr.push(utcDate);
        }
        return arr;
    };

    displayChart = () => {
        var data = []; // [{ x: '07/31/2021', y: 2.30 }]
        var labels = [];

        const dateRange = {
            startDate: this.props.startDate,
            endDate: this.props.endDate
        };
        var dates = this.getDaysBetweenDates(dateRange.startDate, dateRange.endDate);
        dates.forEach(date => {
            const formattedDate = convertToNumericMonthAndDayAndYear(date, false);

            if (!data.find(x => x.x == formattedDate)) {

                data.push({ x: formattedDate, y: 0 });

                const formattedLabelDate = convertToNumericMonthAndDayAndYear(date, false);
                labels.push(formattedLabelDate);
            }
        });

        this.props.commissions.forEach(commission => {
            const formattedDate = convertToNumericMonthAndDayAndYear(new Date(commission.createdDate), false);

            const item = _.find(data, (item) => item?.x === formattedDate);
            if (item) {
                item.y += commission.qualifiedCommissionAmount;
            } else {
                data.push({ x: formattedDate, y: commission.qualifiedCommissionAmount });
            }

            const label = _.find(labels, (label) => label === formattedDate);
            if (!label) {
                labels.push(formattedDate);
            }
        });

        const { options, series } = this.state;
        series[0].data = data;
        options.labels = labels;

        return (
            <div id="chart">
                <ReactApexChart options={options} series={series} type="bar" />
            </div>
        );
    }

    displaySummaryTableHeader = () => {
        return (
            <thead key={0}>
                <th  style={{ padding: "4px" }}><strong>Status</strong></th>
                <th  style={{ padding: "4px" }}><strong>Sales Amount</strong></th>
                <th  style={{ padding: "4px" }}><strong>Returns</strong></th>
                <th  style={{ padding: "4px" }}><strong>Commission</strong></th>
            </thead>
        );
    }

    displaySummaryTableRows = () => {
        let commissionsByStatus = [
            { status: "Qualified", purchaseAmount: 0, returnAmount: 0, qualifiedCommissionAmount: 0 },
            { status: "Eligible", purchaseAmount: 0, returnAmount: 0, qualifiedCommissionAmount: 0 },
            { status: "Pending", purchaseAmount: 0, returnAmount: 0, qualifiedCommissionAmount: 0 },
            { status: "Paid", purchaseAmount: 0, returnAmount:0, qualifiedCommissionAmount: 0 },
        ];

        let infoPrompts = [
            "A Qualified Purchase occurred and is held by the brand or other parties to account for possible returns, refunds, or other adjustments.",
            "The net commissions eligible for payment. Eligible commissions are paid at each RockPorch pay date so long as total eligible commissions exceed the Minimum Payment Threshold, currently set at $100.",
            "The commission payment has been made."
        ]

        for (let i = 0; i < this.props.commissions.length; i++) {
            for (let j = 0; j < commissionsByStatus.length; j++) {
                if (commissionsByStatus[j].status.toLowerCase() == this.props.commissions[i].status.toLowerCase()) {
                    commissionsByStatus[j].purchaseAmount += this.props.commissions[i].purchaseAmount;
                    commissionsByStatus[j].returnAmount += this.props.commissions[i].returnAmount;
                    commissionsByStatus[j].qualifiedCommissionAmount += this.props.commissions[i].qualifiedCommissionAmount;
                    break;
                }
            }
        }

        return (
            commissionsByStatus.map((commission, i) => {
                let infoIconId = "infoIcon-CommChart-" + i;
                return (
                    <tr key={i}>
                        <td style={{ padding: "4px" }}>
                            {commission.status + " "}

                            {/*
                            <button style={{ cursor: "pointer", outline: "none", border: 0, padding: 0, backgroundColor: "white" }} id={infoIconId}>
                                <InformationIcon />
                            </button>
                            <UncontrolledPopover placement="right" trigger="legacy" target={infoIconId}>
                                <PopoverBody>
                                    {infoPrompts[i]}
                                </PopoverBody>
                            </UncontrolledPopover>
                            */}
                        </td>
                        <td style={{ padding: "4px" }}>${commission.purchaseAmount.toFixed(2)} </td>
                        <td style={{ padding: "4px" }}>${commission.returnAmount.toFixed(2)} </td>
                        <td style={{ padding: "4px" }}>${commission.qualifiedCommissionAmount.toFixed(2)}</td>
                    </tr>
                )
            })
        ).reverse();
    }

    reassignLoading = (reassignCreateMessage) => {
    }

    onCommissionReassignClick = async (e) => {
        e.preventDefault();
        if (!this.state.modalUserId == 0) {
            let reassignCreateMessage = { ...this.state.reassignCreateMessage };
            reassignCreateMessage.showMessage = true;
            this.setState({ reassignLoading: true, reassignCreateMessage });

            await this.reassignLogic();

            // Timeout allows the database to finish the update before reloading screen
            this.handleSelect(this.state.selectOptions.currentValue);
            reassignCreateMessage.showMessage = false;
            this.setState({ reassignLoading: false, reassignCreateMessage, show: false });

           
        } else {
            this.setState({ userBlank: true })
        }
    }

    reassignLogic = async () => {
        
            
            let reassignCommissionUrl = `/api/Merchant/ReassignCommission?commissionId=${this.state.modalCommissionId}&userId=${this.state.modalUserId}`;
            if (this.state.copyCheck) {
                reassignCommissionUrl += `&copyCommission=${this.state.copyCheck}`;
            }
            await getRequest(reassignCommissionUrl);
    }
            

    fetchUsers = async (query) => {
        this.setState({ isLoading: true });

        let url = `/api/Account/GetUserSuggestionsByUsername?username=${query}`;

        let users = await getRequest(url);
        this.setState({ userOptions: users, isLoading: false });
    }

    setSelected = (selected) => {
        if (selected != null) {
            this.setState({ userBlank: false });
            const userId = selected.length > 0 ? selected[0]?.id : this.state.modalUserId;
            this.setState({ modalUserId: userId });
        }
    }

    onChangeCopyCheck = () => {
        this.setState({ copyCheck: !this.state.copyCheck });
    }

    render() {
        return (
            <div className="container pt-0 pb-5 px-0 px-md-3" style={this.props.display ? {} : { display: 'none' }}>
                <Modal isOpen={this.state.show}
                    style={
                        {
                            overlay: {
                                position: 'fixed',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                zIndex: 9998,
                            },
                            content: {
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                border: '1px solid #ccc',
                                background: '#fff',
                                overflow: 'auto',
                                WebkitOverflowScrolling: 'touch',
                                borderRadius: '4px',
                                outline: 'none',
                                padding: '50px',
                                minWidth: '300px',
                                height: '550px',
                                zIndex: 1060,
                            }
                        }
                    }
                    onRequestClose={() => this.setState({ show: false })}
                >

                    <div className="d-flex flex-column justify-content-center h-100">
                        <div className="mx-0 my-0">
                            <h1 style={{ textAlign: "center", display: "block", fontSize: '1.75rem' }}>
                                Are you sure that you want to Reassign the Commission?
                            </h1>
                            <p style={{ textAlign: 'center', marginTop: "35px", fontWeight: "bold" }}>
                                Search for the UserName of the User to whom to reassign the commission
                            </p>
                            <p style={{ textAlign: 'center', marginTop: "15px", fontWeight: "bold" }}>
                                Check the 'Copy Commission' checkbox to keep the current commission assignment
                                and copy the commission to the new User
                            </p>
                            {this.state.userBlank ?
                                <>
                                    <br />
                                    <p style={{color: "red"}}>You must select a Username</p>
                                </>
                                : <p></p>
                            }

                        </div>

                        <section className="mb-0 pt-2">
                            <div className="container pt-0 pb-0">
                                <div className="search-interest py-1 mx-auto">
                                    <div className="search-block">
                                        <div className="position-relative mx-auto">
                                            <AsyncTypeahead
                                                id="userSearch"
                                                labelKey="userName"
                                                defaultSelected={this.state.value}
                                                clearButton
                                                onSearch={this.fetchUsers}
                                                onChange={e => this.setSelected(e)}
                                                isLoading={this.state.loading}
                                                options={this.state.userOptions}
                                                placeholder="Search for users..." />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="mb-0 pt-2">
                            <div className="container pt-0 pb-0">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" id="flexCheckDefault" checked={this.state.copyCheck} onChange={this.onChangeCopyCheck} />
                                    <label class="form-check-label" for="flexCheckDefault">
                                        Copy Commission
                                    </label>
                                </div>
                            </div>
                        </section>

                        <div className="d-flex justify-content-center gap-3 mt-4">
                            {/*<button type="button" className="btn btn-primary mr-2" onClick={(e) => this.onCommissionReassignClick(e)}>Reassign</button>*/}
                            <ActionButton
                                className="btn btn-primary ml-3 mt-3"
                                onClick={this.onCommissionReassignClick}
                                isDisabled={false}
                                isLoading={this.state.reassignLoading}
                                message={this.state.reassignCreateMessage}
                            >
                                Reassign
                            </ActionButton>
                            <button type="button" className="btn btn-secondary ml-3 mt-3" onClick={() => this.setState({ show: false })}>Cancel</button>
                        </div>
                    </div>
                </Modal>
                <Toaster />

                <div className={`search-interest py-${this.props.deviceSize === "sm" ? "3" : "4"} mx-auto`}>
                    <div className="search-block">
                        <div className="d-flex justify-content-center mx-0">
                            
                            <div style={{ width:"600px", minWidth: "300px" }}>
                                {this.props.commissionsLoading ?
                                    null :
                                    (
                                        <div className="table-responsive commission-chart-top-table">
                                            <table className="table">
                                                {this.displaySummaryTableHeader()}
                                                <tbody>
                                                    {this.displaySummaryTableRows()}
                                                </tbody>
                                            </table>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>

                {
                    this.props.commissionsLoading ?
                        <LoadSpinner noPaddingTop={true} /> :
                        <>
                            {this.displayChart()}
                            <div className="row mx-0 my-0">
                                <div className="table-responsive product-table">
                                    <table className="table">
                                        {this.props.aggregateChanged != null && this.props.aggregateChanged ?
                                            <colgroup>
                                                <col span="1" style={{ width: "12.5%" }} />
                                                <col span="1" style={{ width: "12.5%" }} />
                                                <col span="1" style={{ width: "12.5%" }} />
                                                <col span="1" style={{ width: "12.5%" }} />
                                                <col span="1" style={{ width: "12.5%" }} />
                                                <col span="1" style={{ width: "12.5%" }} />
                                                <col span="1" style={{ width: "12.5%" }} />
                                                <col span="1" style={{ width: "12.5%" }} />

                                            </colgroup>
                                            :
                                            <colgroup>
                                                <col span="1" style={{ width: "16%" }} />
                                                <col span="1" style={{ width: "16%" }} />
                                                <col span="1" style={{ width: "16%" }} />
                                                <col span="1" style={{ width: "16%" }} />
                                                <col span="1" style={{ width: "16%" }} />
                                                <col span="1" style={{ display: !(this.props.aggregateChanged != null && this.props.aggregateChanged) && this.props.deviceSize === "sm" ? "none" : "", width: "16%" }} />
                                                <col span="1" style={{ display: !(this.props.aggregateChanged != null && this.props.aggregateChanged) && this.props.deviceSize === "sm" ? "none" : "" }} />
                                            </colgroup>
                                        }
                                      

                                        <thead>
                                            <tr>
                                                {this.displayTableHeader()}
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {this.displayTableRows()}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </>
                }
            </div>
        );
    }
}

export default CommissionsChart;