import React from "react";
import { Link } from "react-router-dom";
import Select from 'react-select'
import AccountBanner from "components/AccountBanner";
import { getRequest, postRequest } from "sharedUtils/httpUtils";
import { convertToNumericMonthAndDayAndYearAndTime } from "sharedUtils/timeUtils";
import LoadSpinner from "components/LoadSpinner";
import _ from "lodash";
import toast, { Toaster } from 'react-hot-toast';
const { DateTime } = require("luxon");

class PaymentRuns extends React.Component {

    selectOptions = {
        last30Days: {
            value: 0,
            label: "Last 30 Days",
            getDateRange: () => {
                return {
                    startDate: DateTime.utc().minus({ days: 30 }).toISO(),
                    endDate: DateTime.utc().toISO()
                }
            }
        },
        last90Days: {
            value: 1,
            label: "Last 90 Days",
            getDateRange: () => {
                return {
                    startDate: DateTime.utc().minus({ days: 90 }).toISO(),
                    endDate: DateTime.utc().toISO()
                }
            }
        },
        last180Days: {
            value: 2,
            label: "Last 180 Days",
            getDateRange: () => {
                return {
                    startDate: DateTime.utc().minus({ days: 180 }).toISO(),
                    endDate: DateTime.utc().toISO()
                }
            }
        },
        last365Days: {
            value: 3,
            label: "Last 365 Days",
            getDateRange: () => {
                return {
                    startDate: DateTime.utc().minus({ days: 365 }).toISO(),
                    endDate: DateTime.utc().toISO()
                }
            }
        },
    };

    state = {
        loading: false,
        creatingNewPaymentRun: false,
        paymentRunInfos: {},
        selectOptions: {
            options: [
                this.selectOptions.last30Days,
                this.selectOptions.last90Days,
                this.selectOptions.last180Days,
                this.selectOptions.last365Days,
            ],
            defaultValue: this.selectOptions.last30Days,
            currentValue: this.selectOptions.last30Days,
        }
    }

    componentDidMount = async () => {
        let scrollOptions = {
            left: 0,
            top: 0,
            behavior: 'auto'
        }
        window.scrollTo(scrollOptions);

        this.handleSelect(this.state.selectOptions.defaultValue);
    }

    breadCrumbList = [
        { link: '/Admin', text: 'Admin' },
        { link: '/Admin/PaymentRuns', text: 'Payment Runs' }
    ]

    headers = ["Id", "Run Date", "Total Records", "Pending", "Skipped", "Rejected", "No Payment Email", "Approved", "Paid", "Status"];

    handleSelect = async (selectedOption) => {
        if (selectedOption) {
            const selectOptions = { ...this.state.selectOptions };
            selectOptions.currentValue = selectedOption;
            this.setState({ selectOptions });
        }
        const dateRange = selectedOption?.getDateRange();

        this.setState({
            loading: true
        });

        let paymentRunInfos = await this.getPaymentRunInfos(dateRange?.startDate, dateRange?.endDate);
        this.setState({
            paymentRunInfos,
            loading: false
        });
    }

    getPaymentRunInfos = async (startDate, endDate) => {
        let url = `api/merchant/GetPaymentRunInfos`;
        if (startDate) {
            url = `${url}?startDate=${startDate}`;
        }
        if (endDate) {
            url = url.includes('?') ? `${url}&endDate=${endDate}` : `${url}?endDate=${endDate}`;
        }
        let paymentRunInfos = await getRequest(url) ?? [];
        return _.orderBy(paymentRunInfos, ['paymentRunId'], ['desc']);
    }

    handleCreateNewPaymentRun = async (e) => {
        e.preventDefault();

        if (window.confirm('Are you sure you wish to create a new payment run?')) {
            
            this.setState({
                loading: true
            });

            let url = 'api/merchant/CreatePaymentRun';
            let runPaymentsResponse = await postRequest(url, null);

            toast.success(
                <div className="p-2">
                    <h3 className="text-center">Success</h3>
                    <hr />
                    <p><small>Created PaymentRunId: {runPaymentsResponse.paymentRunId}</small></p>
                    <p><small>Records created: {runPaymentsResponse.paymentRunDetailRecordCount}</small></p>
                </div>,
                {
                    duration: 4000,
                    position: 'top-center'
                }
            );

            this.handleSelect(this.state.selectOptions.defaultValue);
        }
    }

    displayTableHeader = () => {
        return this.headers.map((header, i) => {
            return (
                <th key={i}><strong>{header}</strong></th>
            )
        })
    }

    displayTableRows = () => {
        if (this.state.paymentRunInfos && this.state.paymentRunInfos.length > 0) {
            return this.state.paymentRunInfos.map((paymentRunInfo) => {
                return (
                    <tr key={paymentRunInfo.paymentRunId}>
                        <td>
                            <Link to={{pathname: `/admin/paymentRunDetails/${paymentRunInfo.paymentRunId}`, paymentRun: paymentRunInfo }}>
                                {paymentRunInfo.paymentRunId}
                            </Link>
                        </td>
                        <td>{convertToNumericMonthAndDayAndYearAndTime(paymentRunInfo.createdDate, false).replace(',', '')}</td>
                        <td>{paymentRunInfo.paymentRunDetailCount}</td>
                        <td>{paymentRunInfo.pendingCount} (${paymentRunInfo.pendingAmount.toFixed(2)})</td>
                        <td>{paymentRunInfo.skippedCount} (${paymentRunInfo.skippedAmount.toFixed(2)})</td>
                        <td>{paymentRunInfo.rejectedCount} (${paymentRunInfo.rejectedAmount.toFixed(2)})</td>
                        <td>{paymentRunInfo.noPaymentEmailCount} (${paymentRunInfo.noPaymentEmailAmount.toFixed(2)})</td>
                        <td>{paymentRunInfo.approvedCount} (${paymentRunInfo.approvedAmount.toFixed(2)})</td>
                        <td>{paymentRunInfo.paidCount} (${paymentRunInfo.paidAmount.toFixed(2)})</td>
                        <td>{paymentRunInfo.paymentRunStatus}</td>
                    </tr>
                )
            })
        }
        else {
            return (
                <tr key={0}>
                    <td colSpan={`${this.headers.length}`}>No data</td>
                </tr>
            )
        }
    }

    render() {
        return (
            <>
                <Toaster />
                <section>
                    <AccountBanner breadCrumbList={this.breadCrumbList}>
                        <h1 className="mb-4">Payment Runs</h1>
                        <p className="mb-0">Manage payments</p>
                    </AccountBanner>
                </section>
                <section className="gray-bg full-height">
                    <div className="container">
                        <div className="row mt-0 mx-0 py-4">
                            <div className="col-12 col-md-4 px-0">
                                <Select
                                    isMulti={false}
                                    isClearable={false}
                                    isSearchable={true}
                                    placeholder="Select timeframe ..."
                                    blurInputOnSelect={true}
                                    captureMenuScroll={true}
                                    onChange={(e) => { this.handleSelect(e) }}
                                    options={this.state.selectOptions.options}
                                    defaultValue={this.state.selectOptions.defaultValue}
                                />
                            </div>
                            <div className="col-0 py-1 col-md-4 py-md-0 px-0">
                            </div>
                            <div className="col-12 col-md-4 px-0">
                                <button className="btn btn-primary w-100" onClick={(e) => this.handleCreateNewPaymentRun(e)}>Create New Payment Run</button>
                            </div>
                        </div>
                        {
                            this.state.loading ?
                                <LoadSpinner /> :
                                <>
                                    <div className="row mt-0 mx-0">
                                        <div className="table-responsive product-table">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        {this.displayTableHeader()}
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {this.displayTableRows()}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </>
                        }                        
                    </div>
                </section>
            </>
        )
    }
}

export default PaymentRuns;