import React, { useState, useEffect } from "react";
import RightArrowIcon from "components/icons/RightArrowIcon";
import LeftArrowIcon from "components/icons/LeftArrowIcon";
import ImageWrapper from "components/ImageWrapper";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { toggleShareModal, togglePostPageModal, toggleLockerPageModal, toggleProductPageModal } from "stateManagement/reducers/modalReducer";
import { trimProductsArray } from "sharedUtils/postFunctions";
import { useSwipeable } from "react-swipeable"; 
import { getRequest } from "sharedUtils/httpUtils";
import PostPageProduct from "components/PostPageProduct";
import FeedPostGridItem from "components/Feed/FeedPostGridItem";
import SaleIcon from "components/icons/SaleIcon";
import ProfileEmbed from "./ProfileEmbed";
import { Link } from 'react-router-dom';
import ThumbsUpIcon from "components/icons/ThumbsUpGeneric";


/**
 * @description A post component for a three column feed grid
 * @param {{
 * post?:object,
 * isPictureAndProducts:boolean,
 * showPins:boolean
 * redirectUrl:string
 * togglePinned : (shareCode)=>
 * isPinned:boolean
 * showUserTile: boolean
 * }} props
 */
const FeaturedPostsAndProduct = (props) => {
    const [postsProductsPage, setPostsProductsPage] = useState(0);
    const [postsProducts, setPostsProducts] = useState([]);
    const [sectors, setSectors] = useState([]);
    const [itemsPerPage, setItemsPerPage] = useState(4);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        props.setShowTopNav(false);
    //    populateData();
    }, []);

    useEffect(() => {
        if (!!(props.featuredData) && props.isActive && !isLoaded) {
            setPostsProducts(props.featuredData?.featuredContent?.postsProducts);
            setSectors(props.featuredData?.sectors);
            setIsLoaded(true);
        }
    }, [props.isActive, isLoaded, props.featuredData])


    useEffect(() => {
        if (props.deviceSize === "sm") {
            setItemsPerPage(2);
        } else {
            setItemsPerPage(4)
        }
    }, [props.deviceSize]);

    const renderSaleOverlay = (sale) => {
        if (sale) {
            return (
                <span className="on-sale-icon">
                    <SaleIcon />
                </span >
            );
        }
    }

    const postsProductsPageHandlers = useSwipeable({
        onSwipedLeft: () => {
            nextPage();
        },
        onSwipedRight: () => {
            prevPage();
        },
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
    });


    const prevPage = () => {
        if (postsProducts.length > itemsPerPage) {
            let page = postsProductsPage - 1;
            if (page < 0) {
                page = Math.floor((postsProducts.length - 1) / itemsPerPage);
            }

            setPostsProductsPage(page);
        }
    }

    const nextPage = () => {
        if (postsProducts.length > itemsPerPage) {
            let page = postsProductsPage + 1;
            if (page * itemsPerPage >= postsProducts.length) {
                page = 0;
            }

            setPostsProductsPage(page);
        }
    }


    const renderProductPagination = () => {
        if (postsProducts && postsProducts.length > itemsPerPage) {
            return (
                <>
                    <a href="/"
                        onClick={(e) => {
                            e.preventDefault();
                            prevPage()
                        }}>
                        <span style={{ left: itemsPerPage < 4 ? "-5%" : "" }} className="item-count-prev d-flex justify-content-center align-items-center">
                            {"<"}
                        </span>
                    </a>

                    <a href="/"
                        onClick={(e) => {
                            e.preventDefault();
                            nextPage()
                        }}>
                        <span style={{ right: itemsPerPage < 4 ? "-5%" : "" }} className="item-count-next d-flex justify-content-center align-items-center">
                            {">"}
                        </span>
                    </a>
                </>
            );
        }
    }

    const toTitleCase = (string) => {
        return string.toLowerCase().replace(/(?:^|\s|-)\w/g, function (match) {
            return match.toUpperCase();
        });
    }

    const renderThumbsUpOverlay = (product, singleColumn = false) => {
        if (product.creatorCount > 0) {
            let url = "/Product/ProductPage/" + product.shareCode + "?r=0&b=1&showBrandNav=true";
            return (
                <a href={url} target="_blank" style={{ cursor: "pointer" }}>
                    <span className="thumbs-up-overlay position-absolute" style={{ cursor: "pointer", left: singleColumn ? "25px" : "22px", top: singleColumn ? "22px" : "15px" }}>
                        <ThumbsUpIcon height="16" width="16" fill={"#e55934"} />
                        <span style={{ marginLeft: "5px", paddingTop: "2px" }}>{!!(product.creatorCount) ? product.creatorCount : ""}</span>
                    </span>
                </a>
            )
        }
    }

    let renderProducts = () => {
        if (postsProducts && postsProducts?.length > 0) {
            return postsProducts.slice(postsProductsPage * itemsPerPage, postsProductsPage * itemsPerPage + itemsPerPage).map((postsProduct, i) => {
                //let redirectUrl = post.type === 2 ? `/Locker/LockerPage/${post?.shareCode}` : `/post/postPage/${post?.shareCode}`
                let sizes = ["sm"];
                let deviceSize = sizes.includes(props.deviceSize);

                if (!(postsProduct.userMerchantProductId)) {
                    let redirectUrl = postsProduct.type === 2 ? `/Locker/LockerPage/${postsProduct?.shareCode}?showBrandNav=true` : postsProduct.type === 4 ? `/Blog/BlogPage/${postsProduct?.shareCode}?showBrandNav=true` : `/post/postPage/${postsProduct?.shareCode}?showBrandNav=true`

                    return (
                        <div className={`col-md-3 px-1 py-1 mx-0 my-0 grid-item ${itemsPerPage < 4 && i === 0 ? "mr-1" : itemsPerPage < 4 && i === 1 ? "ml-1" : "ml-2 mr-2"}`}
                            style={{ maxWidth: itemsPerPage < 4 ? "48%" : "22%", border: "1px solid #E9ECEF", borderRadius: "15px" }}
                            key={i}
                        >
                            <FeedPostGridItem
                                post={postsProduct}
                                isPictureAndProducts={postsProduct.type === 2}
                                redirectUrl={redirectUrl}
                                deviceSize={props.deviceSize}
                                loggedInUser={props.loggedInUser}
                                showUserTile={true}
                                savedByUser={postsProduct.savedByUser}
                                hideItemCount={![2, 4].includes(postsProduct.type)}
                                disableRedirects={true}
                                brandEmbed={true}
                                isBrandSmall={itemsPerPage < 4}
                            />
                        </div >
                    )
                }

                return (
                    <div key={i} className={`col-md-3 px-1 py-1 mx-0 my-0 grid-item ${itemsPerPage < 4 && i === 0 ? "mr-1" : itemsPerPage < 4 && i === 1 ? "ml-1" : "ml-2 mr-2"}`} style={{ padding: "1px", maxWidth: itemsPerPage < 4 ? "48%" : "22%", border: "1px solid #E9ECEF", borderRadius: "15px" }}>
                        <div className="product-in-feed" style={{ paddingBottom: itemsPerPage < 4 && props.screenWidth < 393 ? "109%" : itemsPerPage < 4 && props.screenWidth < 500 ? "112%" : itemsPerPage < 4 ? "115%" : props.screenWidth < 850 ? "110%" : props.screenWidth < 960 ? "113%" : props.screenWidth < 1160 ? "117%" : "119%"}}>
                            {renderThumbsUpOverlay(postsProduct)}
                            {deviceSize ? renderSaleOverlay(postsProduct.sale) : <></>}
                            <PostPageProduct
                                product={postsProduct}
                                loggedInUser={props.loggedInUser}
                                brandEmbed={true}
                                isBrandSmall={itemsPerPage < 4}
                                size={`ProductGrid`}
                            />
                        </div>

                        <div className="d-flex flex-wrap justify-content-between align-items-center py-0 px-1" style={{ background: "white", borderRadius: "0px 0px 15px 15px" }}>
                            <div className="users user-tile-general py-1 ml-2" style={{ whiteSpace: "nowrap", minHeight: "25px" }}>
                                <h5 style={{ marginBottom: "0px" }}><div className="position-relative" style={{ maxWidth: "1px" }}>{postsProduct.manufacturer?.toLowerCase().includes("rcktemp") ? postsProduct.merchant : postsProduct.manufacturer}{postsProduct.sale && !deviceSize ? <SaleIcon style={{ marginLeft: "3px" }} /> : ""}</div></h5>
                            </div>
                            <div className="users user-tile-general py-1 ml-2" style={{ whiteSpace: "nowrap" }}>
                                <h5 style={{ marginBottom: "0px" }}>
                                    <div style={{ display: "table", tableLayout: "fixed", width: "100%" }}>
                                        <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", display: "table-cell" }}>
                                            {toTitleCase(postsProduct.name)}
                                        </div>
                                    </div>
                                </h5>
                            </div>
                            <div className="users user-tile-general py-1 ml-2" style={{ whiteSpace: "nowrap" }}>
                                <h5 style={{ marginBottom: "0px" }}><div className="d-flex" style={{ maxWidth: "1px" }}><div className={`${postsProduct.onSale ? "mr-1" : ""}`} >{postsProduct.sale && !!(postsProduct.salePrice) ? "$" + postsProduct.salePrice.toFixed(2) : <></>}</div><div className={`${postsProduct.sale && !!(postsProduct.salePrice) ? "on-sale-price" : ""}`}>${postsProduct.sale && !!(postsProduct.salePrice) && !!(postsProduct.originalPrice) ? postsProduct.originalPrice.toFixed(2) : postsProduct.price.toFixed(2)}</div></div></h5>
                            </div>
                        </div>
                    </div>
                )
            })
        }
    }

    let gridItem = () => {
        if (postsProducts?.length > 0) {
            return (
                <div className="explore-feed-wrapper" style={{ width: props.screenWidth > 1149 ? "103%" : itemsPerPage < 4 ? "" : "101.5%", margin: props.screenWidth > 1149 ? "0 -10% 0 -1.3%" : itemsPerPage < 4 ? "" : "0 -10% 0 -0.7%", zIndex: "898", borderRadius: "20px", overflow: itemsPerPage < 4 ? "visible" : "hidden" }}>
                    <div className="feed-slider-products mt-3 pb-0" style={props.style}>
                        <div {...postsProductsPageHandlers} onTouchMove={props.handleCarouselActive} className={`row mx-0 product-image-gallery justify-content-center px-0 position-relative pt-0 ${props.brand ? "mb-0" : "mb-3"} mt-0`} style={{ width: "100%" }}>
                            {renderProductPagination()}
                            {renderProducts()}
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <></>
            )
        }
    }

    const redirect = (sector) => {
        const url = "/Browse/" + sector + "?populatesearch=true";
        window.open(url);
    //    window.location.href = url;
    };

    let renderSectors = () => {
        if (sectors?.length > 0) {
            return sectors.map((sector, index) => {
                const encodedSector = encodeURIComponent(sector);
                return (
                    <button key={index} onClick={() => redirect(encodedSector)} className="btn brand-sector-btn" style={{ padding: itemsPerPage < 4 ? "2px 8px" : "10px 40px", border: "1px solid black", borderRadius: "30px", background: "white", whiteSpace: "nowrap", fontSize: ".9rem", fontWeight: "bold" }}>{sector}</button>
                )
            })
        }
    }

    const outerDivStyle = {
        background: `url("/images/${props.featuredData.userImage}") no-repeat center center`,
        width: itemsPerPage < 4 ? "" :'45%',
        backgroundSize: 'cover',
        position: 'relative',
        paddingBottom: itemsPerPage < 4 ? "100%" : '27.08%',
        borderRadius: "15px",
        border: "1px solid #E9ECEF",
        display: itemsPerPage < 4 ? "none" : "",
    };

    const innerDivStyle = {
        width: '100%',
        height: '100%',
        position: 'absolute',
        left: 0,
        display: 'block',
    };

    return (
        <div className={`${props.screenWidth > 767 ? "mt-3" : ""} mb-3 mx-auto`} style={{ width: "95%" }}>
            <div className={`${itemsPerPage < 4 ? "" : "d-flex"} justify-content-around mb-4`}>
                <div className="d-flex flex-column" style={{ width: itemsPerPage < 4 ? "" : "45%" }}>
                    <ProfileEmbed screenWidth={props.screenWidth} deviceSize={props.deviceSize} setShowTopNav={props.setShowTopNav} brandUsername={!!(props.featuredData?.userName) ? props.featuredData.userName : ""} />
                    <p className={`${itemsPerPage < 4 ? "mb-0" : "mt-4"}`} style={{ fontSize: itemsPerPage < 4 ? "14px" : "20px", lineHeight: itemsPerPage < 4 ? "1.5" : "2rem" }} >
                        {!!(props.featuredData?.userText) ? props.featuredData.userText : ""}
                    </p>
                </div>
                <div style={outerDivStyle}>
                    <div style={innerDivStyle}>
                    </div>
                </div>

            </div>
            <h3 style={{ marginLeft: props.deviceSize === 'sm' ? "" : "2.5%", fontSize: itemsPerPage < 4 ? "1.25rem" : "", fontWeight: 700 }}>Top Content</h3>
            {gridItem()}
            <div className="d-flex justify-content-center mx-auto mt-4" style={{ gap:"10px", flexWrap: "wrap", borderRadius: "20px", width: props.deviceSize === "sm" ? "98%" : "94%" }}>
                {renderSectors()}
            </div>
        </div>
    //        <PostPageEmbedBrand shareCodeDirect={"3zGPAidJU6wxx"} />
    //    <>Featured Posts and Products</>
    )
}

export default FeaturedPostsAndProduct;