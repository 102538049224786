import React, { useEffect } from "react";
import ShareBlock from "components/Share/ShareBlock";
import Modal from "components/Modal";
import { toggleShareModal } from "stateManagement/reducers/modalReducer";

import { connect } from "react-redux";

/**
 * 
 * @param {{showModal:boolean,toggleModal:Function,shareUrl:string}} props
 */
const ShareModal = (props) => {

    useEffect(() => {
        if (props.showModal) {
            document.querySelector("body").style.overflow = "hidden";
        } else {
            document.querySelector("body").style.overflow = "";
        }
    }, [props.showModal]);

    return (
        <Modal isOpen={props.showModal} toggleModal={props.toggleModal}>
            <ShareBlock
                customStyle={{ maxWidth: "100%", backgroundColor: "white" }}
                shareBlockStyle={{ border: "none", backgroundColor: "transparent" }}
                shareCode={`${props.shareUrl}`}
                loggedInUser={props.loggedInUser}
            />
        </Modal>
    )
}

function mapStateToProps(storeState, ownProps) {
    return {
        showModal: storeState.modalReducer.showShareModal,
        shareUrl: storeState.modalReducer.shareLink
    }
}

let mapDispatch = {
    toggleModal: toggleShareModal
}
export default connect(mapStateToProps,mapDispatch)(ShareModal);