import React from "react";
import AccountBanner from "../../components/AccountBanner"
import { Popover, PopoverBody } from "reactstrap";
import { getRequest, postRequest } from "sharedUtils/httpUtils";
import LoadSpinnerOverlay from "components/LoadSpinnerOverlay";
import { convertToNumericMonthAndDayAndYearAndTime } from "sharedUtils/timeUtils";
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Autocomplete from '@material-ui/lab/Autocomplete';
import _ from "lodash";
const { DateTime } = require("luxon");

class AddMerchantCommissionOverride extends React.Component {
    state = {
        selectedOption: null,
        options: [],
        filteredOptions: [],
        value: [],
        isLoading: false,
        integrated: true,
        merchantCommissionOverrideHistoryRecords: [],
        userShare: "",
        effectiveDate: `${DateTime.now().plus({ days: 1 }).toFormat('yyyy-MM-dd')}T00:00`,

        formData: {
            id: 0,
            merchantName: '',
            merchantHostname: '',
            merchantHomepage: '',
            merchantLogoUrl: '',
            integrated: false,
            commission: 0,
            merchantAffiliateProgramManagerId: 0,
            affiliateProgramManagerId: 0,
            affiliateProgramManagerName: ''
        }
    };

    breadCrumbList = [
        { link: '/Admin', text: 'Admin' },
        { link: '/Admin/CommissionOverrides', text: 'Commission Overrides' },
        { link: '/Admin/CommissionOverrides/AddMerchantCommissionOverride', text: 'Add Merchant Commission Override', active: true }
    ];

    integratedCheckbox = withStyles({
        root: {
            '&$checked': {
                color: "#e55934",
            },
        },
        checked: {},
    })((props) => <Checkbox color="default" {...props} />);

    MerchantSelectAutocomplete = withStyles({
        root: {
            marginTop: '0px',
            marginBottom: '0px'
        },
    })((props) => <TextField color="default" {...props} />);

    componentDidMount = async () => {
        await this.fetchMerchants();

        let scrollOptions = {
            left: 0,
            top: 0,
            behavior: 'auto'
        }
        window.scrollTo(scrollOptions);
    }

    fetchMerchants = async (query) => {
        this.setState({ isLoading: true });

        let url = `/api/Merchant/GetCommissionEstimate`;

        let merchants = await getRequest(url);
        let filteredOptions = this.state.integrated ? merchants.filter(x => x.integrated) : merchants;
        this.setState({ options: merchants, filteredOptions, isLoading: false });
    }

    onSubmit = async (event) => {
        event.preventDefault();

        if (this.state.merchantCommissionOverrideHistoryRecords.length > 0) {
            let recordsWithoutEndDate = this.state.merchantCommissionOverrideHistoryRecords.filter(x => !x.endDate);
            let count = recordsWithoutEndDate.length;
            if (count > 0) {
                let isAre = count === 1 ? 'is' : 'are';
                let records = count === 1 ? 'record' : 'records';
                let msg = `Warning: There ${isAre} already ${count} "active" ${records} without an End Date set.\n`;
                msg += ` Are you sure you wish to add a new Merchant Commission Override?`
                if (!window.confirm(msg)) {
                    return;
                }
            }
        }

        let parsedDate = this.state.effectiveDate ? DateTime.fromISO(this.state.effectiveDate) : null;
        if (!parsedDate || !DateTime.isDateTime(parsedDate)) {
            window.alert("Invalid Effective Date");
            return;
        }

        var effectiveDate = parsedDate.toJSDate().toISOString();

        if (!this.state.userShare) {
            window.alert("Invalid User Share");
            return;
        }
        if (this.state.userShare > 100) {
            window.alert("Error: User Share cannot exceed 100%");
            return;
        }
        if (this.state.userShare < 1) {
            let msg = `Warning: User Share is set to less than 1%.\n`;
            msg += `Are you sure you wish to add a new User Commission Override with a UserShare value of ${this.state.userShare}%?`
            if (!window.confirm(msg)) {
                return;
            }
        }

        let userShare = this.state.userShare / 100;

        let merchantAffiliateProgramManagerId = this.state.formData.merchantAffiliateProgramManagerId;

        this.setState({ isLoading: true });

        let request = {
            merchantAffiliateProgramManagerId: merchantAffiliateProgramManagerId,
            userShare: userShare,
            effectiveDate: effectiveDate,
            offset: DateTime.now().offset
        };

        let addMerchantCommissionOverrideUrl = '/api/Merchant/AddMerchantCommissionOverride';
        let userCommissionOverrideHistory = await postRequest(addMerchantCommissionOverrideUrl, request);

        let merchantCommissionOverrideHistoryRecords = await this.getMerchantCommissionOverrideHistory(merchantAffiliateProgramManagerId);

        this.setState({ isLoading: false, showSavedMerchant: true, merchantCommissionOverrideHistoryRecords });

        setTimeout(() => this.setState({ showSavedMerchant: false }), 4000);
    };

    renderSubmit = () => {
        let submitDisabled = true;

        if (this.state.formData.id && this.state.userShare && this.state.effectiveDate) {
            submitDisabled = false;
        }

        return (
            <button type="submit" id="submitMerchantChanges" className="btn btn-secondary mt-3" disabled={submitDisabled} onClick={(e) => { this.onSubmit(e); }}>
                {this.state.savingProfile ?
                    <div className="spinner-border spinner-border-sm" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                    : ""} Submit
            </button>
        );
    }

    getMerchantCommissionOverrideHistory = async (merchantAffiliateProgramManagerId) => {
        let getMerchantCommissionOverrideHistoryUrl = '/api/Merchant/GetMerchantAffiliateProgramManagerOverrideHistory';
        getMerchantCommissionOverrideHistoryUrl += `?offset=${DateTime.now().offset}`;
        getMerchantCommissionOverrideHistoryUrl += `&merchantAffiliateProgramManagerId=${(merchantAffiliateProgramManagerId)}`;

        let merchantCommissionOverrideHistoryRecords = await getRequest(getMerchantCommissionOverrideHistoryUrl) ?? [];
        return merchantCommissionOverrideHistoryRecords;
    };

    setSelected = async (selectedOption) => {
        let formData = {
            id: 0,
            merchantName: '',
            merchantHostname: '',
            merchantHomepage: '',
            merchantLogoUrl: '',
            integrated: false,
            commission: 0,
            merchantAffiliateProgramManagerId: 0,
            affiliateProgramManagerId: 0,
            affiliateProgramManagerName: ''
        }

        if (selectedOption) {
            formData = {
                ...selectedOption
            }
        }

        let merchantCommissionOverrideHistoryRecords = await this.getMerchantCommissionOverrideHistory(formData.merchantAffiliateProgramManagerId);

        this.setState({
            selectedOption,
            formData,
            merchantCommissionOverrideHistoryRecords
        });
    }

    displayTableRows = () => {
        if (this.state.merchantCommissionOverrideHistoryRecords.length === 0) {
            return (
                <tr key={0}>
                    <td colSpan="4" style={{ textAlign: "center" }}>No data</td>
                </tr>
            );
        }

        return _.orderBy(this.state.merchantCommissionOverrideHistoryRecords, ['id'], ['desc']).map((data) => {
            return (
                <tr key={data.id}>
                    <td style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                        <span>{data.id}</span>
                    </td>
                    <td style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                        <span>{(data.userShare * 100).toFixed(0) + '%'}</span>
                    </td>
                    <td style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                        <span>{convertToNumericMonthAndDayAndYearAndTime(data.effectiveDate, false).replace(',', '')}</span>
                    </td>
                    <td style={{ paddingLeft: "4px", paddingRight: "4px", textAlign: "left" }}>
                        <span>{data.endDate ? convertToNumericMonthAndDayAndYearAndTime(data.endDate, false).replace(',', '') : ''}</span>
                    </td>
                </tr>);
        });
    }

    getUrl = () => {
        return this.state.formData.merchantHomepage ? this.state.formData.merchantHomepage : this.state.formData.merchantHostname;
    }

    render() {
        return (
            <>
                <section>
                    <AccountBanner breadCrumbList={this.breadCrumbList}>
                        <h1 className="mb-4">Add Merchant Override</h1>
                        <p className="mb-0">Add merchant commission override</p>
                    </AccountBanner>
                </section>
                <section className="gray-bg full-height">
                    <div className="container pt-0 pb-5">
                        <div className="py-4">
                            <div className="px-3 py-1" style={{ backgroundColor: "#fff" }}>
                                <div className="d-flex">
                                    <div className="ml-auto">
                                        {/* https://v4.mui.com/components/checkboxes/#checkbox-with-formcontrollabel */}
                                        <FormControlLabel
                                            control={
                                                <this.integratedCheckbox
                                                    className=""
                                                    onChange={(e) => {
                                                        let filteredOptions = e.target.checked ? this.state.filteredOptions.filter(x => x.integrated) : this.state.options;
                                                        this.setState({ integrated: e.target.checked, filteredOptions });
                                                    }}
                                                />
                                            }
                                            checked={this.state.integrated}
                                            label="Integrated"
                                            labelPlacement="start"
                                            className="my-0"
                                        />
                                    </div>
                                </div>

                                <div>
                                    {/* https://v4.mui.com/components/autocomplete/ */}
                                    <Autocomplete
                                        freeSolo
                                        id="merchantSelect"
                                        options={this.state.filteredOptions}
                                        getOptionLabel={(option) => `${option.merchantName} (${option.affiliateProgramManagerName})`}
                                        onChange={(event, selected) => {
                                            this.setSelected(selected);
                                        }}
                                        renderInput={(params) => (
                                            <this.MerchantSelectAutocomplete
                                                {...params}
                                                label="Select a merchant..."
                                                margin="normal"
                                                variant="outlined"
                                                InputProps={{ ...params.InputProps, type: 'search', }}
                                                inputProps={{ ...params.inputProps }}
                                            />
                                        )}
                                    />
                                </div>

                                <div className="d-flex">
                                    <div className="ml-auto">
                                        <span className="text-muted small">{this.state.filteredOptions.length} merchants</span>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="user-detail-form">
                            <div className="detail-block">
                                <h5>Merchant</h5>

                                {
                                    this.state.formData.merchantAffiliateProgramManagerId ?
                                        <>
                                            <div className="mb-3">
                                                <img src={this.state.formData.merchantLogoUrl} style={{ maxHeight: "150px", maxWidth: "150px" }} />
                                            </div>

                                            <div>
                                                <table className="table w-auto table-borderless table-sm">
                                                    <tbody>
                                                        <tr>
                                                            <td><span className="font-weight-bold">Affiliate:</span> &nbsp;</td>
                                                            <td>{this.state.formData.affiliateProgramManagerName}</td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="font-weight-bold">Merchant:</span> &nbsp;</td>
                                                            <td>{this.state.formData.merchantName}</td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="font-weight-bold">Merchant Url:</span> &nbsp;</td>
                                                            <td><a href={this.getUrl()} target="_blank">{this.getUrl()}</a></td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="font-weight-bold">Integrated:</span> &nbsp;</td>
                                                            <td>{this.state.formData.integrated ? 'Yes' : 'No'}</td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="font-weight-bold">User Share:</span> &nbsp;</td>
                                                            <td>
                                                                {/* https://v4.mui.com/components/text-fields/ */}
                                                                <TextField
                                                                    id="userShare"
                                                                    InputProps={{
                                                                        endAdornment: <InputAdornment position="end">%</InputAdornment>
                                                                    }}
                                                                    inputProps={{
                                                                        'data-lpignore': 'true'
                                                                    }}
                                                                    style={{ width: '244px' }}
                                                                    value={this.state.userShare}
                                                                    onChange={(e) => {
                                                                        let value = e.target.value.replace(/[^0-9.]/g, '');
                                                                        this.setState({ userShare: value });
                                                                    }}
                                                                />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><span className="font-weight-bold">Effective Date:</span> &nbsp;</td>
                                                            <td>
                                                                {/* https://v4.mui.com/components/pickers/ */}
                                                                <TextField
                                                                    id="effectiveDate"
                                                                    type="datetime-local"
                                                                    defaultValue={this.state.effectiveDate}
                                                                    onChange={(e) => {
                                                                        let effectiveDate = e.target.value;
                                                                        this.setState({ effectiveDate: effectiveDate });
                                                                    }}
                                                                />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                            {this.renderSubmit()}

                                            <Popover
                                                placement="right"
                                                target="submitMerchantChanges"
                                                isOpen={this.state.showSavedMerchant}
                                                toggle={() => this.setState({ savingProfile: false })}
                                            >
                                                <PopoverBody>Changes saved</PopoverBody>
                                            </Popover>

                                            {
                                                this.state.isLoading ?
                                                    <LoadSpinnerOverlay />
                                                    : <></>
                                            }
                                        </>
                                        :
                                        <></>
                                }
                            </div>
                            <div className="table-responsive product-table p-3">
                                <h5>Historical Commission Overrides</h5>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Id</th>
                                            <th>User Share</th>
                                            <th>Effective Date</th>
                                            <th>End Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.displayTableRows()}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default AddMerchantCommissionOverride;