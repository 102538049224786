import React, { useState } from "react";
import ActionButton from "components/ActionButton";
import RPModal from "components/Modal";
import PreviewCard from 'components/Post/Create/PreviewCard';
import { Button, ModalFooter } from 'reactstrap'

/**
 * 
 * @param {{advance:Function,productUrl:string,handleInput:Function, isLoading:boolean, productNotFound:boolean, isModal:boolean}} props 
 */
const ShareABlogPreview = (props) => {

    const renderPreviewPost = () => {
        return (
            <PreviewCard
                handleContentInput={(val) => props.setPostCaption(val)}
                post={props.post}
                loggedInUser={props.loggedInUser}
                deviceSize={props.deviceSize}
                captionValue={props.postCaption}
                blog={true}
            />
        )
    };

    return (
        <RPModal id="shareBlogPreview" toggleModal={props.reduceLevel} isOpen={props.actionLevel === 2} modalDialogCustomClass="modal-dialog-md pb-0" modalBodyCustomClass="modal-body py-0 px-0 mt-0" showHeader={false} modalHeader={<></>} ignoreClickAway={true}>
            {renderPreviewPost()}
            <ModalFooter className="py-2">
                <Button id="buttonId" color="secondary" onClick={props.reduceLevel}>Cancel</Button>
                {true ?
                    <ActionButton
                        isLoading={props.loading}
                        className="btn btn-primary"
                        onClick={props.handleNext}
                    >
                        Next
                    </ActionButton> : null}

            </ModalFooter>
        </RPModal>
    )


}


export default ShareABlogPreview;