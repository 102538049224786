import React from "react";
import PropTypes from "prop-types";

const SiteMapSection = (props) => {
    return (
        <div className="col-6">
            <ul className="navbar-nav flex-grow-1">
                <li className="nav-item">
                    <span style={{ fontSize: "x-large" }}>{props.Title}</span>
                </li>
                {props.Paths}
            </ul>
        </div>
    );
};

SiteMapSection.propTypes = {
    Title: PropTypes.string,
    Paths: PropTypes.array,
};

export default SiteMapSection;
