import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import ImageWrapper from "components/ImageWrapper";

const ShareCard = (props) => {
    const {post, onSelected} = props;
    const [mediaIndex, setMediaIndex] = useState(-1);
    const [postState, setPostState] = useState(post)

    let standard = postState?.raw?.playlistItem?.snippet?.thumbnails?.standard?.url;
    let high = postState?.raw?.playlistItem?.snippet?.thumbnails?.high?.url;
    let medium = postState?.raw?.playlistItem?.snippet?.thumbnails?.medium?.url;
    let defaultThumbnail = postState?.raw?.playlistItem?.snippet?.thumbnails?.default?.url;

    const [imageLink, setImageLink] = useState(!!(standard) ? standard : !!(high) ? high : !!(medium) ? medium : defaultThumbnail);
    const [altImageLink, setAltImageLink] = useState(post?.media[0]?.children[0]?.thumbnailLink);
    const [postId, setPostId] = useState(!!(post?.id) ? post.id : null);

    const handleMounted = async () => {
        if (post?.media?.length > 0) {
            setMediaIndex(0);
        }
    }

    const handleSelected = async (e) => {
        e.preventDefault();
        onSelected(postId);
    }

    useEffect(() => {
        handleMounted();
    }, []);

    useEffect(() => {
        setPostState(post);
        setPostId(post.id)

        standard = post.raw?.playlistItem?.snippet?.thumbnails?.standard?.url;
        high = post.raw?.playlistItem?.snippet?.thumbnails?.high?.url;
        medium = post.raw?.playlistItem?.snippet?.thumbnails?.medium?.url;
        defaultThumbnail = post.raw?.playlistItem?.snippet?.thumbnails?.default?.url;

        setImageLink(!!(standard) ? standard : !!(high) ? high : !!(medium) ? medium : defaultThumbnail);
        setAltImageLink(post.media[0]?.children[0]?.thumbnailLink);
    }, [post]);


    function prevPicture() {
        if (mediaIndex > 0) {
            let newMedia = mediaIndex;
            setMediaIndex(--newMedia);
        } else {
            setMediaIndex(post?.media.length - 1);
        }
    }

    function nextPicture() {
        if (mediaIndex < post?.media.length - 1) {
            let newMedia = mediaIndex;
            setMediaIndex(++newMedia);
        } else {
            setMediaIndex(0);
        }
    }

    let currentImage = post?.media[mediaIndex];

    let content = post?.content ?? post?.title;

    let src = currentImage?.imageLink ? currentImage?.imageLink : currentImage?.children[0]?.thumbnailLink || currentImage?.thumbnailLink || !!(imageLink) ? imageLink : altImageLink;

    if (!(postId)) {
        return <></>
    };

    return (
        <div className="share-card" onClick={handleSelected}>
            <div className="share-card-image-wrapper">
                {!!currentImage || imageLink || altImageLink
                    ? <ImageWrapper
                        //src={currentImage?.imageLink ? currentImage?.imageLink : currentImage?.children[0]?.thumbnailLink || currentImage?.thumbnailLink || !!(imageLink) ? imageLink : altImageLink }
                        src={altImageLink || src}
                        alt=""
                        className="share-card-image"
                        role={0}
                    />
                    : <></>
                }

                {
                    post?.media.length > 1 ?
                        (
                            <>
                                <span className="count position-absolute d-flex justify-content-center align-items-center">
                                    {`${post.media.length}`}
                                </span>
                            </>

                        ) : null
                }
            </div>

                    <div className="text-content px-1 py-1">
                        {content}
                    </div>

            
        </div>);
}

ShareCard.propTypes = {
    userName: PropTypes.string,
    profilePicture: PropTypes.node,
    post: PropTypes.object.isRequired,
    onSelected: PropTypes.func.isRequired,
    loggedInUser: PropTypes.object
}

export default ShareCard;










