const initialState = {
    count: 0,
};

const INCREMENT_COUNT = "INCREMENT_COUNT";
const DECREMENT_COUNT = "DECREMENT_COUNT";

/**
 * @returns {{type:string}}
 */
export function increment() {
    return { type: INCREMENT_COUNT };
}

export default function counterReducer(state = initialState, action) {
    switch (action.type) {
        case INCREMENT_COUNT: {
            return { ...state, count: state.count + 1 };
        }
        case DECREMENT_COUNT: {
            return { ...state, count: state.count - 1 };
        }
        default:
            return state;
    }
}
