import React from "react";
import { Link } from "react-router-dom";
import { Form } from "reactstrap";

class InstagramAuth extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (this.props.location.search === "") {
            return (
                <section className="gray-bg full-height">
                    <div className="container py-5">
                        <div className="col-12">
                            <Form>
                                <div className="row mx-0 my-0">
                                    Connecting to Instagram ...
                                </div>
                            </Form>
                        </div>
                    </div>
                </section>
            );

        }
        return (
            <section className="gray-bg full-height">
                <div className="container py-5">
                    <div className="col-12">
                        <Form>
                            <div className="row mx-0 my-0">
                                You have successfully connected your Instagram account!
                            </div>
                            <div className="row mx-0 my-0">
                                <div class="form-group">
                                    <Link to="/"><button type="submit" class="btn btn-primary">Next</button></Link>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </section>
        );
    }
}

export default InstagramAuth;