import React from "react";
import Feed from "components/Feed/Feed";
import ProductFeed from "components/Feed/ProductFeed";
import PeopleFeed from "components/Feed/PeopleFeed";
import SearchFilterBar from "components/SearchFilterBar";
import NoResults from "components/NoResults";
import { getRequest } from "sharedUtils/httpUtils";
import { getAuthorizedUserData } from "sharedUtils/authUtils";
import LoadSpinner from "components/LoadSpinner";
import { Tabs } from "@material-ui/core";
import { getQueryStringParams, showBrandNav } from "sharedUtils/navUtils"
import { Helmet } from 'react-helmet';


class Search extends React.Component {
    state = {
        isGrid: true,
        lockerSearchResults: [],
        productSearchResults: [],
        postSearchResults: [],
        peopleSearchResults: [],
        searchString: "",
        /*1:Locker, 2:Products, 3:Posts, 4:People*/
        resultCase: 1,
        searching: true,
        loggedInUser: {},
        finishedFetchingLockers: false,
        finishedFetchingPosts: false,
        finishedFetchingProducts: false,
        query: "",
    }
    resultCases = {
        1: { name: "lockers", urlPath: "searchlockers", stateName:"lockerSearchResults" },
        2: { name: "posts", urlPath: "searchposts", stateName: "postSearchResults" },
        3: { name: "people", urlPath: "searchusers", stateName: "peopleSearchResults" },
        viewOrder : [1,2,3]
    }

    async componentDidMount() {
        this.props.setShowTopNav(true, !!(window.location.search.toLowerCase().includes("showbrandnav=")) ? this.props.showBrandNav(window.location.search) : true);
        const params = getQueryStringParams(window.location.search);
        const tab = params.tab;
        if (!!(tab)) {
            let resultCase;
            switch (tab.toLowerCase()) {
                case 'locker':
                    resultCase = 1;
                    break;
                case 'posts':
                    resultCase = 2;
                    break;
                case 'people':
                    resultCase = 3;
                    break;
                default:
                    resultCase = this.state.resultCase;
            }
            this.setState({ resultCase, query: decodeURIComponent(window.location.search) });
        }

        if (this.props.loggedInUser !== null) {
            this.setState({ loggedInUser: this.props.loggedInUser });
        }
        else {
            let user = await getAuthorizedUserData();
            this.setState({ loggedInUser: user });
        }
        this.setState({ searchString: this.props.match.params.searchString }, () => {
            this.fetchResults(this.state.resultCase,true)
        })
        this.props.populateSearch(decodeURIComponent(this.props.match.params.searchString));

        let scrollOptions = {
            left: 0,
            top: 0,
            behavior: 'auto',
            lockerNotFound: true
        }

        window.scrollTo(scrollOptions);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.match.params.searchString !== this.state.searchString || this.props.location.search.toLowerCase() !== this.state.query) {
            const params = getQueryStringParams(window.location.search);
            const tab = params.tab;
            let resultCase = this.state.resultCase;

            if (!!(tab)) {
                switch (tab.toLowerCase()) {
                    case 'locker':
                        resultCase = 1;
                        break;
                    case 'posts':
                        resultCase = 2;
                        break;
                    case 'people':
                        resultCase = 3;
                        break;
                    default:
                        resultCase = this.state.resultCase;
                }
                this.setState({ query: decodeURIComponent(this.props.location.search.toLowerCase()) , resultCase });
            }
            this.setState({ searchString: this.props.match.params.searchString }, () => this.fetchResults(resultCase))
            let scrollOptions = {
                left: 0,
                top: 0,
                behavior: 'auto',
                lockerNotFound: true
            }

            window.scrollTo(scrollOptions);
        }
    }

    fetchResults = async (caseNumber, skipIfEmpty) => {
        this.setState({ searching: true });
        //let paths = ["searchlockers", "searchproducts", "searchposts", "searchusers"];
        let url = `/search/${this.resultCases[caseNumber].urlPath}?userId=${this.state.loggedInUser?.id}&search=${this.state.searchString}&skip=0&take=24`;
        //this.setState({ resultCase: caseNumber });
        let results = await getRequest(url);

        let updateCaseNumber = caseNumber;
        let completedLoop = false;

        if (skipIfEmpty && (results == null || results.length == 0 )) {
            
            while (completedLoop == false && (results == null || results.length == 0 )) {

                let nextCase = this.jumpToNextCase(updateCaseNumber);
                if (nextCase == caseNumber) {
                    completedLoop = true;
                    break;
                }
              
                let nextUrl = `/search/${this.resultCases[nextCase].urlPath}?userId=${this.state.loggedInUser?.id}&search=${this.state.searchString}&skip=0&take=24`;
                results = await getRequest(nextUrl);
             
                updateCaseNumber = nextCase;
            }
            
        }
        //this.setState({ resultCase: updateCaseNumber });

        if (results !== undefined && results !== null && results.length > 0) {
            this.setResults(updateCaseNumber, results);
        }
        else {
            this.setResults(updateCaseNumber, []);
        }

        if (results === undefined || results === null || results?.length < 24) {
            const resultCase = this.state.resultCase;
            if (resultCase === 1) {
                this.setState({ finishedFetchingLockers: true })
            }
            else if (resultCase === 2) {
                this.setState({ finishedFetchingPosts: true })
            }
            else if (resultCase === 3) {
                this.setState({ finishedFetchingPeople: true })
            }
        }
    }

    jumpToNextCase = (currentCase) => {
        let newCase = 1;

        let caseOrder = [...this.resultCases.viewOrder];

        let currentCaseIndex = caseOrder.indexOf(currentCase);

        if (currentCaseIndex === caseOrder.length - 1) {
            return caseOrder[0];
        }
        else {
            return caseOrder[currentCaseIndex + 1];
        }

        /*if(currentCase < 4) {
            newCase = currentCase + 1;    
        }
        return newCase;*/
        
    }

    followUser = async (id) => {
        let url = `/api/Account/Follow?id=${id}`;
        let request = await getRequest(url);
    }

    fetchMore = async () => {
        //let paths = ["searchlockers", "searchproducts", "searchposts", "searchusers"];
        let { lockerSearchResults, postSearchResults, peopleSearchResults, resultCase } = this.state;

        let existingResults = [lockerSearchResults, postSearchResults, peopleSearchResults];

        let skip = existingResults[resultCase - 1].length;

        let url = `/search/${this.resultCases[resultCase].urlPath}?userId=${this.state.loggedInUser?.id}&search=${this.state.searchString}&skip=${skip}&take=24`;
        let data = await getRequest(url);

        if (data === undefined || data === null || data?.length < 24) {
            if (resultCase === 1) {
                this.setState({ finishedFetchingLockers: true })
            }
            else if (resultCase === 2) {
                this.setState({ finishedFetchingPosts: true })
            }
            else if (resultCase === 3) {
                this.setState({ finishedFetchingPeople: true })
            }
        }
        else {

            if (resultCase === 1) {
                let newData = [...lockerSearchResults, ...data];
                this.setState({ lockerSearchResults: newData });
            }
            else if (resultCase === 2) {
                let newData = [...postSearchResults, ...data];
                this.setState({ postSearchResults: newData });
            }
            else if (resultCase === 3) {
                let newData = [...peopleSearchResults, ...data];
                this.setState({ peopleSearchResults: newData });
            }
        }
    }

    setResults = (caseNumber, results) => {
        switch (caseNumber) {
            case 1: {
                this.setState({ lockerSearchResults: results, finishedFetchingLockers: results.length === 0 })
                break;
            }
            case 2: {
                this.setState({ postSearchResults: results, finishedFetchingPosts: results.length === 0 })
                break;
            }
            case 3: {
                this.setState({ peopleSearchResults: results })
                break;
            }
            default: {
                break;
            }
        }
        this.setState({ resultCase: caseNumber, searching: false })
    }

    toggleLayout = () => {
        let newLayout = !this.state.isGrid;
        this.setState({ isGrid: newLayout });
    }


    updatePosts = (newPosts) => {
        let { resultCase } = this.state;

        if (resultCase === 1) {
            this.setState({ lockerSearchResults: newPosts });
        }
        else if (resultCase === 3) {
            this.setState({ postSearchResults: newPosts });
        }
    }

    displayPostTypeResults = () => {
        let postsArray = this.state.resultCase === 1 ? this.state.lockerSearchResults : this.state.postSearchResults;
        let finishedFetching = this.state.resultCase === 1 ? this.state.finishedFetchingLockers : this.state.finishedFetchingPosts;
        if (postsArray.length === 0) {
            return <NoResults />
        }
        else {
            return (
                <Feed isGrid={this.state.isGrid}
                    posts={postsArray}
                    loggedInUser={this.state.loggedInUser}
                    finishedFetching={finishedFetching}
                    fetchMore={this.fetchMore}
                    updateParent={this.updatePosts}
                    showUserTile={true}
                    toggleSignUpModal={this.props.toggleSignUpModal}
                />
            )
        }

    }

    displayPeopleResults = () => {
        if (this.state.peopleSearchResults.length === 0) {
            return <NoResults />
        }
        else {
            return <PeopleFeed people={this.state.peopleSearchResults} toggleSignUpModal={this.props.toggleSignUpModal} fetchMore={this.fetchMore} finishedFetching={this.state.finishedFetchingPeople} loggedInUser={this.props.loggedInUser} />
            /*return this.state.peopleSearchResults.map((person, i) => {
                return (                   
                    <div className="col-md-6 col-lg-4 mb-4" key={i}>
                        <ProfileInfoCardSearchView name={person.name || ""}
                            userName={person.userName || ""}
                            profilePicture={person.imageUrl ? person.imageUrl : "/images/avatar.png"}
                            followAction={this.followUser}
                            id={person.id}
                            followers={person.followers}
                            following={person.following}
                        />
                    </div>
                )
            })*/
        }

    }

    displayResults = () => {
        if (this.state.searching === true) {
            return <LoadSpinner />
        }
        switch (this.state.resultCase) {
            case 3: {
                return this.displayPeopleResults();
            }
            default: {
                return this.displayPostTypeResults();
            }
        }
    }

    render() {
        let filterStyle = this.props.deviceSize === "sm" || this.props.deviceSize === "md" ? { position: "sticky" } : { position: "sticky", top: "50px" };
        let containerAppendClass = this.props.deviceSize === "sm" || this.props.deviceSize === "md" ? "mt-4" : "";
        return (

            <section className={`gray-bg full-height explore-feed-wrapper ${!(this.props.loggedInUser) && ["sm", "md"].includes(this.props.deviceSize) ? "" : "pt-3"}`}>
                <Helmet>
                    <title>RockPorch - Search: {!!(this.props.match.params.searchString) ? this.props.match.params.searchString : ""}</title>
                </Helmet>
                {
                    this.props.brandNav ?
                        <></>
                        :

                        <SearchFilterBar
                            fetchResults={this.fetchResults}
                            isGrid={this.state.isGrid}
                            toggleAction={this.toggleLayout}
                            hideToggleAction={this.state.resultCase === 4}
                            selectedCase={this.state.resultCase}
                            style={filterStyle}
                        />
                }
                
                <div className={`container ${containerAppendClass}`}>
                    <div className="row py-1 grid">
                        <div className="col-12 px-0">
                            {this.displayResults()}
                        </div>
                        {/*<div className="grid-sizer"></div>*/}

                    </div>
                </div>
            </section>

        )
    }
}


export default Search;