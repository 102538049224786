import * as React from "react";

function SvgCheckIcon(props) {
  return (
    <svg
      width={20}
      height={18}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
     
    >
      <path
        d="M19.166 9l-2.033-2.317.283-3.066-3.008-.684-1.575-2.65L10 1.5 7.166.283l-1.575 2.65-3.008.675.283 3.067L.833 9l2.033 2.316-.283 3.075 3.008.684 1.575 2.65L10 16.5l2.833 1.216 1.575-2.65 3.008-.683-.283-3.066L19.166 9zM8.333 13.166L5 9.833l1.175-1.175 2.158 2.15 5.492-5.491L15 6.5l-6.667 6.666z"
              fill={props.proStatusId === 1 ? "#1676EE" : props.fillColor === "brandPrimary" ? "#D46240" : props.fillColor === "grayedOut" ? "#AAAFB4" : "#E55934"}
      />
    </svg>
  );
}

export default SvgCheckIcon;
