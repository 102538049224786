import React from "react"

import PhoneIcon from "components/icons/PhoneIcon";

const MobileShare = (props) => {
    const handleOnClick = (e) => {
        e.preventDefault();
        if (navigator.share) {
            navigator
                .share({
                    title: `RockPorch`,
                    text: `Check out what I found on RockPorch: `,
                    url: `${ props.shareCode }`,
                })
                .then(() => {
                })
                .catch(error => {
                    console.error('Something went wrong sharing the blog', error);
                });
        }
    };

    if (navigator.share) {
        return (
            <a href="/" className="d-flex justify-content-center align-items-center mx-2" onClick={(e) => handleOnClick(e)}>
                <PhoneIcon />
            </a>
        );
    }
    return null;
}

export default MobileShare;