import React from "react";
import { FormGroup, Label, Input } from "reactstrap";
import ActionButton from "../../components/ActionButton";
import { getRequest, postRequest } from 'sharedUtils/httpUtils';
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import LoadSpinner from "../../components/LoadSpinner";
import Modal from "components/Modal";
import { getProperties } from "sharedUtils/postFunctions";

class FeaturedContent extends React.Component {
    state = {
        id: 0,
        addShareCode: "",
        showError: false,
        merchantExists: null,
        isSubmitting: false,
        isDeleting: false,
        isFetching: false,
        show: false,
        selectedCase: 1,
        addSubmitError: false,
        deleteError: false,
        topContentFromDb: [],
        content: [],
        FeaturedContentTable: {
            title: "Featured Content",
            columns: [
                {
                    name: "creatorUserName",
                    label: "Creator",
                    options: {
                        filter: true,
                        sort: true,
                        display: true // Column is not visible. Adding so that it will be included in CSV export.
                    },
                },
                {
                    name: "type",
                    label: "Type",
                    options: {
                        filter: true,
                        sort: true,
                        display: true // Column is not visible. Adding so that it will be included in CSV export.
                    },
                },
                {
                    name: "title",
                    label: "Title",
                    options: {
                        filter: true,
                        sort: true,
                        setCellProps: () => ({ style: { maxWidth: "350px", overflowWrap: "anywhere", wordBreak: "break-all" } }),
                    }
                },
                {
                    name: "imageUrl",
                    label: "Image URL",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            const url = value;
                            return <img src={url} style={{ height: "100px", width: "100px", objectFit: "contain" }} />;
                        },
                    }
                },
                {
                    name: "shareCode",
                    label: "Sharecode",
                    options: {
                        filter: true,
                        sort: true,
                        setCellProps: () => ({ style: { maxWidth: "200px", overflowWrap: "anywhere", wordBreak: "break-all" } }),
                    }
                },
                {
                    name: "postId",
                    label: "Post Id",
                    options: {
                        filter: true,
                        sort: true,
                    }
                },
                {
                    name: "published",
                    label: "Published",
                    options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return String(value);
                        },
                    }
                },
            ],
            options: {
                customToolbarSelect: () => { },
                selectableRows: "multiple",
                sortOrder: {
                    name: 'userId',
                    direction: 'asc'
                },
                customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
                    return (
                        <div className="d-flex flex-row align-items-center mx-1">
                            {/* https://v4.mui.com/components/checkboxes/#checkbox-with-formcontrollabel */}
                            <div className="dropdown inline-block mx-1 MuiTypography-subtitle1">
                                <button className="btn btn-primary dropdown-toggle MuiTypography-subtitle1" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Action
                                </button>
                                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                    <button className="dropdown-item" type="button" onClick={(e) => { this.confirmDelete(selectedRows, displayData); }}>Delete Selected Content</button>
                                    <button className="dropdown-item" type="button" onClick={(e) => { this.confirmPublishSelected(selectedRows, displayData); }}>Publish Selected Content</button>
                                </div>
                            </div>
                        </div>
                    )
                },
            },
            getTheme: () => createTheme({
                overrides: {
                    MUIDataTable: {
                        root: {
                        },
                        paper: {
                        },
                    },
                    MUIDataTableBodyRow: {
                        root: {
                            '&:nth-child(odd)': {
                                backgroundColor: '#F9F9F9'
                            },
                            cursor: 'pointer'
                        }
                    },
                    MUIDataTableBodyCell: {
                        root: {
                        }
                    },
                }
            })
        },
    };

    async componentDidMount() {
        this.setState({ isFetching: true });

        await this.fetchFeaturedContent();

        this.setState({ isFetching: false });
    }

    fetchFeaturedContent = async () => {
        const getUrl = "api/Featured/GetFeaturedContent?admin=true";
        const tc = await getRequest(getUrl);
        let content = this.createContentForDatatable(tc);
        this.setState({ content, topContentFromDb: tc });
    }

    createContentForDatatable = tc => {
        let content = [];
        if (!!(tc) && tc.length > 0) {
            content = tc.map(c => {
                let properties = getProperties(c.properties);
                let imageUrl;

                if (!!(properties)){
                    imageUrl = getProperties(c.properties)
                        .filter(property => property?.parentId == null && (property?.role === 0 || property?.role === 1 || property?.role === 3))
                        .map(property => {
                            return {
                                url: property?.value,
                            }
                        })[0]?.url;
                }
                const creatorUserName = c.user.userName;
                const shareCode = c.shareCode;
                const title = c.title;
                const published = c.featuredPublished
                const postId = c.id;
                const type = this.getType(c.type);
                return {
                    imageUrl, shareCode, title, creatorUserName, postId, type, published
                }
            });
        }
        return content;
    }

    getType = typeId => {
        return typeId === 4 ? "Blog" : typeId === 2 ? "Locker" : "Post";
    }


    getModalTitle = () => {
            return "Add Featured Content Item";
    }

    handleAddContentButton = () => {
        this.setState({ show: true })
    }

    displayToggleButtons = () => {
        let options = [
            {
                name: "Featured Content",
                case: 1,
                action: () => this.setState({ selectedCase: 1 })
            },
        ];

        return options.map((toggle, i) => {
            return (
                <button key={i}
                    className={`btn ${this.state.selectedCase === toggle.case ? "btn-primary btn-lg" : ""}`}
                    onClick={toggle.action}
                >
                    {toggle.name}
                </button>
            )
        })
    }


    breadCrumbList = [
        { link: "/Admin", text: "Admin" },
        { link: "/Admin/Featured", text: "Featured" },
    ];

    handleShareCode = (e) => {
        this.setState({ addShareCode: e.target.value });
    }


    handleSubmit = async () => {
        this.setState({ isSubmitting: true });

        const addUrl = "api/Featured/AddFeaturedContent";

        const payload = {
            shareCode: this.state.addShareCode
        }

        const postsWithAdd = await postRequest(addUrl, payload);
        if (!!(postsWithAdd)) {
            const content = this.createContentForDatatable(postsWithAdd);

            this.setState({ content, show: false });
            this.resetState();
        } else {
            this.setState({ addSubmitError: true });
        }

        this.setState({ isSubmitting: false });
    };

    confirmPublishAll = () => {
        const confirmDelete = window.confirm("ALL ITEMS will be published...");
        if (confirmDelete) {
            this.handlePublish();
        }
    }

    confirmPublishSelected = (selectedRows, displayData) => {
        const confirmDelete = window.confirm("SELECTED ITEMS will be published...");
        if (confirmDelete) {
             this.handlePublish(selectedRows, displayData);
        }
    }


    handlePublish = async (selectedRows = null, displayData = null) => {
        this.setState({ loading: true });
        let postIds = [];
        if (!!(selectedRows) && !!(selectedRows.data) && selectedRows.data.length > 0) {
            const selectedRowsData = selectedRows.data;
            postIds = selectedRowsData.length > 0 ? selectedRowsData.map(row => displayData[row.index].data[5]) : [];
        }

        const publishUrl = "api/Featured/PublishFeaturedContent";

        const payload = {
            postIds
        }

        const publishedPosts = await postRequest(publishUrl, payload);

        if (!!(publishedPosts)) {
            const content = this.createContentForDatatable(publishedPosts);

            this.setState({ content, publishError: false });
        } else {
            this.setState({ publishError: true });
        }

        this.setState({ loading: false });
    };

    confirmDelete = (selectedRows, displayData) => {
        const confirmDelete = window.confirm("Are you sure you want to DELETE this item? This will be permanent...");
        if (confirmDelete) {
            this.handleDelete(selectedRows, displayData);
        }
    }

    handleDelete = async (selectedRows, displayData) => {
        this.setState({ loading: true });

        const selectedRowsData = selectedRows.data;
        const postIds = selectedRowsData.map(row => displayData[row.index].data[5]);
        const deleteUrl = "api/Featured/DeleteFeaturedContentByPostId";

        const payload = {
            postIds
        }

        const postsWithDelete = await postRequest(deleteUrl, payload);

        if (!!(postsWithDelete)) {
            const content = this.createContentForDatatable(postsWithDelete);

            this.setState({ content, deleteError: false });
        } else {
            this.setState({ deleteError: true });
        }

        this.setState({ loading: false });
    }

    resetState = () => {
        this.setState({ addShareCode: "", addSubmitError: false });
    }

    render() {
        const submitDisabled = this.state.addShareCode.length === 0;

        return (
            <>
                <Modal isOpen={this.state.show} modalHeaderClass={"current-deals-modal-header"}
                    style={
                        {
                            overlay: {
                                position: 'fixed',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                backgroundColor: 'lightgray',
                                zIndex: 9998,
                            },
                            content: {
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                background: 'lightgray',
                                overflow: 'auto',
                                WebkitOverflowScrolling: 'touch',
                                borderRadius: '4px',
                                outline: 'none',
                                padding: '50px',
                                minWidth: '300px',
                                height: '350px',
                                zIndex: 1060,
                            }
                        }
                    }
                    onRequestClose={() => { this.setState({ show: false }); this.resetState(true); }}
                    toggleModal={() => { this.setState({ show: false }); this.resetState(true); }}
                >

                    <section className="gray-bg" style={{ marginBottom: "-20px", marginTop: "-5px" }}>
                        <div className="container pt-0 pb-5" >
                            <div className="d-flex flex-column pt-4 mb-2">
                                <h4>{this.getModalTitle()}</h4>
                            </div>
                            
                            <FormGroup className="form-group" >
                                <Label className="control-label">Post/Locker/Blog ShareCode:</Label>
                                <Input
                                    className="form-control"
                                    name="addShareCode"
                                    type="text"
                                    value={this.state.addShareCode}
                                    placeholder="Enter sharecode for Post/Locker/Blog here..."
                                    onChange={this.handleShareCode}
                                />
                            </FormGroup>
                            <p style={{ color: "red", display: this.state.addSubmitError ? "" : "none" }}>ERROR: check shareCode and try again</p>
                            <div className="d-flex">
                                <ActionButton className="btn btn-primary mt-3"
                                    onClick={this.handleSubmit}
                                    isLoading={this.state.isSubmitting}
                                    isDisabled={submitDisabled}
                                >
                                    Add
                                </ActionButton>

                            </div>
                        </div>

                    </section>
                </Modal>
                <section>
                    <div className="container-fluid">
                        {
                            this.state.isFetching ?
                                <LoadSpinner />
                                :
                                <div className="row">
                                    <div className="col">
                                        <div className="text-right mb-2">
                                            <ActionButton className="btn btn-primary mt-3 mr-2"
                                                onClick={this.confirmPublishAll}
                                            >
                                                Publish
                                            </ActionButton>
                                            <ActionButton className="btn btn-primary mt-3"
                                                onClick={this.handleAddContentButton}
                                            >
                                                Add Featured Content
                                            </ActionButton>
                                        </div>
                                        <p style={{ color: "red", display: this.state.deleteError ? "" : "none" }} className="text-right">ERROR DELETING CONTENT: try again</p>
                                        <p style={{ color: "red", display: this.state.publishError ? "" : "none" }} className="text-right">ERROR PUBLISHING CONTENT: try again</p>
                                        <ThemeProvider theme={this.state.FeaturedContentTable.getTheme()}>
                                            <MUIDataTable
                                                title={this.state.FeaturedContentTable.title}
                                                data={this.state.content}
                                                columns={this.state.FeaturedContentTable.columns}
                                                options={this.state.FeaturedContentTable.options}
                                            />
                                        </ThemeProvider>
                                    </div>
                                </div>
                        }
                    </div>
                </section>

            </>
        )
    }
}

export default FeaturedContent;