import React from "react";
import { Link } from "react-router-dom";

import AccountBanner from "components/AccountBanner";
import PenIcon from "components/icons/PenIcon";
import ActionButton from "components/ActionButton";
import FormInputField from "components/FormInputField";

import { getRequest, postRequest } from "sharedUtils/httpUtils";
import { DateTime } from "luxon";
import { createReturn } from "typescript";

class AffiliateNetworks extends React.Component {

    state = {
        affiliateNetworks: [],
        isEditing: false,
        newApmName: "",
        newApmUrl: "",
        commissionRuns: [],
        commissionStatusByApm: [],
    }

    async componentDidMount() {
        let networks = await this.fetchAffiliateNetworks();
        let commissionRuns = await this.fetchCommissionRuns();
        const affiliateNetworks = await this.calculateMerchantSynchronizationDate(networks);
        await this.calculateCommissionRunStatus(commissionRuns, networks);

        this.setState({ affiliateNetworks, commissionRuns });
    }


    addNewApm = async () => {
        let networks = [...this.state.affiliateNetworks];

        let newApm = {
            name: this.state.newApmName,
            apiUrl: this.state.newApmUrl
        }

        networks.push(newApm);

        this.setState({ affiliateNetworks: networks, newApmName: "", newApmUrl: "" });

        let url = "api/AffiliateProgramManager/AddAffiliateProgramManager";

        let created = await postRequest(url, newApm);
    }

    breadCrumbList = [
        { link: '/Admin', text: 'Admin' },
        { link: '/Admin/AffiliateNetworks', text: 'Affiliate Networks' }
    ]

    displayHeaders = () => {
        let headers = ["Name", "Api Url", "Merchant Synch Status", "Commission Status", "Edit"];
        return headers.map((header, i) => {
                if (i === 2 || i === 3) {
                return (
                    <th key={i} style={{ width: "110px" }}>
                        <strong>{header}</strong>
                    </th>
                )
            }
            return (
                <th key={i}>
                    <strong>{header}</strong>
                </th>
            )

        })
    }

    calculateCommissionRunStatus = (commissionRuns, affiliateNetworks) => {
        commissionRuns.map(cr => {
            const affiliateName = affiliateNetworks.filter(affiliate => cr.affiliateProgramManagerId === affiliate.id)[0].name.toLowerCase();
            const isSaS = affiliateName === "shareasale";

            const dateNow = new Date();
            const date = new Date(dateNow);
            const utc = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(),
                date.getUTCDate(), date.getUTCHours(),
                date.getUTCMinutes(), date.getUTCSeconds());
            const currentUtc = new Date(utc);
            const createdDate = new Date(cr.createdDate);

            const commissionRunHours = Math.floor((currentUtc - createdDate) / 1000 / 60 / 60);
            let commissionStatus = "";

            if (isSaS) {
                if (commissionRunHours <= 24) {
                    commissionStatus = "green";
                } else if (commissionRunHours > 24 && commissionRunHours <= 48) {
                    commissionStatus = "yellow";
                } else {
                    commissionStatus = "red";
                }
            } else {
                if (commissionRunHours <= 12) {
                    commissionStatus = "green";
                } else if (commissionRunHours > 12 && commissionRunHours <= 24) {
                    commissionStatus = "yellow";
                } else {
                    commissionStatus = "red";
                }    
            }

            this.setState({ commissionStatusByApm: [...this.state.commissionStatusByApm, { apmId: cr.affiliateProgramManagerId, commissionStatus }] })

        })
    }

    calculateMerchantSynchronizationDate = (networks) => {
        let updatedNetworks = [...networks];
        updatedNetworks.map(network => {
            if (network.merchantSynchDate != undefined && network.merchantSynchDate != null) {
                const dateNow = new Date();
                const date = new Date(dateNow);
                
                const merchantSynchDate = new Date(network.merchantSynchDate);
                const millisecondDate = new Date(merchantSynchDate);
                const fiveHoursInMilliseconds = 60 * 60 * 1000 * 5;
                const merchantSynchDateLocal = millisecondDate - fiveHoursInMilliseconds;

                const merchantSynchDateDifferenceInHours = Math.floor((date - merchantSynchDateLocal) / 1000 / 60 / 60);
                let msdStatus = "";

                if (merchantSynchDateDifferenceInHours <= 24) {
                    msdStatus = "green";
                } else if (merchantSynchDateDifferenceInHours > 24 && merchantSynchDateDifferenceInHours <= 48) {
                    msdStatus = "yellow";
                } else {
                    msdStatus = "red";
                }

                updatedNetworks = updatedNetworks.map(nw => {
                    if (nw.id === network.id) {
                        return { ...nw, merchantSynchStatus: msdStatus };
                    } else {
                        return {...nw}
                    }
                })
            }
        })
        return updatedNetworks;
    }

    displayRows = () => {
        return this.state.affiliateNetworks?.map((network, i) => {
            const statusObj = this.state.commissionStatusByApm.find(status => status.apmId === network?.id);
            const status = statusObj == undefined ? "" : statusObj.commissionStatus;
            return (
                <tr key={i}>
                    <td>
                        {network?.name}
                    </td>

                    <td className="text-left">
                        {network?.apiUrl}
                    </td>

                    <td>
                        <div className={`commission-status-${network?.merchantSynchStatus}`}></div>
                    </td>

                    <td>
                        <div className={`commission-status-${status}`}></div>
                    </td>

                    <td>
                        <Link to={ `/Admin/AffiliateNetworks/${network.id}`}>
                            <PenIcon />
                        </Link>
                    </td>
                </tr>
            )
        })
    }


    fetchAffiliateNetworks = async () => {
        let url = "api/AffiliateProgramManager/GetAffiliateProgramManagers";
        let networks = await getRequest(url);
        return networks;
    }

    fetchCommissionRuns = async () => {
        let url = "api/AffiliateProgramManager/GetLastCompletedCommissionRunAll";
        let commissionRuns = await getRequest(url);
        return commissionRuns;
    }


    render() {
        return (
            <>
                <section>
                    <AccountBanner breadCrumbList={this.breadCrumbList}>
                        <h1 className="mb-4">Affiliate Networks</h1>
                        <p></p>
                    </AccountBanner>
                </section>

                <section className="gray-bg full-height">
                    <div className="container pt-0">

                        <div className="row mx-0 my-0">
                            <div className="table-responsive product-table mt-3">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            {this.displayHeaders()}
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {this.displayRows()}
                                    </tbody>
                                </table>

                                
                            </div>

                            <div className="input-group mt-2">

                                <input
                                    type="text"
                                    className="form-control mx-2"
                                    placeholder="Name"
                                    onChange={e => this.setState({ newApmName: e.target.value })}
                                />

                                <input
                                    type="text"
                                    className="form-control mx-2"
                                    placeholder="Api Url"
                                    onChange={e => this.setState({ newApmUrl: e.target.value })}
                                />

                                

                                <ActionButton
                                    isDisabled={this.state.newApmName.length === 0 || this.state.newApmUrl.length < 5}
                                    className="btn btn-primary mx-2"
                                    onClick={this.addNewApm}
                                >
                                    Add +
                                </ActionButton>
                            </div>
                        </div>

                    </div>
                </section>
            </>
        )
    }
}



export default AffiliateNetworks