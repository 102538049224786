import React from "react";
import { getQueryStringParams } from "sharedUtils/navUtils"
import { Helmet } from "react-helmet";

class MerchantPage extends React.Component {

    async componentDidMount() {
        this.props.setShowTopNav(false);
        const path = window.location.pathname;
        const index = path.lastIndexOf("/") + 1;
        const merchantId = path.slice(index);

        var objUrlParams = new URLSearchParams(window.location.search);
        const creatorUserId = objUrlParams.get("userId");

        const redirectUrl = getQueryStringParams(window.location.search).redirecturl;
        const currentDeal = getQueryStringParams(window.location.search).currentDeal;

        let clickerUserId = "";
        if (this.props?.loggedInUser?.id != null && this.props?.loggedInUser?.id != undefined) {
            clickerUserId = this.props.loggedInUser.id;
        }
        let url = merchantId == null || creatorUserId == null ? "" : `/api/Merchant/RedirectToMerchantWithMerchantIdAndUserId?merchantId=${merchantId}&creatorUserId=${creatorUserId}&referrer=${document.referrer}&clickerUserId=${clickerUserId}`;
        if (!!(redirectUrl)) {
            url = url + "&redirectUrl=" + encodeURIComponent(redirectUrl);
        }
        if (!!(currentDeal)) {
            url = url + "&currentDeal=" + currentDeal;
        }
        if (url !== "") {
            this.merchantPageRedirectGoogleAnalytics4Tracking(url);
            window.location.replace(url);
        }

        this.props.setShowTopNav(false);
    }

    merchantPageRedirectGoogleAnalytics4Tracking = (url) => {
        window.gtag('event', 'MerchantPageRedirect', {
            send_to: 'G-9F2DZRE5FG',
            event_category: 'Redirect',
            event_label: `Merchant Page Redirect`,
            value: url
        });
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>Rockporch - Merchant Redirect</title>
                </Helmet>
            </>
        )
    }
}

export default MerchantPage;