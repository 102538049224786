import React from "react";
import AccountBanner from "../../components/AccountBanner"
import { getRequest, postRequest } from "sharedUtils/httpUtils";
import LoadSpinner from "../../components/LoadSpinner";
import InformationIcon from "components/icons/InformationIcon";
import PenIcon from "components/icons/PenIcon";
import { UncontrolledPopover, Popover, PopoverBody } from "reactstrap";
import { Link } from "react-router-dom";
import { CheckIconBlack } from "../../icons";
import ActionButton from "../../components/ActionButton"
import Modal from 'react-modal';
import { filter, wrap } from "lodash";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { Form, FormGroup, Label, Input } from "reactstrap";


class CreateCommission extends React.Component {

    state = {
        filter: "",
        selectedCase: 1,
        campaignFilter: "",
        filteredPartners: [],
        filteredCampaignPartners: [],
        partners: [],
        options: [],
        merchantValue: [],
        campaignMerchantValue: [],
        selectedMerchant: [],
        selectedCampaignMerchant: [],
        selectedUser: [],
        loading: false,
        sectors: [],
        show: false,
        isLoadingMerchants: false,
        isLoading: false,
        saleAmount: 0,
        showError: false,
        transactionDate: "",
        affiliateNetworkPaidDate: "",
        isSubmitting: false,
        showMerchantInput: false,
        commissionRate: 0,
        creatorCommission: 0,
        popover: false,
        popoverMessage: "Successfully saved commission...",
        zeroSaleAmountCheck: false,
        hundredPercentCommissionRate: false,
    }

    breadCrumbList = [
        { link: '/Admin', text: 'Admin' },
        { link: '/Admin/CreateCommission', text: 'Create Commission' }
    ]

    componentDidMount = async () => {
        let scrollOptions = {
            left: 0,
            top: 0,
            behavior: 'auto'
        }
        window.scrollTo(scrollOptions);
    }



    componentDidUpdate = (prevState) => {
        let element = document.querySelector('html');

        const handleClick = () => {
            if (this.state.popover) {
                this.setState({
                    popover: false, popoverMessage: "Successfully saved commission..." });
            }
        }
     

        element.addEventListener('click', function (e) {
            handleClick();
        });
    }

    getPartners = async () => {
        this.setState({ loading: true });
        let partnersUrl = `/api/Merchant/GetCommissionEstimate?getMaxByMerchant=true&getAllMerchants=true&userId=${this.state.selectedUser[0].id}`;
        let partners = await getRequest(partnersUrl);
        this.setState({ partners: partners, filteredPartners: partners, loading: false });
        if (this.state.selectedMerchant.length > 0) {
            const merchantName = this.state.selectedMerchant[0].merchantName;
            const selectedMerchant = partners.filter(p => p.merchantName === merchantName);
            this.setState({ selectedMerchant });
        }
    }

    onInputChange = (e) => {
        let filter = e.target.value;
        let filteredPartners = this.state.partners.filter(partner => partner.merchantName.toLowerCase().includes(filter.toLowerCase()));

        this.setState({ filter: filter, filteredPartners: filteredPartners });
    };

    onInputChangeQuery = (query) => {
        let filter = query;
        let filteredPartners = this.state.partners.filter(partner => partner.merchantName.toLowerCase().includes(filter.toLowerCase()));

        this.setState({ filter: filter, filteredPartners: filteredPartners });
    };

    onInputChangeCampaignMerchantQuery = (query) => {
        let campaignFilter = query;
        let filteredCampaignPartners = this.state.partners.filter(partner => partner.merchantName.toLowerCase().includes(campaignFilter.trim().toLowerCase()));

        this.setState({ campaignFilter, filteredCampaignPartners });
    };

    setSelectedMerchant = (selectedMerchant) => {
        if (selectedMerchant.length > 0) {
            this.setState({ selectedMerchant, commissionRate: selectedMerchant[0].commission, creatorCommission: this.state.hundredPercentCommissionRate ? this.state.saleAmount : this.state.saleAmount * selectedMerchant[0].commission })
        } else {
            this.setState({ selectedMerchant: [] });
        }
    }

    setSelectedCampaignMerchant = (selectedCampaignMerchant) => {
        if (selectedCampaignMerchant.length > 0) {
            this.setState({ selectedCampaignMerchant, commissionRate: selectedCampaignMerchant[0].commission, creatorCommission: this.state.hundredPercentCommissionRate ? this.state.saleAmount : this.state.saleAmount * selectedCampaignMerchant[0].commission })
        } else {
            this.setState({ selectedCampaignMerchant: [] });
        }
    }

    setSelected = async (selectedUser) => {
        await this.setState({ selectedUser, filteredPartners: [], showMerchantInput: false, isLoadingMerchants: true })
        if (selectedUser.length > 0) {

            await this.getPartners();
            this.setState({ showMerchantInput: true, isLoadingMerchants: false })
        }
    }
     

    fetchUsers = async (query) => {
        this.setState({ isLoading: true });

        let url = `/api/Account/GetUserSuggestionsByUsername?username=${query}`;

        let users = await getRequest(url);
        this.setState({ options: users, isLoading: false });
    }

    onCommissionInputChange = (e) => {
        this.setState({ showError: false, saleAmount: e.target.value, creatorCommission: this.state.hundredPercentCommissionRate ? e.target.value : e.target.value * this.state.commissionRate })
    }

    onDateInputChange = (e) => {
        this.setState({ transactionDate: e.target.value })
    }

    onAffiliateNetworkPaidDateInputChange = (e) => {
        this.setState({ affiliateNetworkPaidDate: e.target.value })
    }

    onSubmit = async () => {
        const saleAmount = Number.parseFloat(this.state.saleAmount);
        if (Number.isNaN(saleAmount) || saleAmount <= 0) {
            this.setState({ saleAmount: 0, showError: true })
            return;
        }

        this.setState({ isSubmitting: true });

        const userId = this.state.selectedUser[0].id;
        const merchantHostname = this.state.selectedMerchant[0].merchantHostname;
        const commission = this.state.hundredPercentCommissionRate ? 1 : this.state.selectedMerchant[0].commission;
        const apmId = this.state.selectedMerchant[0].affiliateProgramManagerId !== 0 ? this.state.selectedMerchant[0].affiliateProgramManagerId : 1; // just force an APM
        const date = this.state.transactionDate;
        const affiliateNetworkPaidDate = this.state.affiliateNetworkPaidDate.length > 0 ? this.state.affiliateNetworkPaidDate : this.state.transactionDate;
        const offset = new Date(this.state.transactionDate).getTimezoneOffset();
        const zeroSaleAmount = this.state.zeroSaleAmountCheck;
        const campaignMerchantHostname =
            this.state.selectedCampaignMerchant?.[0]?.merchantHostname || "";

        const commissionInfo = {
            userId,
            merchantHostname,
            commission,
            saleAmount,
            apmId,
            date,
            affiliateNetworkPaidDate,
            offset,
            zeroSaleAmount,
            campaignMerchantHostname,
        };
        let url = '/api/Account/CreateCommission';
        let result = await postRequest(url, commissionInfo);

        if (typeof result?.commission?.commissionAmount === "number") {
            this.setState({ isSubmitting: false, popover: true });
        } else {
            this.setState({ isSubmitting: false, popover: true, popoverMessage: "ERROR submitting commission..." });
        }
    }

    onChangeDummy = () => {

    }

    handlePopover = () => {
        this.setsState({
            popover: false
        });
    }

    onChangeZeroSaleAmountCheck = () => {
        this.setState({ zeroSaleAmountCheck: !this.state.zeroSaleAmountCheck });
    }

    onChangeHundredPercentCommissionRate = () => {
        this.setState({ hundredPercentCommissionRate: !this.state.hundredPercentCommissionRate });
    }

    render() {
        let enableSubmit = !!(this.state.saleAmount) && this.state.selectedUser.length > 0 && this.state.selectedMerchant.length > 0 && this.state.transactionDate.length > 0;

        //if (this.state.loading) {
        //    return <LoadSpinner />
        //}
        return (
            <div className="overflow-hidden" style={{ display: this.props.display ? "" : "none" }}>
                <section className="gray-bg full-height">
                    <div className="container pt-0 pb-5" style={{ maxWidth: "500px" }}>
                        <div className="search-interest py-4 mx-auto">
                            <div className="search-block">
                                <div className="position-relative mx-auto">
                                    <AsyncTypeahead
                                        id="userSearch"
                                        labelKey="userName"
                                        defaultSelected={this.state.value}
                                        clearButton
                                        onSearch={this.fetchUsers}
                                        onChange={selected => this.setSelected(selected)}
                                        isLoading={this.state.isLoading}
                                        options={this.state.options}
                                        placeholder="Search for users..." />
                                </div>
                            </div>
                        </div>

                        {this.state.showMerchantInput ?
                            <div className="search-interest py-4 mx-auto">
                                <div className="search-block">
                                    <div className="position-relative mx-auto">
                                        <AsyncTypeahead
                                            id="merchantSearch"
                                            labelKey="merchantName"
                                            defaultSelected={this.state.merchantValue}
                                            clearButton
                                            onSearch={this.onInputChangeQuery}
                                            onChange={selected => this.setSelectedMerchant(selected)}
                                            isLoading={this.state.isLoadingMerchants}
                                            options={this.state.filteredPartners}
                                            placeholder="Search for merchants..." />
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="search-interest py-4 mx-auto">
                                <div className="search-block">
                                    <div className="position-relative mx-auto">
                                        <AsyncTypeahead
                                            id="merchantSearch"
                                            labelKey="merchantName"
                                            defaultSelected={this.state.merchantValue}
                                            disabled
                                            clearButton
                                            onSearch={this.onInputChangeQuery}
                                            onChange={selected => this.setSelectedMerchant(selected)}
                                            isLoading={this.state.isLoadingMerchants}
                                            options={this.state.filteredPartners}
                                            placeholder="Search for merchants..." />
                                    </div>
                                </div>
                            </div>
                        }

                        {this.state.selectedMerchant?.length > 0 ?
                            <div className="search-interest py-4 mx-auto">
                                <div className="search-block">
                                    <div className="position-relative mx-auto">
                                        <AsyncTypeahead
                                            id="campaingMerchantSearch"
                                            labelKey="merchantName"
                                            defaultSelected={this.state.campaignMerchantValue}
                                            clearButton
                                            onSearch={this.onInputChangeCampaignMerchantQuery}
                                            onChange={selected => this.setSelectedCampaignMerchant(selected)}
                                            isLoading={this.state.isLoadingMerchants}
                                            options={this.state.filteredCampaignPartners}
                                            placeholder="Search for campaign merchants..." />
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="search-interest py-4 mx-auto">
                                <div className="search-block">
                                    <div className="position-relative mx-auto">
                                        <AsyncTypeahead
                                            id="campaingMerchantSearch"
                                            labelKey="merchantName"
                                            defaultSelected={this.state.campaignMerchantValue}
                                            disabled
                                            clearButton
                                            onSearch={this.onInputChangeCampaignMerchantQuery}
                                            onChange={selected => this.setSelectedCampaignMerchant(selected)}
                                            isLoading={this.state.isLoadingMerchants}
                                            options={this.state.filteredCampaignPartners}
                                            placeholder="Search for campaign merchants..." />
                                    </div>
                                </div>
                            </div>
                        }

                        <FormGroup key="0" className="form-group d-flex flex-column justify-content-between mr-3" style={{ width: "250px", marginTop: "20px", paddingLeft: "20px" }}>
                            <input class="form-check-input" type="checkbox" id="zero-sale-amount" checked={this.state.zeroSaleAmountCheck} onChange={this.onChangeZeroSaleAmountCheck} />
                            <label class="form-check-label" for="zero-sale-amount">
                                Record $0 Sale Amount
                            </label>
                        </FormGroup>

                        <FormGroup key="0" className="form-group d-flex flex-column justify-content-between mr-3" style={{ width: "250px", marginTop: "20px", paddingLeft: "20px" }}>
                            <input class="form-check-input" type="checkbox" id="hundred-percent-commission-rate" checked={this.state.hundredPercentCommissionRate} onChange={this.onChangeHundredPercentCommissionRate} />
                            <label class="form-check-label" for="hundred-percent-commission-rate">
                                100% Commission Rate
                            </label>
                        </FormGroup>
                        
                        <div className="d-flex">
                        <FormGroup key="0" className="form-group d-flex flex-column justify-content-between mr-3" style={{ width: "150px", marginTop: "20px" }}>
                            {this.state.showError ? 
                                <p style={{ color: "red" }}>Sales Amount must be a number greater than zero</p>
                                : <></>
                            }
                            <Label className="control-label">ENTER Sale Amount:</Label>
                            <Input
                                className="form-control"
                                name="saleAmount"
                                type="text"
                                placeholder="Sales Amount..."
                                onChange={(e) => this.onCommissionInputChange(e)}
                                    value={this.state.saleAmount !== 0 ? this.state.saleAmount: ""}
                            />
                        </FormGroup>

                        <div className="position-relative" style={{ top: "78px", right: "8px" }}>
                            <h4>&times;</h4>
                        </div>

                            <FormGroup key="10" className="form-group d-flex flex-column justify-content-between my-3" style={{ width: "200px", marginTop: "20px" }}>
                            <Label className="control-label">Creator Commission Rate:</Label>
                            <Input
                                disabled
                                className="form-control"
                                name="saleAmount"
                                type="text"
                                placeholder="Commission rate..."
                                onChange={(e) => this.onChangeDummy(e)}
                                    value={this.state.hundredPercentCommissionRate ? "100%" : this.state.commissionRate != null && this.state.commissionRate !== 0 ? this.state.commissionRate * 100 + "%" : ""}
                            />
                            </FormGroup>

                            <div className="position-relative" style={{ top: "78px", left: "8px" }}>
                                <h4>&#x3D;</h4>
                            </div>
                            <FormGroup key="20" className="form-group d-flex flex-column justify-content-between ml-3" style={{ width: "150px", marginTop: "20px" }}>
                            <Label className="control-label">Creator Commission:</Label>
                            <Input
                                className="form-control"
                                name="saleAmount"
                                type="text"
                                disabled    
                                placeholder="Commission..."
                                onChange={(e) => this.onChangeDummy}
                                    value={this.state.creatorCommission != null && this.state.creatorCommission !== 0 ? "$" + Number.parseFloat(this.state.creatorCommission).toFixed(2) : ""}
                            />
                            </FormGroup>
                        </div>

                        <FormGroup key="1" className="form-group" style={{ width: "380px", marginTop: "20px", zIndex: 25 }}>
                            <Label className="control-label">Transaction Date:</Label>
                            <Input
                                className="form-control"
                                name="transactionDate"
                                type="date"
                                onChange={(e) => this.onDateInputChange(e)}
                                value={this.state.transactionDate}
                            />
                        </FormGroup>

                        <FormGroup key="1" className="form-group" style={{ width: "380px", marginTop: "20px", zIndex: 25 }}>
                            <Label className="control-label">Affiliate Network Paid Date: <span style={{ fontSize: "10px" }}>*defaults to Transaction Date when blank</span></Label>
                            <Input
                                className="form-control"
                                name="affiliateNetworkPaidDate"
                                type="date"
                                onChange={(e) => this.onAffiliateNetworkPaidDateInputChange(e)}
                                value={this.state.affilateNetworkPaidDate}
                            />
                        </FormGroup>

                        <ActionButton className="btn btn-primary"
                            onClick={this.onSubmit}
                            message={{ showMessage: this.state.popover, messageText: this.state.popoverMessage }}
                            isLoading={this.state.isSubmitting}
                            isDisabled={!enableSubmit}
                        >
                            Submit
                        </ActionButton>
                    </div>
                </section>
            </div>
        )
    }
}

export default CreateCommission;