import React, { useState, useEffect } from "react";
import { Modal } from "reactstrap";
import FormTextArea from "components/FormTextArea";
import FormInputField from "components/FormInputField";
import { getRequest } from "sharedUtils/httpUtils";
import StarRatingComponent from "react-star-rating-component";
import RatingIcon from "components/icons/RatingIcon";
import RatingIconHalf from "components/icons/RatingIconHalf";
import RatingIconEmpty from "components/icons/RatingIconEmpty";
import { FormGroup, Label } from "reactstrap";
import ActionButton from "components/ActionButton";

/**
 * 
 * @param {{
 * showModal:boolean,
 * toggleModal:Function,
 * product:object,
 * productDescription:string,
 * saveDescription:(description:string)=>void,
 * sectors:[],
 * updateSectors:(sectorIds:Array<Number>)=>void,
 * saveSectorChanges:()=>void,
 * updateProductRating:(rating:Number)=>void
 * }} props
 */
const EditProductModal = (props) => {
    const [description, setDescription] = useState('');
    const [sectors, setSectors] = useState([]);
    const [sectorFilter, setSectorFilter] = useState("");
    const [productRating, setProductRating] = useState(0);
    const [product, setProduct] = useState({});
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        if (!saving) {
            setDescription(props.product?.userDescription || "");
            setSectors(props.sectors);
            setProductRating(props.product?.rating || 0);
            setProduct(props.product);
        }
    }, [props.product]);

    function removeSector(sectorId) {
        let productSectors = [...props.product.sectorIds];
        let location = productSectors.findIndex(secId => secId === sectorId);
        productSectors.splice(location, 1);
        props.updateSectors(productSectors);
    }

    function addSector(sectorId) {
        let productSectors = [...props.product.sectorIds];
        productSectors.push(sectorId);
        props.updateSectors(productSectors)
    }

    function displaySectors() {
        return sectors.filter(sector => sector.name.toLowerCase().includes(sectorFilter.toLowerCase()))
            .map((sector, i) => {
                if (props.product?.sectorIds?.includes(sector.sectorId)) {
                    return (
                        <button className="btn d-flex align-items-center active" onClick={() => { removeSector(sector.sectorId); setSectorFilter(""); }} key={i}>
                            {sector.name}
                        </button>
                    )
                }
                else {
                    return (
                        <button className="btn d-flex align-items-center" onClick={() => {addSector(sector.sectorId); setSectorFilter("");}} key={i}>
                            {sector.name}
                        </button>
                    )
                }
            });
    }

    function startHover(nextValue, prevValue, name) {
        setProductRating(nextValue);
    }

    function startHoverOut(nextValue, prevValue, name) {
        setProductRating(product.rating || 0);
    }

    function displayRating() {
        return <StarRatingComponent
            name="editProductRating"
            starCount={5}
            value={productRating}
            renderStarIcon={(index, value) => {
                if (index <= value) {
                    return (<span className="ratingIcon" key={index}><RatingIcon /></span>);
                } else {
                    return (<span className="ratingIcon" key={index}><RatingIconEmpty /></span>);
                }
            }}
            renderStarIconHalf={(index, value) => {
                return (<span className="ratingIcon" key={index}><RatingIconHalf /></span>);
            }}
            onStarClick={async (nextValue, prevValue, name) => {
                setProductRating(nextValue);
            }}
        />
    }


    return (
        <Modal style={{ marginTop: "100px"}} isOpen={props.showModal}
            toggle={props.toggleModal}
        >
            <div className="p-3">
                <div className="row mt-0">
                    <div className="col-md-12">
                        <div className="form">
                            <FormTextArea
                                title="Product Review"
                                name="description"
                                handleInput={(e) => setDescription(e.target.value)}
                                type="textarea"
                                value={description}
                                placeholder=""
                                inputStyle={{ backgroundColor: "#e9ecef" }}
                            />

                            <FormInputField title="Filter sectors"
                                name="sectorFilter"
                                value={sectorFilter}
                                onInputChange={(e) => setSectorFilter(e.target.value)}
                                placeholder="Search for interests..."
                                inputStyle={{ backgroundColor: "#e9ecef" }}
                            />

                            <FormGroup>
                                <Label>
                                    <strong>Rating</strong>
                                </Label>
                                <br/>

                                {displayRating()}
                            </FormGroup>
                            <div className="tag-results-sm d-flex justify-content-center flex-wrap py-0">
                                {displaySectors()}
                            </div>

                            <div className="form-group button-block text-md-right mb-0">

                                <button className="btn btn-secondary mt-3" onClick={props.toggleModal}>
                                    Cancel
                                </button>

                                <ActionButton className="btn btn-primary ml-3 mt-3"
                                    onClick={
                                        async () => {
                                            setSaving(true);
                                            await props.saveDescription(description);
                                            await props.saveSectorChanges();
                                            await props.submitRating(productRating, product);
                                            await props.updateProductsAndLinks();
                                            setSaving(false);
                                            props.toggleModal();
                                            props.goToSavedLinks();
                                        }
                                    }
                                    isLoading={saving}
                                >
                                    Save
                                </ActionButton>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Modal>

    )
}


export default EditProductModal;