import React, { Component } from "react";
import ActionButton from "components/ActionButton";
import { postSpreadsheet } from "sharedUtils/httpUtils";

class AmazonCommissionUpload extends Component {
    state = {
        isLoading: false,
        error: false,
    }

    handleFileChange = (event) => {
        const file = event.target.files[0];
        this.handleUpload(file);
    };

    handleUpload = async (file) => {
        this.setState({ error: false, isLoading: true });

        const formData = new FormData();
        formData.append("file", file);
        console.log(this.props.affiliateProgramManager?.id, this.props.affiliateProgramManager)
        const response = await postSpreadsheet("/Products/UploadCommissions?apmId=" + this.props.affiliateProgramManager?.id, formData);

        this.setState({ error: !(response), isLoading: false });
    };

    render() {
        return (
            <>
                <section className="gray-bg pb-3 pt-4">
                    <div>
                        <h5 style={{ fontSize: "15px", fontWeight: "bold" }}>Amazon Commission Report CSV Upload</h5>
                        <p
                            className="mb-0 mt-3"
                            style={{ color: "red", display: this.state.error ? "" : "none" }}
                        >
                            There was an error uploading the report. Try again.
                        </p>
                        <input
                            type="file"
                            accept=".csv, .zip"
                            onChange={this.handleFileChange}
                            style={{ display: "none" }}
                            ref={(input) => (this.fileInput = input)}
                        />
                        <ActionButton
                            className="btn btn-primary mt-1"
                            style={{ width: "215px" }}
                            onClick={() => this.fileInput.click()}
                            isLoading={this.state.isLoading}
                        >
                            Upload Report
                        </ActionButton>
                    </div>
                </section>
            </>
        );
    }
}

export default AmazonCommissionUpload;
