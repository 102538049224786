import axios from 'axios';

const instance = axios.create();

const setDelay = (callback) => {
    setTimeout(() => {
        callback();
    }, 800);
}

const clientRequest = (method, url, data, delay = true, headers) => new Promise((resolve, reject) => {
    (() => {
        if (method === 'get') {
            return instance.request({
                url, method, params: data, headers,
            });
        }
        return instance.request({
            url, method, data, headers: {},
        });
    })()
        .then((res) => {
            if (delay) {
                setDelay(() => {
                    resolve(res.data);
                });
            } else {
                resolve(res.data);
            }
        })
        .catch((err) => {
            const { data: error } = err.response || {};
            reject(error);
        });
});

export const ClientRequest = {
    get: (endpoint, data, delay, headers) => clientRequest('get', endpoint, data, delay, headers),
    post: (endpoint, data, delay) => clientRequest('post', endpoint, data, delay),
    put: (endpoint, data, delay) => clientRequest('put', endpoint, data, delay),
    del: (endpoint, data, delay) => clientRequest('delete', endpoint, data, delay),
};