import React from "react";
import AccountBanner from "../../components/AccountBanner";
import { Form, FormGroup, Label, Input } from "reactstrap";
import ActionButton from "../../components/ActionButton";
import { isValidUrl } from "sharedUtils/validationUtils";
import { getRequest, postRequest } from 'sharedUtils/httpUtils';
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { convertToNumericMonthAndDay } from "sharedUtils/timeUtils";
import LoadSpinner from "../../components/LoadSpinner";
import Modal from "components/Modal";
import FeaturedContent from "./FeaturedContent";
import FeaturedProducts from "./FeaturedProducts";
import FeaturedCreators from "./FeaturedCreators";


class Featured extends React.Component {
    state = {
        selectedCase: 1,
    };

    displayToggleButtons = () => {
        let options = [
            {
                name: "Featured Content",
                case: 1,
                action: () => this.setState({ selectedCase: 1 })
            },
            {
                name: "Featured Products",
                case: 2,
                action: () => this.setState({ selectedCase: 2 })
            },
            {
                name: "Featured Creators",
                case: 3,
                action: () => this.setState({ selectedCase: 3 })
            },
        ];

        return options.map((toggle, i) => {
            return (
                <button key={i}
                    className={`btn ${this.state.selectedCase === toggle.case ? "btn-primary btn-lg" : ""}`}
                    onClick={toggle.action}
                >
                    {toggle.name}
                </button>
            )
        })
    }

    displayContent = () => {
        const selected = this.state.selectedCase;

        switch (selected) {
            case 1:
                return <FeaturedContent />
            case 2:
                return <FeaturedProducts />
            case 3:
                return <FeaturedCreators />
        }
    }

    breadCrumbList = [
        { link: "/Admin", text: "Admin" },
        { link: "/Admin/Featured", text: "Featured" },
    ];

    render() {
        return (
            <>
                <section>
                    <AccountBanner breadCrumbList={this.breadCrumbList}>
                        <h1 className="mb-4">Featured</h1>
                        <p className="mb-0">Manage Featured Content, Products, and Creators for the marketing site</p>
                    </AccountBanner>
                </section>
                <section className="filter-wrapper" >
                    <div className="container">
                        <div className="row py-2 mt-0">
                            <div className="col-12 d-flex justify-content-between">
                                <div className="filter-block d-flex">
                                    {this.displayToggleButtons()}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {this.displayContent()}
            </>
        )
    }
}

export default Featured;