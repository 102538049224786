import React, { useState } from "react";
import LoadSpinner from "components/LoadSpinner";
import { Link } from "react-router-dom";
import RockporchLogo from "icons/RockporchLogo";
import RockporchMountainIcon from "components/icons/RockporchMountainIcon";
import FormInputField from "components/FormInputField";
import ActionButton from "components/ActionButton";
import { Popover, PopoverBody, PopoverHeader } from "reactstrap";
import { isValidUrl } from "sharedUtils/validationUtils";
import { useCallback } from "react";
import { useEffect, useRef } from "react";
/**
 * 
 * @param {{advance:Function,productUrl:string,handleInput:Function, isLoading:boolean, productNotFound:boolean, isModal:boolean}} props 
 */
const LookupProductUrlForm = (props) => {

    let [showLearnMore, setLearnMore] = useState(false);
    let [showValidationMessage, setShowValidationMessage] = useState(false);

    const inputRef = useRef(null);

    useEffect(() => {
        if (inputRef && inputRef.current) {
            inputRef.current.focus();
        }
    }, [])

    useEffect(() => {
        let isValid = isValidUrl(props.productUrl);
        if (props.productUrl.length > 3 && (isValid === false)) {
            setShowValidationMessage(true);
        }
        else {
            setShowValidationMessage(false);
        }
    }, [props.productUrl])

    let isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    let buttonStyle = {
        background: "#D2E3E0"
    }

    function toggleShowLearnMore() {
        setLearnMore(true);
        setTimeout(() => {
            setLearnMore(false)
        }, 5000);
    }

    const advanceButtonDisabled = (url) =>  (isValidUrl(url) === false);

    let bookmarkUrl = `${window.location.origin}/addProductTool/addProductTool.js`;
    let bookmarkOrigin = window.location.origin;

    const displayValidationMessage = () => {
        if (showValidationMessage && advanceButtonDisabled) {
            return (
                <p style={{ fontSize: "12px" }}>

                    Sorry, that doesn't look like a product URL. <br />
                    Copy and paste the URL of the product you're trying to add. <br />
                    Example: <span style={{ color: "#e55934" }}>https://www.example.com/...</span>

                </p>
            )
        }
        else {
            return <span></span>
        }
    }

    const onClickValidationCheck = () => {
        if (isValidUrl(props.url) !== false) props.advance();
    }

    const keypressValidationCheck = (e) => {
        if (isValidUrl(props.url) !== false) props.onKeypress(e);
    }

    if (props.isModal === false) {
        return (
            <div style={{ margin: "20% auto", textAlign: "center" }}>
                {
                    props.isLoading === true ? (
                        <>
                            <LoadSpinner />
                            <p className="mt-2">Looking up product ...</p>
                        </>
                    ): <span></span>
                }

                {
                    showValidationMessage === true ? (

                        displayValidationMessage()
                        
                        ):<span></span>
                }
                        

                {
                    props.productNotFound === true ? (
                        <>
                            <p style={{ color: "#e55934" }}>
                                {props.productNotFoundMessage !== "" ? "Sorry!" : ""}<br />
                                {props.productNotFoundMessage}
                            </p>
                            <h6 className="text">Click <Link to="/Account/Brands" onClick={props.toggle}>HERE</Link> for a list of Integrated Merchants</h6>
                        </>
                    ) : <span></span>
                }
            </div>
        )
    }

    
    else {
        return (
            <div className="col-md-6 text-center" style={props.customStyle}>
                <div className="share-block" style={{ ...props.shareBlockStyle }}>
                    {/*<ShareForm shareCode={props.shareCode} title={props.title} />*/}


                    <div style={{ textAlign: "left" }}>
                        <h5 className="mb-4">Add Product</h5>
                        <h6 className="text">Paste product URL here.  All product URLs must be from a <Link to="/Account/Brands" onClick={props.toggle}>Merchant Partner</Link></h6>

                        <div className="mb-4 d-flex justify-content-around" style={{ marginLeft: "0px" }}>

                            {/*<FormInputField onInputChange={props.handleInput} />*/}
                            <input
                                ref={inputRef}
                                className="w-75 mr-3"
                                type="text"
                                defaultValue={props.productUrl}
                                id="myInput"
                                onChange={props.handleInput}
                                onKeyPress={keypressValidationCheck}
                                style={{ backgroundColor: "#e9ecef", border: "none" }}
                            />
                            <ActionButton onClick={onClickValidationCheck} className="btn btn-primary" isLoading={props.isLoading} isDisabled={advanceButtonDisabled(props.productUrl) }> Add Product</ActionButton>
                        </div>



                    </div>

                    {
                        showValidationMessage === true ? (

                            displayValidationMessage()

                        ) : <span></span>
                    }

                    {
                        props.productNotFound === true ? (
                            <>
                                <small style={{ color: "#e55934" }}>
                                    Sorry!<br />
                                    {props.productNotFoundMessage}
                                </small>
                                <p>&nbsp;</p>
                                <h6 className="text">Click <Link to="/Account/Brands" onClick={props.toggle}>HERE</Link> for a list of Merchant Partners</h6>
                            </>
                        ) : <span></span>
                    }

                    <hr />
                    {
                        props?.isModal !== false ?
                            isMobileDevice ?
                                (<div>
                                    <p>
                                        <b style={{ color: "#e55934" }}>PRO TIP: </b> When adding several products to your inventory, the process moves much faster on a desktop!
                                    </p>
                                </div>)
                                :
                                (<div>
                                    <p>
                                        Drag the button below to your browser's bookmark bar. When on a merchant partner's product page, click the RockPorch bookmark to add the product to your Inventory.
                                        <Link className="ml-1" id="addProductLearnMore" to="/" onClick={e => {
                                            e.preventDefault();
                                            toggleShowLearnMore();
                                        }}>
                                            Learn more
                                        </Link>

                                        <Popover placement="bottom"
                                            isOpen={showLearnMore}
                                            target="addProductLearnMore"
                                            trigger="legacy"
                                            toggle={() => setLearnMore(false)}
                                        >
                                            <PopoverBody>
                                                You must be on an individual product page to add the product. The tool will not work if multiple products are on the current page.
                                            </PopoverBody>
                                        </Popover>
                                    </p>

                                    <a href={`javascript:(function() { \n
                                    let location = "${bookmarkOrigin}" +"/Product/AddProductModal?url="+ encodeURIComponent(window.location.href);\n
                                    window.open(location,"AddProduct","toolbar=no,status=no,resizable=yes,width=800,height=640,scrollbars=yes")\n
                                })()`}
                                        className="btn"
                                        style={buttonStyle} title="Drag to your bookmark bar"
                                        onClick={(e) => e.preventDefault()}
                                    >
                                        <RockporchMountainIcon /> +Add to RockPorch
                                    </a>

                                </div>) : <span></span>
                    }
                </div>
            </div>
        )
    }


}


export default LookupProductUrlForm;