import React from "react";

/**
 * 
 * @param {{height:number,width:number}} props
 */
const RockporchMountainIcon = (props) => {
    return (
        <svg width={props.width || 16} height={props.height || 16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M11.424 3.99533L13.712 7.99067H11.432L6.856 15.9907H4.568L11.424 3.99533H9.136L2.288 16H0L9.144 0L11.424 3.99533ZM0 11.9953V8L4.568 0H6.856L0 11.9953ZM13.712 8L16 11.9953V15.9907L13.712 11.9953H11.424L13.712 8ZM11.424 11.9953L13.712 15.9907H9.144L11.424 11.9953Z" fill="#191D30" />
        </svg>
        )
}

export default RockporchMountainIcon;