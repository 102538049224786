import React from "react";

/**
 * 
 * @param {{width:Number,height:Number,fill:string}} props
 */
const AnalyticsIcon = (props) => {
    return (
        <svg width={props.width || "20"} height={props.height || "20"} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.8333 2.5H4.16667C3.25 2.5 2.5 3.25 2.5 4.16667V15.8333C2.5 16.75 3.25 17.5 4.16667 17.5H15.8333C16.75 17.5 17.5 16.75 17.5 15.8333V4.16667C17.5 3.25 16.75 2.5 15.8333 2.5ZM7.5 14.1667H5.83333V8.33333H7.5V14.1667ZM10.8333 14.1667H9.16667V5.83333H10.8333V14.1667ZM14.1667 14.1667H12.5V10.8333H14.1667V14.1667Z" fill={props.fill || "black"} fillOpacity="0.6" />
        </svg>
    )
}

export default AnalyticsIcon;