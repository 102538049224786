import React, { Component } from "react";
import _ from "lodash";
import LoadSpinner from "./LoadSpinner";
import ImageWrapper from "components/ImageWrapper";
import { titleCase } from "sharedUtils/productUtils";
import MerchantRowMobile from "views/Account/AnalyticsByMerchantRowMobile";
import NoResults from "components/NoResults";
import Modal from 'react-modal';


const { DateTime } = require("luxon");


class AnalyticsByMerchantTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            startDate: DateTime.utc().minus({ months: 1 }).startOf('day'),
            endDate: DateTime.utc().startOf('day'),
            tab: 1,
            show: false,
            showSales: false,
            clicked: null,
            salesClicked: null,
        };
    }

    componentDidMount = async () => {
        await this.props.resetPerformanceTabs();
        await this.props.getMerchantAnalytics(this.props.startDate, this.props.endDate);
    }

    componentDidUpdate = async (prevProps, prevState) => {
        if (prevProps.userChanged != null && prevProps.userChanged != this.props.userChanged && this.props.userChanged) {
            await this.props.getMerchantAnalytics(this.props.startDate, this.props.endDate);
        }
        if (prevProps.aggregateChanged != null && prevProps.aggregateChanged !== this.props.aggregateChanged && this.props.display) {
            await this.props.getMerchantAnalytics(this.props.startDate, this.props.endDate);
        }
        if (prevProps.browseChanged != null && prevProps.browseChanged !== this.props.browseChanged && this.props.display) {
            await this.props.getMerchantAnalytics(this.props.startDate, this.props.endDate);
        }
    }

    showModal = (e) => {
        e.preventDefault();
        this.setState({ show: !this.state.show });
    };

    hideModal = () => {
        this.setState({ show: false, clicked: null });
        this.props.clearPerformance();
        this.props.clearSourceSort();
    }

    showSalesModal = (e) => {
        e.preventDefault();
        this.setState({ showSales: !this.state.showSales });
    };

    hideSalesModal = () => {
        this.setState({ showSales: false, salesClicked: null });
        this.props.clearPerformance();
        this.props.clearSalesSourceSort();
    }

    displayMerchantMobile = () => {
        if (this.props.merchantAnalytics?.length === 0) {
            return (
                <div className="mt-3">
                    <NoResults />
                </div>
            )
        }

        const merchantData = {
            imageUrl: "",
            name: "",
            price: "",
            sale: "",
            salePrice: "",
            integrated: "",
            merchant: "",
            clicks: "clicks",
            sales: "sales",
            conversionRate: "conversions"
        };

        if (this.props.merchantAnalytics?.length > 0) {
            const analytics = [merchantData, ...this.props.merchantAnalytics];
            return analytics?.map((merchant, i) => {
                return (
                    <div key={i}>
                        <MerchantRowMobile
                            handleGetLink={this.props.handleGetLink}
                            merchant={merchant}
                            productKey={i}
                            key={i}
                            edit={() => { }}
                            hide={() => { }}
                            toggleProductModal={() => { }}
                            toggleShareModal={() => { }}
                            isUserMerchant={false}
                            editClick={() => { }}
                            hideClick={() => { }}
                            deviceSize={this.props.deviceSize}
                            handleSortSelect={this.props.handleMerchantSortSelect}
                            sortDescending={this.props.merchantSortDescending}
                            selectOptions={this.props.merchantSelectOptions}
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            getAnalyticsPerformanceSourceByMerchant={this.props.getAnalyticsPerformanceSourceByMerchant}
                            handleClicksClick={this.handleClicksClick}
                            handleSalesClicksClick={this.handleSalesClicksClick}
                        />
                    </div>
                )

            })
        }
        return <></>;
    }

    displayTableHeader = () => {
        let headers = ["Brand", "Clicks", "Sales Count", "Sales", "Commissions", "Conversion"];

        return headers.map((header, i) => {
            const options = [
                { label: "Merchant", value: 1 },
                { label: "Clicks", value: 2 },
                { label: "Sales", value: 3 },
                { label: "Conversion Rate", value: 4 },
                { label: "Sales Amt", value: 5 },
                { label: "Commission", value: 6 },
            ]

            if (header === "Brand") {
                return (
                    <th key={i} onClick={() => this.props.handleMerchantSortSelect(options[0])} style={{ width: header === "Retail Price" ? "150px" : "", maxWidth: i === 0 ? "230px" : i === 1 || i === 2 ? "120px" : "", wordWrap: "break-word", cursor: "pointer", border: "none" }}>
                        <strong>{header}</strong>
                        <span className={`brand-nav-dropdown-toggle position-relative mr-2`} style={{ top: this.props.merchantSortDescending ? "-4px" : "9px", left: this.props.merchantSortDescending ? "" : "6px", }}>
                            <span className="brand-nav-chevron-iconset brand-nav-chevron-span" style={{ transform: this.props.merchantSortDescending ? "" : "rotate(180deg)" }}>
                                <svg aria-hidden="true" className="brand-nav-chevron-svg" fill="rgb(119,119,119)" version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 20 20">
                                    <title>Expand</title>
                                    <path className={`brand-nav-lower-menu-hover${this.props.merchantSelectOptions.currentValue.label === "Merchant" ? "" : "-transparent"}`} d="M5.293 9.707l6 6c0.391 0.391 1.024 0.391 1.414 0l6-6c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0l-5.293 5.293-5.293-5.293c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414z"></path>
                                </svg>
                            </span>
                        </span>
                    </th>
                );
            } else if (header === "Clicks") {
                return (
                    <th key={i} onClick={() => this.props.handleMerchantSortSelect(options[1])} style={{ paddingLeft: "7px", width: header === "Retail Price" ? "150px" : "", maxWidth: i === 0 ? "230px" : i === 1 || i === 2 ? "120px" : "", wordWrap: "break-word", cursor: "pointer", border: "none" }}>
                        <strong>{header}</strong>
                        <span className={`brand-nav-dropdown-toggle position-relative mr-2`} style={{ top: this.props.merchantSortDescending ? "-4px" : "9px", left: this.props.merchantSortDescending ? "" : "6px", }}>
                            <span className="brand-nav-chevron-iconset brand-nav-chevron-span" style={{ transform: this.props.merchantSortDescending ? "" : "rotate(180deg)" }}>
                                <svg aria-hidden="true" className="brand-nav-chevron-svg" fill="rgb(119,119,119)" version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 20 20">
                                    <title>Expand</title>
                                    <path className={`brand-nav-lower-menu-hover${this.props.merchantSelectOptions.currentValue.label === "Clicks" ? "" : "-transparent"}`} d="M5.293 9.707l6 6c0.391 0.391 1.024 0.391 1.414 0l6-6c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0l-5.293 5.293-5.293-5.293c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414z"></path>
                                </svg>
                            </span>
                        </span>
                    </th>
                );
            } else if (header === "Sales Count") {
                return (
                    <th key={i} onClick={() => this.props.handleMerchantSortSelect(options[2])} style={{ width: header === "Retail Price" ? "150px" : "", maxWidth: i === 0 ? "230px" : i === 1 || i === 2 ? "120px" : "", wordWrap: "break-word", cursor: "pointer", border: "none" }}>
                        <strong>{header}</strong>
                        <span className={`brand-nav-dropdown-toggle position-relative mr-2`} style={{ top: this.props.merchantSortDescending ? "-4px" : "9px", left: this.props.merchantSortDescending ? "" : "6px", }}>
                            <span className="brand-nav-chevron-iconset brand-nav-chevron-span" style={{ transform: this.props.merchantSortDescending ? "" : "rotate(180deg)" }}>
                                <svg aria-hidden="true" className="brand-nav-chevron-svg" fill="rgb(119,119,119)" version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 20 20">
                                    <title>Expand</title>
                                    <path className={`brand-nav-lower-menu-hover${this.props.merchantSelectOptions.currentValue.label === "Sales" ? "" : "-transparent"}`} d="M5.293 9.707l6 6c0.391 0.391 1.024 0.391 1.414 0l6-6c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0l-5.293 5.293-5.293-5.293c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414z"></path>
                                </svg>
                            </span>
                        </span>
                    </th>
                );
            } else if (header === "Conversion") {
                return (
                    <th key={i} onClick={() => this.props.handleMerchantSortSelect(options[3])} style={{ width: header === "Retail Price" ? "150px" : "", maxWidth: i === 0 ? "230px" : i === 1 || i === 2 ? "120px" : "", wordWrap: "break-word", cursor: "pointer", border: "none", textAlign: "center" }}>
                        <strong>{header}</strong>
                        <span className={`brand-nav-dropdown-toggle position-relative mr-2`} style={{ top: this.props.merchantSortDescending ? "-4px" : "9px", left: this.props.merchantSortDescending ? "" : "6px", }}>
                            <span className="brand-nav-chevron-iconset brand-nav-chevron-span" style={{ transform: this.props.merchantSortDescending ? "" : "rotate(180deg)" }}>
                                <svg aria-hidden="true" className="brand-nav-chevron-svg" fill="rgb(119,119,119)" version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 20 20">
                                    <title>Expand</title>
                                    <path className={`brand-nav-lower-menu-hover${this.props.merchantSelectOptions.currentValue.label === "Conversion Rate" ? "" : "-transparent"}`} d="M5.293 9.707l6 6c0.391 0.391 1.024 0.391 1.414 0l6-6c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0l-5.293 5.293-5.293-5.293c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414z"></path>
                                </svg>
                            </span>
                        </span>
                    </th>
                );
            } else if (header === "Sales") {
                return (
                    <th key={i} onClick={() => this.props.handleMerchantSortSelect(options[4])} style={{ width: header === "Retail Price" ? "150px" : "", maxWidth: i === 0 ? "230px" : i === 1 || i === 2 ? "120px" : "", wordWrap: "break-word", cursor: "pointer", border: "none", textAlign: "center" }}>
                        <strong>{header}</strong>
                        <span className={`brand-nav-dropdown-toggle position-relative mr-2`} style={{ top: this.props.merchantSortDescending ? "-4px" : "9px", left: this.props.merchantSortDescending ? "" : "6px", }}>
                            <span className="brand-nav-chevron-iconset brand-nav-chevron-span" style={{ transform: this.props.merchantSortDescending ? "" : "rotate(180deg)" }}>
                                <svg aria-hidden="true" className="brand-nav-chevron-svg" fill="rgb(119,119,119)" version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 20 20">
                                    <title>Expand</title>
                                    <path className={`brand-nav-lower-menu-hover${this.props.merchantSelectOptions.currentValue.label === "Sales Amt" ? "" : "-transparent"}`} d="M5.293 9.707l6 6c0.391 0.391 1.024 0.391 1.414 0l6-6c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0l-5.293 5.293-5.293-5.293c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414z"></path>
                                </svg>
                            </span>
                        </span>
                    </th>
                );
            } else if (header === "Commissions") {
                return (
                    <th key={i} onClick={() => this.props.handleMerchantSortSelect(options[5])} style={{ width: header === "Retail Price" ? "150px" : "", maxWidth: i === 0 ? "230px" : i === 1 || i === 2 ? "120px" : "", wordWrap: "break-word", cursor: "pointer", border: "none", textAlign: "center" }}>
                        <strong>{header}</strong>
                        <span className={`brand-nav-dropdown-toggle position-relative mr-2`} style={{ top: this.props.merchantSortDescending ? "-4px" : "9px", left: this.props.merchantSortDescending ? "" : "6px", }}>
                            <span className="brand-nav-chevron-iconset brand-nav-chevron-span" style={{ transform: this.props.merchantSortDescending ? "" : "rotate(180deg)" }}>
                                <svg aria-hidden="true" className="brand-nav-chevron-svg" fill="rgb(119,119,119)" version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 20 20">
                                    <title>Expand</title>
                                    <path className={`brand-nav-lower-menu-hover${this.props.merchantSelectOptions.currentValue.label === "Commission" ? "" : "-transparent"}`} d="M5.293 9.707l6 6c0.391 0.391 1.024 0.391 1.414 0l6-6c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0l-5.293 5.293-5.293-5.293c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414z"></path>
                                </svg>
                            </span>
                        </span>
                    </th>
                );
            } else {
                return (
                    <th key={i} style={{ paddingLeft: "12px", width: header === "Retail Price" ? "150px" : "", maxWidth: i === 0 ? "230px" : i === 1 || i === 2 ? "120px" : "", wordWrap: "break-word", border: "none" }}>
                        <strong>{header}</strong>
                    </th>
                );
            }
        });
    };

    handleClicksClick = (e, merchant, clicksZero) => {
        if (clicksZero) {
            return;
        }
        e.stopPropagation();
        this.props.getAnalyticsPerformanceSourceByMerchant(this.props.startDate, this.props.endDate, merchant.merchantId);
        this.setState({ clicked: merchant });
        this.showModal(e);
    }

    handleSalesClicksClick = (e, merchant, salesZero) => {
        if (salesZero) {
            return;
        }
        e.stopPropagation();
        this.props.getAnalyticsPerformanceSalesSourceByMerchant(this.props.startDate, this.props.endDate, merchant.merchantId);
        this.setState({ salesClicked: merchant });
        this.showSalesModal(e);
    }

    displayTableRows = () => {
        if (this.props.merchantAnalytics?.length > 0) {
            return this.props.merchantAnalytics.map((merchant, i) => {
                let getLinkUrl = "https://";
                if (window.location.host.includes("app.rockp") && !!merchant.shareSubdomain) {
                    getLinkUrl += merchant.shareSubdomain + ".rockporch.com";
                } else {
                    getLinkUrl += window.location.host;
                }

                getLinkUrl = getLinkUrl + "/mlink/" + merchant.merchantId + "?userId=" + this.props.userId;

                const clicksZero = merchant.clicks === 0;
                const salesZero = merchant.sales === 0;

                return (
                    <tr key={i} className="hover-background"
                        onMouseEnter={() => this.setState({ hoverRow: i })}
                        onMouseLeave={() => this.setState({ hoverRow: -2 })}
                        style={{ cursor: "pointer", borderBottom: this.state.hoverRow === i + 1 ? "1px solid gray" : "" }}
                        onClick={() => this.props.handleGetLink(getLinkUrl)}
                    >
                        <td style={{ background: "white", maxWidth: "210px", wordWrap: "break-word", border: "none" }}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <ImageWrapper src={merchant.logoUrl} style={{ objectFit: "contain" }} />
                                <h6>{titleCase(merchant.merchant.trim())}</h6>
                            </div>
                        </td>

                        <td className="pr-5 brand-primary-color" onClick={(e) => this.handleClicksClick(e, merchant, clicksZero)} style={{ textAlign: "center", maxWidth: "120px", wordWrap: "break-word", border: "none" }}>
                            <span className={`${clicksZero ? "" : "brand-primary-color"}`}>{merchant.clicks}</span>
                        </td>

                        <td className="pr-5" onClick={(e) => this.handleSalesClicksClick(e, merchant, salesZero)} style={{ textAlign: "center", maxWidth: "120px", wordWrap: "break-word", border: "none" }}>
                            <span className={`${salesZero ? "" : "brand-primary-color"}`}>{merchant.sales}</span>
                        </td>

                        <td className=" brand-primary-color" style={{ textAlign: "center", maxWidth: "100px", wordWrap: "break-word", border: "none" }}>
                            <span>${merchant.salesAmount.toFixed(2)}</span>
                        </td>

                        <td className=" brand-primary-color" style={{ textAlign: "center", maxWidth: "100px", wordWrap: "break-word", border: "none" }}>
                            <span>${merchant.commission.toFixed(2)}</span>
                        </td>

                        <td className="pr-4" style={{ maxWidth: "120px", wordWrap: "break-word", border: "none" }}>
                            <span>{merchant.conversionRate}%</span>
                        </td>

                    </tr>
                )
            })
        }
    }

    handleSourceClicksSort = async () => {
        await this.props.handleSourceReferrerClick(false, this.props.sourceSortAscending);
        this.props.handleSourceClicksSort();
    }

    handleSalesSourceClicksSort = async () => {
        await this.props.handleSalesSourceReferrerClick(false, this.props.salesSourceSortAscending);
        this.props.handleSourceClicksSort();
    }

    handleSourceReferrerSort = async () => {
        await this.props.handleSourceReferrerClick(true, this.props.sourceSortAscending);
        this.props.handleSourceClicksSort();
    }

    handleSalesSourceReferrerSort = async () => {
        await this.props.handleSalesSourceReferrerClick(true, this.props.salesSourceSortAscending);
        this.props.handleSourceClicksSort();
    }

    displayMerchantClicksTable = () => {
        if (this.props.performanceSourceMerchant?.length > 0) {
            return (
                <div className="product-table mx-auto mb-3 post-edit-products-scroll" style={{ width: "90%", maxHeight: "300px" }}>
                    <table key={-1} className="table" style={{ border: 0, margin: "0 auto" }}>
                        <colgroup>
                            <col span="1" style={{ width: "75%" }} />
                            <col span="1" style={{ width: "25%" }} />

                        </colgroup>
                        <tbody>
                            <tr className="pb-3">
                                <td style={{ border: 0, padding: 0 }}>
                                    <div onClick={this.handleSourceReferrerSort} style={{ cursor: "pointer" }}>
                                        <h6 style={{ marginBottom: 0, padding: 0, fontWeight: "bold" }} >Source</h6>
                                        <span className={`brand-nav-dropdown-toggle position-relative mr-2`} style={{ top: this.props.sourceSortAscending ? "16px" : "29px", left: this.props.sourceSortAscending ? "" : "6px", }}>
                                            <span className="brand-nav-chevron-iconset brand-nav-chevron-span" style={{ transform: this.props.sourceSortAscending ? "" : "rotate(180deg)" }}>
                                                <svg aria-hidden="true" className="brand-nav-chevron-svg" fill="rgb(119,119,119)" version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 20 20">
                                                    <title>Expand</title>
                                                    <path className={`brand-nav-lower-menu-hover${this.props.sourceSortReferrer ? "" : "-transparent"}`} d="M5.293 9.707l6 6c0.391 0.391 1.024 0.391 1.414 0l6-6c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0l-5.293 5.293-5.293-5.293c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414z"></path>
                                                </svg>
                                            </span>
                                        </span>
                                    </div>
                                </td>
                                <td style={{ border: 0, padding: 0 }}>
                                    <div onClick={this.handleSourceClicksSort} style={{ cursor: "pointer" }}>
                                        <h6 style={{ marginBottom: 0, padding: 0, fontWeight: "bold", display: "flex", justifyContent: "end" }}>Clicks</h6>
                                        <span className={`brand-nav-dropdown-toggle position-relative mr-2`} style={{ top: this.props.sourceSortAscending ? "16px" : "29px", left: this.props.sourceSortAscending ? "" : "6px", }}>
                                            <span className="brand-nav-chevron-iconset brand-nav-chevron-span" style={{ transform: this.props.sourceSortAscending ? "" : "rotate(180deg)" }}>
                                                <svg aria-hidden="true" className="brand-nav-chevron-svg" fill="rgb(119,119,119)" version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 20 20">
                                                    <title>Expand</title>
                                                    <path className={`brand-nav-lower-menu-hover${!this.props.sourceSortReferrer ? "" : "-transparent"}`} d="M5.293 9.707l6 6c0.391 0.391 1.024 0.391 1.414 0l6-6c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0l-5.293 5.293-5.293-5.293c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414z"></path>
                                                </svg>
                                            </span>
                                        </span>
                                    </div>
                                </td>
                            </tr>
                            {this.displayMerchantsClickRows()}
                        </tbody>
                    </table>
                </div>
            );
        }
    }

    displayMerchantsClickRows = () => {
        return this.props.performanceSourceMerchant.map((m, i) => {
            return (
                <>
                    <tr key={i} className="py-3" style={{ borderBottom: this.props.performanceSourceMerchant.length - 1 === i ? "" : "1px solid lightgray" }}>
                        <td className="pl-1" style={{ border: 0, wordBreak: "break-all", marginRight: "10px", paddingLeft: 0, paddingRight: 0 }}>
                            <span style={{ fontSize: "16px" }}>{m.referrer?.trim().length > 0 ? m.referrer : "direct / not provided"}</span>
                        </td>
                        <td style={{ border: 0, wordBreak: "break-all", paddingLeft: 0, paddingRight: 0 }}>
                            <span style={{ fontSize: "16px" }}>{m.count}</span>
                        </td>
                    </tr>
                </>
            )
        });
    }

    displayMerchantSalesClicksTable = () => {
        if (this.props.performanceSalesSourceMerchant?.length > 0) {
            return (
                <div className="product-table mx-auto mb-3 post-edit-products-scroll" style={{ width: "90%", maxHeight: "300px" }}>
                    <table key={-1} className="table" style={{ border: 0, margin: "0 auto" }}>
                        <colgroup>
                            <col span="1" style={{ width: "75%" }} />
                            <col span="1" style={{ width: "25%" }} />

                        </colgroup>
                        <tbody>
                            <tr className="pb-3">
                                <td style={{ border: 0, padding: 0 }}>
                                    <div onClick={this.handleSalesSourceReferrerSort} style={{ cursor: "pointer" }}>
                                        <h6 style={{ marginBottom: 0, padding: 0, fontWeight: "bold" }} >Source</h6>
                                        <span className={`brand-nav-dropdown-toggle position-relative mr-2`} style={{ top: this.props.salesSourceSortAscending ? "16px" : "29px", left: this.props.salesSourceSortAscending ? "" : "6px", }}>
                                            <span className="brand-nav-chevron-iconset brand-nav-chevron-span" style={{ transform: this.props.salesSourceSortAscending ? "" : "rotate(180deg)" }}>
                                                <svg aria-hidden="true" className="brand-nav-chevron-svg" fill="rgb(119,119,119)" version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 20 20">
                                                    <title>Expand</title>
                                                    <path className={`brand-nav-lower-menu-hover${this.props.salesSourceSortReferrer ? "" : "-transparent"}`} d="M5.293 9.707l6 6c0.391 0.391 1.024 0.391 1.414 0l6-6c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0l-5.293 5.293-5.293-5.293c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414z"></path>
                                                </svg>
                                            </span>
                                        </span>
                                    </div>
                                </td>
                                <td style={{ border: 0, padding: 0 }}>
                                    <div onClick={this.handleSalesSourceClicksSort} style={{ cursor: "pointer" }}>
                                        <h6 style={{ marginBottom: 0, padding: 0, fontWeight: "bold", display: "flex", justifyContent: "end" }}>Clicks</h6>
                                        <span className={`brand-nav-dropdown-toggle position-relative mr-2`} style={{ top: this.props.salesSourceSortAscending ? "16px" : "29px", left: this.props.salesSourceSortAscending ? "" : "6px", }}>
                                            <span className="brand-nav-chevron-iconset brand-nav-chevron-span" style={{ transform: this.props.salesSourceSortAscending ? "" : "rotate(180deg)" }}>
                                                <svg aria-hidden="true" className="brand-nav-chevron-svg" fill="rgb(119,119,119)" version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 20 20">
                                                    <title>Expand</title>
                                                    <path className={`brand-nav-lower-menu-hover${!this.props.salesSourceSortReferrer ? "" : "-transparent"}`} d="M5.293 9.707l6 6c0.391 0.391 1.024 0.391 1.414 0l6-6c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0l-5.293 5.293-5.293-5.293c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414z"></path>
                                                </svg>
                                            </span>
                                        </span>
                                    </div>
                                </td>
                            </tr>
                            {this.displayMerchantsSalesClickRows()}
                        </tbody>
                    </table>
                </div>
            );
        }
    }

    displayMerchantsSalesClickRows = () => {
        return this.props.performanceSalesSourceMerchant.map((m, i) => {
            return (
                <>
                    <tr key={i} className="py-3" style={{ borderBottom: this.props.performanceSalesSourceMerchant.length - 1 === i ? "" : "1px solid lightgray" }}>
                        <td className="pl-1" style={{ border: 0, wordBreak: "break-all", marginRight: "10px", paddingLeft: 0, paddingRight: 0 }}>
                            <span style={{ fontSize: "16px" }}>{m.referrer?.trim().length > 0 ? m.referrer : "direct / not provided"}</span>
                        </td>
                        <td style={{ border: 0, wordBreak: "break-all", paddingLeft: 0, paddingRight: 0 }}>
                            <span style={{ fontSize: "16px" }}>{m.count}</span>
                        </td>
                    </tr>
                </>
            )
        });
    }

    render() {
        return (
            <>
                {
                    this.props.analyticsLoading ?
                        <LoadSpinner /> :
                        <section>
                            <Modal isOpen={this.state.show}
                                className="post-edit-products-scroll"
                                style={
                                    {
                                        overlay: {
                                            position: 'fixed',
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                            zIndex: 9998,
                                        },
                                        content: {
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            background: 'white',
                                            overflow: 'auto',
                                            WebkitOverflowScrolling: 'touch',
                                            outline: 'none',
                                            padding: '20px',
                                            minWidth: '350px',
                                            minHeight: '500px',
                                            zIndex: 1060,
                                            borderRadius: ".3rem",
                                        }
                                    }
                                }
                                onRequestClose={this.hideModal}
                            >

                                <div className="d-flex flex-column h-100 bg-white">
                                    <div className="mx-0 my-0">
                                        <div className="d-flex flex-column pt-1">
                                            <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                                                <ImageWrapper src={this.state.clicked?.logoUrl} style={{ objectFit: "contain", height: "50px", width: "50px" }} />
                                                <h6 style={{ color: "black", fontSize: "24px", marginBottom: 0 }}>{titleCase(this.state.clicked?.merchant ?? "Product")}</h6>
                                            </div>
                                            {
                                                this.props.analyticsPerformanceSourceByMerchantLoading ?
                                                    <LoadSpinner /> :
                                                    <>
                                                        {this.displayMerchantClicksTable()}
                                                    </>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </Modal>
                            <Modal isOpen={this.state.showSales}
                                className="post-edit-products-scroll"
                                style={
                                    {
                                        overlay: {
                                            position: 'fixed',
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                            zIndex: 9998,
                                        },
                                        content: {
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            background: 'white',
                                            overflow: 'auto',
                                            WebkitOverflowScrolling: 'touch',
                                            outline: 'none',
                                            padding: '20px',
                                            minWidth: '350px',
                                            minHeight: '500px',
                                            zIndex: 1060,
                                            borderRadius: ".3rem",
                                        }
                                    }
                                }
                                onRequestClose={this.hideSalesModal}
                            >

                                <div className="d-flex flex-column h-100 bg-white">
                                    <div className="mx-0 my-0">
                                        <div className="d-flex flex-column pt-1">
                                            <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                                                <ImageWrapper src={this.state.salesClicked?.logoUrl} style={{ objectFit: "contain", height: "50px", width: "50px" }} />
                                                <h6 style={{ color: "black", fontSize: "24px", marginBottom: 0 }}>{titleCase(this.state.salesClicked?.merchant ?? "Product")}</h6>
                                            </div>
                                            {
                                                this.props.analyticsPerformanceSalesSourceByMerchantLoading ?
                                                    <LoadSpinner /> :
                                                    <>
                                                        {this.displayMerchantSalesClicksTable()}
                                                    </>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </Modal>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col" style={{ padding: "0" }}>

                                        {["sm", "md"].includes(this.props.deviceSize) ?
                                            this.displayMerchantMobile()
                                            :
                                            this.props.merchantAnalytics?.length === 0 ?
                                            <div className="mt-3">
                                                <NoResults />
                                            </div>   
                                            :
                                            <div className="table-responsive product-table" style={{ overflow: "visible" }}>
                                                <table className="table" style={{ border: 0 }}>
                                                    <thead>
                                                        <tr>
                                                            {this.displayTableHeader()}
                                                        </tr>
                                                    </thead>


                                                    <tbody>
                                                        {this.displayTableRows()}
                                                    </tbody>
                                                </table>
                                            </div>}
                                    </div>
                                </div>
                            </div>
                        </section>
                }
            </>
        );
    }
}

export default AnalyticsByMerchantTable;