import React from "react";
import { getRequest, postRequest } from "sharedUtils/httpUtils";
import { getMessageCount } from "sharedUtils/notificationUtils";
import MessageDetails from "../Account/MessageDetails";
import NotificationDotIcon from "../../components/icons/NotificationDotIcon"
import { convertToNumericMonthAndDay, convertToAbbreviatedMonthAndDay } from "../../sharedUtils/timeUtils"
import ImageWrapper from "components/ImageWrapper";
import BlueTickIconXY from "components/icons/BlueTickIconXY";

class Messages extends React.Component {
    
    state = {
        messages: [],
        messagesPerPage: 10,
        page: 1,
        messageCount: 0,
        pagingControls: [],
        showMessageDetails: false,
        sender: null,
        conversationId: null
    }

    componentDidMount = async () => {
        this.getMessageCount();
        this.getMessages();
        this.props.setOnSelected(() => {
            this.onClickPage(1);
        });
    }

    getMessageCount = async () => {
        let messageCount = await getMessageCount(false);
        this.setState({ messageCount: messageCount });
    }

    getMessages = async () => {
        let messagesUrl = '/api/Account/GetMessageHeaders?skip=' + (this.state.page - 1) * this.state.messagesPerPage + '&take=' + this.state.messagesPerPage;
        let messages = await getRequest(messagesUrl) ?? [];
        this.setState({ messages: messages });
    }

    refreshState = async () => {
        this.getMessageCount();
        this.getMessages();
    }

    renderHeaderLine = () => {
        return (
            <p className="float-md-right">Showing messages {(this.state.page - 1) * this.state.messagesPerPage + this.state.messages.length > 0 ? 1 : 0} - {(this.state.page - 1) * this.state.messagesPerPage + this.state.messages.length} of {this.state.messageCount}</p>
        );
    }

    onClickPage = async (page) => {
        await this.setState({ page: page });
        this.getMessages();
    }

    onClickMessage = async (message) => {
        let messages = this.state.messages;

        this.setState({ messages: messages, sender: message.sender, showMessageDetails: true, conversationId: message.conversationId });
    }

    renderMessages = () => {
        return this.state.messages.map((message, i) => {
            return (
                <div className="row" onClick={() => this.onClickMessage(message)} key={i}>
                    <div className="col-12">
                        <div className="messageRow">
                            <div className="messageRow-userImage">
                                    <ImageWrapper
                                        className=""
                                        alt="avatar"
                                        src={message.sender.imageUrl || "/images/avatar2.svg"} />
                            </div>
                            <div className="messageRow-userNameAndMessage">
                                <div className="messageRow-userName">
                                    {message.sender.userName}                                    
                                </div>
                                <div className="messageRow-message">
                                    {message.text}
                                </div>
                            </div>
                            <div className="messageRow-date">
                                {
                                    message.viewedDate ?
                                        <>
                                            <span title={convertToNumericMonthAndDay(message.createdDate, false)}>{convertToAbbreviatedMonthAndDay(message.createdDate, false)}</span>
                                        </> :
                                        <>
                                            <span title={convertToNumericMonthAndDay(message.createdDate, false)}><strong>{convertToAbbreviatedMonthAndDay(message.createdDate, false)}</strong></span>
                                            <NotificationDotIcon customClass="ml-5px" />
                                        </>
                                }
                            </div>
                        </div>
                        <div className="messageRow-bottomBorder">
                            <div className="messageRow-bottomBorder-spacerLeft"></div>
                            <div className="messageRow-bottomBorder-spacerRight"></div>
                        </div>
                    </div>
                </div>
            );
        });
    }

    renderPagingControls = () => {
        let pageCount = Math.ceil(this.state.messageCount / this.state.messagesPerPage);        
        let pagingControls = [];

        for (let i = 1; i <= pageCount; ++i) {
            pagingControls.push(<li key={i} className={this.state.page === i ? "page-item active" : "page-item"}><button className="page-link" value={i} onClick={() => this.onClickPage(i)}>{i}</button></li>);
        }

        return <>{pagingControls}</>;
    }

    onClickBack = async () => {
        await this.refreshState();
        this.setState({ showMessageDetails: false });
    }

    handleMessageInput = (e) => {
        this.setState({ newMessage: e.target.value });
    }

    onClickSend = async (message) => {
        let messages = this.state.messages;
        let index = messages.findIndex((m) => { return m.conversationId === message.conversationId });

        messages[index].text = message.text;
        messages[index].createdDate = message.createdDate;
        
        this.setState({ newMessage: "", messages: messages });
    }

    render() {
        if (this.props.display) {
            if (this.state.showMessageDetails) {
                return <MessageDetails loggedInUser={this.props.loggedInUser} user={this.state.sender} onClickBack={this.onClickBack} onClickSend={this.onClickSend} conversationId={this.state.conversationId} />;
            } else {
                let pageCount = Math.ceil(this.state.messageCount / this.state.messagesPerPage);  
                return (
                    <div className="container px-0 py-5">
                        <div className="row message-wrapper2 mx-0 my-0">
                            <div className="col-12 p-0 px-md-3">
                                <div className="message-top clearfix text-center text-md-left">
                                    {this.renderHeaderLine()}
                                </div>
                            </div>
                            <div className="col-12 p-0 px-md-3">
                                {this.renderMessages()}
                            </div>

                            {
                                pageCount > 1 ?
                                    <nav aria-label="..." className="col-12 pt-5 pb-3">
                                        <ul className="pagination justify-content-center">
                                            {this.renderPagingControls()}
                                        </ul>
                                    </nav> :
                                    <></>
                            }

                        </div>
                    </div>)
            }
        }
        return <></>;
    }
}

export default Messages;