import { getRequest } from "sharedUtils/httpUtils";

export let getMessageCount = async (unviewedOnly) => {
    let messageCountUrl = `/api/Account/GetMessageCount?unviewedOnly=${unviewedOnly}`;
    return await getRequest(messageCountUrl);
}

export let getNotificationCount = async (unviewedOnly) => {
    let notificationCountUrl = `/api/Account/GetNotificationCount?unviewedOnly=${unviewedOnly}`;
    return await getRequest(notificationCountUrl);
}