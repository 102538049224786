import React from "react";
import AccountBanner from "../../components/AccountBanner"
import { getRequest } from "sharedUtils/httpUtils";
import UserCommissionOverrides from "../../components/CommissionOverrides/UserCommissionOverrides";
import MerchantCommissionOverrides from "../../components/CommissionOverrides/MerchantCommissionOverrides";
import UserMerchantCommissionOverrides from "../../components/CommissionOverrides/UserMerchantCommissionOverrides";

class CommissionOverrides extends React.Component {

    state = {
        selectedCase: 1,
        merchantCommissionOverridesLoading: false,
        userMerchantCommissionOverridesLoading: false,
        userCommissionOverrides: [],
        merchantCommissionOverrides: [],
        userMerchantCommissionOverrides: []
    }

    breadCrumbList = [{ link: '/Admin', text: 'Admin' }, { link: '/Admin/CommissionOverrides', text: 'Commission Overrides' }];
    userCommissionOverridesBreadCrumbList = [
        ...this.breadCrumbList,
        { link: '/Admin/CommissionOverrides', text: 'User Commission Overrides', active: true }
    ];
    merchantCommissionOverridesBreadCrumbList = [
        ...this.breadCrumbList,
        { link: '/Admin/CommissionOverrides', text: 'Merchant Commission Overrides', active: true }
    ];
    userMerchantCommissionOverridesBreadCrumbList = [
        ...this.breadCrumbList,
        { link: '/Admin/CommissionOverrides', text: 'User/Merchant Commission Overrides', active: true }
    ];

    componentDidMount = async () => {
        let scrollOptions = {
            left: 0,
            top: 0,
            behavior: 'auto'
        }
        window.scrollTo(scrollOptions);
    }

    getMerchantCommissionOverrides = async () => {
        let getMerchantCommissionOverridesUrl = '/api/Merchant/GetMerchantCommissionOverrides';

        this.setState({ merchantCommissionOverridesLoading: true });
        let merchantCommissionOverrides = await getRequest(getMerchantCommissionOverridesUrl) ?? [];
        this.setState({ merchantCommissionOverrides: merchantCommissionOverrides, merchantCommissionOverridesLoading: false });
    }

    getUserMerchantCommissionOverrides = async () => {
        let getUserMerchantCommissionOverridesUrl = '/api/Merchant/GetUserMerchantCommissionOverrides';

        this.setState({ userMerchantCommissionOverridesLoading: true });
        let userMerchantCommissionOverrides = await getRequest(getUserMerchantCommissionOverridesUrl) ?? [];
        this.setState({ userMerchantCommissionOverrides: userMerchantCommissionOverrides, userMerchantCommissionOverridesLoading: false });
    }

    displayToggleButtons = () => {
        let options = [
            {
                name: "User Commission Overrides",
                case: 1,
                action: () => this.setState({ selectedCase: 1 })
            },
            {
                name: "Merchant Commission Overrides",
                case: 2,
                action: () => this.setState({ selectedCase: 2 })
            },
            {
                name: "User/Merchant Commission Overrides",
                case: 3,
                action: () => this.setState({ selectedCase: 3 })
            }

        ];

        return options.map((toggle, i) => {
            return (
                <button key={i}
                    className={`btn ${this.state.selectedCase === toggle.case ? "btn-primary btn-lg" : ""}`}
                    onClick={toggle.action}
                >
                    {toggle.name}
                </button>
            )
        })
    }

    render() {
        return (
            <>
                <section>
                    <AccountBanner breadCrumbList={this.state.selectedCase === 1 ? this.userCommissionOverridesBreadCrumbList :
                        this.state.selectedCase === 2 ? this.merchantCommissionOverridesBreadCrumbList :
                            this.state.selectedCase === 3 ? this.userMerchantCommissionOverridesBreadCrumbList :
                                this.breadCrumbList}>
                        <h1 className="mb-4">Commission Overrides</h1>
                        <p className="mb-0">Manage User and Merchant Commission Overrides</p>
                    </AccountBanner>
                </section>
                <section className="filter-wrapper" >
                    <div className="container">
                        <div className="row py-2 mt-0">
                            <div className="col-12 d-flex justify-content-between">
                                <div className="filter-block d-flex">
                                    {this.displayToggleButtons()}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="gray-bg full-height pt-1">
                    <UserCommissionOverrides
                        display={this.state.selectedCase === 1}
                        loggedInUser={this.props.loggedInUser}
                    />

                    <MerchantCommissionOverrides
                        display={this.state.selectedCase === 2}
                        loggedInUser={this.props.loggedInUser}
                        merchantCommissionOverrides={this.state.merchantCommissionOverrides}
                        merchantCommissionOverridesLoading={this.state.merchantCommissionOverridesLoading}
                        getMerchantCommissionOverrides={this.getMerchantCommissionOverrides} />

                    <UserMerchantCommissionOverrides
                        display={this.state.selectedCase === 3}
                        loggedInUser={this.props.loggedInUser}
                        userMerchantCommissionOverrides={this.state.userMerchantCommissionOverrides}
                        userMerchantCommissionOverridesLoading={this.state.userMerchantCommissionOverridesLoading}
                        getUserMerchantCommissionOverrides={this.getUserMerchantCommissionOverrides} />

                </section>
            </>
        )
    }
}

export default CommissionOverrides;