import React from "react";


/**
 * 
 * @param {{title:string, bodyStyle:object, errorMessage:string}} props
 */
const NoResults = (props) => {
    return (
        <div className="card-text-center" style={{ backgroundColor: "white", borderRadius:"0px", width:"70%",margin:"0 auto" }}>
            <div className="card-body">
                <h5 className="card-title mb-0">No results found</h5>
                {props.errorMessage ? <p className="mt-2 card-text">{props.errorMessage || ""}</p> : null}
                <div>
                    {props.children}
                </div>
            </div>
        </div>
    )
}

export default NoResults;