import React from "react";

/**
 * 
 * @param {{width:Number,height:Number,fill:string}} props
 */
const AnalyticsIconNew = (props) => {
    return (
        <svg onClick={props.onClick || (() => { })} width={props.width || "20"} height={props.height || "20"} xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" viewBox="0 0 256 256" enableBackground="new 0 0 256 256" xmlSpace="preserve">
            <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
            <g>
                <g>
                    <g>
                        <path className={props.fillClass || ""} fill="#000000" d="M218.4,29.2c-11.6,3.1-18.9,15.3-16,26.5c0.4,1.4,0.6,2.7,0.4,2.8c-0.9,0.8-27.3,18.9-27.8,19.1c-0.4,0.2-1.7-0.4-2.9-1.2c-2.9-2-6.8-3.3-10.6-3.5c-6.5-0.5-12.3,1.8-17.2,6.7l-3,2.9l-11.3-3.9l-11.3-3.9l-0.6-3.8c-1.2-8.1-4.5-13.4-10.9-17.2c-2.9-1.7-7.7-3-11.1-3c-9.5,0-18.2,6.5-21,15.6c-1.1,3.5-1.2,9.1-0.3,12.1l0.6,2.1l-14.2,10l-14.2,9.9l-2.3-1.5c-12.8-8.6-30-2-33.9,13c-4.1,15.7,11,30.7,26.6,26.6c11.8-3,19.2-15.2,16.3-26.4l-0.6-2.4l1.4-1.1c3.3-2.4,26.4-18.4,26.9-18.6c0.3-0.1,1.5,0.5,2.7,1.2c8.5,5.9,20.3,4.5,27.8-3.1l2.9-3l11.3,3.9l11.4,3.9l0.5,3.9c0.9,6.5,3,10.7,7.2,14.5c12,10.8,31.2,5.4,36-10c0.9-3.1,1-5.2,0.3-13c-0.1-1.3,0.6-1.8,13.9-11.1l14-9.7l2.2,1.5c12.7,8.6,29.9,1.9,33.8-13C249.4,40,234.2,24.9,218.4,29.2z M227.3,40.1c4,1.2,7.7,6.3,7.7,10.5c0,5.7-5.3,11.1-11.1,11.1c-2.9,0-5.3-1.1-7.6-3.5c-4.7-4.7-4.7-10.6,0-15.2C219.5,39.7,223,38.8,227.3,40.1z M101.4,63.1c2.6,1.4,4.8,4.4,5.4,7.4c1.1,4.6-1.4,9.7-5.7,11.9c-1.5,0.8-2.8,1-5.2,1.1c-2.8,0-3.5-0.2-5.5-1.4c-7.8-4.9-6.7-16.6,2-19.9C94.8,61.4,98.9,61.7,101.4,63.1z M165.7,85.5c1.3,0.8,2.7,2.2,3.5,3.5c1.2,2,1.4,2.7,1.4,5.8c0,3.1-0.1,3.8-1.4,5.8c-1.9,3.1-4.8,4.8-8.6,5.1c-2.5,0.2-3.2,0-5.4-1.1c-9.1-4.7-8-17.2,1.8-20.5C159.3,83.4,163.5,84,165.7,85.5z M35.5,106.4c2.5,0.7,6.4,4.6,7.1,7.1c1.3,4.3,0.4,7.8-2.9,11c-4.7,4.7-10.6,4.7-15.2,0c-4.7-4.7-4.7-10.6,0-15.2C27.7,106.1,31.2,105.2,35.5,106.4z" />
                        <path className={props.fillClass || ""} fill="#000000" d="M206.1,95.5c-1.9,0.8-3.8,3.2-4.1,5c-0.1,0.8-0.2,28.7-0.1,62c0.1,60.3,0.1,60.5,1.1,61.7c0.5,0.7,1.5,1.7,2.2,2.2c1.2,0.9,1.8,1,18.7,1c16.9,0,17.5,0,18.7-1c0.7-0.5,1.7-1.5,2.2-2.2c1-1.2,1-1.4,1-63c0-61.6,0-61.7-1-63c-0.5-0.7-1.5-1.7-2.2-2.2c-1.2-0.9-1.9-1-18.2-1.1C211.1,94.9,207.2,95,206.1,95.5z" />
                        <path className={props.fillClass || ""} fill="#000000" d="M78.7,117.5c-0.6,0.3-1.7,0.9-2.2,1.5c-2.5,2.4-2.4-0.4-2.4,53.5v50l1.1,1.5c0.6,0.8,1.6,2,2.3,2.5c1.2,0.9,1.9,1,18.1,1.1c16,0.1,16.9,0.1,18.6-0.8c1-0.5,2.3-1.7,2.9-2.7l1.1-1.8v-50v-50l-1.1-1.8c-0.6-1-1.8-2.1-2.9-2.6c-1.7-0.9-2.7-0.9-18-0.9C86.4,117,79.4,117.2,78.7,117.5z" />
                        <path className={props.fillClass || ""} fill="#000000" d="M141.7,140.1c-1,0.6-2.3,1.8-2.8,2.7c-1,1.6-1,2.1-1,40.6s0,39,1,40.6c0.5,0.9,1.8,2.1,2.8,2.7c1.8,1,1.9,1,18.7,0.9c16.3-0.1,17-0.2,18.2-1.1c0.7-0.5,1.8-1.7,2.4-2.5l1-1.5v-39v-39l-1-1.5c-0.6-0.8-1.7-2-2.4-2.5c-1.2-0.9-1.9-1-18.2-1.1C143.5,139.1,143.4,139.1,141.7,140.1z" />
                        <path className={props.fillClass || ""} fill="#000000" d="M14.4,161.8c-1.9,0.8-3.8,3.2-4.1,5c-0.1,0.8-0.2,13.7-0.1,28.8c0.1,26.7,0.1,27.3,1.1,28.5c0.5,0.7,1.5,1.7,2.2,2.2c1.2,0.9,1.8,1,18.7,1c16.9,0,17.5,0,18.7-1c0.7-0.5,1.7-1.5,2.2-2.2c1-1.2,1-1.7,1-29.8s0-28.5-1-29.8c-0.5-0.7-1.5-1.7-2.2-2.2c-1.2-0.9-1.9-1-18.2-1.1C19.3,161.2,15.5,161.3,14.4,161.8z" />
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default AnalyticsIconNew;