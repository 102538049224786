import * as React from "react";
import { Form } from "reactstrap";
import FormInputField from "../../components/FormInputField";

import AccountBanner from "../../components/AccountBanner";

import { getRequest, postRequest } from "../../sharedUtils/httpUtils";
import { Helmet } from 'react-helmet';

class ProSettings extends React.Component {

    minLengthMessage = 'Minimum 8 characters';
    doesNotMatchMessage = 'Password does not match'

    state = {
        formData: {
            phoneNumber: "",
            address: "",
            city: "",
            state: "",
            postalCode: "",
            country: "",
        },
        minLengthMessage: "",
        doesNotMatchMessage: "",
        saving: false,
        email: "",
        formEmail: "",
        emailMessage: ""
    }

    componentDidMount = async () => {
        let scrollOptions = {
            left: 0,
            top: 0,
            behavior: 'auto'
        }
        window.scrollTo(scrollOptions);

        this.setState({ formData: this.convertToFormData(this.props.loggedInUser) });

        this.getPaymentEmail();
    }

    getPaymentEmail = async () => {
        let paymentEmail = await getRequest("api/Account/GetPaymentEmail");
        this.setState({ formEmail: paymentEmail?.email ?? "", email: paymentEmail?.email ?? ""});
    }

    onInputChangeEmail = (e) => {
        let emailRegex = /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/
        let validEmail = emailRegex.test(e.target.value);

        if (!validEmail) {
            this.setState({ formEmail: e.target.value, validEmail: validEmail, emailMessageStyle: "", emailMessage: "Invalid email address" });
        } else {
            this.setState({ formEmail: e.target.value, validEmail: validEmail, emailMessageStyle: "", emailMessage: "" });
        }
    }

    onInputChange = (e) => {
        let formkey = e.target.name;
        let newData = e.target.value;
        let formData = this.state.formData;
        formData[formkey] = newData;

        if (formkey === "newPassword") {
            if (newData.length < 8 && newData.length > 0) {
                this.setState({ minLengthMessage: this.minLengthMessage });
            } else {
                this.setState({ minLengthMessage: "" });
            }
        }

        if (formkey === "confirmPassword" || formkey === "newPassword") {
            if (formData.newPassword !== formData.confirmPassword) {
                this.setState({ doesNotMatchMessage: this.doesNotMatchMessage });
            } else {
                this.setState({ doesNotMatchMessage: "" });
            }
        }
        this.setState({ formData: formData });
    };

    onSubmit = async (event) => {
        event.preventDefault();
        this.setState({ saving: true });
        let userUrl = '/api/Account/PostUser';
        let user = await postRequest(userUrl, this.convertToUserModel());
        this.props.setLoggedInUser(user);
        this.setState({ saving: false });
    };

    onEmailSubmit = async (event) => {
        event.preventDefault();
        this.setState({ savingEmail: true });
        let userUrl = '/api/Account/UpdatePaymentEmail';
        let result = await postRequest(userUrl, { email: this.state.formEmail });
        if (result) {
            this.setState({ email: this.state.formEmail, savingEmail: false, emailMessageStyle: "", emailMessage: "We've sent a confirmation email to the address you specified." });
        } else {
            this.setState({ savingEmail: false, emailMessageStyle: "form-text-alert", emailMessage: "Unable to update email.  Please try again." });
        }
    };

    renderSubmit = () => {
        let enabled = false;
        const properties = ["phoneNumber", "address", "city", "state", "postalCode", "country"];
        for (let i = 0; i < properties.length; ++i) {
            if (this.state.formData[properties[i]]?.length > 0) {
                enabled = true;
            }
        }

        if (enabled) {
            return (<button type="submit" className="btn btn-secondary mt-3" onClick={(e) => this.onSubmit(e)}>
                {this.state.saving ?
                    <div className="spinner-border spinner-border-sm" role="status">
                        <span className="sr-only">Loading...</span>
                    </div> : ""} Submit</button>)
        }
        return <button type="submit" className="btn btn-secondary mt-3" disabled onClick={(e) => this.onSubmit(e)}>Submit</button>
    }

    renderEmailSubmit = () => {
        if (this.state.email !== this.state.formEmail && this.state.validEmail) {
            return (<button type="submit" className="btn btn-secondary mt-3" onClick={(e) => this.onEmailSubmit(e)}>
                {this.state.savingEmail ?
                    <div className="spinner-border spinner-border-sm" role="status">
                        <span className="sr-only">Loading...</span>
                    </div> : ""} Submit</button>)
        }
        return <button type="submit" className="btn btn-secondary mt-3" disabled onClick={(e) => this.onEmailSubmit(e)}>Submit</button>
    }


    renderPersonalInfo = () => {
        return (
            <div className="detail-block">
                <Helmet>
                    <title>RockPorch - Creator Settings</title>
                </Helmet>
            <h5>Personal Information</h5>
            <form action="">
                <FormInputField
                    title="Phone"
                    name="phoneNumber"
                    inputType="tel"
                    value={this.state.formData.phoneNumber}
                    onInputChange={this.onInputChange}
                    className="form-group"
                />

                <FormInputField
                    title="Address"
                    name="address"
                    inputType="text"
                    value={this.state.formData.address}
                    onInputChange={this.onInputChange}
                    className="form-group"
                />

                <div className="form-row">
                    <div className="form-group col-md-4">
                        <label htmlFor="state"><strong>City</strong></label>
                        <input type="text" className="form-control" name="city" value={this.state.formData.city} onChange={(e) => this.onInputChange(e)} />
                    </div>
                    <div className="form-group col-md-4">
                        <label htmlFor="state"><strong>State</strong></label>
                        <input type="text" className="form-control" name="state" value={this.state.formData.state} onChange={(e) => this.onInputChange(e)} />
                    </div>
                    <div className="form-group col-md-4">
                        <label htmlFor="postalcode"><strong>Postal Code</strong></label>
                        <input type="text" className="form-control" name="postalCode" value={this.state.formData.postalCode} onChange={(e) => this.onInputChange(e)} />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-4">
                        <label htmlFor="country"><strong>Country</strong></label>
                        <input type="text" className="form-control" name="country" value={this.state.formData.country} onChange={(e) => this.onInputChange(e)} />
                    </div>
                </div>
                {this.renderSubmit()}
            </form>
        </div>);
    }

    renderPaymentInfo = () => {
        return (<form>
            <div className="detail-block">
                <h5>Commissions & Payments</h5>
                <h6>Connect Your PayPal Account</h6>
                <p>Enter the email address associated with your PayPal account to get paid</p>
                <FormInputField
                    title="PayPal Email"
                    name="email"
                    inputType="email"
                    value={this.state.formEmail}
                    onInputChange={this.onInputChangeEmail}
                    smallText={this.state.emailMessage}
                    smallTextStyle={this.state.emailMessageStyle}
                />
                {this.renderEmailSubmit()}
            </div>
        </form>);
    }
    breadCrumbList = [{ link: '/Account/CreatorDashboard', text: 'Creator Dashboard' }, { link: '/Account/ProSettings', text: 'Creator Settings', active: true }];

    convertToFormData = (user) => {
        return {
            phoneNumber: user.phoneNumber ?? "",
            address: user.address ?? "",
            city: user.city ?? "",
            state: user.state ?? "",
            postalCode: user.postalCode ?? "",
            country: user.country ?? ""
        };
    }

    convertToUserModel = () => {
        return {
            name: this.props.loggedInUser.name,
            firstName: this.props.loggedInUser.firstName,
            lastName: this.props.loggedInUser.lastName,
            bio: this.props.loggedInUser.bio,
            tagLine: this.props.loggedInUser.tagLine,
            website: this.props.loggedInUser.website,
            googleUsername: this.props.loggedInUser.googleUsername,
            facebookUsername: this.props.loggedInUser.facebookUsername,
            instagramUsername: this.props.loggedInUser.instagramUsername,
            imageUrl: this.props.loggedInUser.imageUrl,
            userName: this.props.loggedInUser.userName,
            email: this.props.loggedInUser.email,
            phoneNumber: this.state.formData.phoneNumber,
            proStatusId: this.props.loggedInUser.proStatusId,

            address: this.state.formData.address,
            city: this.state.formData.city,
            state: this.state.formData.state,
            postalCode: this.state.formData.postalCode,
            country: this.state.formData.country,

            onboardingComplete: this.props.loggedInUser.onboardingComplete,
            profileBannerUrl: this.props.loggedInUser.profileBannerUrl,

            quickStart: this.props.loggedInUser.quickStart
        }
    }

    render() {
        return (
            <section>
                <AccountBanner breadCrumbList={this.breadCrumbList}>
                    <h1 className="mb-4">Creator Settings</h1>
                    <p className="mb-0">Manage personal and payment information</p>
                </AccountBanner>

                <div className="gray-bg full-height">
                    <div className="container">
                        <div className="row py-5 mt-0">                           
                            <div className="col-12">
                                <div className="user-detail-form">
                                        {this.renderPersonalInfo()}
                                        {this.renderPaymentInfo()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
};

export default ProSettings;
