import { func } from "prop-types";

const modalState = {
    showModal: false,
    showAddProductModal: false,
    showCreateLockerModal: false,
    showProductSelectModal: false,
    showShareModal: false,
    showSharePostModal: false,
    shareLink: "",
    followersFollowing: {
        showFollowersFollowing: false,
        userId: null,
        followersFollowing: "",
        productId: null,
    },
    fetchFollowing: false,
    postPageModal: {
        showPostPageModal: false,
        post: null,
        loggedInUser: null,
        isCommenting: false,
        parentModal: {
            type: null,
            shareCode: null,
            parentState: null
        }
    },
    blogPageModal: {
        showBlogPageModal: false,
        post: null,
        loggedInUser: null,
        isCommenting: false,
        parentModal: {
            type: null,
            shareCode: null,
            parentState: null
        }
    },
    lockerPageModal: {
        showLockerPageModal: false,
        post: null,
        loggedInUser: null,
        isCommenting: false,
        parentModal: {
            type: null,
            shareCode: null,
            parentState: null
        },
        
    },
    productPageModal: {
        showProductPageModal: false,
        product: null,
        loggedInUser: null,
        isCommenting: false,
        parentModal: {
            type: null,
            shareCode: null,
            parentState:null
        },
        isBrowse: false,
        isSearch: false,
    },
    addProductModal: {
        stage: 1,
        productUrl: "",
        isLoading: false
    },
    shareBlogModal: {
        showShareBlogModal: false,
    },
    shareDeepLinkModal: {
        showShareDeepLinkModal: false,
    },
    addProductOrDeepLinkModal: {
        showAddProductOrDeepLinkModal: false,
    },
    //modalCase: 
}

const TOGGLE_MODAL = "TOGGLE_MODAL";
const TOGGLE_ADD_PRODUCT_MODAL = "TOGGLE_ADD_PRODUCT_MODAL";
const TOGGLE_SHARE_BLOG_MODAL = "TOGGLE_SHARE_BLOG_MODAL";
const TOGGLE_SHARE_DEEP_LINK_MODAL = "TOGGLE_SHARE_DEEP_LINK_MODAL";
const TOGGLE_ADD_PRODUCT_OR_DEEP_LINK_MODAL = "TOGGLE_ADD_PRODUCT_OR_DEEP_LINK_MODAL";
const TOGGLE_CREATE_LOCKER_MODAL = "TOGGLE_CREATE_LOCKER_MODAL";
const TOGGLE_PRODUCTS_SELECTOR = "TOGGLE_PRODUCTS_SELECTOR";
const TOGGLE_SHARE_MODAL = "TOGGLE_SHARE_MODAL";
const TOGGLE_FOLLOWING_MODAL = "TOGGLE_FOLLOWING_MODAL";
const TOGGLE_FETCH_FOLLOWING = "TOGGLE_FETCH_FOLLOWING";
const TOGGLE_POST_PAGE_MODAL = "TOGGLE_POST_PAGE_MODAL";
const TOGGLE_LOCKER_PAGE_MODAL = "TOGGLE_LOCKER_PAGE_MODAL";
const TOGGLE_BLOG_PAGE_MODAL = "TOGGLE_BLOG_PAGE_MODAL";
const TOGGLE_PRODUCT_PAGE_MODAL = "TOGGLE_PRODUCT_PAGE_MODAL";
const TOGGLE_SHARE_POST_MODAL = "TOGGLE_SHARE_POST_MODAL";
const CLOSE_ALL_MODALS = "CLOSE_ALL_MODALS";

const resetState = () => {
    let newState = { ...modalState };
    let keys = Object.keys(newState);
    keys.forEach((stateKey) => {
        if (stateKey === "shareLink" || stateKey === "postForProductSelection") {
            newState[stateKey] = ""
        } else if (stateKey === "followersFollowing") {
            newState[stateKey] = { showFollowersFollowing: false, userId: null, followersFollowing: "" };
        } else if (stateKey === "postPageModal") {
            newState[stateKey] = {
                showPostPageModal: false, post: null, loggedInUser: null, isCommenting: false, parentModal: {
                    type: null,
                    shareCode: null,
                    parentState: null
                }
            }
        } else if (stateKey === "blogPageModal") {
            newState[stateKey] = {
                showBlogPageModal: false, post: null, loggedInUser: null, isCommenting: false, parentModal: {
                    type: null,
                    shareCode: null,
                    parentState: null
                }
            }
        } else if (stateKey === "lockerPageModal") {
            newState[stateKey] = {
                showLockerPageModal: false, shareCode: "", isCommenting: false, parentModal: {
                    type: null,
                    shareCode: null,
                    parentState: null
                }
            }
        } else if (stateKey === "productPageModal") {
            newState[stateKey] = {
                showProductPageModal: false, shareCode: "", isCommenting: false, parentModal: { type: null, shareCode: null, parentState: null }, isBrowse: false, isSearch: false,
            }
        } else if (stateKey === "addProductModal") {
            newState[stateKey] = { showAddProductModal: false, stage: 1, productUrl: "", isLoading: false }
        } else if (stateKey === 'shareBlogModal') {
            newState[stateKey] = { showShareBlogModal: false }
        } else if (stateKey === 'shareDeepLinkModal') {
            newState[stateKey] = { showShareDeepLinkModal: false }
        } else if (stateKey === 'addProductOrDeepLinkModal') {
            newState[stateKey] = { showAddProductOrDeepLinkModal: false }
        } else {
                newState[stateKey] = false;
        }

    })
    return newState;
}

function toggleBodyScroll(shouldHide) {
    var current = document.querySelector("body").style.overflow;

    if (shouldHide === true) {
        document.querySelector("body").style.overflow = "hidden";
    } else {
        document.querySelector("body").style.overflow = ""
    }
}

export function closeAllModals() {
    return { type: CLOSE_ALL_MODALS }
}

export function toggleSharePostModal() {
    return { type: TOGGLE_SHARE_POST_MODAL }
}

export function toggle() {
    return { type: TOGGLE_MODAL }
}

export function toggleAddProductModal() {
    return { type: TOGGLE_ADD_PRODUCT_MODAL }
}

export function toggleShareBlogModal() {
    return { type: TOGGLE_SHARE_BLOG_MODAL }
}

export function toggleShareDeepLinkModal() {
    return { type: TOGGLE_SHARE_DEEP_LINK_MODAL }
}

export function toggleAddProductOrDeepLinkModal() {
    return { type: TOGGLE_ADD_PRODUCT_OR_DEEP_LINK_MODAL }
}

export function toggleCreateLockerModal() {
    return { type: TOGGLE_CREATE_LOCKER_MODAL }
}

export function toggleProductSelectModal() {
    return { type: TOGGLE_PRODUCTS_SELECTOR }
}

export function toggleFetchFollowing() {
    return { type: TOGGLE_FETCH_FOLLOWING }
}

export function toggleShareModal(shareLink) {
    return { type: TOGGLE_SHARE_MODAL, payload: shareLink }
}

export function toggleFollowersFollowingModal(userId, followersFollowing, productId) {
    return {
        type: TOGGLE_FOLLOWING_MODAL, payload: { userId, followersFollowing, productId }
    }
}

export function togglePostPageModal(post, loggedInUser, isCommenting, setPost,parentModal) {
    return {
        type: TOGGLE_POST_PAGE_MODAL,
        payload: { post, loggedInUser, isCommenting, setPost,parentModal }
    }
}

export function toggleBlogPageModal(post, loggedInUser, isCommenting, setPost, parentModal) {
    return {
        type: TOGGLE_BLOG_PAGE_MODAL,
        payload: { post, loggedInUser, isCommenting, setPost, parentModal }
    }
}

export function toggleLockerPageModal(post, loggedInUser, isCommenting, setPost,parentModal) {
    return {
        type: TOGGLE_LOCKER_PAGE_MODAL,
        payload: { post, loggedInUser, isCommenting, setPost,parentModal }
    }
}

export function toggleProductPageModal(product, loggedInUser, isCommenting, setProduct,parentModal, isBrowse, isSearch) {
    return {
        type: TOGGLE_PRODUCT_PAGE_MODAL,
        payload: { product, loggedInUser, isCommenting, setProduct,parentModal, isBrowse, isSearch }
    }
}

export default function modalReducer(state = modalState, action) {
    switch (action.type) {
        case TOGGLE_MODAL: {
            let newShowModal = !state.showModal;
            return { ...state, showModal: newShowModal }
        }
        case TOGGLE_ADD_PRODUCT_MODAL: {
            let newState = resetState();
            let changes = { showAddProductModal: !state.showAddProductModal };
            toggleBodyScroll(changes.showAddProductModal)
            return { ...newState, ...changes }
        }
        case TOGGLE_SHARE_BLOG_MODAL: {
            let newState = resetState();
            let changes = {
                shareBlogModal: {
                    showShareBlogModal: !state.shareBlogModal.showShareBlogModal,
                }
            }
            toggleBodyScroll(changes.shareBlogModal.showShareBlogModal)
            return { ...newState, ...changes }
        }

        case TOGGLE_SHARE_DEEP_LINK_MODAL: {
            let newState = resetState();
            let changes = {
                shareDeepLinkModal: {
                    showShareDeepLinkModal: !state.shareDeepLinkModal.showShareDeepLinkModal,
                }
            }
            toggleBodyScroll(changes.shareDeepLinkModal.showShareDeepLinkModal)

            return { ...newState, ...changes }
        }

        case TOGGLE_ADD_PRODUCT_OR_DEEP_LINK_MODAL: {
            let newState = resetState();
            let changes = {
                addProductOrDeepLinkModal: {
                    showAddProductOrDeepLinkModal: !state.addProductOrDeepLinkModal.showAddProductOrDeepLinkModal,
                }
            }
            toggleBodyScroll(changes.addProductOrDeepLinkModal.showAddProductOrDeepLinkModal)
            return { ...newState, ...changes }
        }
        case TOGGLE_CREATE_LOCKER_MODAL: {
            let newState = resetState();
            let changes = { showCreateLockerModal: !state.showCreateLockerModal }
            toggleBodyScroll(changes.showCreateLockerModal)
            return { ...newState, ...changes };
        }
        case TOGGLE_PRODUCTS_SELECTOR: {
            let newState = resetState();
            let changes = { showProductSelectModal: !state.showProductSelectModal };
            toggleBodyScroll(changes.showProductSelectModal)
            return { ...newState, ...changes };
        }
        case TOGGLE_SHARE_MODAL: {
            let newState = resetState();
            let changes = { showShareModal: !state.showShareModal, shareLink: action.payload };
            toggleBodyScroll(changes.showShareModal)
            return { ...newState, ...changes }
        }
        case TOGGLE_FOLLOWING_MODAL: {
            let newState = resetState();
            let changes = {
                followersFollowing: {
                    showFollowersFollowing: !state.followersFollowing.showFollowersFollowing,
                    userId: action.payload.userId,
                    followersFollowing: action.payload.followersFollowing,
                    productId: action.payload.productId,
                }
            }
            toggleBodyScroll(changes.followersFollowing.showFollowersFollowing)
            return {...newState,...changes}
        }

        case TOGGLE_FETCH_FOLLOWING: {
            let newFetchFollowing = !state.fetchFollowing;
            return {...state, fetchFollowing: newFetchFollowing }
        }

        case TOGGLE_POST_PAGE_MODAL: {
            let newState = resetState();
           
            let changes = {
                postPageModal: {
                    showPostPageModal: !state.postPageModal.showPostPageModal,
                    post: action.payload.post,
                    loggedInUser: action.payload.loggedInUser,
                    isCommenting: action.payload.isCommenting,
                    parentModal : action.payload.parentModal
                }               
            }
            toggleBodyScroll(changes.postPageModal.showPostPageModal);
            return { ...newState,...changes }
        }

        case TOGGLE_BLOG_PAGE_MODAL: {
            let newState = resetState();

            let changes = {
                blogPageModal: {
                    showBlogPageModal: !state.blogPageModal.showBlogPageModal,
                    post: action.payload.post,
                    loggedInUser: action.payload.loggedInUser,
                    isCommenting: action.payload.isCommenting,
                    parentModal: action.payload.parentModal
                }
            }
            toggleBodyScroll(changes.blogPageModal.showBlogPageModal);
            return { ...newState, ...changes }
        }

        case TOGGLE_LOCKER_PAGE_MODAL: {
            let newState = resetState();
            
            let changes = {
                lockerPageModal: {
                    showLockerPageModal: !state.lockerPageModal.showLockerPageModal,
                    post: action.payload.post,
                    loggedInUser: action.payload.loggedInUser,
                    isCommenting: action.payload.isCommenting,
                    parentModal: action.payload?.parentModal
                }
            }
            toggleBodyScroll(changes.lockerPageModal.showLockerPageModal);
            return { ...newState,...changes }
        }

        case TOGGLE_PRODUCT_PAGE_MODAL: {
            let newState = resetState();
            
            let changes = {
                productPageModal: {
                    showProductPageModal: !state.productPageModal.showProductPageModal,
                    product: action.payload.product,
                    loggedInUser: action.payload.loggedInUser,
                    isCommenting: action.payload?.isCommenting || false,
                    parentModal: action.payload?.parentModal,
                    isBrowse: action.payload?.isBrowse || false,
                    isSearch: action.payload?.isSearch || false
                }
               
            }
            toggleBodyScroll(changes.productPageModal.showProductPageModal);
            return { ...newState, ...changes }
        }
        case TOGGLE_SHARE_POST_MODAL: {
            let newState = resetState();
            let changes = {
                showSharePostModal: !state.showSharePostModal
            }            
            return { ...newState, ...changes }
        }
        case CLOSE_ALL_MODALS: {
            let newState = resetState();
            document.querySelector("body").style.overflow = ""
            return { ...newState };
        }
        default: {
            return state;
        }
    }
}