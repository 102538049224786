import React from 'react'
import { Component } from 'react';
import authService from './AuthorizeService';
import { AuthenticationResultStatus } from './AuthorizeService';
import { LoginActions, QueryParameterNames, ApplicationPaths } from './ApiAuthorizationConstants';

// The main responsibility of this component is to handle the user's login process.
// This is the starting point for the login process. Any component that needs to authenticate
// a user can simply perform a redirect to this component with a returnUrl query parameter and
// let the component perform the login and return back to the return url.
export class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            message: undefined
        };
    }

    componentDidMount() {
        const action = this.props.action;
        switch (action) {
            case LoginActions.Login:
                this.login(this.getReturnUrl());
                break;
            case LoginActions.LoginCallback:
                this.processLoginCallback();
                break;
            case LoginActions.LoginFailed:
                const params = new URLSearchParams(window.location.search);
                const error = params.get(QueryParameterNames.Message);
                this.setState({ message: error });
                break;
            case LoginActions.Profile:
                this.redirectToProfile();
                break;
            case LoginActions.Register:
                this.redirectToRegister();
                break;
            default:
                throw new Error(`Invalid action '${action}'`);
        }
    }

    renderLoggingIn = () => {
        return (
            <div className="container-fluid sign-up-block">
                <div className="row">
                    <div className="col-md-5 px-0">
                        <figure className="m-0 w-100 h-100">
                            <img src="/images/sign-up-bg.png" alt="" className="w-100 h-100 bg-image" />
                        </figure>
                        <div className="logo position-absolute">
                            <img src="/images/Logo.svg" alt="" />
                            <h1>Tell Your Story. Share in the Adventure.</h1>
                        </div>
                    </div>
                    <div className="col-md-7">
                        <div className="sign-up-form w-100 mx-auto pb-3">
                            <h2 className="mb-4 mb-md-5 text-center text-lg-left">Logging in ...</h2>
                            <div className="mb-4 mb-md-5">
                                <p>Please wait while we log you in.</p>
                                <p>{this.state.message}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }

    render() {
        const action = this.props.action;

        switch (action) {
            case LoginActions.Login:
                return (this.renderLoggingIn());
            case LoginActions.LoginCallback:
                return (this.renderLoggingIn());
            case LoginActions.Profile:
            case LoginActions.Register:
                return (<div></div>);
            default:
                throw new Error(`Invalid action '${action}'`);
        }
    }

    async login(returnUrl) {
        const state = { returnUrl };
        const result = await authService.signIn(state);
        switch (result.status) {
            case AuthenticationResultStatus.Redirect:
                break;
            case AuthenticationResultStatus.Success:
                await this.setIsLoggedInCookie();
                await this.navigateToReturnUrl(returnUrl);
                break;
            case AuthenticationResultStatus.Fail:
                this.setState({ message: result.message });
                break;
            default:
                throw new Error(`Invalid status result ${result.status}.`);
        }
    }

    async setIsLoggedInCookie() {
        let todayEpoch = Date.now();
        let today = todayEpoch.toLocaleString();
        let todayDate = new Date();
        let exDate = new Date(todayDate.getFullYear() + 50, todayDate.getMonth(), todayDate.getDay());
        
        let hostname = window.location.hostname;
        let domain = hostname === "localhost" ? hostname : hostname.substring(hostname.indexOf("."));

        document.cookie = `_Rockporch.App.LoggedIn=${today};domain=${domain};expires=${exDate.toISOString()}`;
    }

    async processLoginCallback() {
        const url = window.location.href;
        const result = await authService.completeSignIn(url);
        switch (result.status) {
            case AuthenticationResultStatus.Redirect:
                // There should not be any redirects as the only time completeSignIn finishes
                // is when we are doing a redirect sign in flow.
                throw new Error('Should not redirect.');
            case AuthenticationResultStatus.Success:
                await this.navigateToReturnUrl(this.getReturnUrl(result.state));
                break;
            case AuthenticationResultStatus.Fail:
                this.setState({ message: result.message });
                break;
            default:
                throw new Error(`Invalid authentication result status '${result.status}'.`);
        }
    }

    getReturnUrl(state) {
        const params = new URLSearchParams(window.location.search);
        const fromQuery = params.get(QueryParameterNames.ReturnUrl);
        return (state && state.returnUrl) || fromQuery || `${window.location.origin}/`;
    }

    redirectToRegister() {
        this.redirectToApiAuthorizationPath(`${ApplicationPaths.IdentityRegisterPath}?${QueryParameterNames.ReturnUrl}=${encodeURI(ApplicationPaths.Login)}`);
    }

    redirectToProfile() {
        this.redirectToApiAuthorizationPath(ApplicationPaths.IdentityManagePath);
    }

    redirectToApiAuthorizationPath(apiAuthorizationPath) {
        const redirectUrl = `${window.location.origin}${apiAuthorizationPath}`;
        // It's important that we do a replace here so that when the user hits the back arrow on the
        // browser he gets sent back to where it was on the app instead of to an endpoint on this
        // component.
        window.location.replace(redirectUrl);
    }

    navigateToReturnUrl(returnUrl) {
        // It's important that we do a replace here so that we remove the callback uri with the
        // fragment containing the tokens from the browser history.
        window.location.replace(returnUrl);
    }
}
