import React from "react";
import FeedThreeColumn from "components/Feed/FeedThreeColumn";
import FeedPostGridItem from "components/Feed/FeedPostGridItem";
import FeedSingleColumn from "components/Feed/FeedSingleColumn";
import FeedPost from "components/Feed/FeedPost";
import FeedFilterBar from "components/FeedFilterBar";
import { trimProductsArray } from "../../sharedUtils/postFunctions";
import blankFeedPost from "../../sharedUtils/blankFeedPost";
//import SearchFilterBar from "components/SearchFilterBar";
import { getProperties } from "../../sharedUtils/postFunctions";
import { getRequest, postRequest } from "sharedUtils/httpUtils";
import Feed from "components/Feed/Feed";
import LoadSpinner from "components/LoadSpinner";
import { Helmet } from 'react-helmet';
import Modal from "../../components/Modal";
import ShareBlock from "components/Share/ShareBlock";
import MerchantShareBlock from "components/Share/MerchantShareBlock";
import { toggleProductPageModal, toggleShareModal } from "stateManagement/reducers/modalReducer";
import { connect } from "react-redux";
import { getMerchantShareLink } from "../../sharedUtils/shareLinkPrefixes"

/**
 * @description The Explore view. Would contain the state and methods for handling the explore feed
 */
class Explore extends React.Component {
    //constructor(props)
    state = {
        isGrid: true,
        posts: [],
        isSearching: false,
        lockerSearchResults: [],
        productSearchResults: [],
        postSearchResults: [],
        peopleSearchResults: [],
        loggedInUser: {},
        finishedFetching: false,
        isLoading: true,
        modalOpen: false,
        shareLink: "",
    }

    componentDidMount() {
        //this.props.setShowTopNav(true);
        this.props.setShowTopNav(true, !!(window.location.search.toLowerCase().includes("showbrandnav=")) ? this.props.showBrandNav(window.location.search) : true);
        this.setState({ loggedInUser: this.props.loggedInUser }, () => {
            this.populateData();
        })
        if (["sm", "md"].includes(this.props.deviceSize)) {
            let scrollOptions = {
                left: 0,
                top: 0,
                behavior: 'auto',
                lockerNotFound: true
            }
            window.scrollTo(scrollOptions);
        }
    }

    async populateData() {
        const url = `${this.state.loggedInUser == null ? 'api/Post/GetExploreFeedPostsGuest' : 'api/Post/GetExploreFeedPosts'}?userId=${this.state.loggedInUser?.id}&skip=0&take=24`
        let posts = await getRequest(url);
        if (posts == null) {
            posts = await getRequest('api/Post/GetExploreFeedPostsGuest?skip=0&take=24');

            this.props.setLoggedInUser(undefined);
        }

        this.setState({ posts, isLoading: false });
    }

    toggleProductShareModal = async (urlFragment) => {
        let showModal = !this.state.modalOpen;
        let shareLink = await getMerchantShareLink(urlFragment);

        if (showModal) {
            this.setState({ modalOpen: showModal, shareLink });
        }
        else {
            this.setState({ modalOpen: showModal, shareLink: "" });
        }
    }


    displayShareModal = () => {
        let shareProps = {
            shareCode: this.state.shareLink,
            title: "Merchant share",
            customStyle: { maxWidth: "100%", backgroundColor: "white" },
            shareBlockStyle: { border: "none", backgroundColor: "transparent" },
            loggedInUser: this.props.loggedInUser,
            type: "merchant",
        }
        return (
            <Modal isOpen={this.state.modalOpen} toggleModal={this.toggleProductShareModal}>
                <ShareBlock {...shareProps}>
                    <MerchantShareBlock
                        {...shareProps}
                    />
                </ShareBlock>
            </Modal>
        )
    }

    fetchMore = async () => {
        // todo: replace with endpoint to get posts based on user's interests or sectors
        let userId = this.state.loggedInUser?.id;

        if (this.state.posts.length > 0) {
            const url = `${this.state.loggedInUser == null ? 'api/Post/GetExploreFeedPostsGuest' : 'api/Post/GetExploreFeedPosts'}?userId=${userId}&skip=${this.state.posts.length}&take=24`;
            const data = await getRequest(url);

            if (data === undefined || data === null || data?.length === 0) {
                this.setState({ finishedFetching: true })
            }
            else {
                let newData = [...this.state.posts, ...data];
                this.setState({ posts: newData });
            }
        }

    }

    toggleColumns = () => {
        let newState = !this.state.isGrid;
        this.setState({ isGrid: newState });
    }

    updatePosts = (newPosts) => {
        this.setState({ posts: newPosts });
    }

    render() {
        if (this.state.isLoading === true) {
            return (
                <>
                    < LoadSpinner />
                    <Helmet>
                        <title>RockPorch - Explore</title>
                    </Helmet>
                </>
                ) 
        }
        return (
            <>
                <Helmet>
                    <title>RockPorch - Explore</title>
                </Helmet>
                {this.displayShareModal()}

                <div className="container-fluid gray-bg full-height explore-feed-wrapper px-0"
                    style={
                        this.props.deviceSize === "sm" ? { paddingLeft: "1px", paddingRight: "1px" } : {}
                    }
                >

                    <FeedFilterBar
                        toggleAction={this.toggleColumns}
                        isGrid={this.state.isGrid}
                        className={`home-filter-bar ${this.props.deviceSize === "sm" || this.props.deviceSize === "md" ? "mt-0" : ""}`}
                    />

                    <div className="container" style={{ margin: "auto" }}>

                        <div className="row mt-0">
                            {
                                this.state.isGrid === true ? null : (
                                    <div className="col-md-3 user-profile top-section-child ">
                                    </div>
                                )
                            }

                            <div className={`${this.state.isGrid === true || this.props.deviceSize === "md" ? "col-12" : "col-md-6"} px-0 px-md-5 top-section-child`} >

                                <div className='mt-1 home-feed-wrapper row grid mx-0'>
                                    <div style={{ width: "100%" }}>
                                        <Feed
                                            posts={this.state.posts}
                                            isGrid={this.state.isGrid}
                                            loggedInUser={this.state.loggedInUser}
                                            fetchMore={this.fetchMore}
                                            finishedFetching={this.state.finishedFetching}
                                            showFollowSuggestions={false}
                                            deviceSize={this.props.deviceSize}
                                            updateParent={this.updatePosts}
                                            showUserTile={true}
                                            toggleSignUpModal={this.props.toggleSignUpModal}
                                            screenWidth={this.props.screenWidth}
                                            toggleProductShareModal={this.toggleProductShareModal}
                                        />
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

function mapStateToProps(storeState, ownProps) {
    return { interactionState: storeState.interactionReducer }
}

export default connect(mapStateToProps, { toggleProductPageModal, toggleShareModal })(Explore);