import React from "react";
import ProfileFilterBar from "./ProfileFilterBar";
import authService from "components/api-authorization/AuthorizeService";
import LoadSpinner from "components/LoadSpinner";
import { getRequest, postRequest } from "sharedUtils/httpUtils";
import ProfileHeader from "./ProfileHeader";
import ProfileAboutSection from "./ProfileAboutSection"
import Feed from "components/Feed/Feed";
import ProductFeed from "components/Feed/ProductFeed";
import MessageDetails from "../Account/MessageDetails";
import { connect } from "react-redux";
import { toggleFollowersFollowingModal, closeAllModals, toggleFetchFollowing } from "stateManagement/reducers/modalReducer";
import { Helmet } from 'react-helmet';

class Profile extends React.Component {
    state = {
        userInformation: {
            id: 0,
            proStatusId: 0,
            bio: "",
            name: "",
            imageUrl: "",
            tagLine: "",
            userName: "",
            followers: 0,
            following: 0,
            merchantProducts: {
                $values: []
            },
            sectors: {
                $values: []
            }
        },
        loggedInUser: {},
        profileBanner: "images/Profile-Banner.png",
        uploadingProfileBanner: false,
        followers: 0,
        following: 0,
        isGrid: true,
        lockers: [],
        products: [],
        posts: [],
        activityFeed: [],
        showMessageDetails: false,
        isFollowing: false,
        isLoading: true,
        finishedFetching: false,
        finishedFetchingProducts: false,
        selectedFile: {},
        selectedFileUrl: null,
        uploadingBackground: false,
        selectedCase: 2, //1:Activity, 2:Lockers, 3:Products, 4:Posts, 5:About
        postCount: 0,
        productCount: 0,
        lockerCount: 0,
        finishedFetchingActivity: false,
        finishedFetchingLockers: false,
        finishedFetchingPosts: false,
    }

    toggleGrid = () => {
        let newIsGrid = !this.state.isGrid;
        this.setState({ isGrid: newIsGrid });
    }

    async componentDidMount() {
        this.props.setShowTopNav(true, !!(window.location.search.toLowerCase().includes("showbrandnav=")) ? this.props.showBrandNav(window.location.search) : true);
        this.props.closeAllModals();
        let userName = this.props.match.params.userName;

        let user = await this.fetchUserInformation(userName);
        if (user == undefined || user == null) {
            this.props.history.push("/NotFound")
        }
        else {
            this.setState({ userInformation: user });

            await Promise.all([
                this.fetchCounts(user.id),
                this.fetchIsFollowing(user.id),
                this.fetchProducts(0, 96),
                this.fetchActivityFeed(0, 12)]);

            this.setState({ isLoading: false });
        }
        document.addEventListener("_productAddedEvent", this.updateProducts);
        window.addEventListener('storage', () => {
            var productAdded = new Date(window.localStorage.getItem('productAdded'));
            if (productAdded < new Date()) {
                this.updateProducts();
            }
        });
    }

    updateProducts = async () => {
        let basePath = this.props.loggedInUser ? "/Products/GetUserMerchantProductsAuthorized" : "/Products/GetUserMerchantProducts";
        let products = await getRequest(`${basePath}/?userName=${this.state.userInformation.userName}&skip=0&take=96&onlyPostedProducts=true`);
        let fullProducts = products?.map((product) => {
            product.user = this.state.userInformation
            return product;
        });
        this.setState({ products: fullProducts });
    }

    componentWillUnmount = () => {
        document.removeEventListener("_productAddedEvent", this.updateProducts);
        window.removeEventListener('storage', () => { });
    }

    async componentDidUpdate(prevProps, prevState) {
        if (this.props.match.params.userName !== prevProps.match.params.userName) {
            this.props.closeAllModals();

            this.setState({
                profileBanner: "images/Profile-Banner.png",
                uploadingProfileBanner: false,
                followers: 0,
                following: 0,
                isGrid: true,
                lockers: [],
                products: [],
                posts: [],
                activityFeed: [],
                showMessageDetails: false,
                isFollowing: false,
                isLoading: true,
                finishedFetching: false,
                finishedFetchingProducts: false,
                selectedFile: {},
                selectedFileUrl: null,
                uploadingBackground: false,
                selectedCase: 1, //1:Activity, 2:Lockers, 3:Products, 4:Posts, 5:About
                postCount: 0,
                productCount: 0,
                lockerCount: 0,
                finishedFetchingActivity: false,
                finishedFetchingLockers: false,
                finishedFetchingPosts: false
            }, async () => {
                let user = await (this.fetchUserInformation(this.props.match.params.userName));
                if (user === undefined || user == null) {
                    this.props.history.push("/NotFound");
                }
                else {
                    this.setState({ userInformation: user });

                    await Promise.all([
                        this.fetchCounts(user.id),
                        this.fetchIsFollowing(user.id),
                        this.fetchProducts(0, 96),
                        this.fetchActivityFeed(0, 24)]);

                    this.setState({ isLoading: false })
                }
            })            
        }
    }

    selectMediaToUpload = (e) => {
        e.preventDefault();
        this.setState({ uploadingProfileBanner: true });
        let selectedFileTempUrl = URL.createObjectURL(e.target.files[0]);
        this.setState({ selectedFile: e.target.files[0], selectedFileUrl: selectedFileTempUrl }, async () => {
            let uploadImageResponse = await this.uploadBackroundImage();
            let userInformation = this.state.userInformation;
            userInformation.profileBannerUrl = uploadImageResponse.profileBannerUrl;

            this.setState({ userInformation, uploadingProfileBanner: false });
        })
    }

    uploadBackroundImage = async () => {
        if (this.state.selectedFile != null) {
            const formData = new FormData();
            formData.append(`profileBanner`, this.state.selectedFile);

            let url = `/api/Account/UploadProfileBannerPic`;
            let newProperties = await postRequest(url, formData, null, false);
            this.props.setLoggedInUser(newProperties);
            return newProperties;
        }
        else return null;
    }

    fetchUserInformation = async (username) => {
        let url = `/api/account/getProfile?userName=${username}`;
        let data = await getRequest(url);
        if (data == null || data == undefined) {
            return null;
        }
        else {
            const trackingUrl = `/api/account/trackProfileView${!!(this.props.loggedInUser) ? "Authorized" : ""}?creatorUserId=${data.id}&referrer=${document.referrer}`;
            await getRequest(trackingUrl)
            this.setState({ userInformation: data }, async () => {
                if (data.proStatusId !== 1) {
                    this.setState({ selectedCase: 5 });
                }
                await this.fetchIsFollowing(this.state.userInformation.id)
            });
            return data;
        }
       

        
    }

    fetchCounts = async (userId) => {
        let url = `api/Post/GetPostCount?userId=${userId}&onlyPublished=true`;
        let count = await getRequest(url);

        let sum = count.length > 0 ? count.reduce(function (a, b) {
            return { count: a.count + b.count };
        }) : { count: 0 };
        let totalCount = sum.count;
        let lockerCount = 0;
        let lockers = count.find(({ userPostTypeId }) => userPostTypeId === 2);

        if (lockers) {
            lockerCount = lockers.count;
        }

        let productCountUrl = `/Products/GetUserMerchantProductCount?userId=${userId}`
        let productCount = (await getRequest(productCountUrl)).count;

        this.setState({ postCount: totalCount - lockerCount, lockerCount, productCount });
    }

    fetchIsFollowing = async (userId) => {
        let url = `/api/Account/IsFollowing?id=${userId}`;
        let data = await getRequest(url);
        this.setState({ isFollowing: data });
    }

    fetchActivityFeed = async (skip, take) => {
        let url = this.props.loggedInUser != null ? '/api/post/getPostsByUserAuthorized' : '/api/post/getPostsByUser';

        let data = await getRequest(`${url}?userName=${this.state.userInformation.userName}&skip=${skip}&take=${take}`);

        let lockers = data.filter((post) => post.type === 2);
        let lockerIds = this.state.lockers.map((locker) => locker.id);

        let posts = data.filter((post) => post.type !== 2);
        let postIds = this.state.posts.map((post) => post.id);

        let lockersToAdd = lockers.filter((locker) => !lockerIds.includes(locker.id));
        let postsToAdd = posts.filter((post) => !postIds.includes(post.id));

        this.setState({
            activityFeed: this.state.activityFeed.concat(data),
            finishedFetchingActivity: data.length < take ? true : false,
            finishedFetchingPosts: data.length < take ? true : this.state.finishedFetchingPosts,
            finishedFetchingLockers: data.length < take ? true : this.state.finishedFetchingLockers,
            lockers: [...this.state.lockers, ...lockersToAdd],
            posts: [...this.state.posts, ...postsToAdd]
        });
    }

    fetchProducts = async (skip, take) => {
        this.setState({ finishedFetchingProducts: false })

        let basePath = this.props.loggedInUser ? "/Products/GetUserMerchantProductsAuthorized" : "/Products/GetUserMerchantProducts";

        let products = await getRequest(`${basePath}?userName=${this.state.userInformation.userName}&skip=${skip}&take=${take}&onlyPostedProducts=true`);
        let fullProducts = products.map((product) => {
            product.user = this.state.userInformation
            return product;
        });

        let productList = [...this.state.products, ...fullProducts];

        this.setState({ products: productList });

        if (!take) {
            this.setState({ finishedFetchingProducts: true })
        } else if (products.length < take) {
            this.setState({ finishedFetchingProducts: true })
        }
    }

    fetchLockers = async (skip, take) => {
        var postResults = await getRequest(`/api/post/getPostsByUser?userName=${this.state.userInformation.userName}&skip=${skip}&take=${take}&onlyLockers=true`);

        let lockers = postResults.filter((post) => post.type === 2);
        let lockerIds = this.state.lockers.map((locker) => locker.id);

        let lockersToAdd = lockers.filter((locker) => !lockerIds.includes(locker.id));

        this.setState({ lockers: [...this.state.lockers, ...lockersToAdd], finishedFetchingLockers: postResults.length < take ? true : false });
    }

    fetchPosts = async (skip, take) => {
        var postResults = await getRequest(`/api/post/getPostsByUser?userName=${this.state.userInformation.userName}&skip=${skip}&take=${take}&onlyPosts=true`);

        let posts = postResults.filter((post) => post.type !== 2);
        let postIds = this.state.posts.map((post) => post.id);
        let postsToAdd = posts.filter((post) => !postIds.includes(post.id));

        this.setState({ posts: [...this.state.posts, ...postsToAdd], finishedFetchingPosts: postResults.length < take ? true : false });
    }

    displayContent = (isGrid = this.state.isGrid) => {
        switch (this.state.selectedCase) {
            case 3: {
                if (isGrid === false) {
                    return (
                        <ProductFeed products={this.state.products} isGrid={isGrid} loggedInUser={this.props.loggedInUser} finishedFetching={this.state.finishedFetchingProducts}
                            fetchMore={() => this.fetchProducts(this.state.products.length, 96)} toggleSignUpModal={this.props.toggleSignUpModal}/>);
                }
                return (
                    <div className="product-grid">
                        <div className="row mt-0 mx-0">
                            <ProductFeed products={this.state.products}
                                loggedInUser={this.props.loggedInUser}
                                isGrid={isGrid}
                                finishedFetching={this.state.finishedFetchingProducts}
                                fetchMore={() => this.fetchProducts(this.state.products.length, 96)}
                                toggleSignUpModal={this.props.toggleSignUpModal}
                                deviceSize={this.props.deviceSize}
                            />
                        </div>
                    </div>
                )
            }
            case 1: {
                return (
                    <Feed loggedInUser={this.props.loggedInUser}
                        posts={this.state.activityFeed}
                        isGrid={isGrid}
                        finishedFetching={this.state.finishedFetchingActivity}
                        showFollowSuggestions={false}
                        fetchMore={() => this.fetchActivityFeed(this.state.activityFeed.length, 24)}
                        updateParent={(updates) => this.setState({ activityFeed: updates })}
                        showPins={true}
                        toggleSignUpModal={this.props.toggleSignUpModal}
                        deviceSize={this.props.deviceSize}
                    />
                )
            }
            case 2: {
                return <Feed loggedInUser={this.props.loggedInUser}
                    posts={this.state.lockers}
                    isGrid={isGrid}
                    finishedFetching={this.state.finishedFetchingLockers}
                    showFollowSuggestions={false}
                    fetchMore={() => this.fetchLockers(this.state.lockers.length, 24)}
                    updateParent={(updates) => this.setState({ lockers: updates })}
                    toggleSignUpModal={this.props.toggleSignUpModal}
                    deviceSize={this.props.deviceSize}
                />
            }
            case 4: {
                return <Feed loggedInUser={this.props.loggedInUser}
                    posts={this.state.posts}
                    isGrid={isGrid}
                    finishedFetching={this.state.finishedFetchingPosts}
                    showFollowSuggestions={false}
                    fetchMore={() => this.fetchPosts(this.state.posts.length, 24)}
                    updateParent={(updates) => this.setState({ posts: updates })}
                    toggleSignUpModal={this.props.toggleSignUpModal}
                    deviceSize={this.props.deviceSize}
                />
            }
            case 5: {
                return <ProfileAboutSection userInformation={this.state.userInformation} deviceSize={this.props.deviceSize} />;
            }
            default: {
                return <Feed loggedInUser={this.props.loggedInUser}
                    posts={this.state.activityFeed}
                    isGrid={isGrid}
                    finishedFetching={this.state.finishedFetchingActivity}
                    showFollowSuggestions={false}
                    fetchMore={() => this.fetchActivityFeed(this.state.activityFeed.length, 24)}
                    updateParent={(updates) => this.setState({ activityFeed: updates })}
                    toggleSignUpModal={this.props.toggleSignUpModal}
                    deviceSize={this.props.deviceSize}
                />
            }
        }
    }

    followOrUnfollow = async () => {
        const prevIsFollowing = this.state.isFollowing;
        this.setState({ isFollowing: !this.state.isFollowing });
        await this.props.toggleFetchFollowing();
        let token = await authService.getAccessToken();

        let url = "/api/Account/"
        if (prevIsFollowing) {
            url += "Unfollow"
        } else {
            url += "Follow"
        }
        url += "?id=" + this.state.userInformation.id;

        const response = await fetch(url,
            {
                headers: !token ? {} : { Authorization: `Bearer ${token}` },
            });

        if (response.ok) {
            this.setState(() => {
                let { followers } = this.state.userInformation;
                let newCount = this.state.isFollowing === false ? (followers - 1) : (followers + 1);
                let newState = { ...this.state };
                newState.userInformation.followers = newCount;
                this.setState(newState);

            });

        //    if (!this.props.fetchFollowing) this.props.toggleFetchFollowing();
        }
    }

    onClickMessage = async () => {
        this.setState({ showMessageDetails: true });
    }

    onClickBack = async () => {
        this.setState({ showMessageDetails: false });
    }

    displayFilterBar = () => {
        if (this.state.selectedCase !== 5) {
            return <ProfileFilterBar toggleAction={this.toggleGrid} isGrid={this.state.isGrid} />
        }
    }

    handleFetchLockersTab = () => {
        if (this.state.lockers.length === 0 && !this.state.finishedFetchingLockers) {
            this.fetchLockers(0, 24);
        }
        this.setState({ selectedCase: 2 });
    }

    render() {
        let { selectedCase, isLoading } = this.state;
        let { name, imageUrl, proStatusId, tagLine, userName, followers, following } = this.state.userInformation;
        if (isLoading === true) {
            return (
                <>
                    < LoadSpinner />
                        <Helmet>
                        <title>RockPorch - Profile: {!!(this.props.match.params.userName) ? this.props.match.params.userName : ""}</title>
                        </Helmet>
                </>
            )
        }
        else if (this.state.userInformation === "notFound") {
            return (
                <>
                    <h1>This user does not exist</h1>
                    <Helmet>
                        <title>RockPorch - Profile: {!!(this.props.match.params.userName) ? this.props.match.params.userName : ""}</title>
                    </Helmet>
                </>
            )
        }
        else {
            if (this.state.showMessageDetails) {
                return (
                    <>
                        <MessageDetails onClickBack={this.onClickBack} user={this.state.userInformation} loggedInUser={this.props.loggedInUser} />
                        <Helmet>
                            <title>RockPorch - Profile: {!!(this.props.match.params.userName) ? this.props.match.params.userName : ""}</title>
                        </Helmet>
                    </>
                )
            }

            return (
                <section>
                    <Helmet>
                        <title>RockPorch - Profile: {!!(this.props.match.params.userName) ? this.props.match.params.userName : ""}</title>
                    </Helmet>
                    <ProfileHeader
                        deviceSize={this.props.deviceSize}
                        showFollowersOrFollowing={(text) => {
                            if (this.props.loggedInUser) {
                                this.props.toggleFollowersFollowingModal(this.state.userInformation.id, text, null)
                            }
                            else {
                                this.props.toggleSignUpModal()
                            }

                        }}
                        userInformation={this.state.userInformation}
                        fetchProducts={() => this.setState({ selectedCase: 3 })}
                        fetchActivityFeed={() => this.setState({ selectedCase: 1 })}
                        fetchPosts={() => this.setState({ selectedCase: 4 })}
                        fetchLockers={this.handleFetchLockersTab}
                        selectedCase={selectedCase}
                        isAuthenticated={this.props.loggedInUser !== undefined}
                        isLoggedInUser={this.props.loggedInUser?.userName === userName}
                        loggedInUser={this.props.loggedInUser}
                        setLoggedInUser={this.props.setLoggedInUser}
                        isFollowing={this.state.isFollowing}
                        followAction={this.followOrUnfollow}
                        onClickMessage={this.onClickMessage}
                        productCount={this.state.productCount}
                        postCount={this.state.postCount}
                        lockerCount={this.state.lockerCount}
                        fetchAbout={() => this.setState({ selectedCase: 5 })}
                        uploadingProfileBanner={this.state.uploadingProfileBanner}
                        location={this.props.location}
                        toggleSignUpModal={this.props.toggleSignUpModal}
                        hamOpen={this.props.hamOpen}
                        setToggleMenu={this.props.setToggleMenu}
                        setHomeFeedSaveUser={this.props.setHomeFeedSaveUser}
                        setExploreSaveUser={this.props.setExploreSaveUser}
                        screenWidth={this.props.screenWidth}
                    />

                    <input type="file"
                        accept="image/png,image/jpeg"
                        name="files"
                        id="uploadProfileBanner"
                        onChange={(e) => this.selectMediaToUpload(e)}
                        style={{ display: "none" }}
                    />

                    {this.displayFilterBar()}

                    <div className="container-fluid gray-bg full-height explore-feed-wrapper px-0">

                        <div className="row m-0">
                            <div className="container px-0 px-md-5 mt-1 top-section-child">
                                {this.displayContent()}
                            </div>
                        </div>


                    </div>

                </section>
            )
        }

    }
}

function mapStateToProps(storeState, componentProps) {
    let result = { fetchFollowing: storeState.modalReducer.fetchFollowing }
    return result;
}

export default connect(mapStateToProps, { toggleFollowersFollowingModal, closeAllModals, toggleFetchFollowing })(Profile);