import React, { useState, useEffect, useRef } from "react";
import RightArrowIcon from "components/icons/RightArrowIcon";
import LeftArrowIcon from "components/icons/LeftArrowIcon";
import ImageWrapper from "components/ImageWrapper";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { toggleShareModal, togglePostPageModal, toggleLockerPageModal, toggleProductPageModal } from "stateManagement/reducers/modalReducer";
import { trimProductsArray } from "sharedUtils/postFunctions";
import { Swipeable } from "react-swipeable";
import LoadSpinner from "components/LoadSpinner";

import { getRequest } from "sharedUtils/httpUtils";
import PostPageProduct from "components/PostPageProduct";
import FeedPostGridItem from "components/Feed/FeedPostGridItem";
import SaleIcon from "components/icons/SaleIcon";
import ProfileEmbed from "./ProfileEmbed";
import { Link } from 'react-router-dom';
import FeaturedPostsAndProduct from "./FeaturedPostsAndProduct";
import FeaturedContent from "./FeaturedContent";
import {
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption
} from 'reactstrap';

/**
 * @description A post component for a three column feed grid
 * @param {{
 * post?:object,    
 * isPictureAndProducts:boolean,
 * showPins:boolean
 * redirectUrl:string   
 * togglePinned : (shareCode)=>
 * isPinned:boolean
 * showUserTile: boolean
 * }} props
 */
const FeaturedPostsAndProducts = (props) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);
    const [featuredData, setFeaturedData] = useState([]);
    const [carouselActive, setCarouselActive] = useState(true);

    const [lastTotalHeight, setLastTotalHeight] = useState(150);
    const [checkHeight, setCheckHeight] = useState(1);

    const getHeightMessage = (e) => {
        if (e.data === 'get-rp-featured-posts-products-height') {
            messageTotalHeight();
        }
    }

    const messageTotalHeight = () => {
        setCheckHeight({ ...-checkHeight });
    };

    useEffect(() => {
        props.setShowTopNav(false);

        // update the height onload
        populateData().then(() => {
            setTimeout(() => {
                sendHeight();
            }, 2000)
        });

        window.addEventListener('message', getHeightMessage);
        window.addEventListener('resize', messageTotalHeight);
        return () => {
            window.removeEventListener('resize', messageTotalHeight);
            window.removeEventListener('message', getHeightMessage);
        };
    }, []); 

    const sendHeight = () => {
        let totalHeight = document.documentElement.offsetHeight;

        if (lastTotalHeight !== totalHeight && totalHeight > 150) {
            setLastTotalHeight(totalHeight);

            window.parent.postMessage({
                id: 'rp-featured-posts-products',
                height: totalHeight,
            }, '*');
        }
    }

    useEffect(() => {
        sendHeight();
    }, [checkHeight])

    const populateData = async () => {
        let url = `api/Featured/GetFeaturedPostsAndProducts`;
        let data = await getRequest(url);

        if (data == undefined) {
            setFeaturedData([]);
        } else {
            //setPostsProducts(data.postsProducts.$values);
            setFeaturedData(data);

            const totalHeight = document.documentElement.offsetHeight;
            setLastTotalHeight(totalHeight);
            setTimeout(messageTotalHeight, 4000);
        }
    }

    const handleCarouselActive = () => {
        setCarouselActive(false);

        setTimeout(() => {
            setCarouselActive(true);
        }, 300);
    };

    const next = () => {
        if (animating) return;
        if (carouselActive) {
            const nextIndex = activeIndex === featuredData.length - 1 ? 0 : activeIndex + 1;
            setActiveIndex(nextIndex);
            setTimeout(messageTotalHeight, 1000);
        }
    }

    const previous = () => {
        if (animating) return;
        if (carouselActive) {
            const nextIndex = activeIndex === 0 ? featuredData.length - 1 : activeIndex - 1;
            setActiveIndex(nextIndex);
            setTimeout(messageTotalHeight, 1000);
        }
    }

    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
        setTimeout(messageTotalHeight, 1000);
    }

    const slides = featuredData.map((data, index) => {
        const isActive = index === activeIndex;
        return (
            <CarouselItem
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
                key={index}
            >
                <FeaturedPostsAndProduct handleCarouselActive={handleCarouselActive}  screenWidth={props.screenWidth} isActive={isActive} featuredData={featuredData[index]} deviceSize={props.deviceSize} setShowTopNav={props.setShowTopNav} />
            </CarouselItem>
        );
    });

    if (featuredData.length === 0) {
        return (
            <LoadSpinner />    
        )
    }

    return (
        <div className={`row brand-embed pt-0 mt-md-5 mb-0 justify-content-center pb-0`} id="contentDiv" style={{ width: "90%", margin: "0 auto" }}>
            <Carousel
                activeIndex={activeIndex}
                next={next}
                previous={previous}
                interval={5000}
                ride="carousel"
                style={{ overflow: "visible" }}
            >
                <CarouselIndicators items={featuredData} activeIndex={activeIndex} onClickHandler={goToIndex} />
                {slides}
                <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
                <CarouselControl direction="next" directionText="Next" onClickHandler={next} />            </Carousel>
        </div>
        //<FeaturedContent featuredData={featuredData[activeIndex]} setShowTopNav={props.setShowTopNav} />

    )
}
const mapDispatch = { toggleShareModal, togglePostPageModal, toggleLockerPageModal, toggleProductPageModal }

export default connect(null, mapDispatch)(FeaturedPostsAndProducts);