import React, { Component } from 'react';
import { Navbar, NavbarBrand, NavItem } from 'reactstrap';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Link, NavLink, withRouter } from 'react-router-dom';
import LoginMenu from './api-authorization/LoginMenu';
import './NavMenu.css';
import CloseIcon from "components/icons/CloseIcon";
import { ApplicationPaths, QueryParameterNames } from './api-authorization/ApiAuthorizationConstants';
import NotificationDotIcon from './icons/NotificationDotIcon';

import { toggleShareModal, toggleAddProductModal, toggleCreateLockerModal, toggleFollowersFollowingModal, toggleFetchFollowing } from "stateManagement/reducers/modalReducer";
import { connect } from "react-redux";
import { getReferralShareLink } from "../sharedUtils/shareLinkPrefixes"

import rpLogo from "./icons/images/rockporch-logo.svg";

class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            collapsed: true,
            showMobileSearchBar: false,
            searchInput: "",
            redirectToSearch: false,
            submenuOpen: true,
        };

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.collapseMenu = this.collapseMenu.bind(this);
    }

    componentDidMount = async () => {
        this.setState({ showMobileSearchBar: this.props.location.pathname.toLowerCase().startsWith("/searchresults") });
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevState.collapsed !== this.state.collapsed) {
            this.props.hamburgerOpen(!this.state.collapsed);
        }
        if (this.props.toggleMenu != undefined && this.props.toggleMenu != null && prevProps.toggleMenu !== this.props.toggleMenu) {
            this.setState({ collapsed: false });
        }
    }

    submitSearch = (e) => {
        if (e.keyCode === 13 && this.state.searchInput.length > 1) {
            document.getElementById("searchSubmitMobile").click();

            // This code changes the focus to the search icon in order to force mobile keyboards to close
            document.getElementById("search-button").focus();
        }
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    }

    collapseMenu() {
        this.setState({
            collapsed: true
        });
    }

    mapThreeDotIconDropdown = () => {
        let menu = [,
            { title: "Explore", path: "/Explore" },
            { title: "Home", path: "https://www.rockporch.com/home", target: "blank_" },
        ];

        return menu.map((menuItem, i) => {
            let nestedItems = menuItem?.nestedPaths?.map((nested, i) => {
                return (
                    <li key={i}>
                        <a className="dropdown-item nav-link override-active" onClick={() => { this.collapseMenu() }} href={nested.path}>{nested.title}</a>
                    </li>
                )
            })
            if (menuItem.title === "Explore") {
                return (
                    <li key={i}>
                        <NavLink className="dropdown-item nav-link override-active" to={menuItem.path} onClick={(e) => { this.collapseMenu() }}>{menuItem.title}</NavLink>
                    </li>
                );
            }
            return (
                <li key={i}>
                    {
                        menuItem.target ?
                            <a className="dropdown-item nav-link override-active" href={menuItem.path} onClick={() => { this.collapseMenu() }} target={menuItem.target}>{menuItem.title}</a>
                            :
                            <a className="dropdown-item nav-link override-active" href={menuItem.path} onClick={() => { this.collapseMenu() }}>{menuItem.title}</a>
                    }
                    {menuItem?.nestedPaths !== undefined ? (
                        <ul className="submenu dropdown-menu">
                            {nestedItems}
                        </ul>
                    ) : null}
                </li>
            )
        })
    }

    mapCreatorMenu = () => {
        let menu = [
            { title: "Referral Link", path: "" },
            { title: "My Feed", path: "/Nav/HomeFeed" },
            { title: "My Saves", path: "/Account/Saves" },
            { title: "Account Settings", path: "/Account/AccountSettings" },
            { title: "Messages", path: "/Account/NotificationsAndMessages" }
        ];


        return menu.map((menuItem, i) => {
            let nestedItems = menuItem?.nestedPaths?.map((nested, i) => {
                return (
                    <li key={i}>
                        <NavLink className="dropdown-item nav-link override-active" to={nested.path} onClick={() => { this.collapseMenu() }}>{nested.title}</NavLink>
                    </li>
                )
            })

            if (menuItem.title === "Referral Link") {
                return (
                    <li key={i} className="notification-menu-messages-wrapper">
                        <a href="#" className="dropdown-item nav-link override-active"
                            onClick={(e) => {
                                e.preventDefault();
                                this.collapseMenu();
                                this.props.toggleShareModal(getReferralShareLink(this.props.loggedInUser?.userName))
                            }}
                        >{menuItem.title}</a>
                    </li>
                )
            }

            return (
                <li key={i}>
                    {
                        menuItem.target ?
                            <NavLink className="dropdown-item nav-link override-active" to={menuItem.path} target={menuItem.target} onClick={() => { this.collapseMenu() }}>{menuItem.title}</NavLink>
                            :
                            <NavLink className="dropdown-item nav-link override-active" to={menuItem.path} onClick={() => { this.collapseMenu() }}>{menuItem.title}</NavLink>
                    }
                    {menuItem?.nestedPaths !== undefined ? (
                        <ul className="submenu dropdown-menu">
                            {nestedItems}
                        </ul>
                    ) : null}
                </li>
            )
        })
    }

    mapNonCreatorMenu = () => {
        let menu = [];

        if (this.props?.loggedInUser?.proStatusId === 1) {
            menu = [
                { title: "Referral Link", path: "" },
                { title: "My Feed", path: "/Nav/HomeFeed" },
                { title: "My Saves", path: "/Account/Saves" },
                { title: "Account Settings", path: "/Account/AccountSettings" },
                { title: "My Creators", path: "/" }
            ];
        } else {
            menu = [
                { title: "My Feed", path: "/Nav/HomeFeed" },
                { title: "My Saves", path: "/Account/Saves" },
                { title: "Account Settings", path: "/Account/AccountSettings" },
                { title: "My Creators", path: "/" }
            ];
        }

        return menu.map((menuItem, i) => {
            let nestedItems = menuItem?.nestedPaths?.map((nested, i) => {
                return (
                    <li key={i}>
                        <NavLink className="dropdown-item nav-link override-active" to={nested.path} onClick={() => { this.collapseMenu() }}>{nested.title}</NavLink>
                    </li>
                )
            })

            if (menuItem.title === "My Creators") {
                return (
                    <li key={i} className="notification-menu-messages-wrapper">
                        <a href="#" className="dropdown-item nav-link override-active"
                            onClick={(e) => {
                                e.preventDefault();
                                this.collapseMenu();
                                this.showFollowersFollowing("following");
                            }}
                        >{menuItem.title}</a>
                    </li>
                )
            }

            if (menuItem.title === "Referral Link") {
                return (
                    <li key={i} className="notification-menu-messages-wrapper">
                        <a href="#" className="dropdown-item nav-link override-active"
                            onClick={(e) => {
                                e.preventDefault();
                                this.collapseMenu();
                                this.props.toggleShareModal(getReferralShareLink(this.props.loggedInUser?.userName))
                            }}
                        >{menuItem.title}</a>
                    </li>
                )
            }

            if (menuItem.title === "My Feed") {
                return (
                    <li key={i} className="notification-menu-messages-wrapper" id="my-feed-menu" >
                        <NavLink className="dropdown-item nav-link override-active" to={menuItem.path} onClick={() => { this.collapseMenu() }}>{menuItem.title}</NavLink>
                    </li>
                )
            }

            return (
                <li key={i}>
                    {
                        menuItem.target ?
                            <NavLink className="dropdown-item nav-link override-active" to={menuItem.path} target={menuItem.target} onClick={() => { this.collapseMenu() }}>{menuItem.title}</NavLink>
                            :
                            <NavLink className="dropdown-item nav-link override-active" to={menuItem.path} onClick={() => { this.collapseMenu() }}>{menuItem.title}</NavLink>
                    }
                    {menuItem?.nestedPaths !== undefined ? (
                        <ul className="submenu dropdown-menu">
                            {nestedItems}
                        </ul>
                    ) : null}
                </li>
            )
        })
    }

    showFollowersFollowing = (followersFollowing) => {
        this.props.toggleFollowersFollowingModal(this.props.loggedInUser.id, followersFollowing, null);
    }

    render() {
        let showMobileSearchBar = (["sm", "md"].includes(this.props.deviceSize) && this.state.showMobileSearchBar === true);
        const logoutPath = {
            pathname: `${ApplicationPaths.LogOut}`, state: { local: true }
        };
        const registerPath = `${ApplicationPaths.Register}`;
        const link = document.location;
        const returnUrl = `${link.pathname}${link.search}${link.hash}`;
        const redirectUrl = `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${returnUrl}`;
        const showSubmenu = { listStyle: "none", position: "relative", right: "20%", marginTop: "3px", marginRight: "-40px", marginLeft: "25px" };

        return (
            <React.Fragment>
                <div style={this.props.showTopNav ? { marginBottom: "60px" } : { display: "none" }}>

                    <header className="sticky-nav" style={{ width: "100%", zIndex: 1060 }}>

                        <Navbar className="py-2 navbar-expand-lg navbar-main-wrapper d-flex justify-content-between" light>
                            <div className="d-flex" style={["lg", "xl"].includes(this.props.deviceSize) ? { marginLeft: "-8%" } : {}} >
                                <ButtonDropdown isOpen={!this.state.collapsed} toggle={this.toggleNavbar} style={{ position: "relative", right: this.props.deviceSize === "lg" ? "12%" : this.props.deviceSize === "xl" ? "12%" : "", display: this.props.deviceSize === "lg" ? "block" : "block", zIndex: "1000", margin: "0 5px" }}>
                                    <DropdownToggle className="navbar-toggler" tag="button" type="button" >
                                        <span className="navbar-toggler-icon" ></span>
                                    </DropdownToggle>

                                    <ul className={`dropdown-menu ${this.state.collapsed ? "" : "show"} sm-menu-dropdown`}
                                        style={{}}
                                        aria-labelledby="menu-dropdown" >

                                        {this.mapThreeDotIconDropdown()}


                                        {this.props?.loggedInUser == null || this.props?.loggedInUser == undefined ?
                                            <></> :
                                            <>
                                                <li className="menu-hr">
                                                    <hr />
                                                </li>

                                                <li>
                                                    <span style={{ fontWeight: "bold", marginLeft: "27px", paddingRight: "10px" }}>
                                                        {this.props?.loggedInUser?.userName == null || this.props?.loggedInUser?.userName == undefined ?
                                                            this.props?.loggedInUser?.email : this.props?.loggedInUser?.userName}
                                                    </span>
                                                    <ul className="" style={showSubmenu}>
                                                        {this.mapNonCreatorMenu()}
                                                    </ul>
                                                </li>

                                            </>
                                        }

                                        <li className="menu-hr menu-hr-bottom">
                                            <hr />
                                        </li>

                                        {
                                            this.props?.loggedInUser?.isAdmin === true ? (
                                                <li>
                                                    <NavLink tag={Link} to="/Admin" className="dropdown-item override-active" onClick={() => { this.collapseMenu() }}>Admin</NavLink>
                                                </li>
                                            ) : <span></span>
                                        }

                                        <li>
                                            <a className="dropdown-item nav-link override-active" onClick={() => { this.collapseMenu() }} href="https://www.rockporch.com/terms/" target="blank_">Terms of Service</a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item nav-link override-active" onClick={() => { this.collapseMenu() }} href="https://www.rockporch.com/privacy-policy/" target="blank_">Privacy Policy</a>
                                        </li>

                                        {this.props?.loggedInUser == null || this.props?.loggedInUser == undefined ?
                                            <></> :
                                            <>
                                                <li>
                                                    <NavLink tag={Link} to="/Contact" className="dropdown-item override-active" onClick={() => { this.collapseMenu() }}>Contact</NavLink>
                                                </li>

                                                <li>
                                                    <NavLink tag={Link} to={logoutPath} className="dropdown-item override-active" onClick={() => { this.props.setShowTopNav(false) }}>Log Out</NavLink>
                                                </li>
                                            </>
                                        }

                                        {this.props?.loggedInUser?.proStatusId === 0 ?
                                            <li>
                                                <NavLink tag={Link} to="/Account/CreatorApp" className="dropdown-item override-active" onClick={() => { this.collapseMenu() }}>Apply as Creator</NavLink>
                                            </li> :
                                            <></>
                                        }

                                        {this.props?.loggedInUser == null || this.props?.loggedInUser == undefined ?
                                            <div>
                                                <li>
                                                    <NavLink tag={Link} className="dropdown-item" to={redirectUrl}>Login</NavLink>
                                                </li>
                                            </div> :
                                            <></>
                                        }

                                    </ul>
                                </ButtonDropdown>


                                {
                                    ["sm", "md"].includes(this.props.deviceSize) ? (

                                        <>
                                            <button className="btn pl-0 pt-0" id="search-button" style={{ margin: "0 5px ", padding: "0" }}
                                                onClick={() => {
                                                    this.setState({ showMobileSearchBar: !this.state.showMobileSearchBar })
                                                }}>
                                                <img src={process.env.PUBLIC_URL + '/images/search-icon.svg'} alt="Search" />
                                            </button>


                                        </>

                                    ) : <span></span>
                                }
                            </div>
                            {(this.props.deviceSize === "sm" || this.props.deviceSize === "md") ?
                                <div className={this.props?.loggedInUser?.proStatusId === 1 ? "" : this.props.loggedInUser == null && this.loggedInUser == undefined ? "" : "navbar-brand-centering-div"}
                                    style={{ paddingLeft: "12%" }}>
                                    <NavbarBrand tag={Link} id="rp-nav-logo" to="/Nav/Explore" style={{ position: "relative", left: "-10%", width: "100px", margin: this.props?.loggedInUser?.proStatusId === 1 || ["sm", "md"].includes(this.props?.deviceSize) ? "" : "0 0 0 30%" }}>
                                        <img src={rpLogo} alt='' />
                                    </NavbarBrand>
                                </div> :
                                <div className="col-2">
                                    <NavbarBrand tag={Link} to="/Nav/Explore" className="navbar-brand" id="rp-nav-logo" style={{}}>
                                        <img src={rpLogo} alt='' />
                                    </NavbarBrand>
                                </div>
                            }

                            <LoginMenu
                                setShowTopNav={this.props.setShowTopNav}
                                loggedInUser={this.props.loggedInUser}
                                setLoggedInUser={this.props.setLoggedInUser}
                                deviceSize={this.props.deviceSize}
                                collapseMenu={this.collapseMenu}
                                showMobileSearchBar={this.state.showMobileSearchBar}
                                style={{ zIndex: 1060 }}
                                embed={this.props.embed}
                            />

                        </Navbar>


                    </header>

                </div>

                {
                    showMobileSearchBar === true ? (

                        <div className="form-group w-100 mx-0 my-0 py-0"
                            style={
                                {
                                    overflowX: "hidden", position: "sticky", top: "60px", zIndex: 999, backgroundColor: "#fff", paddingTop: "5px !important", paddingBottom: "5px !important", display: this.state.collapsed === true ? "block" : "none"
                                }
                            }>
                            <div style={{ position: "relative" }}>
                                <img src={process.env.PUBLIC_URL + '/images/search-icon.svg'} style={{ position: "absolute", top: "15px", left: "20px" }} alt="Search" />
                            </div>
                            <input className="form-control mr-sm-2"
                                type="search"
                                placeholder="Search..."
                                aria-label="Search"
                                id="mobile-search-bar"
                                onChange={(e) => this.setState({ searchInput: e.target.value })}
                                value={this.state.searchInput}
                                onKeyUp={(e) => this.submitSearch(e)}
                                style={{
                                    display: "inline",
                                    backgroundColor: "#e9ecef",
                                    border: "none",
                                    paddingLeft: "40px",
                                    paddingBottom: "7px",
                                    borderRadius: "30px",
                                    width: "97%",
                                    marginLeft: "1.5%",
                                    marginTop: "5px",
                                    marginBottom: "5px"
                                }}
                            />
                            <button className="close mr-2 mt-2" id="search-close-button"
                                style={{ position: "absolute", right: "24px", bottom: "15px" }}
                                onClick={() => {
                                    this.setState({ searchInput: "", showMobileSearchBar: false })
                                }}
                            >
                                <CloseIcon />
                            </button>

                            <Link to={`/SearchResults/${this.state.searchInput}`} id="searchSubmitMobile"></Link>
                        </div>

                    ) : <span></span>
                }

            </React.Fragment>
        );
    }
}


function mapStateToProps(storeState, componentProps) {
    let result = { feedLayout: storeState.feedLayoutReducer, fetchFollowing: storeState.modalReducer.fetchFollowing }
    return result;
}

export default withRouter(connect(mapStateToProps, { toggleShareModal, toggleAddProductModal, toggleCreateLockerModal, toggleFollowersFollowingModal, toggleFetchFollowing })(NavMenu));