import React from "react";

/**
 * 
 * @param {{fill:string}} props
 */
const SaleIcon = (props) => {
    return (
            <svg id="Layer_1" style={props.style} width={props.width || "18"} height={props.height || "18"} data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 121.51">
                <title>sale-tag</title>
                <path className="sale-cls-1" d="M4,1.35,45.28,0a4.14,4.14,0,0,1,3.2,1.2q35.63,35.57,71.27,71.14A11,11,0,0,1,119.49,88L88.55,118.13l-.24.25h0l0,0a10.71,10.71,0,0,1-7.87,3.12,11.27,11.27,0,0,1-7.38-3l-.37-.35h0L2.6,47.05a4.09,4.09,0,0,1-1.19-2.76L0,5.63A4.15,4.15,0,0,1,4,1.35Zm15.7,11a6.34,6.34,0,0,1,4.64,1.82,6.52,6.52,0,0,1,2.09,4.61A6.57,6.57,0,0,1,20,25.41a6.19,6.19,0,0,1-4.67-1.77,6.24,6.24,0,0,1-2-4.59,6.58,6.58,0,0,1,6.42-6.71Z" />
                <path className="sale-cls-2" d="M45.55,4.15,4.15,5.5,5.56,44.14l70,71.07a6.88,6.88,0,0,0,9.84.16L116.57,85a6.85,6.85,0,0,0,.16-9.83L45.55,4.15Z" />
                <path className="sale-cls-3" d="M19.72,12.34a6.35,6.35,0,0,1,4.64,1.81,6.56,6.56,0,0,1,2.09,4.62A6.57,6.57,0,0,1,20,25.41a6.15,6.15,0,0,1-4.67-1.77,6.23,6.23,0,0,1-2-4.58,6.37,6.37,0,0,1,1.85-4.72,6.29,6.29,0,0,1,4.57-2Z" />
                <path className="sale-cls-1" d="M22.41,38.15l5.42-3.85a1,1,0,0,1,1.47.25l.06.08a30.92,30.92,0,0,0,1.9,2.83,21,21,0,0,0,1.94,2.25c.72.72,1.34,1.32,1.86,1.79l.81.7.05-.05-1.73-2.07a17.8,17.8,0,0,1-2.42-3.62,7.47,7.47,0,0,1-.84-3.37A7,7,0,0,1,31.74,30a11.51,11.51,0,0,1,2.16-2.89l.07-.06a15.27,15.27,0,0,1,3.62-2.77,6.78,6.78,0,0,1,3.64-.86,8.18,8.18,0,0,1,3.58,1.24,19.26,19.26,0,0,1,3.74,3l.06.07A47.85,47.85,0,0,1,52,31.51a36.92,36.92,0,0,1,2.74,3.82,1.06,1.06,0,0,1-.33,1.46l-5.14,3.75a1.07,1.07,0,0,1-1.48-.23c-.7-1-1.36-1.85-2-2.62l0-.07a23.37,23.37,0,0,0-1.61-1.79c-.53-.53-1-1-1.45-1.39L42.15,34l0,0,1.22,1.47A20.46,20.46,0,0,1,46,39.41,8.33,8.33,0,0,1,46.91,43a7.36,7.36,0,0,1-.71,3.17A10,10,0,0,1,44.14,49a16.08,16.08,0,0,1-1.88,1.62,9.91,9.91,0,0,1-1.92,1.11,11,11,0,0,1-1.77.61,6.61,6.61,0,0,1-1.66.19,5.78,5.78,0,0,1-1.66-.28,8.7,8.7,0,0,1-1.68-.75l-.07,0c-.52-.29-1-.57-1.41-.86a13.34,13.34,0,0,1-1.22-.92c-.88-.76-1.73-1.59-2.56-2.42l-.06-.06a45.81,45.81,0,0,1-3.35-3.73,39.4,39.4,0,0,1-2.78-3.9,1.07,1.07,0,0,1,.29-1.42ZM43.7,61.81l-5.93-5.93a1.05,1.05,0,0,1,0-1.5,1.12,1.12,0,0,1,.29-.21l22-13a1.07,1.07,0,0,1,1.28.17l8.69,8.68a1.06,1.06,0,0,1,.11,1.37l-13,22a1.07,1.07,0,0,1-1.45.38,1.2,1.2,0,0,1-.21-.17l-5.93-5.92a1.07,1.07,0,0,1-.11-1.38l1.65-2.65-3.45-3.45L45,62a1.07,1.07,0,0,1-1.31-.14Zm24.8,24.8L57.26,75.36a1.05,1.05,0,0,1,0-1.5L74.82,56.3a1.07,1.07,0,0,1,1.5,0l5.62,5.62a1.07,1.07,0,0,1,0,1.5L69.62,75.74l4.87,4.87a1.05,1.05,0,0,1,0,1.5L70,86.61a1.07,1.07,0,0,1-1.5,0Zm20,6.78-4.88-4.87-.63.63,6.14,6.14a1.07,1.07,0,0,1,0,1.5l-4.5,4.5a1.07,1.07,0,0,1-1.5,0L70.67,88.78a1.07,1.07,0,0,1,0-1.5L88.24,69.72a1.07,1.07,0,0,1,1.5,0L102.1,82.08a1.05,1.05,0,0,1,0,1.5,1.12,1.12,0,0,1-.21.17l-5.12,3.73a1,1,0,0,1-1.36-.11l-5.3-5.28-.86.86,4.87,4.87a1.07,1.07,0,0,1,0,1.5l-4.07,4.07a1.07,1.07,0,0,1-1.5,0Z" />
                <path className="sale-cls-3" d="M23,39l5.42-3.85a27.54,27.54,0,0,0,4,5.3A36.54,36.54,0,0,0,36,43.62l1.4-1.41-2.3-2.75Q31.93,35.72,32,33.11a7.81,7.81,0,0,1,2.67-5.23c2.4-2.4,4.57-3.52,6.51-3.38S45.31,26,47.8,28.44a42.31,42.31,0,0,1,6.05,7.45l-5.2,3.79a39,39,0,0,0-3.77-4.6,28.7,28.7,0,0,0-2.81-2.53l-1.38,1.37,1.83,2.22q3.31,4.05,3.33,6.85a7.35,7.35,0,0,1-2.46,5.27,11.62,11.62,0,0,1-3.47,2.51,7.15,7.15,0,0,1-3,.72,6.18,6.18,0,0,1-2.84-.9,15.56,15.56,0,0,1-2.53-1.72c-.65-.56-1.49-1.34-2.5-2.36A41.75,41.75,0,0,1,23,39ZM44.45,61.06l-5.93-5.93,22.11-13,8.69,8.68-13,22.11L50.38,67l2.13-3.43-4.64-4.64-3.42,2.14ZM59.23,51.5l-5.88,4,2.59,2.59,4-5.86-.68-.69Zm10,34.36L58,74.61,75.57,57.05l5.62,5.62L68.12,75.74l5.62,5.62-4.49,4.5Zm20,6.78L83.68,87l-2.14,2.14L88.42,96l-4.49,4.5L71.42,88,89,70.47l12.36,12.36-5.2,3.8-6-6L87.75,83l5.62,5.62L89.3,92.64Z" />
            </svg>
       )
}

export default SaleIcon;