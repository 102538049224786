import React from "react";
import { MentionsInput, Mention } from "react-mentions";


/**
 * 
 * @param {{
 * className:string,
 * value:any,
 * onChange:Function,
 * style:object?, 
 * allowSuggestionsAboveCursor:boolean?,
 * data:Function,
 * placeholder:string?
 * }} props
 */
const MentionsInputTextArea = (props) => {
    return (
        <MentionsInput
            className={props.className}
            value={props.value}
            onChange={props.onChange}
            style={props.style || { height: "auto" }}
            allowSuggestionsAboveCursor={props.allowSuggestionsAboveCursor || true}
            placeholder={props.placeholder || ""}
        >
            <Mention
                trigger="@"
                data={props.data}
                displayTransform={(id, display) => `@${display}`}
                markup="#!^(__id__)%~@(((__display__)))"
                renderSuggestion={(entry, search, highlightedDisplay, index, focused) => {
                    return <span><strong>@{entry.display}</strong><div><small>{entry.name}</small></div></span>
                }}
            >
            </Mention>
        </MentionsInput>
        )
}

export default MentionsInputTextArea;