import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import BlueTickIcon from "components/icons/BlueTickIcon";
import ImageWrapper from "components/ImageWrapper";
import DummyProfilePicture from "components/DummyProfilePicture";
import { fetchUserSuggestions, blankUserSuggestion, mapPostMedia } from "sharedUtils/userSuggestion";
import { getRequest, postRequest } from "sharedUtils/httpUtils";
import LoadSpinner from "components/LoadSpinner";
import UserTileGeneral from "components/UserTileGeneral";

import { toggleFetchFollowing } from "stateManagement/reducers/modalReducer";


/**
 *
 *  @param {{
 * user:object,
 * loggedInUser:object
 * }} props
 */

const ProfileInfoCardOnboarding = props => {

    React.useEffect(() => {
        if (props.user != undefined) {
            setWhoToFollows([{ user: props.user }])
            setLoading(false)
        }
        else {
            getData()
        }
        
    }, []);

    const [whoToFollows, setWhoToFollows] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    let getData = async () => {
        let suggestions = await fetchUserSuggestions(props.loggedInUser.id);
        setWhoToFollows(suggestions)

        const populated = suggestions?.length > 0;
        props.setWhoToFollowsPopulated(populated);

        setLoading(false);
    }

    let mapImages = (whoToFollow) => {
        let posts = [];

        for (let i = 0; i < whoToFollow.posts?.$values?.length && i < 4; ++i) {
            let properties = whoToFollow.posts?.$values[i]?.properties?.$values?.filter(x => x.role === 0 || x.role === 8 );
            if (properties.length > 0) {
                posts.push(<div className="col-3 px-0" key={i}>
                    <div className="onboard-post-image" style={{ paddingBottom: "100%", position: "relative", overflow: "hidden", margin: "2px 2px 2px 2px"}}>
                        <img src={properties[0].value} style={{border: "1px solid #efecec", position: "absolute", width: "100%", height: "100%", top: 0, left: 0, objectFit: "cover"}}/>
                    </div>
                </div>);
            }
        }
        return posts;
    };

    let onFollowClick = async (whoToFollow) => {
        let index = whoToFollows.indexOf(whoToFollow);

        let newWhoToFollows = [...whoToFollows];
        newWhoToFollows[index].isFollowing = newWhoToFollows[index].isFollowing === true ? false : true;

        let url = "/api/Account/"
        if (whoToFollows[index].isFollowing) {
            url += "Follow"
        } else {
            url += "Unfollow"
        }
        url += "?id=" + whoToFollows[index].user.id;

        setWhoToFollows(newWhoToFollows);

        let response = await getRequest(url);

        if (!props.fetchFollowing) props.toggleFetchFollowing();
    }

    let renderWhoToFollow = (whoToFollow) => {
        /*if (props.clickable) {
            return (<Link to={
                {
                    pathname: `/Profile/${whoToFollow.user.userName}`,
                    state: {
                        userId: whoToFollow.user.id
                    }
                }
            } >
                {renderUserDetails(whoToFollow)}
            </Link>)
        }*/

        return renderUserDetails(whoToFollow);
    }

    let renderUserDetails = (whoToFollow) => {
        return (
            <UserTileGeneral user={whoToFollow.user} subtitle={" "} showCheckMark={false} useLargeAvatars={true}/>
        );
    }

    let renderFollowButton = (i) => {
        if (whoToFollows[i].isFollowing === true) {
            return (<button className="btn follow-btn d-flex justify-content-center" onClick={() => onFollowClick(whoToFollows[i])}>
                Unfollow - </button>);
        }
        return (<button className="btn follow-btn d-flex justify-content-center" onClick={() => onFollowClick(whoToFollows[i])}>
            Follow + </button>);
    }

    if (loading) {
            return <LoadSpinner />
    }

    return whoToFollows.map((whoToFollow, i) => {
        return (<div key={i} className="user-follow">
            <div className="follow-info w-100 d-flex justify-content-between">                
                {renderWhoToFollow(whoToFollow)}
                {renderFollowButton(i)}
            </div>
            <div className="row mx-0 image-gallery-onboard justify-content-center px-0 position-relative mt-2">
                {mapImages(whoToFollow)}
            </div>
        </div>)
    });
};

function mapStateToProps(storeState, componentProps) {
    let result = { fetchFollowing: storeState.modalReducer.fetchFollowing }
    return result;
}

export default connect(mapStateToProps, { toggleFetchFollowing })(ProfileInfoCardOnboarding);