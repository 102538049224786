import React from "react";
import { Link, NavLink, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import ProfileInfoCard from "components/ProfileInfoCard";
import ShareBar from "components/Share/ShareBar";
import CommentForm from "components/CommentForm";
import CommentTile from "components/CommentTile";
import PostBanner from "components/PostBanner";
import ShareBlock from "components/Share/ShareBlock";
import Modal from "components/Modal";
import PostPageProduct from "components/PostPageProduct";
import LoadSpinner from "components/LoadSpinner";
import ImageWrapper from "components/ImageWrapper";
import UserTileSmall from "components/UserTileSmall";
import UserTileGeneral from "components/UserTileGeneral";
import MessageDetails from "../Account/MessageDetails";
import BlueTickIconXY from "../../components/icons/BlueTickIconXY";

import { toggleShareModal, toggleProductPageModal, toggleFollowersFollowingModal, closeAllModals } from "stateManagement/reducers/modalReducer";

import BookmarkIcon from "components/icons/BookmarkIcon";
import ShareIosIcon from "components/icons/ShareIosIcon";
import SaleIcon from "components/icons/SaleIcon";
import ThumbsUpIcon from "components/icons/ThumbsUpGeneric";

import blankFeedPost from "../../sharedUtils/blankFeedPost";
import { getProperties } from "sharedUtils/postFunctions";
import { getAuthorizedUserData } from "sharedUtils/authUtils";
import { getRequest, postRequest } from "sharedUtils/httpUtils";
import { convertToLocaleDateTimeString } from "sharedUtils/timeUtils"
import { handleCommentMarkup, replaceUrls } from "sharedUtils/markupUtils";
import { trimProductsArray } from "sharedUtils/postFunctions";
//import createHistory from "history/createBrowserHistory";
import { getPostShareLink } from "../../sharedUtils/shareLinkPrefixes"
import InformationIcon from "../../components/icons/InformationIcon";
import { Helmet } from 'react-helmet';

class PostPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showMessageDetails: false,
            likes: {
                count: 0,
                likedByUser: false
            },
            loggedInUser: null,
            isAuthorized: false,
            commentInput: "",
            shareCode: "",
            post: blankFeedPost,
            showDescriptionModal: false,
            selectedProduct: 0,
            isLoading: true,
            loadingComments: false,
            productPage: 0,
            submittingComment: false,
            shareLink: "",
            savedByUser: false,
            collapsed: true,
            contentStyle: this.notOverflowedContentStyle,
            contentOverflowed: false,
            maxHeight: "41px",
        }
    }

    commentsTextArea = React.createRef();
    contentRef = React.createRef();
    bioRef = React.createRef();
    titleRef = React.createRef();
    picRef = React.createRef();
    userTileRef = React.createRef();
    editPostButtonRef = React.createRef();

    collapsedContentStyle = { marginTop: "0em", maxHeight: "42px", textOverflow: "ellipsis", overflow: "hidden" };
    notOverflowedContentStyle = { marginTop: "0em", marginBottom: "0.5em", maxHeight: "42px", textOverflow: "ellipsis", overflow: "hidden" };
    expandedContentStyle = { marginTop: "0em" };

    async componentDidMount() {
        if (this.props.setShowTopNav) {
            this.props.setShowTopNav(true, !!(window.location.search.toLowerCase().includes("showbrandnav=")) ? this.props.showBrandNav(window.location.search) : true);
        }

        let shareCode = "";
        let post = null;
        let loggedInUser = null;

        let scrollOptions = {
            left: 0,
            top: 0,
            behavior: 'auto',
            lockerNotFound: true
        }

        if (this.props.isModal !== true) {
            window.scrollTo(scrollOptions);
            this.props.closeAllModals();
        }

        // from feed
        if (this.props.match.params != undefined && this.props.match.params.post != undefined) {
            shareCode = this.props.match.params.post.shareCode;
            post = this.props.match.params.post;
            loggedInUser = this.props.match.params.loggedInUser;
            // from modal
        } else if (this.props.location.state != undefined && this.props.location.state.destinationState != undefined && this.props.location.state.destinationState.params != undefined &&
            this.props.location.state.destinationState.params.post != undefined) {

            shareCode = this.props.location.state.destinationState.params.post.shareCode;
            post = { ...this.props.location.state.destinationState.params.post, comments: [] };
            loggedInUser = this.props.location.state.destinationState.params.loggedInUser;

            // from direct
        } else {
            shareCode = this.props.match.params.shareCode;
            loggedInUser = this.props.loggedInUser;
        }

        let shareLink = await getPostShareLink(shareCode);

        if (loggedInUser == undefined) {
            let data = await this.fetchPost(shareCode, false);
            if (data == undefined || data == null) {
                this.props.history.push("/NotFound");
                //window.location.href = window.location.origin + "/NotFound";
                //this.setState({ post: "notFound", isLoading: false, shareCode, shareLink })
            } else {
                this.setState({ post: data, isLoading: false, shareCode, shareLink });
            }
        } else {
            if (post == undefined) {
                let data = await this.fetchPost(shareCode, true);
                if (data == undefined || data == null) {
                    this.props.history.push("/NotFound");
                    //this.setState({ post: "notFound", isLoading: false, loggedInUser, shareCode, shareLink })
                } else {
                    let isFollowing = await this.fetchIsFollowing(data.user?.id);
                    this.setState({ post: data, isFollowing, isLoading: false, loggedInUser, shareCode, shareLink });

                    this.fetchComments(0, 3);
                }
            } else {
                let trackBaseUrl = this.state.loggedInUser !== undefined ? `api/Post/TrackPostViewAuthorized` : `api/Post/TrackPostView`
                let trackPostViewUrl = `${trackBaseUrl}?userPostId=${post.id}&referrer=${document.referrer}`;

                getRequest(trackPostViewUrl);

                if (post.comments == undefined || post.comments.length == undefined) {
                    post.comments = [];
                };
                await this.setState({ post: post, isLoading: false, loggedInUser, shareCode, shareLink });

                let isFollowing = await this.fetchIsFollowing(post.user?.id);
                this.setState({ isFollowing });
                if (post.comments.length === 0) {
                    this.fetchComments(0, 3);
                }
            }
        }

        if (this.commentsTextArea?.current != null && this.props.isModal === true && this.props.isCommenting === true) {
            this.commentsTextArea.current.scrollIntoView();
            this.commentsTextArea.current.focus();
        }


        const savedByUser = this.props?.match?.params?.post?.savedByUser;
        this.setState({ savedByUser });

        // THIS IS LOGIC TO CALCULATE THE HEIGHT OF THE CONTENT <p> FOR 'SEE MORE'
        // FOR DEVICE SIZES THAT ARE NOT SMALL - it determines if height of ProfileInfoCard 
        // is less than the expanded < p > and then puts it in contentOverflowed as well as 
        // calculate the height of the of the 'SEE MORE' <p> based on it's expanded size vs.
        // the size of the ProfileInfoCard and applies it to the styling.

        // SMALL DEVICES SET HEIGHT TO 2 LINES OF TEXT WHICH IS ~40px
        let contentOverflowed = false;

        const picHeight = this.picRef?.current?.offsetHeight;
        const userTileHeight = this.userTileRef?.current?.offsetHeight;
        const editButtonHeight = this.editPostButtonRef?.current?.offsetHeight;

        if (this.props.deviceSize === "sm" && this.contentRef?.current?.offsetHeight > 40) {
            this.setState({ contentOverflowed: true, contentStyle: this.collapsedContentStyle })
        }

        this.setState({ contentStyle: this.expandedContentStyle });
        const bioHeight = this.bioRef?.current?.offsetHeight;
        const contentHeight = this.contentRef?.current?.offsetHeight;
        const titleHeight = this.titleRef?.current?.offsetHeight;

        this.setState({ contentStyle: this.notOverflowedContentStyle })

        const largeScreenContentHeight = editButtonHeight == undefined && editButtonHeight == null ? userTileHeight + titleHeight : userTileHeight + editButtonHeight + titleHeight;

        if (this.props.deviceSize !== "sm" && picHeight < largeScreenContentHeight) {
            const overflow = largeScreenContentHeight - picHeight;
            const maxHeight = contentHeight - overflow > 80 ? Math.floor((contentHeight - overflow) / 20) * 20 - 60 + "px" : "41px";

            this.setState({ contentOverflowed: true, maxHeight, contentStyle: this.collapsedContentStyle })
        }

        this.collapsedContentStyle = { marginTop: "0em", maxHeight: this.props.deviceSize === "sm" ? "42px" : this.state.maxHeight, textOverflow: "ellipsis", overflow: "hidden" };
        this.notOverflowedContentStyle = { marginTop: "0em", marginBottom: "0.5em", maxHeight: this.props.deviceSize === "sm" ? "42px" : this.state.maxHeight, textOverflow: "ellipsis", overflow: "hidden" };
        this.expandedContentStyle = { marginTop: "0em" };

        if (this.state.contentOverflowed) {
            this.setState({  contentStyle: this.collapsedContentStyle });
        } else if (!contentOverflowed && this.props.deviceSize !== "sm") {
            this.setState({ contentStyle: this.expandedContentStyle })
        }
    }


    async componentDidUpdate(prevProps, prevState) {
        if (this.props?.match?.params?.shareCode != prevProps?.match?.params?.shareCode) {
            this.setState({ isLoading: true });

            let shareCode = this.props.match.params.shareCode;
            let isAuthorized = this.props.loggedInUser !== null;
            let post = !!(this.props.location?.state?.destinationState?.params?.post) ? this.props.location.state.destinationState?.params?.post : null;

            if (post == null || post == undefined) {
                post = await this.fetchPost(shareCode, isAuthorized);

                if (post == null || post == undefined) {
                    this.props.history.push("/NotFound");
                    return -1
                }
                else {
                    let isFollowing = await this.fetchIsFollowing(post.user?.id);

                    await this.fetchLikes(shareCode, isAuthorized);
                    await this.fetchComments(0, 25);
                    this.setState({ isFollowing });
                }
            }
            
            

            this.setState({ post, isLoading:false });
        }
    }

    removeCommentUsername = text => {
        const regex = /#!\^.*?\)\)\)/g;
        return text.replace(regex, '');
    }

    fetchIsFollowing = async (userId) => {
        let url = `/api/Account/IsFollowing?id=${userId}`;
        let data = await getRequest(url);
        return data;
        //this.setState({ isFollowing: data });
    }

    // obsolete
    fetchLikes = async (shareCode, isAuthorized) => {
        let baseUrl = isAuthorized === true ? `/api/post/getLikesAuthorized` : `/api/post/getLikes`;
        let url = `${baseUrl}?shareCode=${shareCode}`;
        let data = await getRequest(url);
        this.setState({ likes: data });
    }

    fetchPost = async (shareCode, isAuthorized) => {
        let baseUrl = isAuthorized === true ? "/api/post/getPostAuthorized" : "/api/post/getPost";
        let url = `${baseUrl}?shareCode=${shareCode}&referrer=${document.referrer}`
        let data = await getRequest(url);

        return data;
        //this.setState({ post: data });
    }

    followAction = async () => {
        this.setState({ isFollowing: !this.state.isFollowing })

        let endpoint = this.state.isFollowing === true ? 'unfollow' : 'follow';
        let url = `/api/account/${endpoint}?id=${this.state.post.user.id}`
        let data = await getRequest(url);
    }

    fetchComments = async (skip = this.state.post.comments.length, take = 25) => {
        return;
        this.setState({ loadingComments: true });
        let baseUrl = this.state.loggedInUser !== undefined ? `/api/post/getCommentsAuthorized` : `/api/post/getComments`;
        let url = `${baseUrl}?shareCode=${this.state.shareCode}&skip=${skip}&take=${take}`;
        let data = await getRequest(url);
        let newState = { ...this.state };
        newState.post.comments = [...data, ...this.state.post.comments];

        this.setState({ ...newState, loadingComments: false });
    }

    handleCommentInput = (e) => {
        this.setState({ commentInput: e.target.value });
    }

    handleTextMarkup = (text) => {
        let urlHandled = replaceUrls(text);

        let markupHandled = urlHandled.map(retVal => {
            if (typeof retVal === 'string') {
                return handleCommentMarkup(retVal,true);
            }
            else {
                return retVal;
            }
        });

        return markupHandled;
    }

    likePost = async (shareCode) => {
        let num = this.state.post.likedByUser === true ? -1 : 1;
        let newLikes = {
            likeCount: this.state.post.likeCount + num,
            likedByUser: !this.state.post.likedByUser
        }
        let newPost = { ...this.state.post, ...newLikes };

        if (this.props.match && this.props.match.params && this.props.match.params.post) {
            this.props.match.params.post.likedByUser = newLikes.likedByUser;
            this.props.match.params.post.likeCount = newLikes.likeCount;
        }

        this.setState({ post: newPost }, async () => {
            let url = `/api/post/likePost?shareCode=${shareCode}`
            let data = await postRequest(url);
        });
    }

    likeComment = async (comment, key) => {
        let num = this.state.post.comments[key].likedByUser === true ? -1 : 1;
        let newState = { ...this.state };
        newState.post.comments[key].likes += num;
        newState.post.comments[key].likedByUser = !newState.post.comments[key].likedByUser;

        this.setState(newState, async () => {
            let requestObject = {
                id: comment.id,
                likedByUser: num < 0
            }

            let url = `/api/post/likeComment`;
            let data = await postRequest(url, requestObject);
        });

    }

    savePost = async (shareCode) => {
        let reqBody = { shareCode };

        let route = this.state.post.savedByUser === false ? `/api/post/savePost` : `/api/post/removeSavedPost`;
        let data = await postRequest(route, reqBody);

        let newPost = { ...this.state.post };
        newPost.savedByUser = !this.state.post.savedByUser

        if (this.props.match && this.props.match.params && this.props.match.params.post) {
            this.props.match.params.post.savedByUser = newPost.savedByUser;
        }

        this.setState({ post: newPost });
    }

    renderBookmarkOverlay = () => {
        const shareCode = this.props?.match?.params?.post?.shareCode;
        return (
            <span className="locker-page-bookmark-overlay position-absolute" onClick={(e) => {
                if (this.props.loggedInUser) {
                    e.preventDefault();
                    let savedByUser = !this.state.savedByUser;
                    this.setState({ savedByUser });
                    this.savePost(shareCode);
                } else {
                    this.props.toggleSignUpModal();
                }

            }} style={{ cursor: "pointer" }}>
                <BookmarkIcon height="16" width="16" fill={this.state.savedByUser ? "#e55934" : "none"} />
            </span>);
    }

    renderShareOverlay = () => {
        return (<span className="locker-page-share-overlay position-absolute" onClick={() => {
            this.props.toggleShareModal(this.state.shareLink)
        }} style={{ cursor: "pointer" }}>
            <ShareIosIcon color="#E55934" />
        </span>);
    }

    renderSaleOverlay = (sale) => {
        if (sale && ["sm", "md"].includes(this.props.deviceSize)) {
            return (
                <span className="on-sale-icon">
                    <SaleIcon />
                </span >
            );
        }
    }

    onClickMessage = async () => {
        this.setState({ showMessageDetails: true });
    }

    onClickBack = async () => {
        this.setState({ showMessageDetails: false });
    }

    postComment = async () => {
        this.setState({ submittingComment: true });

        let dataToPost = {
            text: this.state.commentInput
        }
        let shareCode = this.state.post.shareCode;
        let url = `/api/post/postComment?shareCode=${shareCode}&postType=${this.state.post.type}`;

        var createdOn = new Date();
        createdOn.setSeconds(createdOn.getSeconds() - 1);
        let dummyComment = {
            likedByUser: false,
            likes: 0,
            replyCount: 0,
            text: this.state.commentInput,
            user: this.state.loggedInUser,
            createdOn: new Date()
        };

        let comments = [...this.state.post.comments];
        comments.push(dummyComment);

        let newState = { ...this.state };
        newState.commentInput = "";
        newState.post.comments = comments;
        newState.post.commentCount += 1;

        if (this.props.match && this.props.match.params && this.props.match.params.post) {
            this.props.match.params.post.comments = [...newState.post.comments];
            this.props.match.params.post.commentCount = newState.post.commentCount;
            this.props.match.params.post.totalCount = newState.post.totalCount;
        }

        this.setState(newState, async () => {
            var response = await postRequest(url, dataToPost);
            this.setState({ submittingComment: false });
        });
    }

    prevPage = () => {
        let page = this.state.productPage - 1;
        if (page < 0) {
            page = Math.floor(this.state.post.productItems?.$values?.length / 4);
        }

        this.setState({ productPage: page });
    }

    nextPage = () => {
        let page = this.state.productPage + 1;
        if (page * 4 > this.state.post.productItems?.$values?.length) {
            page = 0;
        }

        this.setState({ productPage: page });
    }

    renderProductPagination = () => {
        if (this.state.post.productItems?.$values?.length > 4) {
            return (
                <>
                    <a href="/"
                        onClick={(e) => {
                            e.preventDefault();
                            this.prevPage()
                        }}>
                        <span className="item-count-prev position-absolute d-flex justify-content-center align-items-center">
                            {"<"}
                        </span>
                    </a>

                    <span className="item-count position-absolute d-flex justify-content-center align-items-center">
                        {this.state.post.productItems?.$values?.length}
                    </span>

                    <a href="/"
                        onClick={(e) => {
                            e.preventDefault();
                            this.nextPage()
                        }}>
                        <span className="item-count-next position-absolute d-flex justify-content-center align-items-center">
                            {">"}
                        </span>
                    </a>
                </>
            );
        }
    }

    toTitleCase = (string) => {
        return string.toLowerCase().replace(/(?:^|\s|-)\w/g, function (match) {
            return match.toUpperCase();
        });
    }   

    renderInfoOverlay = (product) => {
        return <span className="info-overlay position-absolute" onClick={() => {
            this.props.toggleProductPageModal(product, this.props.loggedInUser, false, () => null)
        }} style={{ cursor: "pointer" }}>
            <InformationIcon fill="#E55934" />
        </span>
    }

    renderThumbsUpOverlay = (product) => {
        const productId = !!(product.productId) ? product.productId : product.id;

        if (!(product.creatorCount) || product.creatorCount === 0) {
            return <></>;
        }

        return (
            <span className="thumbs-up-overlay position-absolute" onClick={() => this.toggleCreatorsModal(productId)} style={{ cursor: "pointer", top: "15px" }}>
                <ThumbsUpIcon height="16" width="16" fill={"#e55934"} />
                <span style={{ marginLeft: "5px", paddingTop: "2px" }}>{!!(product.creatorCount) ? product.creatorCount : ""}</span>
            </span>
        )

    }

    toggleCreatorsModal = (productId) => {
        const followersFollowing = "creators";
        this.props.toggleFollowersFollowingModal(!!(this.props.loggedInUser) ? this.props.loggedInUser.id : -1, followersFollowing, productId);
    }

    renderProductImages = () => {
        return trimProductsArray(this.state.post.productItems?.$values)?.sort(function (a, b) {
            return a.ordinal - b.ordinal;
        }).map((val, i) => {
            return (
                <div key={i} className="col-4 col-md-3 px-1 py-1">
                    <div className="post-page-product">
                        <div className="product-image-in-page">
                            {this.renderInfoOverlay(val)}
                            {this.renderThumbsUpOverlay(val)}
                            <PostPageProduct
                                deviceSize={this.props.deviceSize}
                                comment={val.comment}
                                product={val}
                                productKey={i}
                                key={i}
                                showDescription={(i) => this.toggleDescriptionModal(i)}
                                post={this.state.post}
                                loggedInUser={this.state.loggedInUser}
                                parentModal={this.props.isModal === true ? { type: "post", shareCode: this.props.match.params?.post?.shareCode, parentState: { ...this.state.post } } : null}
                            />
                        </div>
                        <div className="d-flex flex-wrap justify-content-between align-items-center py-0 px-1" style={{ background: "white", borderRadius: "0px 0px 15px 15px" }}>
                            <div className="users user-tile-general py-1 ml-2" style={{ whiteSpace: "nowrap" }}>
                                <h5 style={{ marginBottom: "0px" }}><div style={{ maxWidth: "1px" }}>{val.manufacturer?.toLowerCase().includes("rcktemp") ? val.merchant : val.manufacturer}{val.sale && !!(val.salePrice) && !["sm", "md"].includes(this.props.deviceSize) ? <SaleIcon style={{ marginLeft: "3px" }} /> : <></>}</div></h5>
                            </div>
                            <div className="users user-tile-general py-1 ml-2" style={{ width: "95%", whiteSpace: "nowrap" }}>
                                <h5 style={{ marginBottom: "0px" }}>
                                    <div style={{ display: "table", tableLayout: "fixed", width: "100%" }}>
                                        <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", display: "table-cell" }}>
                                            {this.toTitleCase(val.name)}
                                        </div>
                                    </div>
                                </h5>
                            </div>
                            <div className={`users user-tile-general py-1 ml-${val.salePrice ? "2" : "1"}`} style={{ whiteSpace: "nowrap" }}>
                                <h5 style={{ marginBottom: "0px" }}><div className="d-flex" style={{ maxWidth: "1px" }}><div className="mr-1" >{val.sale && !!(val.salePrice) ? "$" + val.salePrice.toFixed(2) : <></>}</div><div className={`${val.sale && !!(val.salePrice) ? "on-sale-price" : ""}`}>${val.sale && !!(val.salePrice) && !!(val.originalPrice) ? val.originalPrice.toFixed(2) : val.price.toFixed(2)}</div></div></h5>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
    }

    renderComments = () => {
        let comments = this.state.post.comments;
        if (comments && comments.length && comments.length > 0) {
            return comments.map((comment, i) => {
                return (<CommentTile comment={comment} loggedInUser={this.state.loggedInUser} likeAction={this.likeComment} key={i} commentKey={i} />)
            })
        }

    }

    toggleDescriptionModal = (productIndex) => {
        let newModalState = !this.state.showDescriptionModal;
        this.setState({ showDescriptionModal: newModalState, selectedProduct: productIndex });
    }

    renderDescriptionModal = () => {
        if (this.state.showDescriptionModal === true) {
            return (
                <Modal isOpen={this.state.showDescriptionModal} toggleModal={this.toggleDescriptionModal} showHeader={false}>
                    <div className="share-block" style={{ border: "none", backgroundColor: "transparent" }}>
                        <UserTileGeneral user={this.state.post.user} size={"Profile"} subtitle={" "} showCheckMark={false}/>
                        <p className="pt-3 mb-0">{this.state.post.productItems?.$values[this.state.selectedProduct].comment}</p>
                    </div>
                </Modal>
            )
        }
    }

    renderCommentForm = () => {
        if (this.state.loggedInUser) {
            return (
                <div className="container comment-block pt-0 pb-0" id="post-page-comments">
                    <div className="row mt-0">
                        <CommentForm
                            user={this.state.loggedInUser}
                            handleCommentInput={this.handleCommentInput}
                            commentText={this.state.commentInput}
                            postComment={this.postComment}
                            commentsTextArea={this.commentsTextArea}
                            submittingComment={this.state.submittingComment}
                        />
                    </div>
                </div>
            )
        }
    }

    renderCommentSectionHeading = () => {
        let commentCount = this.state.post.comments.length;
        let totalComments = this.state.post.commentCount;
        if (commentCount === 0) {
            return "No Comments";
        }
        else if (commentCount === 1) {
            return `${commentCount} comment`
        }
        else {
            return `${commentCount} of ${totalComments} comments`
        }
    }

    renderSectors = () => {
        return this.state.post.sectors?.$values?.map((sector, i) => {
            return (
                <button key={i} className="btn btn-secondary d-flex align-items-center" disabled>
                    {sector.sector.name}
                </button>
            )
        });
    }

    contentSection = () => {
        if (!this.state.contentOverflowed) {
            return (
                <div ref={this.titleRef}>
                    <p className="post-page-text mt-3" style={this.state.contentStyle} ref={this.contentRef} >
                       {this.handleTextMarkup(this.state.post?.content)}
                    </p>
                </div>
            )
        } else {
            if (this.state.collapsed) {
                return (
                    <>
                        <div className="mt-3 d-flex flex-column" ref={this.titleRef}>
                            <div>
                                <p className="post-page-text" style={this.state.contentStyle} ref={this.contentRef}>
                                    {this.handleTextMarkup(this.state.post?.content)}
                                </p>

                            </div>
                            <div className="content px-1 see-more" style={{ textAlign: "right", width: "100%", marginRight: "10px" }}>
                                <Link className="see-more" to="#" onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({ contentStyle: this.expandedContentStyle, collapsed: false });
                                }}>
                                    see more
                                </Link>
                            </div>
                        </div></>)

            } else {
                return (
                    <>
                        <div className="mt-3 d-flex flex-column" ref={this.titleRef}>
                            <div>
                                <p className="post-page-text" style={this.state.contentStyle} ref={this.contentRef}>
                                    {this.handleTextMarkup(this.state.post?.content)}
                                </p>

                            </div>
                            <div className="content px-1 see-more" style={{ textAlign: "right", width: "100%", marginRight: "10px" }}>
                                <Link className="see-more" to="#" onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({ contentStyle: this.collapsedContentStyle, collapsed: true });
                                }}>
                                    see less
                                </Link>
                            </div>
                        </div>
                    </>
                )
            }
        }
    }

    contentSectionSmall = () => {
        if (!this.state.contentOverflowed) {
            return (
                <p className="post-page-text mt-3" ref={this.contentRef} style={this.state.contentStyle}>
                    <span>
                        <strong>
                            <Link to={`/Profile/${this.state.post.user.userName}`} style={{ color: "black" }} className="bold-link">
                                {this.state.post.user.userName}
                            </Link>
                        </strong>
                    </span> {handleCommentMarkup(this.state.post.content, true)}
                </p>
            )
        } else {
            if (this.state.collapsed) {
                return (
                    <>
                        <div className="mt-3 d-flex flex-column" ref={this.titleRef}>
                            <p className="post-page-text mt-3" ref={this.contentRef} style={this.state.contentStyle}>
                                <span>
                                    <strong>
                                        <Link to={`/Profile/${this.state.post.user.userName}`} style={{ color: "black" }} className="bold-link">
                                            {this.state.post.user.userName}
                                        </Link>
                                    </strong>
                                </span> {handleCommentMarkup(this.state.post.content, true)}
                            </p>
                            <div className="content px-1 see-more" style={{ textAlign: "right", width: "100%", marginRight: "10px" }}>
                                <Link className="see-more" to="#" onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({ contentStyle: this.expandedContentStyle, collapsed: false });
                                }}>
                                    see more
                                </Link>
                            </div>
                        </div></>)

            } else {
                return (
                    <>
                        <div className="mt-3 d-flex flex-column" ref={this.titleRef}>
                            <p className="post-page-text mt-3" ref={this.contentRef} style={this.state.contentStyle}>
                                <span>
                                    <strong>
                                        <Link to={`/Profile/${this.state.post.user.userName}`} style={{ color: "black" }} className="bold-link">
                                            {this.state.post.user.userName}
                                        </Link>
                                    </strong>
                                </span> {handleCommentMarkup(this.state.post.content, true)}
                            </p>
                            <div className="content px-1 see-more" style={{ textAlign: "right", width: "100%", marginRight: "10px" }}>
                                <Link className="see-more" to="#" onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({ contentStyle: this.collapsedContentStyle, collapsed: true });
                                }}>
                                    see less
                                </Link>
                            </div>
                        </div>
                    </>
                )
            }
        }
    }

    renderFollowButton() {
        if (this.state.isFollowing != undefined && this.state.isFollowing != null && (this.props.loggedInUser == null || this.props.loggedInUser.id !== this.state.post.user.id)) {
            return (
                <button onClick={() => {
                    if (this.props.loggedInUser) {
                        this.followAction()
                    } else {
                        this.props.toggleSignUpModal();
                    }
                }}
                    className="btn modal-follow-btn">{this.state.isFollowing ? "Unfollow -" : "Follow +"}</button>
            )
        }
    }

    truncateContentForTitle(str) {
        if (str.length <= 20) {
            return str;
        } else {
            return str.substr(0, 20) + "...";
        }
    }

    getPageTitle = () => {
        const shareCode = this.props.match?.params?.shareCode;

        return "RockPorch - Post: " + shareCode;
    };


    render() {
        if (this.state.post === "notFound") {
            return <h1>Post not found</h1>
        }

        let commentCount = this.state.post?.comments?.length;
        let postImages = getProperties(this.state.post?.properties)
            .filter(property => property?.parentId == null && (property?.role === 0 || property?.role === 1 || property?.role === 3))
            .map(property => {
                return {
                    url: property?.value,
                    role: property?.role,
                    id: property?.id,
                    width: property?.width,
                    height: property?.height
                }
            });

        let { userName, imageUrl } = this.state.post?.user;
        let webLayouts = ["md", "lg", "xl"];
        let { deviceSize, isModal } = this.props;

        if (this.state.isLoading === true) {
            return (
                <>
                    < LoadSpinner />
                    {
                        !!(this.props.isModal) ?
                            <></>
                            :
                            <Helmet>
                                <title>{this.getPageTitle()}</title>
                            </Helmet>
                    }
                </>
            )
        }

        let parsedDate = convertToLocaleDateTimeString(new Date(this.state.post?.postedOn), false)

        if (this.state.showMessageDetails) {
            return (
                <>
                    <MessageDetails onClickBack={this.onClickBack} user={this.state.post.user} loggedInUser={this.state.loggedInUser} />
                    {
                        !!(this.props.isModal) ?
                            <></>
                            :
                            <Helmet>
                                <title>{this.getPageTitle()}</title>
                            </Helmet>
                    }
                </>
            )
        }

        //if the post isn't published, redirect to HomeFeed
        if ([0, 2, 6].includes(this.state.post.status) === true && this.state.post.user.id !== this.state.loggedInUser?.id && this.props.isModal !== true) {
            return (
                <>
                    <Redirect to="/Explore" />
                    {
                        !!(this.props.isModal) ?
                            <></>
                            :
                            <Helmet>
                                <title>{this.getPageTitle()}</title>
                            </Helmet>
                    }
                </>
            )
        }

        return (
            <section className={`container ${this.props.deviceSize === "sm" ? "pl-0 pr-0" : ""}`}>
                {
                    !!(this.props.isModal) ?
                        <></>
                        :
                        <Helmet>
                            <title>{this.getPageTitle()}</title>
                        </Helmet>
                }
                {this.renderDescriptionModal()}

                {
                    this.props.deviceSize === "sm" ? (
                        <div className={`row pl-0 mx-0 pt-0 pb-2 ${isModal === true ? "mt-0" : ""}`}
                            style={(isModal == undefined || isModal === false) ? { marginTop: "70px" } : {}}
                        >

                            <div className="col-8 mt-0 pl-2">
                                <UserTileGeneral user={this.state.post.user} size={"Profile"} subtitle={" "} showCheckMark={false} />
                            </div>
                            <div className="col-4 mt-0 pl-2 text-right">
                                {this.renderFollowButton()}
                            </div>
                        </div>
                    ) : null
                }

                <div className="row mx-0 mt-0">
                    <div className={`col-md-8 ${this.props.deviceSize === "sm" ? "mt-0 px-0" : "pr-2 mt-2 ml-0 px-0"}`} ref={this.picRef}>
                        {this.renderBookmarkOverlay()}
                        {this.renderShareOverlay()}
                        <PostBanner images={postImages} customClass="post-page-banner-modal" postPage={true}
                            properties={this.state.post.properties} />


                    </div>

                    {
                        webLayouts.includes(this.props.deviceSize) ? (
                            <div className="col-md-4 mt-2 px-0 py-1" ref={this.bioRef}>
                                <div className="row mx-0 my-0 justify-content-between">
                                <div ref={this.userTileRef} className="col-8 mt-0 px-0">
                                    <UserTileGeneral user={this.state.post.user} size={"Profile"} subtitle={" "} showCheckMark={false} />
                                </div>
                                <div className="col-4 mt-0 px-0 text-right">
                                        {this.renderFollowButton()}
                                </div>
                                </div>
                                {
                                    this.state.loggedInUser?.id === this.state.post.user.id ? (
                                        <div ref={this.editPostButtonRef}>
                                            <Link to={`/Post/EditPost/${this.state.post.shareCode}?edit=true`}>
                                                <button className="btn btn-sm btn-secondary mt-1">
                                                    <small>
                                                        Edit Post
                                                    </small>
                                                </button>
                                            </Link>
                                        </div>

                                    ) : <span></span>
                                }

                                {this.contentSection()}
                            </div>

                        ) : null
                    }
                </div>



                <div className={`container container-top-gap mt-1 px-2`}>
                    <hr className="divider-hr" />

                    {
                        this.props.deviceSize === "sm" ? (
                            <div>
                                <div>
                                    {this.contentSectionSmall()}
                                </div>
                                <hr className="divider-hr" />
                            </div>

                        ) : null
                    }

                    {
                        this.state.post?.productItems?.$values?.length > 0 ? (
                            <div className="container product-grid position-relative pt-0 pb-3">
                                <div className={`row product-image-gallery ${this.state.post?.productItems?.$values?.length >= 4 ? " justify-content-left" : "justify-content-center"} px-0 position-relative mt-2`}>
                                    {this.renderProductImages()}
                                </div>
                            </div>

                        ) : null
                    }

                </div>
            </section>
        )
    }
}

const mapDispatch = {
    toggleShareModal: toggleShareModal,
    closeAllModals: closeAllModals,
    toggleProductPageModal, toggleProductPageModal,
    toggleFollowersFollowingModal: toggleFollowersFollowingModal,
}

export default connect(null, mapDispatch)(PostPage);