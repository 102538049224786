import React, { useState } from "react";
import Modal from "components/Modal";
import { connect } from "react-redux";
import { toggleShareBlogModal } from "stateManagement/reducers/modalReducer";
import ShareABlogLookupUrlForm from "components/ShareABlog/ShareABlogLookupUrlForm"
import ShareABlogPreview from "components/ShareABlog/ShareABlogPreview"

import PreviewCard from 'components/Post/Create/PreviewCard';
import { useHistory } from "react-router-dom";
import { postRequest } from "sharedUtils/httpUtils";

/**
 * 
 * @param {{loggedInUser:object, userId:any,followersFollowing:string, setLoggedInUser:Function}} props
 */
const ShareABlogModal = (props) => {
    const history = useHistory();
    const blankPost = {
        title: "",
        content: "",
        platformName: "blogShare",
        type: 4,
        id: null,
        media: [
            {
                imageLink: "",
                isExternalReference: true,
                children: [
                    {
                        resourceLink: "",
                    }
                ],
            },
        ],
    }

    const [validUrl, setValidUrl] = useState("");
    const [loading, setLoading] = useState(false);

    const [post, setPost] = useState(blankPost);
    const [actionLevel, setActionLevel] = useState(1);
    const [postCaption, setPostCaption] = useState("");

    if (props.showModal) {
        document.querySelector("body").style.overflow = "hidden";
    } else {
        document.querySelector("body").style.overflow = "";
    }

    const uuidv4 = () => {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    };

    const handleUrlSubmit = (lp, url) => {
        setValidUrl(url);

        const newPost = { ...post };
        newPost.id = uuidv4();
        newPost.title = !!(lp.title) ? lp.title : "";
        newPost.content = !!(lp.description) ? lp.description : "";

        newPost.media[0].imageLink = lp.image;
        newPost.media[0].children[0].resourceLink = url;

        setPost(newPost);
        advanceLevel();
    }

    const advanceLevel = () => {
        setActionLevel(actionLevel + 1);
    }

    const reduceLevel = () => {
        setActionLevel(actionLevel - 1);
        setPost({ ...blankPost });
        setValidUrl("");
    }

    const handleNext = async () => {
        const route = '/api/post/InsertBlogPost';
        let selectedPost = {...post};
        selectedPost.content = !!(postCaption) ? postCaption : selectedPost.content;

        setLoading(true);
        let data = await postRequest(route, selectedPost);
        setLoading(false);
        setPost({ ...blankPost });
        setValidUrl("");
        setActionLevel(actionLevel - 1);
        history.push(`/Blog/NewBlogPage/${data.shareCode}`, { state: { justCreated: true } });
    }

    return (
        <Modal isOpen={props.showModal} toggleModal={() => props.toggleModal(null)}>
            {
                actionLevel === 1 ?
                    <ShareABlogLookupUrlForm
                        customStyle={{ maxWidth: "100%", backgroundColor: "white" }}
                        shareBlockStyle={{ backgroundColor: "transparent", border: "0px transparent", padding: "0px" }}
                        handleUrlSubmit={handleUrlSubmit}
                        validUrl={validUrl}
                    />
                    :
                    <ShareABlogPreview
                        reduceLevel={reduceLevel}
                        advanceLevel={advanceLevel}
                        post={post}
                        actionLevel={actionLevel}
                        handleNext={handleNext}
                        setPostCaption={setPostCaption}
                        postCaption={postCaption}
                        loggedInUser={props.loggedInUser}
                        deviceSize={props.deviceSize}
                        loading={loading}
                    />
            }
        </Modal>

    )
}

function mapStateToProps(storeState) {
    return {
        showModal: storeState.modalReducer.shareBlogModal.showShareBlogModal,
    }
}

const mapDispatch = {
    toggleModal: toggleShareBlogModal
}

export default connect(mapStateToProps, mapDispatch)(ShareABlogModal);