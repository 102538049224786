import React, { useState} from "react";
import Modal from "components/Modal"
import { Link } from "react-router-dom";
import FormInputField from "components/FormInputField";
import { postRequest } from "../../sharedUtils/httpUtils";
import { getPostShareLink, getLockerShareLink, getBlogShareLink} from "../../sharedUtils/shareLinkPrefixes"
import { Popover, PopoverHeader, PopoverBody } from "reactstrap";
import ActionButton from "components/ActionButton";

/**
 * 
 * @param {{loggedInUser:object,post:object,deletePost:Function, toggleMenu:Function}} props
 */
const PostThreeDotMenu = (props) => {
    const [showReport, setShowReport] = React.useState(false);
    const [comment, setComment] = React.useState("");
    const [shareLink, setShareLink] = React.useState("");    
    const [showCopiedTextPopover, togglePopover] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    const [performingDelete, setPerformingDelete] = useState(false);

    React.useEffect(() => {
        async function getShareLink() {           
            let shareLink = props.post?.type === 2 ? await getLockerShareLink(props.post?.shareCode) : props.post?.type === 4 ? getBlogShareLink(props.post?.shareCode) : await getPostShareLink(props.post?.shareCode);
            setShareLink(shareLink);
        };
        getShareLink();
    }, []);

    function copyTextToClipBoard(text) {
        navigator.clipboard.writeText(text)
        togglePopover(true);
        setTimeout(() => togglePopover(false),2500)
    }

    async function deletePost() {
        setPerformingDelete(true);
        let url = `/api/Locker/DeleteLocker`;
        let payload = {
            user: {
                id: props.post.user.id
            },
            type: 2,
            shareCode: props.post.shareCode,
            id: props.post.id
        }
        await postRequest(url, payload);

        setPerformingDelete(false);
        props.toggleMenu();
        if (props.deletePost) {
            props.deletePost(props.post.shareCode);
        } else {
            window.location.reload();
        }        
    }

    const renderReportLink = () => {
        if (props.loggedInUser?.id !== props.post?.user?.id) {
            return (
                <div className="row">
                    <Link className="col-12"to="#" onClick={(e) => { e.preventDefault(); props.toggleMenu(); setShowReport(true); }}>
                        Report
                    </Link>
                    <Link className="col-12 mt-1" id="copyText" to="#" onClick={(e) => { e.preventDefault(); copyTextToClipBoard(shareLink); }}>
                        Copy link
                    </Link>
                    <Popover isOpen={showCopiedTextPopover} target="copyText" placement="bottom" toggle={() => togglePopover(false)}>
                        <PopoverBody>
                            Copied link to clipboard : { shareLink }
                        </PopoverBody>
                    </Popover>
                </div>
            )
        }
    }

    const renderEditLink = () => {
        if (props.loggedInUser?.id === props.post?.user?.id) {
            let redirect = props.post?.type === 2 ? `/Locker/NewLockerPage/${props.post?.shareCode}` : props.post?.type === 4 ? `/Blog/EditBlog/${props.post?.shareCode}` : `/Post/EditPost/${props.post?.shareCode}`;

            return (
                <>
                    <div className="pt-3">
                        <Link
                            onClick={() => { props.toggleMenu() }}
                            style={{ color: "black" }} to={{
                            pathname: redirect,
                            state: { post: props.post }                            
                        }}>Edit</Link>
                    </div>
                    <div className="pt-1">
                        <Link style={{ color: "black" }} to="#" onClick={(e) => { setIsDeleting(true) }}>Delete</Link>
                    </div>
                </>
            )
        }
    }

    const handleInput = (e) => {
        setComment(e.target.value);
    }

    const submitReport = async () => {

        await postRequest('/api/Post/ReportPost/', { userPostId: props.post.id, comment: comment });

        setComment("");
        setShowReport(false);
    }

    const renderReport = () => {
        return <div className="col-md-12 text-center">
            <div style={{ textAlign: "left" }}>
                <h5 className="mb-4">Report</h5>
                <h6 className="text">Please let us know why this post may be inappropriate</h6>

                <div className="form">
                    <FormInputField
                        name="comment"
                        inputType="textarea"
                        onInputChange={(e) => handleInput(e)}
                        value={comment} />

                    <div className="form-group button-block text-md-right mb-0">
                        {comment.length > 0 ?
                            <button className="btn btn-primary ml-4" onClick={submitReport}>Submit</button>
                            : <button className="btn btn-primary ml-4 disabled" onClick={submitReport}>Submit</button>}
                    </div>
                </div>
            </div>
        </div>
    }

    const toggleShowReport = () => {
        setComment("");
        setShowReport(!showReport);
    }

    if (showReport) {
        return (
            <Modal
                isOpen={showReport}
                toggleModal={toggleShowReport}
                showHeader={false}
                modalDialogCustomClass="post-report-modal-dialog"
            >
                {renderReport()}
            </Modal>
        );

    }
    return (
        <Modal isOpen={props.isOpen} toggleModal={props.toggleMenu} zIndex={2000} modalDialogCustomClass="post-report-modal-dialog">
            <div className="col-md-12 text-center" style={{ maxWidth: "100%" }}>
                {renderReportLink()}
                {renderEditLink()}

                {
                    isDeleting === true ? (
                        <div className="pt-1">
                            Are you sure you want to delete this? <br/>
                            <button className="mt-3 btn btn-secondary mx-1" onClick={() => setIsDeleting(false)}>
                                Cancel
                            </button>

                            <ActionButton className="mt-3 btn btn-danger mx-1" onClick={deletePost} isLoading={performingDelete}>
                                Delete
                            </ActionButton>
                        </div>
                    ): <span></span>
                }
            </div>
        </Modal>);
}

export default PostThreeDotMenu;