import React from "react";
import { withRouter } from 'react-router-dom';
import { Form, FormGroup, Input, CustomInput } from "reactstrap";
import ActionButton from "../../components/ActionButton";
import { getRequest, postRequest } from "sharedUtils/httpUtils";
import toast, { Toaster } from 'react-hot-toast';

/**
 * 
 * @param {{userInformation:object}} props
 */
class CreatorSettings extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            editMode: false,
            persistanceUpdated: false,
            paymentEmail: "",
            validEmail: true,
            userInformation: {
                address: "",
                city: "",
                state: "",
                postalCode: "",
                country: "",
            },
            paymentEmailPopover: false,
            isLoadingSaleMade: false,
            ucp: null,
        }
    }

    componentDidMount = () => {
        this.parseUserInfo();
        this.fetchUserCommunicationPreference();
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.paymentEmail !== this.props.paymentEmail) {
            this.setState({ paymentEmail: this.props.paymentEmail })
        }
        if (this.state.userInformation !== prevState.userInformation) {
            this.props.updateUserInformation(this.state.userInformation);
        }
        if (this.props.cancelEdit) {
            this.parseUserInfo();
            this.setState({ paymentEmail: this.props.persistentPaymentEmail ?? "" })
            this.props.resetCancelEdit();
        }
    }

    fetchUserCommunicationPreference = async () => {
        this.setState({ isLoadingSaleMade: true});

        const url = `api/Account/GetUserCommunicationPreference`
        const ucp = await getRequest(url);
        if (ucp != undefined && ucp != null) {
            this.setState({ ucp });
        }

        this.setState({ isLoadingSaleMade: false });
    };

    updateSettings = async (ucpInit) => {
        this.setState({ isLoadingSaleMade: true });

        const ucpObject = {
            paymentEmail: ucpInit.paymentEmail,
            salesEmail: ucpInit.salesEmail,
        }

        const userCommunicationPreferenceUrl = `api/Account/UpdateUserCommunicationPreference`;
        await postRequest(userCommunicationPreferenceUrl, ucpObject);

        this.setState({ isLoadingSaleMade: false });
    }

    checkEmailCriteria = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        let validEmail = emailRegex.test(email);
        console.log(email, validEmail);
        this.setState({ validEmail })
    }

    onInputChange = (e) => {
        let formKey = e.target.name;
        let newData = e.target.value;

        if (formKey === 'paymentEmail') {
            this.checkEmailCriteria(newData.trim());

            this.setState({ paymentEmail: newData.trim() });
            this.props.updatePaymentEmail(newData.trim());
        }
        const userInformation = this.state.userInformation;
        userInformation[formKey] = newData;

        this.setState({ userInformation });
        this.props.updateUserInformation(userInformation);
    };

    onUcpInputChange = async (e) => {
        let name = e.target.name;
        let value = e.target.value;

        const ucp = { ...this.state.ucp };
        ucp[name] = !ucp[name];
        this.updateSettings(ucp);
        this.setState({ ucp });
    }

    parseUserInfo = () => {
        if (!!(this.props.userInformation)) {
            const propsUserInformation = this.state.persistanceUpdated && !!(this.props.persistentUserInformation) ? this.props.persistentUserInformation : this.props.userInformation;

            const userInformation = {
                address: propsUserInformation.address ?? "",
                city: propsUserInformation.city ?? "",
                state: propsUserInformation.state ?? "",
                postalCode: propsUserInformation.postalCode ?? "",
                country: propsUserInformation.country ?? "",
            };
            this.setState({ userInformation });
        }
    }

        
    render() {
        return (
            <div className="row mx-0 pt-0" style={{ display: this.props.hideDisplay ? "none" : "", fontFamily: "'Archivo', sans-serif", marginTop: this.props.deviceSize === "sm" ? "-19px" : "" }}>
                <Toaster />
                <h6 style={{ display: this.props.deviceSize === "sm" ? "none" : "" }} >Personal Information</h6>

                <div className="col-lg-12 px-0">
                    <div className={`${["sm", "md"].includes(this.props.deviceSize) ? "mb-3" : ""}`}>
                        <div>
                            <span style={{ fontSize: "13px", color: "gray" }}>Address</span>
                        </div>
                        {
                            this.props.editMode ?
                                <FormGroup className="form-group">
                                    <Input
                                        className="form-control"
                                        name="address"
                                        onChange={(e) => this.onInputChange(e)}
                                        disabled={this.props.isSaving}
                                        value={this.state.userInformation.address}
                                    />
                                </FormGroup> :
                                <p className="" style={{ display: "inline" }}>{this.state.userInformation.address}</p>
                        }
                    </div>
                </div>

                <div className="col-lg-12 px-0">

                    <div className={`row ${["sm", "md"].includes(this.props.deviceSize) ? "mt-0" : "mb-3"}`}>
                        <div className={`col-lg-4 ${["sm", "md"].includes(this.props.deviceSize) ? "mb-3" : ""}`}>
                            <div>
                                <span style={{ fontSize: "13px", color: "gray" }}>City</span>
                            </div>
                            {
                                this.props.editMode ?
                                    <FormGroup className="form-group">
                                        <Input
                                            className="form-control"
                                            name="city"
                                            onChange={(e) => this.onInputChange(e)}
                                            disabled={this.props.isSaving}
                                            value={this.state.userInformation.city}
                                        />
                                    </FormGroup> :
                                    <p className="" style={{ display: "inline" }}>{this.state.userInformation.city}</p>
                            }
                        </div>
                        <div className={`col-lg-4 ${["sm", "md"].includes(this.props.deviceSize) ? "mb-3" : ""}`}>
                            <div>
                                <span style={{ fontSize: "13px", color: "gray" }}>State</span>
                            </div>
                            {
                                this.props.editMode ?
                                    <FormGroup className="form-group">
                                        <Input
                                            className="form-control"
                                            name="state"
                                            onChange={(e) => this.onInputChange(e)}
                                            disabled={this.props.isSaving}
                                            value={this.state.userInformation.state}
                                        />
                                    </FormGroup> :
                                    <p className="" style={{ display: "inline" }}>{this.state.userInformation.state}</p>
                            }
                        </div>
                        <div className={`col-lg-4 ${["sm", "md"].includes(this.props.deviceSize) ? "mb-3" : ""}`}>
                            <div>
                                <span style={{ fontSize: "13px", color: "gray" }}>Zip Code</span>
                            </div>
                            {
                                this.props.editMode ?
                                    <FormGroup className="form-group">
                                        <Input
                                            className="form-control"
                                            name="postalCode"
                                            onChange={(e) => this.onInputChange(e)}
                                            disabled={this.props.isSaving}
                                            value={this.state.userInformation.postalCode}
                                        />
                                    </FormGroup> :
                                    <p className="" style={{ display: "inline" }}>{this.state.userInformation.postalCode}</p>
                            }
                        </div>
                    </div>

                    <div className={`row mb-5`}>
                        <div className={`col-lg-4 ${["sm", "md"].includes(this.props.deviceSize) ? "mb-3" : ""}`}>
                            <div>
                                <span style={{ fontSize: "13px", color: "gray" }}>Country</span>
                            </div>
                            {
                                this.props.editMode ?
                                    <FormGroup className="form-group">
                                        <Input
                                            className="form-control"
                                            name="country"
                                            onChange={(e) => this.onInputChange(e)}
                                            disabled={this.props.isSaving}
                                            value={this.state.userInformation.country}
                                        />
                                    </FormGroup> :
                                    <p className="" style={{ display: "inline" }}>{this.state.userInformation.country}</p>
                            }
                        </div>
                    </div>

                    <h6>Commissions & Payments</h6>
                    <div id="paypalAddress" className="mb-3">
                        <div className="mb-3">
                            <h6 style={{ fontSize: "13px" }}>Connect Your PayPal Account</h6>
                            <h6 style={{ fontSize: "13px" }}>Enter the email address associated with your PayPal account to get paid</h6>
                        </div>
                        {
                            this.props.editMode ?
                                <FormGroup className="form-group">
                                    <p className="mb-0" style={{ fontSize: "13px", color: "gray" }}>PayPal Email Address</p>
                                    <div className="input-group">
                                        <Input
                                            id="paymentEmail"
                                            className="form-control"
                                            name="paymentEmail"
                                            onChange={(e) => this.onInputChange(e)}
                                            disabled={this.props.isSaving}
                                            value={this.state.paymentEmail}
                                        />
                                        <div className="input-group-append">
                                            <ActionButton
                                                className="btn btn-primary"
                                                onClick={() => {
                                                    toast.success(
                                                        <div className="p-2">
                                                            <h4 className="text-center">Payment Email Updated</h4>
                                                            <p className="text-center mx-auto px-2 pt-2 pb-">You're not done!  We've sent an email to the address you provided.  You need to click the "Verify" link in that email to confirm this change.</p>
                                                        </div>,
                                                        {
                                                            duration: 3000,
                                                            position: 'top-center',
                                                            icon: null,
                                                        }
                                                    );
                                                    this.props.savePaymentEmail();
                                                }}
                                                isLoading={this.props.isSavingPaymentEmail}
                                                isDisabled={!this.state.validEmail || (this.props.persistentPaymentEmail.trim() === this.props.paymentEmail.trim() || !(this.props.paymentEmail?.trim()))}
                                            >
                                                Save Email
                                            </ActionButton>
                                        </div>
                                    </div>
                                    <p className="mt-2" style={{ color: "red", display: this.state.validEmail ? "none" : "" }}>Email must be in the correct format ie. name@company.com</p>

                                </FormGroup> :
                                <>
                                    <p className="mb-0" style={{ fontSize: "13px", color: "gray" }}>PayPal Email Account:</p>
                                    <p style={{ display: "inline" }}>{!!(this.state.paymentEmail) && this.state.paymentEmail.trim().length > 0 ? this.state.paymentEmail : "No Paypal Email Address On File"}</p>
                                </>
                        }
                        <div className="mt-5">
                            <div>
                                <Form>
                                    <div className="detail-block">
                                        <FormGroup>
                                            <CustomInput type="switch" name="salesEmail" checked={this.state.ucp?.salesEmail} onChange={(e) => this.onUcpInputChange(e)} label="I would like to receive communications from RockPorch regarding my sales and commissions" id="salesEmailSwitch" className="salesEmailSwitch" />
                                         </FormGroup>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        );
    };
}


export default withRouter(CreatorSettings);
