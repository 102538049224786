import React from "react";
import { connect } from "react-redux";
import { getRequest } from "sharedUtils/httpUtils";
import FollowersFollowingRender from "./FollowersFollowingRender";
import { toggleFetchFollowing } from "stateManagement/reducers/modalReducer";



class CreatorsList extends React.Component {

    /**
     * 
     * @param {{loggedInUser:object,userId:any,followersOrFollowing:string}} props
     */
    constructor(props) {
        super(props);
        this.state = {
            creators: [],
            finishedFetchingCreators: false,
        }
    }

    async componentDidMount() {
        if (true) {
            let users = await this.fetchCreators(this.props.productId, !!(this.props.userId) ? this.props.userId : -1, 0, 10);
            this.setState({ creators: users });
        }
    }

    fetchCreators = async (productId, userId, skip, take) => {
        var users = await getRequest(`api/Account/GetCreators?productId=${productId}&userId=${userId}&skip=${skip}&take=${take}`);
        return users;
    }

    fetchMore = async () => {
        let { creators } = this.state;
        let users;
        users = await this.fetchCreators(this.props.productId, this.props.userId, creators.length, 10);
        if (users === undefined || users === null || users.length === 0) {
            this.setState({ finishedFetchingCreators: true });
        }
        else {
            let updated = [...this.state.creators, ...users];
            this.setState({ creators: updated })
        }
    }

    setUsers = (users) => {
        this.setState({ creators: users });
    }

    render() {

        let { followersOrFollowing } = this.props;
        let heading = "Recommended by";

        return (
            <FollowersFollowingRender
                isFollowers={this.props.followersOrFollowing === "followers"}
                isCreators={this.props.followersOrFollowing === "creators"}
                finishedFetching={!this.state.finishedFetchingCreators}
                fetchMore={this.fetchMore}
                followingFollowersCreatorsLength={this.state.creators?.length}
                heading={heading}
                users={this.state.creators}
                followersOrFollowing={this.props.followersOrFollowing}
                setUsers={this.setUsers}
                fetchFollowing={this.props.fetchFollowing}
                toggleFetchFollowing={this.props.toggleFetchFollowing}
                loggedInUser={this.props.loggedInUser}
            />
        )
    }
}

function mapStateToProps(storeState) {
    let result = { fetchFollowing: storeState.modalReducer.fetchFollowing };
    return result;
}

export default connect(mapStateToProps, { toggleFetchFollowing })(CreatorsList);
