import React from "react";
import AccountBanner from "components/AccountBanner";
import { getRequest, postRequest } from "sharedUtils/httpUtils";
import CreatorApplicationActionDropdown from "views/Admin/CreatorApplicationActionDropdown";
import CreatorAppAdmin from "./CreatorAppAdmin";
import { convertToLocaleDateTimeString } from "../../sharedUtils/timeUtils";
import { Link } from "react-router-dom"
import FeedFilterBar from "components/FeedFilterBar";
import DownloadIcon from "components/icons/DownloadIcon";
import App from "../../App";

class CreatorApplications extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            applications: [],
            selectedCase: 0,
            dateAsc: true,
            isDragging: false,
            startX: 0,
            scrollLeft: 0,
        }
    }

    componentDidMount = async () => {
        let applications = await this.fetchApplications();
        this.setState({ applications });
    }

    breadCrumbList = [
        { link: '/Admin', text: 'Admin' },
        { link: '/Admin/CreatorApplications', text: 'Creator Applications' }
    ]

    handleMouseDown = (e) => {
        e.preventDefault();
        this.setState({
            isDragging: true,
            startX: e.clientX,
            scrollLeft: this.scrollContainer.scrollLeft,
        });

        document.addEventListener('mousemove', this.handleMouseMove);
        document.addEventListener('mouseup', this.handleMouseUp);
    };

    handleMouseMove = (e) => {
        if (!this.state.isDragging) return;
        const x = e.clientX;
        const walk = (x - this.state.startX) * 3; // Adjust the scrolling speed here
        this.scrollContainer.scrollLeft = this.state.scrollLeft - walk;
    };

    handleMouseUp = () => {
        this.setState({
            isDragging: false,
        });

        document.removeEventListener('mousemove', this.handleMouseMove);
        document.removeEventListener('mouseup', this.handleMouseUp);
    };

    fetchApplications = async () => {
        let url = `api/CreatorApplicationAdmin/GetAllUnapprovedApplications`;

        if (this.props.loggedInUser?.isAdmin === true) {
            let results = await getRequest(url);
            return results;
        }
        else {
            return [];
        }
        
    }

    fetchApprovedOrDeniedApplications = async (isApproved) => {
        let url = `api/CreatorApplicationAdmin/GetApprovedOrRejectedApplications?isApproved=${isApproved}`;

        if (this.props.loggedInUser?.isAdmin === true) {
            let results = await getRequest(url);
            return results;         
        }
    }

    fetchAllApplications = async () => {
        let url = `api/CreatorApplicationAdmin/GetAllApplications`;

        if (this.props.loggedInUser?.isAdmin === true) {
            let results = await getRequest(url);
            return results;
        }   
    }

    synchApplication = async (application) => {
        let url = `api/Account/SynchApplicationToHubspot`;
        await postRequest(url, application);
    }

    platformUsernameAsLink(application) {
        let url = "";
        switch (application?.platform?.toLowerCase()) {
            case "facebook":
                url = `https://www.facebook.com/${application.platformUsername}`;
                break;
            case "instagram":
                let normalizedInstagram = application.platformUsername?.startsWith("@") === true ? application.platformUsername.substring(1) : application.platformUsername
                url = `https://www.instagram.com/${normalizedInstagram}`;
                break;
            case "youtube":
                url = `https://www.youtube.com/${application.platformUsername}`;
                break;
            case "tiktok":
                url = `https://www.tiktok.com/@${application.platformUsername}`;
                break;
            case "blog":
                url = application.platformUsername.startsWith("http") ? url : `https://${application.platformUsername}`;
                break;
            default:
                return;
        }
        return (
            <a href={url} className={`${!!(application.noMargin) ? "" : "ml-3"}`} target="_blank">{application.platformUsername}</a>
        );
    }

    updateApplication = async (application, forDelete = false) => {
        let applications = this.state.applications;
        let index = applications.indexOf((app) => { return app.id === application.id });
        applications[index] = application;
        this.setState({ applications });

        let url = `api/CreatorApplicationAdmin/UpdateApplication?delete=` + forDelete;
        await postRequest(url, application);
    }

    approveApplication = (application) => {
        application.approved = 1;
        let oldApplications = [...this.state.applications];
        let applications = oldApplications.filter(app => app.id !== application.id);

        this.updateApplication(application);
        this.setState({ applications });
    }

    rejectApplication = (application) => {
        application.approved = 0;
        let oldApplications = [...this.state.applications];
        let applications = oldApplications.filter(app => app.id !== application.id);

        this.updateApplication(application);
        this.setState({ applications });
    }

    deleteApplication = (application) => {
        let json = JSON.parse(application.jsonApplication);
        json.hide = true;
        let jsonApplication = JSON.stringify(json);
        application.jsonApplication = jsonApplication;

        let oldApplications = [...this.state.applications];
        let applications = oldApplications.map(app => {
            if (app.id === application.id) {
                return application;
            } else {
                return app;
            }
        })

        this.updateApplication(application, true);
        this.setState({ applications });
    }

    unDeleteApplication = (application) => {
        let json = JSON.parse(application.jsonApplication);
        json.hide = false;
        let jsonApplication = JSON.stringify(json);
        application.jsonApplication = jsonApplication;

        let oldApplications = [...this.state.applications];
        let applications = oldApplications.map(app => {
            if (app.id === application.id) {
                return application;
            } else {
                return app;
            }
        })

        this.updateApplication(application, true);
        this.setState({ applications });
    }

    renderUpcomingProjects = (upcomingProjects) => {
        if (upcomingProjects && upcomingProjects.length > 0) {
            const descriptionUrlObjectArray = upcomingProjects.split(',').map((item) => {
                const [description, url] = item.trim().split('!@#');
                return { description, url };
            });

            return (
                <div style={{ minWidth: "200px" }}>
                    {descriptionUrlObjectArray.map((item, index) => (
                        <div key={index} className={`${index > 0 ? "mt-3" : ""}`} style={{ display: "flex", alignItems: "center" }}>
                            <div style={{ flex: "1" }}>{item.description}</div>
                            <div className="ml-3">
                                <DownloadIcon link={item.url} />
                            </div>
                        </div>
                    ))}
                </div>
            );
        }
    }



    displayTableRows = () => {
        if (this.state.applications?.length > 0) {
            return this.state.applications?.map((application, i) => {
                let jsonApplication = JSON.parse(application.jsonApplication);
                //if (application.approved == null) {
                if ((this.state.selectedCase === 3 && !!(jsonApplication.hide)) || (this.state.selectedCase !== 3 && !(jsonApplication.hide))) {
                    return (
                        <tr key={i}>
                            <td>{convertToLocaleDateTimeString(application.createdDate, false)}</td>
                            <td>{jsonApplication?.realName}</td>
                            <td>
                                <Link to={`/profile/${jsonApplication.userName}`} target="_blank">
                                    {jsonApplication?.userName}
                                </Link>
                            </td>
                            <td>{jsonApplication?.phone}</td>
                            <td>{application.hasOwnProperty('appliedPreviously') ? application.appliedPreviously.toString() : ''}</td>
                            <td>{jsonApplication?.followers}</td>
                            <td>{jsonApplication?.platform}</td>
                            <td>{this.platformUsernameAsLink(jsonApplication)}</td>
                            <td>
                                {jsonApplication?.additionalPlatforms && (
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        {jsonApplication.additionalPlatforms.map((item, index) => (
                                            <div key={index} style={{ whiteSpace: "nowrap" }}>
                                                {item.platform}, {this.platformUsernameAsLink({ platform: item.platform, platformUsername: item.userName, noMargin: true })}, {item.followers}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </td>
                            <td>
                                {
                                    !!(jsonApplication.blog) ?
                                        <a href={jsonApplication.blog.toLowerCase().startsWith("https://") ? jsonApplication.blog : "https://" + jsonApplication.blog} rel="noreferrer" target="_blank">{jsonApplication.blog}</a>
                                        :
                                        <></>
                                }
                            </td>
                            <td>{!!(jsonApplication?.usState) ? jsonApplication.usState + ", " + jsonApplication.audience : jsonApplication?.audience}</td>
                            <td>{jsonApplication?.learnMore}</td>
                            <td>{this.renderUpcomingProjects(jsonApplication?.upcomingProjects)}</td>
                            <td>{jsonApplication?.mediaPermission}</td>
                            <td>{jsonApplication?.hearAboutUs}</td>
                            <td>{jsonApplication?.postFrequency}</td>
                            <td style={{ whiteSpace: 'nowrap', overflowX: 'auto' }}>
                                <div style={{ maxHeight: '100px', overflowY: 'auto', maxWidth: "500px" }}>
                                    {jsonApplication?.whyRockporchToday}
                                </div>
                            </td>
                            <td>{jsonApplication?.howWillUseRockporch}</td>
                            <td>{jsonApplication?.timeCommitment}</td>
                            <td>{application.referralUserName}</td>
                            <td>
                                <CreatorApplicationActionDropdown synchApplication={this.synchApplication} application={application} approveApplication={this.approveApplication} rejectApplication={this.rejectApplication} deleteApplication={this.deleteApplication} unDeleteApplication={this.unDeleteApplication} deletedTab={this.state.selectedCase === 3} />
                            </td>

                        </tr>
                    )
                };
                return <></>;
            })
        } else {
            return "";
        }
    }

    downwardChevron = () => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
            </svg>
        )
    }

    upwardChevron = () => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-up" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z" />
            </svg>
        )
    }

    handleDateSort = () => {
        const sortedApplications = [...this.state.applications];
        const ascending = !this.state.dateAsc;

        sortedApplications.sort((a, b) => {
            const dateA = new Date(a.createdDate);
            const dateB = new Date(b.createdDate);

            // Use ternary operator to determine sorting order
            return ascending ? dateA - dateB : dateB - dateA;
        });

        this.setState({ applications: sortedApplications, dateAsc: !this.state.dateAsc });
    }

    displayTableHeader = () => {
        let headers = ["Application Date","Real Name","User Name", "Phone","Applied Previously", "Primary Followers", "Primary Platform", "Primary Platform Username", "Other Platforms", "Personal Blog", "Audience", "Learn More","Upcoming Projects", "Media", "Heard", "Weekly Posts", "Going On In Life?", "How RockPorch Will Be Used", "Consider Full-Time", "Referrer", "Approve"];
        return headers.map((header, i) => {
            if (header === "Application Date") {
                return (
                    <th key={i} className="position-relative">
                        <strong>{header}</strong>
                        <div>
                            <a href="#" onClick={(e) => { e.preventDefault(); this.handleDateSort(); }} style={{ position: "absolute", bottom: "12px", left: "74px" }}><span>{this.state.dateAsc ? this.downwardChevron() : this.upwardChevron()}</span></a>
                        </div>
                    </th>
                )
            }
            return (
                <th key={i}><strong>{ header}</strong></th>
                )
        })
    }

    displayToggleButtons = () => {
        let options = [
            {
                name: "Pending",
                case: 0,
                action: async () => {
                    let applications = await this.fetchApplications();
                    this.setState({ applications:applications, selectedCase: 0 })
                }
            },
            {
                name: "Approved",
                case: 1,
                action: async() => {
                    let applications = await this.fetchApprovedOrDeniedApplications(1);
                    this.setState({ applications: applications, selectedCase: 1 });
                }
            },
            {
                name: "Rejected",
                case: 2,
                action: async () => {
                    let applications = await this.fetchApprovedOrDeniedApplications(0);;
                    this.setState({ applications: applications, selectedCase: 2 });
                }
            },
            {
                name: "Deleted",
                case: 3,
                action: async () => {
                    let applicationsAll = await this.fetchAllApplications();

                    let applications = applicationsAll.filter(app => {
                        if (app.jsonApplication) {
                            try {
                                let json = JSON.parse(app.jsonApplication);
                                if (json && json.hide === true) {
                                    return true;
                                }
                            } catch (error) {
                                console.error('Error parsing jsonApplication:', error);
                            }
                        }
                        return false;
                    });

                    this.setState({ applications, selectedCase: 3 });
                }
            },
            {
                name: "Creator App Admin",
                case: 4,
                action: async () => { 
                    this.setState({ selectedCase: 4 });
                }
            }
        ];

        return options.map((toggle, i) => {
            return (
                <button key={i}
                    className={`btn ${this.state.selectedCase === toggle.case ? "btn-primary btn-lg" : ""}`}
                    onClick={async()=>await toggle.action()}
                >
                    {toggle.name}
                </button>
            )
        })
    }

    render() {
        return (
            <div>
                <section>
                    <AccountBanner breadCrumbList={this.breadCrumbList}>
                        <h1 className="mb-4">Creator Applications</h1>
                        <p className="mb-0">Manage creator applications</p>
                    </AccountBanner>
                </section >

                <section className="gray-bg full-height">

                    <FeedFilterBar innerClassName="row py-2 mt-0">
                        {this.displayToggleButtons()}
                    </FeedFilterBar>

                    {
                        this.state.selectedCase === 4 ?
                            <CreatorAppAdmin />
                            :
                            <div className="container">
                                <div className="row mt-0 mx-0 py-5">
                                    <div
                                        className="table-responsive product-table"
                                        style={{ overflow: "overlay", paddingBottom: "75px" }}
                                        ref={(container) => (this.scrollContainer = container)}
                                        onMouseDown={this.handleMouseDown}
                                    >
                                        <table
                                            className="table"
                                        >
                                            <thead>
                                                <tr>
                                                    {this.displayTableHeader()}
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {this.displayTableRows()}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>                    }
                    
                </section>
            </div>
        )
    }
}


export default CreatorApplications;