import React, { Component } from "react";
import ActionButton from "components/ActionButton";

class UploadProductCategories extends Component {
    handleFileChange = (event) => {
        const file = event.target.files[0];
        this.props.handleUpload(file);
    };

    render() {
        return (
            <>
                <section className="gray-bg pb-3 px-4">
                    <div className="d-flex flex-column align-items-center">
                        <p
                            className="mb-0 mt-3"
                            style={{ color: "red", display: this.props.error ? "" : "none" }}
                        >
                            There was an error uploading the spreadsheet. Try again.
                        </p>
                        <input
                            type="file"
                            accept=".xlsx"
                            onChange={this.handleFileChange}
                            style={{ display: "none" }}
                            ref={(input) => (this.fileInput = input)}
                        />
                        <ActionButton
                            className="btn btn-primary mt-3"
                            style={{ width: "215px" }}
                            onClick={() => this.fileInput.click()}
                            isLoading={this.props.isLoading}
                        >
                            Upload Spreadsheet
                        </ActionButton>
                    </div>
                </section>
            </>
        );
    }
}

export default UploadProductCategories;
