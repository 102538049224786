import React from "react";

const RightArrowIcon = (props) => {
    return (
        <svg width={props.x || 32} height={props.y || 32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.1974 24.1515C12.0656 24.2828 11.9997 24.4535 11.9997 24.6636C11.9997 24.8475 12.0656 25.0051 12.1974 25.1364C12.3556 25.2677 12.527 25.3333 12.7115 25.3333C12.8961 25.3333 13.0543 25.2677 13.1861 25.1364L21.1353 17.1788C21.2671 17.0212 21.333 16.8505 21.333 16.6667C21.333 16.4828 21.2671 16.3121 21.1353 16.1545L13.1861 8.19697C13.0543 8.06566 12.8961 8 12.7115 8C12.527 8 12.3556 8.06566 12.1974 8.19697C12.0656 8.32828 11.9997 8.49899 11.9997 8.70909C11.9997 8.89293 12.0656 9.05051 12.1974 9.18182L19.4347 16.6667L12.1974 24.1515Z" fill="#212529"/>
        </svg>
    )
}

export default RightArrowIcon;