import React from 'react';
import ActionButton from "../../components/ActionButton"
import { CustomInput } from "reactstrap";

import { postRequest } from "sharedUtils/httpUtils";

const BrandTable = ({ apm, showModal, setIntegratedOff }) => {

    const [state, setState] = React.useState(apm);

    const onActiveChanged = async (e) => {
        let apms = state;
        apms[e.target.id].deleted = !apms[e.target.id].deleted;
        setState([...apms]);

        // finds if any apms have a deleted = false and if so is converted into a boolean of false 
        // ie. [at least one apms is not deleted] means allDeleted = false
        const allDeleted = !(apms.find(({ deleted }) => deleted === false));

        if (allDeleted) {
            setIntegratedOff();
        }

        await postRequest('/api/AffiliateProgramManager/UpdateMerchantAffiliateProgramManagerDeleted', apms[e.target.id]);
    }

    const displayTableRows = () => {
        const tableRows = state.map((item, i) => {
            const lastRowClasses = i === state.length - 1 && i !== 0 ? "d-md-flex justify-content-between align-items-center  border-left-0 border-right-0 border-bottom-0" :
                "d-md-flex justify-content-between align-items-center border-0";
            return (
                <tr key={i}>
                    <td>{item?.affiliateProgramManagerName}</td>
                    <td><CustomInput
                        id={i}
                        type="switch"
                        name={item?.affiliateProgramManagerName}
                        onChange={onActiveChanged}
                        checked={!state[i].deleted} />
                    </td>
                    <td>{`${item?.commission * 100}%`}</td>
                    <td>{'refreshStatus' in item ? item.refreshStatus : "N/A"}</td>
                    <td style={{ textAlign: "left" }}>{item?.feedLastProcessed ? <div className="pb-0">{new Date(item?.feedLastProcessed).toLocaleDateString()}</div> : "N/A"}</td>
                    <td className={lastRowClasses}>
                        
                        {'dateLastRefreshed' in item ? new Date(item.dateLastRefreshed).toLocaleDateString() : "N/A"}
                        <div className="d-flex align-items-center">
                            { item.internalId &&
                                <ActionButton className={`btn btn-primary ${item.internalId ? "" : "disabled"}`}
                                onClick={(e) => { showModal(e, item.affiliateProgramManagerId) }}
                                >
                                    Refresh
                                </ActionButton>
                            }
                            {!item.internalId &&
                                <ActionButton isDisabled={true} className={`btn btn-primary ${item.internalId ? "" : "disabled"}`} >
                                    Refresh
                                </ActionButton>
                            }
                        </div>
                        </td>
                </tr>
            )
        })
        return tableRows;
    }

    return (
        <div className="container">
                <div className="row mt-0 mx-0 pt-3 pb-5">
                    <div className="table-responsive product-table" style={{ overflow: "auto" }}>
                    <table className="table" style={{ backgroundColor: '#e9ecef' }}>
                            <thead>
                                <tr>
                                    <th><strong>Name</strong></th>
                                    <th><strong>Active</strong></th>
                                    <th><strong>Commission</strong></th>
                                    <th><strong>Refresh Status</strong></th>
                                    <th><strong>Feed Last Updated</strong></th>
                                    <th><strong>Date Last Refreshed</strong></th>
                                </tr>
                            </thead>

                            <tbody>
                                {displayTableRows()}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
    )
}

export default BrandTable;