

/**
 * 
 * @param {Number} price
 */
function currencyFormat(price) {
    return '$' + price.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

function titleCase(title) {
    //console.log("titleCase called...")
    var sentence = title.split(" ");
    for (var i = 0; i < sentence.length; i++) {
        if (sentence[i].toLowerCase() === 'ii' || sentence[i].toLowerCase() === 'iii' || sentence[i].toLowerCase() === 'iv' ||
            sentence[i].toLowerCase() === 'vi' || sentence[i].toLowerCase() === 'vii' || sentence[i].toLowerCase() === 'viii'
            || sentence[i].toLowerCase() === 'xi' || sentence[i].toLowerCase() === 'xii') {

            sentence[i] = sentence[i].toUpperCase();
        } else {
            sentence[i] = sentence[i][0]?.toUpperCase() + sentence[i]?.slice(1);
        }
    }

    var updatedSentence = sentence.join(" ");
    //console.log("sentence: " + updatedSentence);

    return updatedSentence

}

module.exports = {
    currencyFormat,
    titleCase
}