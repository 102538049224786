import React, { useState} from "react";
import Modal from "components/Modal";
import ProductsSelectorForm from "components/UserMerchantProducts/ProductsSelectorForm";
import { connect } from "react-redux";
import { toggleProductSelectModal } from "stateManagement/reducers/modalReducer";
import { NavLink } from "react-router-dom";
import { Navbar } from "reactstrap";
import CloseIcon from "components/icons/CloseIcon";
import { postRequest } from "sharedUtils/httpUtils";
import ProductSelectorModalHeader from "components/UserMerchantProducts/ProductSelectorModalHeader";
import ProductSelectorModalFooter from "components/UserMerchantProducts/ProductSelectorModalFooter";
import { useEffect } from "react";
import { debounce } from "throttle-debounce";

/**
 * 
 * @param {{postShareCode:string, existingProducts:[object],addToLocker:Function, deviceSize:string}} props
 */
const ProductsSelectorModal = (props) => {

    const [selectedProducts, setProducts] = useState([]);
    const [productSearchString, setProductSearchString] = useState("");
    const [hidScrollBar, setHidScrollBar] = useState(false);
    const [searchFilter, setSearchFilter] = useState("");

    useEffect(() => {
        if (props.showModal) {
            if (document.querySelector("body").style.overflow !== "hidden") {
                setHidScrollBar(true);
                document.querySelector("body").style.overflow = "hidden"
            }
        } else {
            if (hidScrollBar) {
                document.querySelector("body").style.overflow = "";
                setHidScrollBar(false);
            }
        }
    }, [props.showModal]);

    /**
     * 
     * @param {object[]} products
     */
    async function addToLocker(products) {
        if (selectedProducts.length > 0) {
            await props.addToLocker(selectedProducts)
            toggle();
        }
    }

    function selectProduct(product) {
        let newProducts = [...selectedProducts];
        let exists = newProducts.findIndex((prod) => prod.shareCode === product.shareCode);
        if (exists === -1) {
            newProducts.push(product);
        }
        else {
            newProducts.splice(exists, 1);
        }

        setProducts(newProducts);
    }

    async function toggle() {
        await setProducts([]);
        props.toggleModal();
    }

    function searchHandle(e) {
        
        setSearchFilterProp(e.target.value);
    }

    const setSearchFilterProp = debounce(300, (search) => {
        setSearchFilter(search);
    });

    return (
        <Modal
            isOpen={props.showModal}
            toggleModal={toggle}
            modalCustomClass="share-post-modal"
            modalDialogCustomClass="modal-xl modal-dialog-lg modal-dialog-md"
            modalBodyCustomClass="modal-body"
            modalFooter={<ProductSelectorModalFooter addToLocker={addToLocker} selectedProducts={selectedProducts} />}
            modalHeader={<ProductSelectorModalHeader toggleModal={toggle} searchHandle={searchHandle} />}
        >
            <ProductsSelectorForm
                toggleModal={toggle}
                postShareCode={props.postShareCode}
                existingProducts={props.existingProducts}
                addToLocker={addToLocker}
                selectProduct={selectProduct}
                selectedProducts={selectedProducts}
                searchFilter={searchFilter}
                deviceSize={props.deviceSize}
            />
        </Modal>
        )
}

function mapStateToProps(storeState, ownProps) {
    return {
        showModal: storeState.modalReducer.showProductSelectModal,
        postShareCode: storeState.modalReducer.postForProductSelection
    }
}

let mapDispatch = {
    toggleModal: toggleProductSelectModal
}

export default connect(mapStateToProps, mapDispatch)(ProductsSelectorModal);