import React from "react"

const GreyTickIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.1666 10L17.1333 7.68333L17.4166 4.61667L14.4083 3.93333L12.8333 1.28333L9.99998 2.5L7.16665 1.28333L5.59165 3.93333L2.58331 4.60833L2.86665 7.675L0.833313 10L2.86665 12.3167L2.58331 15.3917L5.59165 16.075L7.16665 18.725L9.99998 17.5L12.8333 18.7167L14.4083 16.0667L17.4166 15.3833L17.1333 12.3167L19.1666 10ZM8.33331 14.1667L4.99998 10.8333L6.17498 9.65833L8.33331 11.8083L13.825 6.31667L15 7.5L8.33331 14.1667Z" fill="black" fillOpacity="0.6" />
        </svg>
    )
}

export default GreyTickIcon;