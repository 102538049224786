import React, { useState } from "react"
import PropTypes from "prop-types";
import ModalHeader from "./ModalHeader";
import VectorIcon from "./icons/VectorIcon";
import CloseIcon from "./icons/CloseIcon";
import ThreeDotsHorizontalIcon from "./icons/ThreeDotsHorizontalIcon";
import PostThreeDotMenu from "../views/Post/PostThreeDotMenu";
import { Modal as RSModal } from "reactstrap";
import { Link } from "react-router-dom";
import {closeAllModals} from "stateManagement/reducers/modalReducer"
import {connect} from "react-redux"

/**
     *
     * @param {{
     *  id:string,
     *  isOpen:boolean,
     *  toggleModal:Function,
     *  modalUseCase:string,
     *  allowOverflow:boolean,
     *  showHeader:boolean,
     *  modalCustomClass:string,
     *  modalDialogCustomClass:string,
     *  modalBodyCustomClass:string,
     *  modalHeaderRedirect:string,
     *  modalFooter:import("typescript").JsxElement
     *  modalHeader:import("typescript").JsxElement
     *  }} props
     */
const Modal = (props) => {

    let clientIdText = !(props && props.id) ? 'myModal' : 'props.id';
    let [clientId, setClientId] = useState(clientIdText);
    const [showDotMenu, setShowDotMenu] = useState(false);

    const toggleDotMenu = () => {
        setShowDotMenu(!showDotMenu);
    }

    function showHeader() {
        let transparent = { backgroundColor: "transparent", border: "0px" };
        let { toggleModal } = props;
        if (props.modalHeader) {
            return props.modalHeader
        }
        else {
            if (props.showHeader === true) {
                return (
                    <ModalHeader>
                        <div>
                            {
                                props.hideOpenAsPage ? <></> :
                                    <Link to={{ pathname: props.modalHeaderRedirect || "#", state: { destinationState: props.state } }} onClick={props.closeAllModals}
                                    >
                                        <button className="btn btn-light" style={transparent}>
                                            <VectorIcon /> <small>Open as page</small>
                                        </button>
                                    </Link>
                            }
                            <button className="btn btn-light" onClick={toggleModal} style={transparent}>
                                <CloseIcon/> <small>Close</small>
                            </button>
                            {props.showThreeDotMenu ?
                                <button className="btn btn-light" onClick={() => { toggleModal(); toggleDotMenu(); }} style={transparent}>
                                    <ThreeDotsHorizontalIcon />
                                </button> : null}
                        </div>
                    </ModalHeader>
                )
            } else {
                return (
                    <ModalHeader className={props.modalHeaderClass || "pt-3"} creatorApp={props.creatorApp}>
                        <div style={{ width: "100%" }}>
                            <span>
                                <button className="close"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        toggleModal();
                                    }}
                                    style={{ padding: "0px", zIndex: 5 }}
                                >
                                    {
                                        props.creatorApp ?
                                            <CloseIcon width={20} height={20} fill={"white"} style={{ position: "relative", top: "5px" }} />
                                            :
                                            <CloseIcon width={15} height={15} opacity={1} />
                                    }

                                </button>
                            </span>
                        </div>
                    </ModalHeader>
                )
            }
        }

    }

    function showModal() {
        if (props.isOpen === true) {
            return (

                <div className={`modal fade ${props.modalCustomClass || ""} show`}
                    id={clientId}
                    role="dialog"
                    style={{ backgroundColor: "rgba(50,50,50,0.7)", display: "block", overflow: "auto" }}
                    onClick={(e) => {
                        if ((props.ignoreClickAway == null || props.ignoreClickAway === false) && e.target === e.currentTarget) {
                            props.toggleModal()
                        }
                    }}
                >

                    <div className={`modal-dialog ${props.centerModal == null || props.centerModal === true ? "modal-dialog-centered" : ""} ${props.modalDialogCustomClass || ""}`} style={props.modalDialogStyle ?? {}} >

                        <div className="modal-content" style={{ marginTop: "100px" }}>

                            {showHeader()}

                            <div className={`${props.modalBodyCustomClass} ${props.creatorApp ? "" : "modal-body"} pt-0 px-0 mt-0` || "modal-body pt-0 px-0 mt-0"} style={{margin: props.creatorApp ? "15px" : ""}}>
                                {/*<div className="container">*/}

                                {props.children}
                                {/*</div>*/}
                            </div>

                            {props.modalFooter ? props.modalFooter : null}
                        </div>

                    </div>

                </div>

            )
        }
    }


    return (
        <>
            {props.showThreeDotMenu ? <PostThreeDotMenu isOpen={showDotMenu} toggleMenu={toggleDotMenu} loggedInUser={props.loggedInUser} post={props.post} /> : null}
            {showModal()}
        </>
    )

}

Modal.propTypes = {
    id: PropTypes.string,
    isOpen: PropTypes.bool,
    modalUseCase: PropTypes.string
};

export default connect(null, {closeAllModals})(Modal);

